import React from 'react';
import { InputComponent } from 'component/Input';

export const TCPlanId = ({
  tcpFormData,
  setTcpFormData,
  errors,
}) => {
  const handleImsmaId = (value) => {
    setTcpFormData((prevFormData) => ({
      ...prevFormData,
      imsma_id: value,
    }));
  };

  return (
    <div className="flex-column-group">
      <div className={`double-text ${errors
        ? 'error'
        : ''}`}
      >
        <span>Ідентифікаційний номер IMSMA TC</span>
        <span>IMSMA ID TC Plan</span>
      </div>
      <div className="text-grey">
        <InputComponent
          id="tcp-plan-id"
          name="tcp-plan-id"
          value={tcpFormData?.imsma_id}
          handleInput={handleImsmaId}
        />
      </div>
    </div>
  );
};

export const TCPlanIdView = ({ imsmaId }) => (
  <div className="flex-column-group">
    <div className="double-text">
      <span>Ідентифікаційний номер IMSMA TC</span>
      <span>IMSMA ID TC Plan</span>
    </div>
    <div className="text-grey">
      {imsmaId}
    </div>
  </div>
);
