import { React, useEffect, useState } from 'react';
import { getLocalCommunities } from 'api/LocationAPI';
import { SelectComponent } from 'component/Select';

export const LocalCommunityFilter = (props) => {
  const [options, setOptions] = useState([]);

  const getLocalCommunityOptions = () => {
    getLocalCommunities(props.regionFilter, props.districtFilter)
      .then((res) => {
        const resOptions = res.data.map((item) => ({
          value: `${item.name} / ${item.eng_name}`,
          label: `${item.name} / ${item.eng_name}`,
        }));

        setOptions(resOptions);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (props.districtFilter) {
      getLocalCommunityOptions();
    }
  }, [props.districtFilter]);

  return (
    <div className="flex-column-group">
      <span>Sort by local community</span>
      <SelectComponent
        className="full"
        value={props.localCommunityFilter
          ? { value: props.localCommunityFilter, label: props.localCommunityFilter }
          : null}
        options={options}
        handleSelect={(e) => {
          props.setSettlementFilter(null);
          props.setLocalCommunityFilter(e);
        }}
        isMulti={false}
        isDisabled={!props.districtFilter}
      />
    </div>
  );
};
