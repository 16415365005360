import React, { useState } from 'react';
import {
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { AccordionHeaderTitle } from 'component/Accordion';
import { CommentBlock, CommentDisplay } from 'component/CommentBlock';
import { MarkingArea, MarkingAreaView } from './MarkedArea';
import { MarkingMap } from './MarkingMap';
import { Coordinates, CoordinatesView } from './Coordinates';
import { MarkingFiles, MarkingFilesView } from './Files';
import { Remarks, RemarksView } from './Remarks';
import { useDispatchWithFallback } from 'hooks/useDispatchWthFallback';

export const MarkingBlock = ({
  formData,
  dispatch:propsDispatch,
}) => {
  const [map, setMap] = useState(null);
const dispatch = useDispatchWithFallback(propsDispatch);

  return (
    <div className="rounded-bg-grey">
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="3"
            block="marking"
            titleUA={(
              <div className="flex-group">
                <span>Маркування</span>
                <span className="star" />
              </div>
            )}
            titleEN="Marking"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <MarkingArea
          formData={formData}
          dispatch={dispatch}
        />
        <MarkingMap
          formData={formData}
          map={map}
          setMap={setMap}
        />
        <div className="flex-column-group gap-40">
          <Coordinates
            formData={formData}
            dispatch={dispatch}
            map={map}
          />
          <MarkingFiles
            formData={formData}
            dispatch={dispatch}
            />
          <Remarks
            formData={formData}
            dispatch={dispatch}
            />
        </div>
      </AccordionItemPanel>
    </div>
  );
};

export const MarkingBlockView = ({ formData, mode, commentsData }) => {
  const {
    comments,
    commentForm,
    savedComment,
    commentEntered,
    onSaveComment,
    onDeleteComment,
    handleComments,
  } = commentsData;
  const [map, setMap] = useState(null);
  return (
    <div className="w-100 ">
      <div className="rounded-bg-grey">
        <AccordionItemHeading>
          <AccordionItemButton>
            <AccordionHeaderTitle
              accordionId="3"
              block="marking"
              titleUA={(
                <div className="flex-group">
                  <span>Маркування</span>
                  <span className="star" />
                </div>
              )}
              titleEN="Marking"
            />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel class="flex-column-group">
          <MarkingAreaView
            markedArea={formData?.info?.marking?.area}
          />
          <MarkingMap
            formData={formData}
            map={map}
            setMap={setMap}
          />
          <CoordinatesView
            coordinates={formData?.info?.marking?.coordinates}
            map={map}
          />
          <MarkingFilesView
            files={formData?.info?.marking?.files}
          />
          <RemarksView
            remarks={formData?.info?.marking?.remarks}
          />
        </AccordionItemPanel>
      </div>
      {mode === 'validation' && (
        <CommentBlock
          comments={comments}
          savedComment={savedComment}
          commentForm={commentForm}
          commentEntered={commentEntered}
          block="marking"
          onDeleteComment={onDeleteComment}
          handleComments={handleComments}
          onSaveComment={onSaveComment}
        />
      )}
      {mode === 'view' &&
      <CommentDisplay comments={commentsData?.comments} block="marking" />}
    </div>
  );
};
