import axios from 'axios';

export const getValidationStatuses = async (type) => {
  const url = `/reports/${type}/statuses/`;
  const response = await axios.get(url);
  return response;
};

export const getTCPFilters = async (filter) => {
  const url = `/reports/clearance/clearance-tasks/filters/choices/?choice_name=${filter}`;
  const response = await axios.get(url);
  return response;
};

export const getOptionsForClearance = async (type) => {
  const url = `/reports/clearance/clearance-tasks/filters/choices/?choice_name=${type}`;
  const response = await axios.get(url);
  return response;
};
