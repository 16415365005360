import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as PlusSvg } from 'assets/images/icons/add_24px.svg';
import { CategoryTranslations } from 'consts/Daily';
import { FileItem, FileItemView } from '../Marking/Files';
import { ADD_EO_CATEGORY_FILE, DELETE_EO_CATEGORY_FILE, UPDATE_EO_CATEGORY_FILES } from 'reducers/dailyFormActions';

export const EoCategoryFiles = ({
  dispatch, category, subCategory, formData,
}) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const existingFiles = formData?.info?.eo_found_categories?.[category]?.links
      ?.filter((link) => link.item === subCategory)
      .map((link) => ({
        item: link.item,
        name: link.name,
        link: link.link,
      })) || [];
    if (JSON.stringify(files) !== JSON.stringify(existingFiles)) {
      setFiles(existingFiles);
    }
  }, [formData?.info?.eo_found_categories?.[category]?.links, category, subCategory]);


  const updateFormData = (updatedFiles) => {
    dispatch({
      type: UPDATE_EO_CATEGORY_FILES,
      payload: {
        category,
        subCategory,
        files: updatedFiles
      }
    });
  };

  const removeFile = (index) => {
    dispatch({
      type: DELETE_EO_CATEGORY_FILE,
      payload: {
        category,
        subCategory,
        index
      }
    });
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };

  const addFile = () => {
    dispatch({
      type: ADD_EO_CATEGORY_FILE,
      payload: {
        category,
        subCategory
      }
    });
    const newFile = {
      item: subCategory,
      name: '',
      link: '',
    };
    const updatedFiles = [...files, newFile];
    setFiles(updatedFiles);
  };

  const handleFileInput = (value, key, index) => {
    const updatedFiles = files.map((file, i) => {
      if (i === index) {
        return { ...file, [key]: value };
      }
      return file;
    });
    setFiles(updatedFiles);
    updateFormData(updatedFiles);
  };


  return (
    <div className="flex-column-group">
      <div className="flex-between">
        <div className="double-text">
          <span>Файли</span>
          <span>Files</span>
        </div>
        <span className="line" />
        <div className="plus-table">
          <PlusSvg onClick={addFile} />
        </div>
      </div>
      {files?.map((file, i) => (
        <FileItem
          key={i}
          index={i}
          removeFile={removeFile}
          file={file}
          handleFileInput={(value, key) => handleFileInput(value, key, i)}
        />
      ))}
    </div>
  );
};

export const EoCategoryFilesView = ({ files, category, subCategory }) => (
  <div className="flex-column-group file-links">
    <FileList
      files={files}
      subCategory={subCategory}
      category={category}
    />
  </div>
);

const FileList = ({ files, category, subCategory }) => {
  const filteredFiles = files?.filter((file) => file.item === subCategory) || [];
  if (!filteredFiles.length) {
    return (
      <span>
        No files found in the
        {' '}
        <b>{CategoryTranslations[category][subCategory]}</b>
        {' '}
        category.
      </span>
    );
  }

  return (
    <div className="flex-column-group">
      {filteredFiles.map((file, i) => (
        <FileItemView key={i} file={file} />
      ))}
    </div>
  );
};
