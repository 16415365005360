import { AccordionBodyItem, AccordionBodyItemView } from 'component/Accordion';
import React from 'react';

export const ReporterName = ({ formData, hasError }) => (
  <AccordionBodyItem
    accordionSubId={(
      <div>
        <span>1.5</span>
        <span className="star" />
      </div>
      )}
    titleUA="Хто зтворив звіт"
    titleEN="Report creator"
    className={hasError ? 'error error-text' : ''}
    children={(
      <span className="no-wrap">
        {formData?.info?.dms?.reporter_name}
      </span>
      )}
  />
);

export const ReporterNameView = ({ reporterName }) => (
  <AccordionBodyItemView
    accordionSubId={1.5}
    titleUA="Хто зтворив звіт"
    titleEN="Report creator"
    children={reporterName || '-'}
  />
);
