import { Column, TwoMovedColumns } from 'component/TwoMovedColumns';
import React, { useEffect, useState } from 'react';

export const TeamBlock = ({
  tcpFormData,
  setTcpFormData,
  members,
  errors,
}) => {
  const [allMembers, setAllMembers] = useState(members);

  const [selectedMembers, setSelectedMembers] = useState(
    allMembers.filter((item) => tcpFormData?.related_users?.includes(item.id)),
  );

  useEffect(() => {
    setTcpFormData((prevFormData) => ({
      ...prevFormData,
      related_users: selectedMembers.map((item) => item.id),
    }));
  }, [selectedMembers]);

  return (
    <div className="rounded-bg-grey">
      <div className="title-with-line">
        <div className="title">
          <span>Створіть склад команди</span>
          <span>Select team members</span>
        </div>
        <div className="line" />
      </div>
      <TwoMovedColumns
        nameFirstColumn="All team members"
        nameSecondColumn={(
          <span className={`${errors.related_users
            ? 'error-text'
            : ''}`}
          >
            Selected members
          </span>
      )}
        contentFirstColumn={[...new Set(allMembers
          ?.filter((item) => !selectedMembers.includes(item)))]}
        contentSecondColumn={selectedMembers}
        setContentFirstColumn={setAllMembers}
        setContentSecondColumn={setSelectedMembers}
      />
    </div>
  );
};

export const TeamBlockView = ({ members }) => (
  <div className="rounded-bg-grey">
    <div className="title-with-line">
      <div className="title">
        <span>Склад команди</span>
        <span>Team members</span>
      </div>
      <div className="line" />
    </div>
    <div className="two-moved-columns">
      <Column
        content={members}
        deleteIcon={false}
      />
    </div>
  </div>
);
