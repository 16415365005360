import { TextareaComponent } from 'component/Textarea';
import React from 'react';
import { UPDATE_MARKING_REMARKS } from 'reducers/dailyFormActions';

export const Remarks = ({ formData, dispatch }) => {
  const handleRemarks = (value) => {
    dispatch({
      type: UPDATE_MARKING_REMARKS,
      payload: value
    });
  };

  return (
    <div className="flex-between">
      <div className="double-text">
        <span>Коментарі</span>
        <span>Comments</span>
      </div>
      <TextareaComponent
        value={formData?.info?.marking?.remarks}
        placeholder="Введіть коментар"
        handleTextarea={handleRemarks}
      />
    </div>
  );
};

export const RemarksView = ({ remarks }) => (
  <div className="flex-column-group">
    <div className="double-text">
      <span>Коментарі</span>
      <span>Comments</span>
    </div>
    <div>{remarks}</div>
  </div>
);
