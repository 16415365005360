import React, { useEffect, useMemo, useState } from 'react';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { AccordionHeaderTitle } from 'component/Accordion';
import { LocationComponent } from 'pages/Reports/blocks/Location/Location';
import { featureCollection, point } from '@turf/helpers';
import { convert3857To4326 } from 'utils/Helper';
import { getMethodTypes } from 'api/TCPApi';
import { RegionView } from 'pages/Reports/ViewBlocks/Location/Region';
import { DistrictView } from 'pages/Reports/ViewBlocks/Location/District';
import { LocalCommunityView } from 'pages/Reports/ViewBlocks/Location/LocalCommunity';
import { LocalityView } from 'pages/Reports/ViewBlocks/Location/Locality';
import { TaskId, TaskIdView } from './TaskId';
import { TaskType, TaskTypeView } from './TaskType';
import { NumberOfDeminers, NumberOfDeminersView } from './NumberOfDeminers';
import { Coordinates } from './Coordinates';
import { MarkingBlock, MarkingBlockView } from '../Marking/Marking';
import { ClearedArea, ClearedAreaView } from './ClearedArea';
import { EOFoundSwitcher, EOFoundSwitcherView } from './EOFoundSwitcher';
import { EOFoundBlock, EOFoundBlockView } from '../EOFound/EOFound';
import { MapBlock, MapBlockView } from './MapBlock';
import { Remarks, RemarksView } from './Remarks';
import { useDispatchWithFallback } from 'hooks/useDispatchWthFallback';
import { CommentDisplay } from 'component/CommentBlock';

export const FieldWorkBlock = ({
  formData,
  dispatch:propsDispatch,
  coords3857,
  setCoords3857,
  zone,
  setZone,
  gridGeojson,
  setGridGeojson,
  loader,
  haReportPolygon,
  errorResponse,
  errors,
  hasError,

}) => {
  const [coords4326, setCoords4326] = useState(null);
  const [methodTypes, setMethodType] = useState([]);
  const dispatch = useDispatchWithFallback(propsDispatch);
  const fieldWorkData = useMemo(() => ({
    taskType: formData?.info?.field_work?.task_type,
    teamActivity: formData?.info?.dms?.team_activity,
    clearanceTaskId: formData?.info?.field_work?.clearance_task_imsma_id
  }), [formData?.info?.field_work?.task_type,
      formData?.info?.dms?.team_activity,
      formData?.info?.field_work?.clearance_task_imsma_id]);

  useEffect(() => {
    if (coords3857) {
      const newGeojson = convert3857To4326(featureCollection([point(coords3857)], zone));
      setCoords4326(newGeojson?.features[0]?.geometry?.coordinates);
    }
  }, [coords3857]);

  useEffect(() => {
    getMethodTypes()
      .then((res) => {
        setMethodType(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (!['battle_area_clearance', 'manual_clearance', 'technical_survey'].includes(formData?.info?.field_work?.task_type)) {
      dispatch({ type: 'RESET_CLEARED_AREA' });
    }
  }, [formData?.info?.field_work?.task_type]);
  return (
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="2"
            block="fieldWork"
            titleUA={(
              <div className="flex-group">
                <span className={hasError('field_work') ? 'error-text' : ''}>Робота у полі</span>
                <span className="star" />
              </div>
            )}
            titleEN="Field work"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        {/* <TypeSwitcher
          formData={formData}
          setFormData={setFormData}
        /> */}
        <TaskId
          taskId={formData?.info?.clearance_task_imsma_id}
        />
        <TaskType
          formData={formData}
          dispatch={dispatch}
          hasError={hasError('field_work.task_type')}
        />
        {(formData.info?.field_work?.task_type === 'battle_area_clearance'
          || formData.info?.field_work?.task_type === 'manual_clearance'
          || formData.info?.field_work?.task_type === 'technical_survey')
          && (
            <>
              <ClearedArea
                formData={formData}
                dispatch={dispatch}
                hasError={hasError('field_work.cleared_area')}
              />
              <EOFoundSwitcher
                formData={formData}
                dispatch={dispatch}
              />
              {formData.info?.field_work?.eo_found && (
                <AccordionItem uuid="eoFound">
                  <EOFoundBlock
                    formData={formData}
                    dispatch={dispatch}
                    hasError={hasError('field_work.eo_found')}
                    errors={errors}
                  />
                </AccordionItem>
              )}
            </>
          )}
        {fieldWorkData.taskType === 'marking' && (
          <AccordionItem uuid="marking">
            <MarkingBlock
              formData={formData}
              dispatch={dispatch}
            />
          </AccordionItem>
        )}
        {formData.info?.field_work?.task_type !== 'marking' && (
          <MapBlock
            methodTypes={methodTypes}
            formData={formData}
            dispatch={dispatch}
            gridGeojson={gridGeojson}
            setGridGeojson={setGridGeojson}
            loader={loader}
            zone={zone}
            haReportPolygon={haReportPolygon}
          />
        )}
        {(formData.info?.field_work?.task_type === 'battle_area_clearance'
          || formData.info?.field_work?.task_type === 'manual_clearance'
          || formData.info?.field_work?.task_type === 'technical_survey') && (
            <Remarks
              formData={formData}
              dispatch={dispatch}
            />
          )}

        <NumberOfDeminers
        formData={formData}
        dispatch={dispatch}
        hasError={hasError}
        />
<LocationComponent
  formData={formData}
  dispatch={dispatch}
  errors={errors}
  fieldBlock="location"
  startSubNumber={4}
  coords3857={coords3857}
  setCoords3857={setCoords3857}
  zone={zone}
  setZone={setZone}
  hasError={hasError}
  customStyles={{
    menu: (provided, state) => ({
      ...provided,
      position: 'static',
      marginBottom: state.selectProps.menuIsOpen ? '100px' : '0',
    }),
    menuList: (provided) => ({
      ...provided,
    }),
    option: (provided, { isSelected, isFocused }) => ({
      ...provided,
    }),
  }}
  customSelectClassName="field-work-settlement-select"
  selectProps={{
    onMenuOpen: () => {
      const selectElement = document.querySelector('.field-work-settlement-select');
      if (selectElement) {
        selectElement.focus();

        const observer = new MutationObserver((mutations) => {
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth'
          });

          setTimeout(() => observer.disconnect(), 300);
        });

        observer.observe(selectElement.parentNode, {
          childList: true,
          subtree: true
        });

        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth'
        });
      }
    }
  }}
/>
        <Coordinates
          coordinates={coords4326}
        />
      </AccordionItemPanel>
    </>
  );
};

export const FieldWorkBlockView = ({
  formData,
  loader,
  gridGeojson,
  setGridGeojson,
  mode,
  zone,
  commentsData,
  haReportPolygon,
}) => {
  const [methodTypes, setMethodType] = useState([]);

  useEffect(() => {
    getMethodTypes()
      .then((res) => {
        setMethodType(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="2"
            block="fieldWork"
            titleUA={(
              <div className="flex-group">
                <span>Робота у полі</span>
                <span className="star" />
              </div>
            )}
            titleEN="Field work"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="custom-accordion-item-panel">
        <TaskIdView
          taskId={formData?.info?.field_work?.clearance_task_imsma_id}
        />
        <TaskTypeView
          taskType={formData?.info?.field_work?.task_type}
        />
      {(Array.isArray(formData.info?.field_work?.task_type) ?
    (formData.info?.field_work?.task_type.includes('battle_area_clearance')
    || formData.info?.field_work?.task_type.includes('manual_clearance')
    || formData.info?.field_work?.task_type.includes('technical_survey'))
        :
    (formData.info?.field_work?.task_type === 'battle_area_clearance'
    || formData.info?.field_work?.task_type === 'manual_clearance'
    || formData.info?.field_work?.task_type === 'technical_survey'))
        && (
            <>
              <ClearedAreaView
                clearedArea={formData?.info?.field_work?.cleared_area}
              />
              <EOFoundSwitcherView
                formData={formData}
              />
              {formData.info?.field_work?.eo_found && (
                <AccordionItem uuid="eoFound" style={{width:'100%'}}>
                <EOFoundBlockView
                  formData={formData}
                  mode={mode}
                  commentsData={commentsData}
                />
              </AccordionItem>
              )}
            </>
          )}
        {(formData.info?.field_work?.task_type === 'marking' ||
        (formData?.info?.field_work?.task_type?.includes('marking') && formData?.info?.marking)
          ) && (
          <AccordionItem uuid="marking" style={{width:'100%'}}>
            <MarkingBlockView
              formData={formData}
              haReportPolygon={haReportPolygon}
              mode={mode}
              commentsData={commentsData}
            />
          </AccordionItem>
              )}
        {(formData.info?.field_work?.task_type !== 'marking' ||
        !formData?.info?.field_work?.task_type.includes('marking')) && (
          <MapBlockView
            methodTypes={methodTypes}
            gridGeojson={gridGeojson}
            setGridGeojson={setGridGeojson}
            formData={formData}
            loader={loader}
            haReportPolygon={haReportPolygon}
            zone={zone}
            mode={mode}
          />
        )}
    {(Array.isArray(formData.info?.field_work?.task_type) ?
    (formData.info?.field_work?.task_type.includes('battle_area_clearance')
    || formData.info?.field_work?.task_type.includes('manual_clearance')
    || formData.info?.field_work?.task_type.includes('technical_survey'))
  :
    (formData.info?.field_work?.task_type === 'battle_area_clearance'
    || formData.info?.field_work?.task_type === 'manual_clearance'
    || formData.info?.field_work?.task_type === 'technical_survey'))
  && (
    <RemarksView
      remarks={formData?.info?.field_work?.remarks}
    />
  )}
        <NumberOfDeminersView
          numberOfDeminers={formData?.info?.field_work?.number_of_deminers_on_the_field || formData?.info?.field_work?.number_of_deminers }
        />
        <RegionView
          formData={formData}
          subId="2.4"
        />
        <DistrictView
          formData={formData}
          subId="2.5"
        />
        <LocalCommunityView
          formData={formData}
          subId="2.6"
        />
        <LocalityView
          formData={formData}
          subId="2.7"
        />
        {/* {formData?.info?.location?.settlement_name && (
          <CoordinatesLocationView
            coordinates={formData?.info?.location?.coordinates}
            subId="2.8"
          />
        )} */}
        {mode === 'view' &&
        <CommentDisplay comments={commentsData?.comments} block="field_work" />}
        </AccordionItemPanel>
    </>
  );
};
