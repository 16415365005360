import { AccordionHeaderTitle } from 'component/Accordion';
import { DonorView } from 'pages/Reports/ViewBlocks/GeneralInfo/Donor';
import { IMSMAIDView } from 'pages/Reports/ViewBlocks/GeneralInfo/IMSMAID';
import { IMSMASwitcherView } from 'pages/Reports/ViewBlocks/GeneralInfo/IMSMASwitcher';
import { InternalHazardIdView } from 'pages/Reports/ViewBlocks/GeneralInfo/InternalHazardId';
import { ReportDateView } from 'pages/Reports/ViewBlocks/GeneralInfo/ReportDate';
import { ReporterOccupationView } from 'pages/Reports/ViewBlocks/GeneralInfo/ReporterOccupation';
import { ReporterOrganizationView } from 'pages/Reports/ViewBlocks/GeneralInfo/ReporterOrganization';
import { ReportProviderView } from 'pages/Reports/ViewBlocks/GeneralInfo/ReportProvider';
import { TeamIdView } from 'pages/Reports/ViewBlocks/GeneralInfo/TeamId';
import React from 'react';
import {
  AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel,
} from 'react-accessible-accordion';
import { CommentDisplay } from 'component/CommentBlock';
import { useUser } from 'routes/UserContext';
import { ReportDateWithSaveButton } from '../../Create/GeneralInfo/ReportDate';

const GeneralInfo = ({
  formData,
  setFormData,
  mode,
  comments,
}) => {
  const { user } = useUser();
  return (
    <AccordionItem uuid="general">
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="1"
            titleUA="Загальна інформація"
            titleEN="General Information"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="custom-accordion-item-panel">
        <InternalHazardIdView formData={formData} />
        <IMSMAIDView formData={formData} />
        <IMSMASwitcherView formData={formData} accordionSubId="1.3" />
        {mode === 'check' || !user?.permissions?.change_eore_report_date || mode === 'validation'
          ? <ReportDateView formData={formData} />
          : (
            <ReportDateWithSaveButton
              formData={formData}
              setFormData={setFormData}
            />
          )}
        <ReporterOrganizationView formData={formData} />
        <ReportProviderView formData={formData} accordionSubId="1.6" />
        <TeamIdView formData={formData} />
        <ReporterOccupationView
          formData={formData}
        // translatedFormData={translatedFormData}
        // translateState={translateState}
        />
        <DonorView formData={formData} />
        {mode === 'check' && <CommentDisplay comments={comments} block="general" />}
      </AccordionItemPanel>
    </AccordionItem>
  );
};

export default GeneralInfo;
