import axios from 'axios';
import { React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { featureCollection } from '@turf/helpers';
import { Loader } from 'component/Loader';
import { LogoFSD } from 'component/Logo';
import { Map } from 'component/Map';
import ntsPoint from 'assets/images/icons/ntsPoint.png';
import { useMapbox } from 'utils/MapboxContext';
import { MainDonorInfo } from './MainDonorInfo';
import { MapDonorPopup } from './MapDonorPopup';

export const DonorMapPage = () => {
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [map, setMap] = useState(null);
  const [loader, setLoader] = useState(true);

  const [mapboxToken, setMapboxToken] = useState(null);
  const [mainInfo, setMainInfo] = useState(null);
  const [donorName, setDonorName] = useState(null);

  const [lmGeojson, setLmGeojson] = useState(null);
  const [bmGeojson, setBmGeojson] = useState(null);
  const [evidenceGeojson, setEvidenceGeojson] = useState(null);
  const [indirectEvidenceGeojson, setIndirectEvidenceGeojson] = useState(null);
  const [turnPointsPolygon, setTurnPointsPolygon] = useState(null);
  const [resetFilter, setResetFilter] = useState(false);

  const [ntsPoints, setNtsPoints] = useState(featureCollection([]));

  const [hazardAreaTypes, setHazardAreaTypes] = useState([]);
  const [hazardTypes, setHazardTypes] = useState([]);
  const { setToken } = useMapbox();

  useEffect(() => {
    if (!map) {
      setToken(mapboxToken);
    }
  }, [map, mapboxToken]);

  const getDonorToken = () => {
    const parts = window.location.pathname.split('/');
    const lastPart = parts[parts.length - 1];
    return lastPart;
  };

  const getDonorGeojson = () => {
    const dateToParam = dateTo ? `&date_to=${dateTo}` : '';
    const dateFromParam = dateFrom ? `&date_from=${dateFrom}` : '';
    let hazardAreaTypesParam = '';
    hazardAreaTypes.forEach((type) => {
      hazardAreaTypesParam += `&hazard_area_type=${type}`;
    });

    let hazardTypesParam = '';
    hazardTypes.forEach((type) => {
      hazardTypesParam += `&hazard_type=${type}`;
    });
    const url = `/donors/${getDonorToken()}/geojson/${dateFromParam}${dateToParam}${hazardAreaTypesParam}${hazardTypesParam}`;
    axios.get(url.replace(/&/, '?'))
      .then((res) => {
        setMapboxToken(res.data.mapbox_token);
        setMainInfo(res.data);
        setLmGeojson(res.data.ha_reports.lm);
        setBmGeojson(res.data.ha_reports.bm);
        setEvidenceGeojson(res.data.ha_reports.direct_evidences);
        setIndirectEvidenceGeojson(res.data.ha_reports.indirect_evidences);
        setTurnPointsPolygon(res.data.ha_reports.polygon);
        setNtsPoints(res.data.nts_reports.polygon);
        setDonorName(res.data.ha_reports.donor_name);
        setLoader(false);
      })
      .catch((err) => {
        console.error(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    if (resetFilter) {
      getDonorGeojson();
      setResetFilter(false);
    }
  }, [resetFilter]);

  useEffect(() => {
    getDonorGeojson();
    document.title = 'Donor map';
  }, []);

  const addNtsGeojson = () => {
    if (!map.getSource('ntsSource')) {
      map.addSource('ntsSource', {
        type: 'geojson',
        data: ntsPoints,
        generateId: true,
      });

      [{ 'nts-icon': ntsPoint }].forEach((icon) => {
        map.loadImage(Object.values(icon)[0], (error, image) => {
          if (error) throw error;
          map.addImage(Object.keys(icon)[0], image);
        });
      });

      map.addLayer({
        id: 'ntsSource-layer',
        type: 'symbol',
        source: 'ntsSource',
        layout: {
          'icon-image': 'nts-icon',
          'icon-size': 1,
          'icon-offset': [0, 0],
          'icon-allow-overlap': true,
        },
        filter: ['==', '$type', 'Point'],
      });
    } else {
      map.getSource('ntsSource').setData(ntsPoints);
    }
  };

  useEffect(() => {
    if (!map) return;
    addNtsGeojson();
  }, [map, ntsPoints]);

  return (
    <>
      <div className="horizontal-nav">
        <Link to="/dashboard">
          <LogoFSD type="white" text="on" />
        </Link>
      </div>
      <Map
        map={map}
        setMap={setMap}
        height="full-public"
        lmGeojson={lmGeojson}
        bmGeojson={bmGeojson}
        evidenceGeojson={evidenceGeojson}
        indirectEvidenceGeojson={indirectEvidenceGeojson}
        turnPointsPolygon={turnPointsPolygon}
        typeInFeature
        googleWms
      />
      <MainDonorInfo
        mainInfo={mainInfo}
        dateTo={dateTo}
        dateFrom={dateFrom}
        setDateTo={setDateTo}
        setDateFrom={setDateFrom}
        updateGeojson={getDonorGeojson}
        setLoader={setLoader}
        setResetFilter={setResetFilter}
        donorName={donorName}
        setHazardAreaTypes={setHazardAreaTypes}
        setHazardTypes={setHazardTypes}
        hazardAreaTypes={hazardAreaTypes}
        hazardTypes={hazardTypes}
      />
      <MapDonorPopup
        map={map}
        setLoader={setLoader}
      />
      <Loader visible={loader} />
      <ToastContainer />
    </>
  );
};
