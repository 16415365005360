import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { SelectComponent } from 'component/Select';
import { Table, TableHeader, TableRow } from 'component/Table';
import { Map } from 'component/Map';
import { LocationIcon } from 'component/LocationIcon';
import { SwapEvidenceIcon } from 'component/SwapEvidenceIcon';
import { TextareaComponent } from 'component/Textarea';
import { ReactComponent as RedirectIcon } from 'assets/images/icons/change.svg';
import { ImageGalleryBlock } from '../ImageGallery';
import { directEvidencesTypes } from '../../../../consts/Evidences';

export const CoordinatesDirectEvidence = (props) => {
  const evidences = props.formData?.info?.evidences?.direct_evidences;

  const handleEvidenceType = (index, value) => {
    props.setFormData((prevFormData) => {
      const updatedDirectEvidences = {
        ...prevFormData.info?.evidences?.direct_evidences,
        items: prevFormData.info?.evidences?.direct_evidences.items
          .map((item, i) => (i === index ? { ...item, evidence_type: value } : item)),
      };
      return {
        ...prevFormData,
        info: {
          ...prevFormData?.info,
          evidences: {
            ...prevFormData?.info?.evidences,
            direct_evidences: updatedDirectEvidences,
          },
        },
      };
    });
  };

  const handleEvidenceDescription = (index, value) => {
    props.setFormData((prevFormData) => {
      const updatedDirectEvidences = {
        ...prevFormData.info.evidences.direct_evidences,
        items: prevFormData.info.evidences.direct_evidences.items.map(
          (item, i) => (i === index ? { ...item, description: value } : item),
        ),
      };
      return {
        ...prevFormData,
        info: {
          ...prevFormData.info,
          evidences: {
            ...prevFormData.info.evidences,
            direct_evidences: updatedDirectEvidences,
          },
        },
      };
    });
  };

  // eslint-disable-next-line react/no-unstable-nested-components, no-shadow
  const TypeOfEvidences = (props) => {
    const options = evidences?.evidence_types?.map((item) => ({
      value: item,
      label: directEvidencesTypes[item],
    }));
    return (
      <SelectComponent
        options={options}
        value={{
          label: directEvidencesTypes[props?.evidence?.evidence_type],
          value: props.evidence.evidence_type,
        }}
        handleSelect={(value) => handleEvidenceType(props.itemKey, value)}
      />
    );
  };

  return (
    <div className="flex-column-group">
      <AccordionBodyItem
        bgStyle={props.bgStyle}
        accordionSubId="4.2"
        titleUA="Координати прямих доказів"
        titleEN="Direct evidence coordinates"
        children={(
          <div className="flex-column">
            <span
              className="text-hover no-wrap"
              onClick={() => props.handleTabChange(1)}
            >
              Gis redirect
              <RedirectIcon />
            </span>
          </div>
        )}
      />
      <div className="accordion-body-wrapper bgGrey">
        <Table className="table-lines-between">
          <TableHeader>
            <th className="no-border-radius double-text">
              <div>Тип доказу</div>
              <div>Type of evidence</div>
            </th>
            <th aria-label="line"><div className="line" /></th>
            <th>Long/UTM X</th>
            <th aria-label="line"><div className="line" /></th>
            <th>Lat/UTM Y</th>
            <th aria-label="line"><div className="line" /></th>
            <th className="no-border-radius">
              Опис\ Description
            </th>
          </TableHeader>
          <tbody>
            {!evidences?.items?.length
              && (
                <TableRow>
                  <td className="empty">Empty</td>
                </TableRow>
              )}
            {evidences && evidences?.items?.map((evidence, index) => (
              <TableRow key={index}>
                <td className="flex-group">
                  <span>
                    4.2.
                    {index + 1}
                    <span className="star">*</span>
                  </span>
                  <TypeOfEvidences
                    evidence={evidence}
                    tab={props.tab}
                    itemKey={index}
                  />
                </td>
                <td aria-label="line"><div className="line" /></td>
                <td>{evidence.long?.toFixed(0)}</td>
                <td aria-label="line"><div className="line" /></td>
                <td>{evidence.lat?.toFixed(0)}</td>
                <td aria-label="line"><div className="line" /></td>
                <td
                  className="flex-group"
                  aria-label="textarea"
                >
                  <TextareaComponent
                    id={index}
                    value={evidence.description}
                    handleTextarea={(value) => handleEvidenceDescription(index, value)}
                    limitChar
                    blueLink
                  />
                </td>
                <td>
                  <div className="evidence-icons">
                    <LocationIcon
                      coords={[Number(`${evidence.long}`), Number(`${evidence.lat}`)]}
                      map={props.map}
                      id={index}
                      source="evidence"
                      zone={props.formData.file_zone}
                    />
                    <ImageGalleryBlock
                      formData={props.formData}
                      photos={props.photos}
                      setPhotos={props.setPhotos}
                      loader={props.loader}
                      setLoader={props.setLoader}
                      dependency="direct_evidence"
                      item={index + 1}
                      setLoaderPhotos={props.setLoaderPhotos}
                    />
                    {(evidence.lat !== 0 && evidence.long !== 0)
                      && (
                        <SwapEvidenceIcon
                          formData={props.formData}
                          setFormData={props.setFormData}
                          photos={props.photos}
                          setPhotos={props.setPhotos}
                          evidenceIndex={index}
                          evidenceTypeFrom="direct_evidences"
                          evidenceTypeTo="indirect_evidences"
                          zone={props.formData.file_zone}
                        />
                      )}
                  </div>
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </div>

      <Map
        id="direct-map"
        map={props.map}
        setMap={props.setMap}
        turnPointsPolygon={props.formData.info.turn_points.geojson}
        evidenceGeojson={props.formData.info.evidences.evidence_geojson}
        hazardAreaType={props.formData.info.general.hazard_area_type}
        evidences={props.formData.info.evidences}
        googleWms
      />
    </div>
  );
};
