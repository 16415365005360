import { SelectComponent } from 'component/Select';
import React, { useState } from 'react';

export const MethodType = ({
  options,
  tcpFormData,
  setTcpFormData,
  errors,
  showValidation
}) => {
  const [selectedOptions, setSelectedOptions] = useState(options?.filter((item) => tcpFormData
    ?.methods?.includes(item.value)));

  const handleMethodType = (value) => {
    setTcpFormData((prevFormData) => ({
      ...prevFormData,
      methods: Object.values(value)?.map((item) => item.value),
    }));
    setSelectedOptions(value);
  };

  return (
    <div className="flex-column-group">
      <div className={`double-text ${(showValidation && errors) ? 'error' : ''}`}>
        <span>Тип методу</span>
        <span>Method Type</span>
      </div>
      <div className="text-grey">
        <SelectComponent
          id="method-type"
          name="method-type"
          isMulti
          value={selectedOptions}
          options={options}
          handleSelect={handleMethodType}
          error={showValidation && errors}
          errorText={showValidation && errors ? errors : ''}
        />
      </div>
    </div>
  );
};

export const MethodTypeView = ({ methodTypes }) => (
  <div className="flex-column-group">
    <div className="double-text">
      <span>Тип методу</span>
      <span>Method Type</span>
    </div>
    <div className="text-grey">
      {methodTypes?.map((method, index) => (
        <div key={index}>{method.name}</div>
      ))}
    </div>
  </div>
);
