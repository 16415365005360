import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { getMethodTypes, getRelatedUsers } from 'api/TCPApi';
import { feature, featureCollection } from '@turf/helpers';
import { CommentBlock, CommentDisplay } from 'component/CommentBlock';
import { HABlock, HABlockView } from './HABlock/HABlock';
import { TCPBlock, TCPBlockView } from './TCPBlock/TCPBlock';
import { TeamBlock, TeamBlockView } from './TeamBlock/TeamBlock';
import { GisBlock, GisBlockView } from './GISBlock/GisBlock';
import { ProgressMapBlock, ProgressMapBlockView } from './ProgressMapBlock/ProgressMapBlock';
import { FilesBlock, FilesBlockView } from './FilesBlock/FilesBlock';

export const TCPFormTabs = ({
  activeTabIndex,
  setActiveTabIndex,
  formData,
  setFormData,
  map,
  setMap,
  turnPointsFiles,
  setTurnPointsFiles,
  filesLinks,
  setFilesLinks,
  crosshair,
  setCrosshair,
  turnPointsFromShape,
  setTurnPointsFromShape,
  tcpFormData,
  setTcpFormData,
  errors,
  showFileValidation,
  showTCPValidation,
}) => {
  const [members, setMembers] = useState([]);
  const [gisLinks, setGisLinks] = useState(tcpFormData?.deminers_files_links || []);
  const [methodOptions, setMethodOptions] = useState(null);
  useEffect(() => {
    getMethodTypes()
      .then((res) => {
        const resOptions = res.data?.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setMethodOptions(resOptions);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    getRelatedUsers()
      .then((res) => {
        const resOptions = res.data?.map((item) => {
          let userName = '';
          if (!item.name && !item.surname) {
            userName = 'No name';
          } else {
            userName = `${item.name || '-'} ${item.surname || '-'}`;
          }
          return ({
            id: item.id,
            value: userName,
          });
        });
        setMembers(resOptions);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (tcpFormData.id) {
      setGisLinks(tcpFormData?.deminers_files_links);
    }
  }, [tcpFormData?.id]);

  useEffect(() => {
    if (!turnPointsFromShape) return;
    setTcpFormData((prev) => ({
      ...prev,
      deminers_polygon: turnPointsFromShape?.features[0]?.geometry,
    }));
  }, [turnPointsFromShape]);
  return (
    <Tabs
      selectedIndex={activeTabIndex}
      onSelect={(index) => {
        if (!formData?.id) {
          toast.error('You cannot change the tab without selecting the HA report');
          return;
        }

        setActiveTabIndex(index);
      }}
    >
      <TabList>
        <Tab>1. HA</Tab>
        <Tab>
          <span className={
            (['donor', 'methods', 'estimate_days', 'internal_id', 'imsma_id']
              .some((field) => errors[field]))
              ? 'error-text'
              : ''
          }
          >
            2. TCP
          </span>
        </Tab>
        <Tab>
          <span className={
            (Array.isArray(errors) && errors?.includes('No related users assigned'))
              || errors?.related_users
              ? 'error-text'
              : ''
          }
          >
            3. Team
          </span>
        </Tab>
        <Tab>
          <span className={
            (Array.isArray(errors) && errors?.includes('Cannot parse polygon'))
              || errors?.deminers_polygon
              ? 'error-text'
              : ''
          }
          >
            4. GIS
          </span>
        </Tab>
        <Tab>
          <span className={`${errors?.crosshair
            ? 'error-text'
            : ''}`}
          >
            5. Progress Map
          </span>
        </Tab>
        <Tab>
          <span className={`${errors?.pdf_file_link
            ? 'error-text'
            : ''}`}
          >
            6. Files
          </span>
        </Tab>
      </TabList>
      <TabPanel>
        <HABlock
          formData={formData}
          setFormData={setFormData}
          map={map}
          setMap={setMap}
        />
      </TabPanel>
      <TabPanel>
        <TCPBlock
          tcpFormData={tcpFormData}
          setTcpFormData={setTcpFormData}
          methodOptions={methodOptions}
          errors={errors}
          showValidation={showTCPValidation}
        />
      </TabPanel>
      <TabPanel>
        <TeamBlock
          tcpFormData={tcpFormData}
          setTcpFormData={setTcpFormData}
          members={members}
          setMembers={setMembers}
          errors={errors}
        />
      </TabPanel>
      <TabPanel>
        <GisBlock
          turnPointsFiles={turnPointsFiles}
          setTurnPointsFiles={setTurnPointsFiles}
          map={map}
          setMap={setMap}
          turnPointsFromShape={turnPointsFromShape}
          setTurnPointsFromShape={setTurnPointsFromShape}
          gisLinks={gisLinks}
          setGisLinks={setGisLinks}
          setTcpFormData={setTcpFormData}
          errors={errors}
        />
      </TabPanel>
      <TabPanel>
        <ProgressMapBlock
          tcpFormData={tcpFormData}
          geojson={formData?.info?.turn_points?.geojson}
          hazardAreaType={formData?.info?.general?.hazard_area_type}
          crosshair={crosshair}
          setCrosshair={setCrosshair}
          errors={errors}
        />
      </TabPanel>
      <TabPanel>
        <FilesBlock
          links={filesLinks}
          setLinks={setFilesLinks}
          setTcpFormData={setTcpFormData}
          errors={errors}
          showValidation={showFileValidation}
        />
      </TabPanel>
    </Tabs>
  );
};

export const TCPFormTabsView = ({
  activeTabIndex,
  setActiveTabIndex,
  formData,
  setFormData,
  map,
  setMap,
  filesLinks,
  tcpFormData,
  mode,
  commentsData,
}) => {
  const [members, setMembers] = useState([]);
  const [gisLinks, setGisLinks] = useState(null);
  const [crosshair, setCrosshair] = useState(null);
  const [turnPointsFromShape, setTurnPointsFromShape] = useState(null);

  const {
    comments,
    commentForm,
    savedComment,
    commentEntered,
    onSaveComment,
    onDeleteComment,
    handleComments,
    blocks,
  } = commentsData;

  useEffect(() => {
    if (!tcpFormData?.id) return;
    setGisLinks(tcpFormData?.deminers_files_links);
    setCrosshair(tcpFormData?.crosshair);
    setTurnPointsFromShape(featureCollection([feature(tcpFormData?.deminers_polygon)]));
  }, [tcpFormData?.id]);

  useEffect(() => {
    const relatedUsers = tcpFormData?.related_users.map((item) => {
      let userName = '';
      if (!item.name && !item.surname) {
        userName = 'No name';
      } else {
        userName = `${item.name || '-'} ${item.surname || '-'}`;
      }
      return ({
        id: item.id,
        value: userName,
      });
    });
    setMembers(relatedUsers || []);
  }, [tcpFormData?.id]);

  return (tcpFormData?.id
    && (
      <Tabs
        selectedIndex={activeTabIndex}
        onSelect={(index) => {
          setActiveTabIndex(index);
        }}
      >
        <TabList>
          <Tab>1. HA</Tab>
          <Tab>2. TCP</Tab>
          <Tab>3. Team</Tab>
          <Tab>4. GIS</Tab>
          <Tab>5. Progress Map</Tab>
          <Tab>6. Files</Tab>
        </TabList>
        <TabPanel>
          <div className="flex-column-group">
            <HABlockView
              formData={formData}
              setFormData={setFormData}
              map={map}
              setMap={setMap}
            />
            {mode === 'validation' && (
              <CommentBlock
                comments={comments}
                savedComment={savedComment}
                commentForm={commentForm}
                commentEntered={commentEntered}
                block={blocks[0]}
                onDeleteComment={onDeleteComment}
                handleComments={handleComments}
                onSaveComment={onSaveComment}
              />
            )}
            {mode === 'view' && (
              <CommentDisplay comments={commentsData?.comments} block="ha" />
            )}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="flex-column-group">
            <TCPBlockView
              tcpFormData={tcpFormData}
            />
            {mode === 'validation' && (
              <CommentBlock
                comments={comments}
                savedComment={savedComment}
                commentForm={commentForm}
                commentEntered={commentEntered}
                block={blocks[1]}
                onDeleteComment={onDeleteComment}
                handleComments={handleComments}
                onSaveComment={onSaveComment}
              />
            )}
            {mode === 'view' && (
              <CommentDisplay comments={commentsData?.comments} block="tcp" />
            )}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="flex-column-group">
            <TeamBlockView
              members={members}
              tcpFormData={tcpFormData}
            />
            {mode === 'validation' && (
              <CommentBlock
                comments={comments}
                savedComment={savedComment}
                commentForm={commentForm}
                commentEntered={commentEntered}
                block={blocks[2]}
                onDeleteComment={onDeleteComment}
                handleComments={handleComments}
                onSaveComment={onSaveComment}
              />
            )}
            {mode === 'view' && (
              <CommentDisplay comments={commentsData?.comments} block="team" />
            )}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="flex-column-group">
            <GisBlockView
              map={map}
              setMap={setMap}
              turnPointsFromShape={turnPointsFromShape}
              links={gisLinks}
            />
            {mode === 'validation' && (
              <CommentBlock
                comments={comments}
                savedComment={savedComment}
                commentForm={commentForm}
                commentEntered={commentEntered}
                block={blocks[3]}
                onDeleteComment={onDeleteComment}
                handleComments={handleComments}
                onSaveComment={onSaveComment}
              />
            )}
            {mode === 'view' && (
              <CommentDisplay comments={commentsData?.comments} block="gis" />
            )}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="flex-column-group">
            <ProgressMapBlockView
              geojson={formData?.info?.turn_points?.geojson}
              hazardAreaType={formData?.info?.general?.hazard_area_type}
              crosshair={crosshair}
              setCrosshair={setCrosshair}
              mode="view"
            />
            {mode === 'validation' && (
              <CommentBlock
                comments={comments}
                savedComment={savedComment}
                commentForm={commentForm}
                commentEntered={commentEntered}
                block={blocks[4]}
                onDeleteComment={onDeleteComment}
                handleComments={handleComments}
                onSaveComment={onSaveComment}
              />
            )}
            {mode === 'view' && (
              <CommentDisplay comments={commentsData?.comments} block="progress_map" />
            )}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="flex-column-group">
            <FilesBlockView
              links={filesLinks}
            />
            {mode === 'validation' && (
              <CommentBlock
                comments={comments}
                savedComment={savedComment}
                commentForm={commentForm}
                commentEntered={commentEntered}
                block={blocks[5]}
                onDeleteComment={onDeleteComment}
                handleComments={handleComments}
                onSaveComment={onSaveComment}
              />
            )}
            {mode === 'view' && (
              <CommentDisplay comments={commentsData?.comments} block="files" />
            )}
          </div>
        </TabPanel>
      </Tabs>
    )
  );
};
