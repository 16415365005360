import { AccordionHeaderTitle } from 'component/Accordion';
import React from 'react';
import {
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { DonorView } from 'pages/Reports/ViewBlocks/GeneralInfo/Donor';
import { Donor } from 'pages/Reports/blocks/GeneralInfo/Donor';
import { ReportDate, ReportDateView } from './ReportDate';
import { Organization, OrganizationView } from './Organization';
import { TeamId, TeamIdView } from './TeamId';
import { ReporterName, ReporterNameView } from './ReporterName';
import { ReporterPosition, ReporterPositionView } from './ReporterPosition';
import { TeamActivity, TeamActivityView } from './TeamActivity';
import { Remarks, RemarksView } from './Remarks';
import { DailyDonor } from './Donor';
import { CommentDisplay } from 'component/CommentBlock';

export const DMSBlock = ({ formData, dispatch, hasError }) => (
  <>
    <AccordionItemHeading>
      <AccordionItemButton>
        <AccordionHeaderTitle
          accordionId="1"
          block="dms"
          titleUA={(
            <div className="flex-group">
              <span className={hasError('dms') ? 'error-text' : ''}>DMS</span>
              <span className="star">*</span>
            </div>
          )}
          titleEN="Clearance daily"
        />
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
      <ReportDate
        date={formData?.info?.dms?.report_date}
        hasError={hasError('dms.report_date')}
      />
      <Organization
        formData={formData}
        dispatch={dispatch}
        hasError={hasError('dms.organization')}
      />
      {formData?.info?.donor && (
        <DailyDonor
          donorId={formData?.info?.donor}
          subId="1.3"
          hasError={hasError('dms.donor')}
        />
      )}
      <TeamId
        formData={formData}
        dispatch={dispatch}
        hasError={hasError('dms.team_id')}
      />
      <ReporterName
        formData={formData}
        hasError={hasError('dms.reporter_name')}
      />
      <ReporterPosition
        formData={formData}
        hasError={hasError('dms.reporter_position')}
      />
      <TeamActivity
        formData={formData}
        dispatch={dispatch}
        hasError={hasError('dms.team_activity')}
      />
      {formData?.info?.dms?.team_activity === 'maintance_day' && (
        <Remarks
          formData={formData}
          dispatch={dispatch}
          hasError={hasError('dms.remarks')}
        />
      )}
    </AccordionItemPanel>
  </>
);

export const DMSBlockView = ({ formData,commentsData,mode}) => (
  <>
    <AccordionItemHeading>
      <AccordionItemButton>
        <AccordionHeaderTitle
          accordionId="1"
          block="dms"
          titleUA={(
            <div className="flex-group">
              <span>DMS</span>
              <span className="star" />
            </div>
          )}
          titleEN="Clearance daily"
        />
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel className="custom-accordion-item-panel">
      <ReportDateView
        date={formData?.info?.dms?.report_date}
      />
      <OrganizationView
        organization={formData?.info?.dms?.organization}
      />
      <DonorView
        formData={formData}
        subId="1.3"
      />
      <TeamIdView
        teamId={formData?.info?.dms?.team_id}
      />
      <ReporterNameView
        reporterName={formData?.info?.dms?.reporter_name}
      />
      <ReporterPositionView
        reporterPosition={formData?.info?.dms?.reporter_position}
      />
      <TeamActivityView
        teamActivity={formData?.info?.dms?.team_activity}
      />
      {(formData?.info?.dms?.team_activity === 'maintance_day' || formData?.info?.dms?.team_activity?.includes('maintance_day')) && (
        <RemarksView
          remarks={formData?.info?.dms?.remarks}
        />
      )}
        {mode === 'view' &&
          <CommentDisplay comments={commentsData?.comments} block="dms" />}
    </AccordionItemPanel>
  </>
);
