import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'assets/styles/pages/task-clearance-plan.scss';
import { Accordion, AccordionItem } from 'react-accessible-accordion';
import { TCPNavigationStatuses } from 'consts/TCP';
import { useValidationComments } from 'hooks/useValidationComments';
import { Loader } from 'component/Loader';
import { REPORT_TYPES } from 'consts/ReportValidation';
import { useDailyFormManagement } from 'hooks/useDailyFormManagement';
import { NavigationTabLinks } from '../../Tabs/NavigationLinks';
import { DMSBlockView } from '../Blocks/DMSBlock/DMSBlock';
import { FieldWorkBlockView } from '../Blocks/FieldWorkBlock/FieldWorkBlock';

export const DailyViewPage = () => {
  const { clearanceId, dailyId } = useParams();
  const [navigationStatuses, setNavigationStatuses] = useState(TCPNavigationStatuses);
  const validationComments = useValidationComments(REPORT_TYPES.DAILY, dailyId);

  const {
    formData,
    loader,
    gridGeojson,
    haReport,
    mapLoader,
    coords3857,
    zone,
    setGridGeojson,
    dispatch
  } = useDailyFormManagement(clearanceId, dailyId);


  useEffect(() => {
    setNavigationStatuses((prev) => ({
      ...prev,
      tcpCreate: 'completed',
      daily: 'active',
      weekly: 'inactive',
      completed: 'inactive',
      done: 'inactive',
    }));
    document.title = 'View daily';
  }, []);

  return (
    <section>
      <div className="flex-column-group">
        <NavigationTabLinks
          statuses={navigationStatuses}
          mode="view"
          id={clearanceId}
        />
        <DailyViewForm
          formData={formData}
          dispatch={dispatch}
          coords3857={coords3857}
          haReportPolygon={haReport?.info?.turn_points?.geojson}
          zone={zone}
          setGridGeojson={setGridGeojson}
          gridGeojson={gridGeojson}
          mapLoader={mapLoader}
          commentsData={validationComments}
        />
      </div>
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};

const DailyViewForm = ({
  formData,
  dispatch,
  coords3857,
  zone,
  gridGeojson,
  haReportPolygon,
  mapLoader,
  commentsData,
  setGridGeojson,
}) => (
  <div className="daily-form">
    <div className="form-container flex-column-group">
      <DailyFormHeader />
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        preExpanded={[
          'dms', 'fieldWork', 'marking', 'battle-area-clearance', 'eoFound']}
        className="custom-accordion"
      >
        <AccordionItem uuid="dms">
          <DMSBlockView
            formData={formData}
            dispatch={dispatch}
            commentsData={commentsData}
            mode="view"
          />
        </AccordionItem>
        {formData?.info?.dms?.team_activity === 'field_work' && (
          <AccordionItem uuid="fieldWork">
            <FieldWorkBlockView
            formData={formData}
            dispatch={dispatch}
            setGridGeojson={setGridGeojson}
            haReportPolygon={haReportPolygon}
            coords3857={coords3857}
            zone={zone}
            gridGeojson={gridGeojson}
            loader={mapLoader}
            mode="view"
            commentsData={commentsData}
            />
          </AccordionItem>
        )}
      </Accordion>
    </div>
  </div>
);

const DailyFormHeader = () => (
  <div className="form-header">
    <span>View a daily</span>
    <div className="line" />
  </div>
);
