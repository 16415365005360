export const SET_TEAM_ACTIVITY = 'SET_TEAM_ACTIVITY';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_ZONE = 'SET_ZONE';
export const SET_HA_REPORT = 'SET_HA_REPORT';
export const SET_LOADER = 'SET_LOADER';
export const SET_COORDS = 'SET_COORDS';
export const SET_GRID_GEOJSON = 'SET_GRID_GEOJSON';
export const SET_TCP_DATA = 'SET_TCP_DATA';
export const SET_FORM_DATA = 'SET_FORM_DATA';
export const SET_EO_FOUND = 'SET_EO_FOUND';
export const SET_DRAFT_STATUS = 'SET_DRAFT_STATUS';
export const SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS';
export const SET_CATEGORY_CHECKED = 'SET_CATEGORY_CHECKED';
export const INIT_CATEGORY_DATA = 'INIT_CATEGORY_DATA';
export const SET_LOCATION_FROM_HA = 'SET_LOCATION_FROM_HA';

export const ADD_MARKING_FILE = 'ADD_MARKING_FILE';
export const ADD_MARKING_COORDINATE = 'ADD_MARKING_COORDINATE';
export const ADD_EO_CATEGORY_FILE = 'ADD_EO_CATEGORY_FILE';
export const ADD_HANDOVER_FILE = 'ADD_HANDOVER_FILE';


export const RESET_CLEARED_AREA = 'RESET_CLEARED_AREA';

export const TOGGLE_EO_CATEGORY_ITEM = 'TOGGLE_EO_CATEGORY_ITEM';
export const TOGGLE_HAND_OVER = 'TOGGLE_HAND_OVER';


export const UPDATE_FIELD = 'UPDATE_FIELD';
export const UPDATE_DMS_FIELD = 'UPDATE_DMS_FIELD';
export const UPDATE_TASK_TYPE = 'UPDATE_TASK_TYPE';
export const UPDATE_CLEARED_AREA = 'UPDATE_CLEARED_AREA';
export const UPDATE_EO_FOUND_CATEGORIES = 'UPDATE_EO_FOUND_CATEGORIES';
export const UPDATE_REMARKS = 'UPDATE_REMARKS';
export const UPDATE_NUMBER_OF_DEMINERS = 'UPDATE_NUMBER_OF_DEMINERS';
export const UPDATE_LOCATION_FIELD = 'UPDATE_LOCATION_FIELD';
export const UPDATE_LOCATION_POINT = 'UPDATE_LOCATION_POINT';
export const UPDATE_MARKING_AREA = 'UPDATE_MARKING_AREA';
export const UPDATE_MARKING_COORDINATES = 'UPDATE_MARKING_COORDINATES';
export const UPDATE_MARKING_FILES = 'UPDATE_MARKING_FILES';
export const UPDATE_MARKING_REMARKS = 'UPDATE_MARKING_REMARKS';
export const UPDATE_HAND_OVER = 'UPDATE_HAND_OVER';
export const UPDATE_CURRENT_DATE = 'UPDATE_CURRENT_DATE';
export const UPDATE_CATEGORY_EVIDENCES = 'UPDATE_CATEGORY_EVIDENCES';
export const UPDATE_EO_CATEGORY_FILES = 'UPDATE_EO_CATEGORY_FILES';
export const UPDATE_EVIDENCE_COORDINATES = 'UPDATE_EVIDENCE_COORDINATES';
export const UPDATE_EVIDENCE_SWITCHER = 'UPDATE_EVIDENCE_SWITCHER';
export const UPDATE_EVIDENCE_UNIT = 'UPDATE_EVIDENCE_UNIT';
export const UPDATE_HAND_OVER_PERSON = 'UPDATE_HAND_OVER_PERSON';
export const UPDATE_HAND_OVER_REMARKS = 'UPDATE_HAND_OVER_REMARKS';
export const UPDATE_HANDOVER_FILE = 'UPDATE_HANDOVER_FILE';
export const UPDATE_CATEGORY_ITEMS_AND_EVIDENCES = 'UPDATE_CATEGORY_ITEMS_AND_EVIDENCES';
export const UPDATE_CATEGORY_DATA = 'UPDATE_CATEGORY_DATA';

export const CLEAR_FORM = 'CLEAR_FORM';
export const CLEAR_ADDITIONAL_INFO = 'CLEAR_ADDITIONAL_INFO';
export const CLEAR_IMSMA_ID = 'CLEAR_IMSMA_ID';
export const DELETE_MARKING_FILE = 'DELETE_MARKING_FILE';
export const DELETE_MARKING_COORDINATE = 'DELETE_MARKING_COORDINATE';
export const DELETE_EO_CATEGORY_FILE = 'DELETE_EO_CATEGORY_FILE';
export const REMOVE_HANDOVER_FILE = 'REMOVE_HANDOVER_FILE';