import { Accordion, AccordionItem } from 'react-accessible-accordion';
import React from 'react';
import { getAllEorePhotos } from 'api/ImagesApi';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as DownloadSvg } from 'assets/images/icons/file_download.svg';
import { Attachments } from '../Blocks/Attachments';
import { PhotosBlock } from '../Blocks/PhotosBlock';

export const PhotosTab = (props) => {
  const {
    formData,
    photos,
    setPhotos,
    attachments,
    setAttachments,
    setLoader,
  } = props;

  return (
    <div className="form-wrapper">
      <div className="flex-group flex-berween">
        <div className="header w-100">
          <span>Фото</span>
          <span className="line" />
          <span>Photos</span>
        </div>
      </div>
      <div className="photo-cards flex-group flex-center">
        <PhotosBlock
          formData={formData}
          photos={photos}
          setPhotos={setPhotos}
        />
      </div>
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        preExpanded={['attachments']}
        className="custom-accordion"
      >
        <AccordionItem uuid="attachments">
          <Attachments
            attachments={attachments}
            setAttachments={setAttachments}
            setLoader={setLoader}
            id={formData?.id}
            mode={props?.mode}
          />
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export const DownloadAllPhotos = (props) => {
  const handleDownloadAllPhotos = (e, reportId) => {
    e.preventDefault();
    props.setLoader(true);
    getAllEorePhotos(reportId)
      .then((response) => {
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'photos.zip');
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        props.setLoader(false);
      });
  };

  return (
    <div className="download-all-photos">
      <ButtonComponent
        className="warning"
        handleButton={(e) => handleDownloadAllPhotos(e, props.reportId)}
        label="Download all"
        iconLeft={<DownloadSvg />}
        type="button"
      />
    </div>
  );
};
