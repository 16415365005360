import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import axios from 'axios';
import { AuthProvider } from 'routes/AuthContext';
import { UserProvider } from 'routes/UserContext';
import { MapboxProvider } from 'utils/MapboxContext';
import { setAxiosInterceptors } from 'utils/Helper';
import { DeepLProvider } from 'utils/DeepLContext';
import reportWebVitals from './reportWebVitals';
import { App } from './App';
import { DispatchProvider } from 'context/DispatchProvider';

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.withCredentials = true;
setAxiosInterceptors();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UserProvider>
    <MapboxProvider>
      <DeepLProvider>
        <AuthProvider>
          <DispatchProvider>
          <App />
          </DispatchProvider>
        </AuthProvider>
      </DeepLProvider>
    </MapboxProvider>
  </UserProvider>,
);

reportWebVitals();
