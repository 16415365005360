import { AccordionBodyItem, AccordionBodyItemView } from 'component/Accordion';
import { InputComponent } from 'component/Input';
import React from 'react';
import { UPDATE_CLEARED_AREA } from 'reducers/dailyFormActions';

export const ClearedArea = ({ formData, dispatch, hasError }) => {
  const validateNumericInput = (value) => {
    const normalizedValue = value.replace(',', '.');

    const regex = /^\d*\.?\d{0,4}$/;

    return value === '' || regex.test(normalizedValue) ? normalizedValue : false;
  };

  const handleClearedArea = (value) => {
    const validValue = validateNumericInput(value);
    if (validValue !== false) {
      dispatch({
        type: UPDATE_CLEARED_AREA,
        payload: validValue,
      });
    }
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>2.2.1</span>
        </div>
      )}
      titleUA="Очищена область"
      titleEN="Area cleared"
      className={hasError ? 'error error-text' : ''}
      children={(
        <InputComponent
          value={formData?.info?.field_work?.cleared_area || ''}
          placeholder="Type here"
          handleInput={handleClearedArea}
          id="clearedArea"
          name="clearedArea"
        />
      )}
    />
  );
};

export const ClearedAreaView = ({ clearedArea  }) => (
  <AccordionBodyItemView
    accordionSubId="2.2.1"
    titleUA="Очищена область"
    titleEN="Area cleared"
    children={clearedArea || '-'}
  />
);
