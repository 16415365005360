/* eslint-disable react/no-unstable-nested-components */
import { React, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './index.scss';

import axios from 'axios';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import { axiosWrapper, formatDateDataPicker } from 'utils/Helper';
import { URLS } from 'routes/Urls';

import { Table, TableHeader, TableRow } from 'component/Table';
import { Tab } from 'component/Tab';
import { Loader } from 'component/Loader';

import { ReactComponent as ResetIcon } from 'assets/images/icons/update2.svg';
import {
  CreateReportDialog,
  DeleteReportDialog,
} from 'component/Dialog';
import { DatePickerWrapper } from 'component/DataPicker';
import { useUser } from 'routes/UserContext';
import { ReactComponent as EditIcon } from '../../assets/images/icons/mode_edit_24px.svg';
import { ReactComponent as CommentIcon } from '../../assets/images/icons/chat.svg';
import { ReactComponent as ViewIcon } from '../../assets/images/icons/Eye-on.svg';
import { ReactComponent as SortByIcon } from '../../assets/images/icons/Sort-by2.svg';
import { FilterByStatusDialog } from './Filters/FilterReportList';
import ReportsList from './ReportsList';

export const NTS_HA_REPORT = () => {
  const { user } = useUser();
  const location = useLocation();
  const [ntsData, setNtsData] = useState([]);
  const [haData, setHaData] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [ntsLoader, setNtsLoader] = useState(false);
  const [haLoader, setHaLoader] = useState(false);
  const [resetFiltersStatus, setResetFiltersStatus] = useState(false);
  const [isNtsNextProperty, setIsNtsNextProperty] = useState('');
  const [isHaNextProperty, setIsHaNextProperty] = useState('');
  const [ntsDataLoaded, setNtsDataLoaded] = useState(false);
  const [haDataLoaded, setHaDataLoaded] = useState(false);
  const isInitialRender = useRef(true);

  const [rotations, setRotations] = useState({
    report_date: 0,
    settlement_code: 0,
    report_name: 0,
    report_area: 0,
    imsma_id: 0,
  });
  const [lastClickedIcon, setLastClickedIcon] = useState(null);
  const teamLeadId = location.state?.teamLeadId || null;
  const [ntsStartDate, setNtsStartDate] = useState(() => formatDateDataPicker(location?.state?.startDate));
  const [ntsEndDate, setNtsEndDate] = useState(() => formatDateDataPicker(location?.state?.endDate));
  const [haStartDate, setHaStartDate] = useState(() => formatDateDataPicker(location?.state?.startDate));
  const [haEndDate, setHaEndDate] = useState(() => formatDateDataPicker(location?.state?.endDate));

  const formatDate = (dateString) => {
    if (!dateString) {
      return '-';
    }
    const date = moment(dateString, 'DD.MM.YYYY');
    if (!date.isValid()) {
      return '-';
    }
    return `${date.format('L')}`;
  };

  const ntsInitParams = {
    validation_status: [],
    hazard_area_type: [],
    dateFrom: ntsStartDate,
    dateTo: ntsEndDate,
    page: 1,
    ordering: [],
  };

  const haInitParams = {
    validation_status: [],
    hazard_area_type: [],
    dateFrom: haStartDate,
    dateTo: haEndDate,
    page: 1,
    ordering: [],
  };

  const [ntsParams, setNtsParams] = useState(ntsInitParams);
  const [haParams, setHaParams] = useState(haInitParams);
  const [sortDirectionNts, setSortDirectionNts] = useState(true);
  const [sortDirectionHa, setSortDirectionHa] = useState(true);

  const ntsAbortControllerRef = useRef(null);
  const haAbortControllerRef = useRef(null);


  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  const generateUrl = (params, reportType) => {
    let url = `/reports/${reportType}`;

    const queryParams = [];

    Object.keys(params).forEach((key) => {
      if (params[key]) {
        if (Array.isArray(params[key]) && params[key].length > 0) {
          queryParams.push(
            params[key].map((value) => `${key}=${value}`).join('&'),
          );
        } else if (params[key] instanceof Date) {
          queryParams.push(`${key}=${params[key].toISOString()}`);
        }
      }
    });

    if (params.page) {
      queryParams.push(`page=${params.page}`);
    }
    if (params.ordering && params.ordering.length > 0) {
      queryParams.push(`ordering=${params.ordering}`);
    }
    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    return url;
  };

  const fetchDataWithSortingAndFilter = (
    params,
    setData,
    setIsNextProperty,
    reportType,
    dateFrom,
    dateTo,
    teamLeadId,
    abortControllerRef,
    setTabLoader,
    isFirstPage = true
  ) => {

    const newAbortController = new AbortController();
    abortControllerRef.current = newAbortController;

    setTabLoader(true);
    const url = generateUrl(params, reportType);

    axiosWrapper(() => {
      axios
        .get(url, {
          params: {
            date_from: dateFrom || null,
            date_to: dateTo || null,
            user_id: teamLeadId || null,
          },
          signal: newAbortController.signal,
        })
        .then((res) => {
          if (isFirstPage) {
            setData(res.data.results);
          } else {
            setData((prev) => [...prev, ...res.data.results]);
          }
          setIsNextProperty(res.data.next);
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            console.warn("Request was canceled:", err.message);
          } else {
            console.error("Request error:", err);
          }
        })
        .finally(() => {
          setTabLoader(false);
        });
    });
  };

  useEffect(() => {
    if (isInitialRender.current) {
      return;
    }

    if (activeTabIndex === 0) {
      if (!ntsDataLoaded || ntsParams.page > 1) {
        fetchDataWithSortingAndFilter(
          ntsParams,
          setNtsData,
          setIsNtsNextProperty,
          'nts/',
          ntsParams?.dateFrom,
          ntsParams?.dateTo,
          teamLeadId,
          ntsAbortControllerRef,
          setNtsLoader,
          ntsParams.page === 1
        );
        setNtsDataLoaded(true);
      }
    }

    return () => {
      if (ntsAbortControllerRef.current) {
        ntsAbortControllerRef.current.abort();
      }
    };
  }, [ntsParams]);

  useEffect(() => {
    if (activeTabIndex === 1) {
      if (!haDataLoaded || haParams.page > 1) {
        fetchDataWithSortingAndFilter(
          haParams,
          setHaData,
          setIsHaNextProperty,
          'ha/',
          haParams?.dateFrom,
          haParams?.dateTo,
          teamLeadId,
          haAbortControllerRef,
          setHaLoader,
          haParams.page === 1
        );
        setHaDataLoaded(true);
      }
    }
    return () => {
      if (haAbortControllerRef.current) {
        haAbortControllerRef.current.abort();
      }
    };
  }, [haParams]);

  const loadMoreData = (type) => {
    switch (type) {
      case 'nts':
        setNtsLoader(true);
        setNtsParams((prevParams) => ({
          ...prevParams,
          page: prevParams.page + 1,
        }));
        break;
      case 'ha':
        setHaLoader(true);
        setHaParams((prevParams) => ({
          ...prevParams,
          page: prevParams.page + 1,
        }));
        break;
      default:
        break;
    }
  };

  const handleSelectedStatusChange = (
    selectedNtsStatus,
    selectedHaStatus,
    selectedAreaType,
  ) => {
    if (!Array.isArray(selectedNtsStatus || selectedHaStatus)) {
      return;
    }
    if (activeTabIndex === 0) {
      setResetFiltersStatus(false);
      setNtsData([]);
      setNtsDataLoaded(false);
      setNtsParams((prevParams) => ({
        ...prevParams,
        page: 1,
        validation_status: selectedNtsStatus.map((status) => status.value),
      }));
    } else if (activeTabIndex === 1) {
      setResetFiltersStatus(false);
      setHaData([]);
      setHaDataLoaded(false);
      setHaParams((prevParams) => ({
        ...prevParams,
        page: 1,
        hazard_area_type: selectedAreaType.map((areaType) => areaType.value),
        validation_status: selectedHaStatus.map((status) => status.value),
      }));
    }
  };


  const navigate = useNavigate();

  const handleSortIconClick = (ordering) => {
    switch (activeTabIndex) {
      case 0:
        setNtsData([]);
        setNtsDataLoaded(false);
        setNtsParams((prevParams) => ({
          ...prevParams,
          page: 1,
          ordering: sortDirectionNts === true ? `-${ordering}` : ordering,
        }));
        setSortDirectionNts((prevDirection) => (prevDirection === true ? false : true));
        break;
      case 1:
        setHaData([]);
        setHaDataLoaded(false);
        setHaParams((prevParams) => ({
          ...prevParams,
          page: 1,
          ordering: sortDirectionHa === true ? `-${ordering}` : ordering,
        }));
        setSortDirectionHa((prevDirection) => (prevDirection === true ? false : true));
        break;
      default:
        break;
    }
    const currentRotation = rotations[ordering] || 0;
    const newRotation = currentRotation + 180;

    if (lastClickedIcon && lastClickedIcon !== ordering) {
      setRotations((prevRotations) => ({
        ...prevRotations,
        [lastClickedIcon]: 0,
      }));
    }
    setRotations((prevRotations) => ({
      ...prevRotations,
      [ordering]: newRotation,
    }));
    setLastClickedIcon(ordering);
  };

  const handleNtsStartDateChange = (date) => {
    navigate(location.state, {});
    const formattedDate = date ? date.format('DD.MM.YYYY') : null;
    setNtsStartDate(formattedDate);
    setNtsData([]);
    setNtsDataLoaded(false);
    setNtsParams((prevParams) => ({
      ...prevParams,
      dateFrom: formattedDate,
      page: 1,
    }));
  };

  const handleNtsEndDateChange = (date) => {
    navigate(location.state, {});
    const formattedDate = date ? date.format('DD.MM.YYYY') : null;
    setNtsEndDate(formattedDate);
    setNtsData([]);
    setNtsDataLoaded(false);
    setNtsParams((prevParams) => ({
      ...prevParams,
      dateTo: formattedDate,
      page: 1,
    }));
  };

  const handleHaStartDateChange = (date) => {
    navigate(location.state, {});
    const formattedDate = date ? date.format('DD.MM.YYYY') : null;
    setHaStartDate(formattedDate);
    setHaData([]);
    setHaDataLoaded(false);
    setHaParams((prevParams) => ({
      ...prevParams,
      dateFrom: formattedDate,
      page: 1,
    }));
  };

  const handleHaEndDateChange = (date) => {
    navigate(location.state, {});
    const formattedDate = date ? date.format('DD.MM.YYYY') : null;
    setHaEndDate(formattedDate);
    setHaData([]);
    setHaDataLoaded(false);
    setHaParams((prevParams) => ({
      ...prevParams,
      dateTo: formattedDate,
      page: 1,
    }));
  };

  const handleTabClick = (index) => {
    setActiveTabIndex(index);
  };

  const confirmPopup = () => {
    const reportName = localStorage.getItem('reportName' || '');
    if (reportName) {
      navigate(URLS.CREATE_NTS_REPORT_PAGE, {
        state: { name: reportName },
      });
    }
  };

  const handleResetClick = () => {
    setResetFiltersStatus(true);
    if (activeTabIndex === 0) {
      setNtsData([]);
      setNtsStartDate(null);
      setNtsEndDate(null);
      setNtsDataLoaded(false);
      setNtsParams({
        validation_status: [],
        hazard_area_type: [],
        dateFrom: null,
        dateTo: null,
        page: 1,
        ordering: [],
      });
    } else if (activeTabIndex === 1) {
      setHaData([]);
      setHaStartDate(null);
      setHaEndDate(null);
      setHaDataLoaded(false);
      setHaParams({
        validation_status: [],
        hazard_area_type: [],
        dateFrom: null,
        dateTo: null,
        page: 1,
        ordering: [],
      });
    }
    navigate(location.state, {});
  };

  const deleteNtsReport = (selectedNtsReportId) => {
    if (activeTabIndex === 0) {

      setNtsLoader(true);
      axiosWrapper(() => {
        axios
          .delete(`/reports/nts/${selectedNtsReportId}`)
          .then(() => {
            setNtsData((prevData) => prevData.filter((item) => item.id !== selectedNtsReportId));
            setNtsData([]);

            setNtsParams({
              dateFrom: null,
              dateTo: null,
              validation_status: [],
              hazard_area_type: [],
              page: 1,
            });
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setNtsLoader(false);
          });
      });
    }
  };

  const getEditReportUrl = (reportType, id) => {
    if (reportType === 'ha_report' || reportType === 'ha') {
      return `/edit-ha-report/${id}`;
    } if (reportType === 'nts_report') {
      return `/edit-nts-report/${id}`;
    }
    return '';
  };
  const [disabledDeleteDialogs, setDisabledDeleteDialogs] = useState({});
  const [disabledButtons, setDisabledButtons] = useState({});

  const handleAuxClick = (event, id) => {
    if (event.button === 1) {
      openIntoNewTab(id);
    }
  };

  const openIntoNewTab = (id) => {
    const mode = 'validation';
    localStorage.setItem('mode', mode);
    setDisabledButtons((prevState) => ({
      ...prevState,
      [id]: { validation: true, edit: true, delete: true },
    }));

    setDisabledDeleteDialogs((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const EditReportLink = ({ reportType, id }) => {
    const url = getEditReportUrl(reportType, id);

    if (!url) return null;
    const isEditDisabled = disabledButtons[id]?.edit;

    return (
      <Link
        to={url}
        state={{ id }}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: isEditDisabled ? '#d3d3d3' : '#868686', pointerEvents: isEditDisabled ? 'none' : 'auto' }}
        onClick={() => openIntoNewTab(id)}
        onAuxClick={(event) => handleAuxClick(event, id)}
      >
        <EditIcon className="edit-icon" />
      </Link>
    );
  };

  const getViewReportUrl = (reportType, id) => {
    if (reportType === 'ha_report' || reportType === 'ha') {
      return `/view-ha-report/${id}`;
    } if (reportType === 'nts_report') {
      return `/view-nts-report/${id}`;
    }
    return '';
  };

  const ViewReportLink = ({ reportType, id }) => {
    const url = getViewReportUrl(reportType, id);
    if (!url) return null;

    return (
      <Link to={url} state={{ id }} target="_blank" rel="noopener noreferrer" style={{ color: '#868686' }}>
        <ViewIcon className="view-icon" />
      </Link>
    );
  };

  const getValidationReportUrl = (reportType, id) => {
    if (reportType === 'ha_report' || reportType === 'ha') {
      return `/validation-ha-report/${id}`;
    } if (reportType === 'nts_report') {
      return `/validation-nts-report/${id}`;
    }
    return '';
  };

  const ValidationReportLink = ({ reportType, id }) => {
    const url = getValidationReportUrl(reportType, id);
    if (!url) return null;
    const isValidationDisabled = disabledButtons[id]?.validation;

    return (
      <Link
        to={url}
        target="_blank"
        rel="noopener noreferrer"
        className="view-icon"
        style={{ color: isValidationDisabled ? '#d3d3d3' : '#868686', pointerEvents: isValidationDisabled ? 'none' : 'auto' }}
        onClick={() => openIntoNewTab(id)}
        onAuxClick={(event) => handleAuxClick(event, id)}
      >
        <CommentIcon className="view-icon" />
      </Link>
    );
  };

  const deleteHaReport = (selectedHaReportId) => {
    if (activeTabIndex === 1) {
      setHaLoader(true);
      axiosWrapper(() => {
        axios
          .delete(`/reports/ha/${selectedHaReportId}`)
          .then(() => {
            setHaData((prevData) => prevData.filter((item) => item.id !== selectedHaReportId));
            setHaData([]);
            setHaParams({
              dateFrom: null,
              dateTo: null,
              validation_status: [],
              hazard_area_type: [],
              page: 1,
            });
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setHaLoader(false);
          });
      });
    }
  };

  const getActionsByRoleAndStatus = (item) => {
    const draftOrRejectedStatus = item?.validation_status?.value === 'draft'
      || item?.validation_status?.value === 'draft_rejected';
    const validationStatus = item?.validation_status?.value?.startsWith('val_')
      || item?.validation_status?.value === 'imsma_rejected';
    return (
      <div onContextMenu={handleContextMenu}>
        {user?.permissions?.change_nto_report
          && draftOrRejectedStatus && (
            <EditReportLink reportType={item.report_type} id={item.id} />
        )}
        {user?.permissions?.view_nto_report && (
          <ViewReportLink reportType={item.report_type} id={item.id} />
        )}
        {user?.permissions?.delete_nto_report
          && draftOrRejectedStatus && (
            <DeleteReportDialog
              deleteNtsReport={
                activeTabIndex === 0 ? () => deleteNtsReport(item.id) : null
              }
              deleteHaReport={
                activeTabIndex === 1 ? () => deleteHaReport(item.id) : null
              }
              isDisabled={disabledDeleteDialogs[item.id]}
            />
        )}
        {user?.permissions?.validate_nto_report
          && item?.is_current_validation_role
          && validationStatus
          && (
            <ValidationReportLink reportType={item.report_type} id={item.id} />
          )}
      </div>
    );
  };

  useEffect(() => {
    document.title = 'NTS and HA reports';
    if (location?.state?.reportType === 'ha') {
      setActiveTabIndex(1);
    }
    window.history.replaceState({}, document.title, window.location.pathname);
  }, []);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    }

    if (activeTabIndex === 0 && !ntsDataLoaded) {
      fetchDataWithSortingAndFilter(
        ntsParams,
        setNtsData,
        setIsNtsNextProperty,
        'nts/',
        ntsParams?.dateFrom,
        ntsParams?.dateTo,
        teamLeadId,
        ntsAbortControllerRef,
        setNtsLoader,
        true
      );
      setNtsDataLoaded(true);
    } else if (activeTabIndex === 1 && !haDataLoaded) {
      fetchDataWithSortingAndFilter(
        haParams,
        setHaData,
        setIsHaNextProperty,
        'ha/',
        haParams?.dateFrom,
        haParams?.dateTo,
        teamLeadId,
        haAbortControllerRef,
        setHaLoader,
        true
      );
      setHaDataLoaded(true);
    }
  }, [activeTabIndex]);


  useEffect(() => {
    return () => {
      if (ntsAbortControllerRef.current) {
        ntsAbortControllerRef.current.abort();
      }
      if (haAbortControllerRef.current) {
        haAbortControllerRef.current.abort();
      }
    };
  }, []);

  return (
    <section>
      <div className="nts-header">
        <div className="nts-title">NTS / HA report</div>
        <div style={{ display: 'flex', gap: '50px' }}>
          <div style={{ display: 'flex', gap: '14px' }}>
            {(
              <FilterByStatusDialog
                activeTabIndex={activeTabIndex}
                handleSelectedStatusChange={handleSelectedStatusChange}
                resetFiltersStatus={resetFiltersStatus}
              />
            )}
            <div
              style={{
                display: 'flex',
                gap: '5px',
              }}
            >
          {activeTabIndex === 0 ? (
  <>
    <div className="flex-group flex-between" style={{ width: '150px' }}>
      <DatePickerWrapper
        value={ntsStartDate ? moment(ntsStartDate, 'DD.MM.YYYY') : null}
        handleDatePicker={handleNtsStartDateChange}
      />
    </div>
    <div className="flex-group flex-between" style={{ width: '150px', paddingRight: '10px' }}>
      <DatePickerWrapper
        value={ntsEndDate ? moment(ntsEndDate, 'DD.MM.YYYY') : null}
        handleDatePicker={handleNtsEndDateChange}
      />
    </div>
  </>
) : (
  <>
    <div className="flex-group flex-between" style={{ width: '150px' }}>
      <DatePickerWrapper
        value={haStartDate ? moment(haStartDate, 'DD.MM.YYYY') : null}
        handleDatePicker={handleHaStartDateChange}
      />
    </div>
    <div className="flex-group flex-between" style={{ width: '150px', paddingRight: '10px' }}>
      <DatePickerWrapper
        value={haEndDate ? moment(haEndDate, 'DD.MM.YYYY') : null}
        handleDatePicker={handleHaEndDateChange}
      />
    </div>
  </>
)}
              <div className="text-hover p-1" onClick={handleResetClick}>
                <ResetIcon color="#868686" />
              </div>
            </div>
          </div>

          <div style={{ marginTop: '10px' }}>
            <Tab>
              <div
                className={`tab-item-custom ${activeTabIndex === 0 ? 'active' : ''
                }`}
                onClick={() => handleTabClick(0)}
              >
                NTS
              </div>
              <div
                className={`tab-item-custom ${activeTabIndex === 1 ? 'active' : ''
                }`}
                onClick={() => handleTabClick(1)}
              >
                HA
              </div>
            </Tab>
          </div>
        </div>
      </div>
      <div className="nts">
        <div className="nts-table">
          <Table>
            <TableHeader>
              <th className="text-with-icon">
                {(
                  <SortByIcon
                    className="sort-icon"
                    style={{
                      transform: `rotate(${rotations.report_date}deg)`,
                    }}
                    onClick={() => handleSortIconClick('report_date')}
                  />
                )}
                Date
              </th>
              <th className="text-with-icon" style={{ flex: '100px' }}>
                {(
                  <SortByIcon
                    className="sort-icon"
                    style={{
                      transform: `rotate(${rotations.settlement_code}deg)`,
                    }}
                    onClick={() => handleSortIconClick('settlement_code')}
                  />
                )}
                KOTTG
              </th>
              <th>Type</th>
              <th className="text-with-icon">
                {(
                  <SortByIcon
                    className="sort-icon"
                    style={{
                      transform: `rotate(${rotations.report_name}deg)`,
                    }}
                    onClick={() => handleSortIconClick('report_name')}
                  />
                )}
                Name
              </th>
              <th className="text-with-icon" style={{ flex: '200px' }}>
                {activeTabIndex === 1 && (
                  <SortByIcon
                    className="sort-icon"
                    style={{
                      transform: `rotate(${rotations.report_area}deg)`,
                    }}
                    onClick={() => handleSortIconClick('report_area')}
                  />
                )}
                Area
              </th>
              <th className="text-with-icon" style={{ flex: '200px' }}>
                {(
                  <SortByIcon
                    className="sort-icon"
                    style={{
                      transform: `rotate(${rotations.imsma_id}deg)`,
                    }}
                    onClick={() => handleSortIconClick('imsma_id')}
                  />
                )}
                IMSMA ID
              </th>
              <th>Status</th>
              <th>Action</th>
            </TableHeader>
            <tbody>
            {activeTabIndex === 0 && (
            ntsData && ntsData.length === 0 ? (
              ntsLoader ? (
                <TableRow>
                  <td colSpan="7" style={{ textAlign: 'center' }}>
                  Loading...
                  </td>
                </TableRow>
              ) : (
                <TableRow>
                  <td colSpan="7">Дані відсутні</td>
                </TableRow>
              )
            ) : (
              <ReportsList
                data={ntsData}
                activeTabIndex={activeTabIndex}
                formatDate={formatDate}
                getActionsByRoleAndStatus={getActionsByRoleAndStatus}
              />
            )
            )}

          {activeTabIndex === 1 && (
          haData && haData.length === 0 ? (
            haLoader ? (
              <TableRow>
                <td colSpan="7" style={{ textAlign: 'center' }}>
                  Loading...
                </td>
              </TableRow>
            ) : (
              <TableRow>
                <td colSpan="7">Дані відсутні</td>
              </TableRow>
            )
          ) : (
            <ReportsList
              data={haData}
              setData={setHaData}
              activeTabIndex={activeTabIndex}
              formatDate={formatDate}
              getActionsByRoleAndStatus={getActionsByRoleAndStatus}
            />
          )
          )}
          </tbody>
          </Table>
        </div>
        <div className="loadMore-div">
          {activeTabIndex === 1 && isHaNextProperty !== null && (
            <button
              className="loadMore-button"
              onClick={() => {
                loadMoreData('ha');
              }}
            >
              Load more...
            </button>
          )}
        </div>
        <div className="loadMore-div">
          {activeTabIndex === 0 && isNtsNextProperty !== null && (
            <button
              className="loadMore-button"
              onClick={() => {
                loadMoreData('nts');
              }}
            >
              Load more...
            </button>
          )}
        </div>
        {user?.permissions?.add_nto_report && (
          <>
            <hr className="nts-line" />
            <div className="nts-footer">
              <CreateReportDialog confirmPopup={confirmPopup} />
            </div>
          </>
        )}
      </div>
      <Loader visible={ntsLoader || haLoader }/>
      <ToastContainer />
    </section>
  );
};
