import React from 'react';
import { Accordion, AccordionItem } from 'react-accessible-accordion';
import ReportRowEOREMaterials from './ReportRowEOREMaterials';
import ReportRowClearanceMethodType from './ReportRowClearanceMethodType';
import ReportRowClearanceFiles from './ReportRowClearanceFiles';

const AccordionSecretSettingsDetails = ({ setLoader, refreshData, type,user }) => (
  <Accordion
    allowMultipleExpanded
    allowZeroExpanded
    className="custom-accordion"
    preExpanded={[]}
  >
    <AccordionItem uuid="usersList">
      {type === 'eore_materials'
        && (
          <ReportRowEOREMaterials
            setLoader={setLoader}
            refreshData={refreshData}
            userContext={user}
          />
        )}
      {type === 'clearance_method_type'
        && (
          <ReportRowClearanceMethodType
            setLoader={setLoader}
            refreshData={refreshData}
          />
        )}
      {type === 'clearance_files'
        && (
          <ReportRowClearanceFiles
            setLoader={setLoader}
            refreshData={refreshData}
          />
        )}
    </AccordionItem>
  </Accordion>
);

export default AccordionSecretSettingsDetails;
