import { Dialog } from '@mui/material';
import { ButtonComponent } from 'component/Button';
import { React, useState } from 'react';
import { ReactComponent as CancelSvg } from 'assets/images/icons/close_24px.svg';
import { ReactComponent as AddSvg } from 'assets/images/icons/add_24px.svg';
import { InputComponent } from 'component/Input';
import { axiosWrapper } from 'utils/Helper';
import { setNewDonor } from 'api/DonorsApi';
import { toast } from 'react-toastify';

export const NewDonor = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.setName(null);
    props.setDescription(null);
  };

  const createNewDonor = () => {
    if (!props.description) {
      toast.warning('You need to set description');
      return;
    }
    props.setLoader(true);
    axiosWrapper(() => {
      const body = {
        name: props.name,
        description: props.description,
      };
      setNewDonor(body)
        .then(() => {
          toast.success('Donor created');
          props.setLoader(false);
          props.getDonorsList(1);
          handleClose();
        })
        .catch((err) => {
          console.error(err);
          toast.error('Donor not created');
          props.setLoader(false);
          handleClose();
        });
    });
  };

  return (
    <div className="buttons-group">
      <ButtonComponent
        iconLeft={<AddSvg />}
        handleButton={handleClickOpen}
        label="New donor"
        className=""
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="confirm-popup">
          <div className="header">
            <span className="title">Create donor</span>
            <span className="closeBtn" onClick={handleClose} />
          </div>

          <div className="flex-column-group">
            <InputComponent
              id="donor-name"
              name="donor-name"
              label="Donor name"
              placeholder="Donor name"
              value={props.name}
              handleInput={props.setName}
              classNameWrapper="align-left"
            />
            <InputComponent
              id="donor-description"
              name="donor-description"
              label="Donor description"
              placeholder="Donor description"
              value={props.description}
              handleInput={props.setDescription}
              classNameWrapper="align-left"
            />
          </div>

          <div className="buttons-group">
            <ButtonComponent
              className="danger"
              iconLeft={<CancelSvg />}
              handleButton={() => {
                handleClose();
              }}
              label="Close"
            />
            <ButtonComponent
              iconLeft={<AddSvg />}
              handleButton={createNewDonor}
              label="Create"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
