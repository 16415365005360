import React from 'react';
import { TableRowReports } from './TableRow/TableRow';

const ReportsList = ({
  data,
  formatDate,
  getActionsByRoleAndStatus,
  activeTabIndex,
  setData,
}) => (
  <TableRowReports
    activeTabIndex={activeTabIndex}
    data={data}
    setData={setData}
    formatDate={formatDate}
    getActionsByRoleAndStatus={getActionsByRoleAndStatus}
  />
);

export default ReportsList;
