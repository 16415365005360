import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Loader } from 'component/Loader';
import { getHAById } from 'api/HaApi';
import { ToastContainer } from 'react-toastify';
import { tcpForm } from 'consts/TCPForm';
import { getTCPFormById } from 'api/TCPApi';
import { TCPNavigationStatuses } from 'consts/TCP';
import { TCPFormTabsView } from '../Tabs/FormTabs';
import 'assets/styles/pages/task-clearance-plan.scss';
import { NavigationTabLinks } from '../Tabs/NavigationLinks';
import { useValidationComments } from 'hooks/useValidationComments';
import { REPORT_TYPES } from 'consts/ReportValidation';

export const TaskClearancePlanPageView = () => {
  const [formData, setFormData] = useState(null);
  const [tcpFormData, setTcpFormData] = useState(tcpForm);
  const [navigationStatuses, setNavigationStatuses] = useState(TCPNavigationStatuses);
  const [loader, setLoader] = useState(true);
  const { clearanceId } = useParams();
  const validationComments = useValidationComments(REPORT_TYPES.TASK_CLEARANCE, clearanceId);

  useEffect(() => {
    setNavigationStatuses((prev) => ({
      ...prev,
      tcpCreate: 'active',
      daily: 'inactive',
      weekly: 'inactive',
      completed: 'inactive',
      done: 'inactive',
    }));
  }, []);

  useEffect(() => {
    getTCPFormById(clearanceId)
      .then((response) => {
        setTcpFormData(response.data);
        getHAById(response.data.ha_report)
          .then((res) => {
            setFormData(res.data);
            setLoader(false);
          })
          .catch((error) => {
            console.error(error);
            setLoader(false);
          });
      })
      .catch((error) => {
        console.error(error);
        setLoader(false);
      });
  }, []);

  return (
    <section>
      <div className="flex-column-group">
        <NavigationTabLinks
          mode="view"
          id={tcpFormData.id}
          statuses={navigationStatuses}
        />
        <TaskClearancePlanFormView
          formData={formData}
          setFormData={setFormData}
          tcpFormData={tcpFormData}
          setTcpFormData={setTcpFormData}
          setLoader={setLoader}
          mode="view"
          commentsData={validationComments}
        />
      </div>
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};

export const TaskClearancePlanFormView = ({
  formData,
  setFormData,
  tcpFormData,
  mode,
  commentsData,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [map, setMap] = useState(null);
  const [filesLinks, setFilesLinks] = useState([]);

  const getAllClearanceLinks = async () => {
    try {
      const response = await axios.get('/reports/clearance/files/');
      const clearancesFiles = response.data?.map((item) => ({
        id: item.id,
        clearance_file: item.name,
        file_link: tcpFormData?.files?.find((file) => file.clearance_file === item.id)?.file_link || '',
      }));
      setFilesLinks(clearancesFiles);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!tcpFormData?.id) return;
    getAllClearanceLinks();
  }, [tcpFormData.id]);

  return (
    <div className="tcp-form">
      <TCPFormTabsView
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
        formData={formData}
        setFormData={setFormData}
        filesLinks={filesLinks}
        map={map}
        setMap={setMap}
        tcpFormData={tcpFormData}
        mode={mode}
        commentsData={commentsData}
      />
    </div>
  );
};
