import { React, useEffect, useState } from 'react';
import { TableRow } from 'component/Table';
import { ValidationStatusComponent, squareMetersToHectares } from 'utils/Helper';
import { DownloadDocxBtn } from '../Actions/DownloadDocx';
import { UploadPdfBtn } from '../Actions/UploadDownloadPdf';

export const TableRowReports = ({
  data,
  formatDate,
  getActionsByRoleAndStatus,
  activeTabIndex,
  setData,
}) => {
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [fileUploadingId, setFileUploadingId] = useState(null);
  const [isFileUploadingPdf, setIsFileUploadingPdf] = useState(false);
  const [fileUploadingIdPdf, setFileUploadingIdPdf] = useState(null);
  const [isImportDropdownOpen, setIsImportDropdownOpen] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const updateReportsList = (updatedList) => {
    setData(updatedList);
  };

  const handleCloseImportDropdown = () => {
    setIsImportDropdownOpen(false);
  };
  const handleClickOutsideModal = (event) => {
    const modal = document.querySelector('.import-dropdown-content');
    if (modal && !modal.contains(event.target)) {
      handleCloseImportDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideModal);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideModal);
    };
  }, []);
  return (
    data?.map((item, index) => (
      <TableRow key={index}>
        <td>{formatDate(item.info?.general?.report_date)}</td>
        <td>{item.info?.location?.settlement_code}</td>
        <td>{item?.report_type?.split('_')[0].toUpperCase()}</td>
        <td style={{ flex: '160px' }}>
          {item.info?.general?.hazard_name || item.info?.general?.name}
        </td>
        <td style={{ flex: '150px' }}>
          {squareMetersToHectares(item.info?.turn_points?.total_area_meters)}
        </td>
        <td style={{ flex: '240px' }}>{item.info?.general?.imsma_id}</td>
        <td>
          {item.validation_status?.value
            && (
              <ValidationStatusComponent status={item.validation_status} />
            )}
        </td>
        <td>
          <div className="all-icons">{getActionsByRoleAndStatus(item)}</div>
          {activeTabIndex !== 0
            && (item?.report_type === 'ha_report' || item?.report_type === 'ha' ? (
              <div className="import-and-export-actions">
                <DownloadDocxBtn
                  isFileUploading={isFileUploading}
                  fileUploadingId={fileUploadingId}
                  setIsFileUploading={setIsFileUploading}
                  setFileUploadingId={setFileUploadingId}
                  isLoading={isLoading}
                  reportId={item.id}
                  setIsLoading={setIsLoading}
                />
                <UploadPdfBtn
                  reportsList={data}
                  isFileUploadingPdf={isFileUploadingPdf}
                  fileUploadingIdPdf={fileUploadingIdPdf}
                  setIsFileUploadingPdf={setIsFileUploadingPdf}
                  setFileUploadingIdPdf={setFileUploadingIdPdf}
                  isImportDropdownOpen={isImportDropdownOpen}
                  setIsImportDropdownOpen={setIsImportDropdownOpen}
                  item={item}
                  onUpdateReportsList={updateReportsList}
                />
              </div>
            ) : (
              ''
            ))}
        </td>
      </TableRow>
    ))
  );
};
