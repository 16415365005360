import { ButtonComponent } from 'component/Button';
import { InputComponent } from 'component/Input';
import { ReactComponent as PlusIcon } from 'assets/images/icons/add_24px.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/delete_24px.svg';
import React, { useEffect } from 'react';

export const GisLinks = ({
  links,
  setLinks,
  setTcpFormData,
}) => {
  useEffect(() => {
    setTcpFormData((prev) => ({
      ...prev,
      deminers_files_links: links,
    }));
  }, [links]);

  const removeLink = (index) => {
    setLinks((prev) => {
      const hasTurnPoints = prev.some((link) => 'turn_points' in link);
      const adjustedIndex = hasTurnPoints && index === 0 ? 1 : index;

      return prev.filter((_, i) => i !== adjustedIndex);
    });
  };

  const addLink = () => {
    setLinks((prev) => [...prev, {
      '': '',
    }]);
  };

  const handleLinkInput = (value, type, index) => {
    setLinks((prev) => {
      const hasTurnPoints = prev.some((link) => 'turn_points' in link);
      const adjustedIndex = hasTurnPoints && index === 0 ? 1 : index;

      const updatedLinks = prev.map((link, i) => {
        if (i === adjustedIndex) {
          if (type === 'key') {
            return { [value]: Object.values(link)[0] };
          }
          return { [Object.keys(link)[0]]: value };
        }
        return link;
      });

      return updatedLinks;
    });
  };

  return (
    <div className="gis-links gis-block-links">
      <div className="flex-column-group">
        {links?.map((link, i) => (
          Object.keys(link)[i] !== 'turn_points' && (
            <LinkItem
              key={i}
              index={i}
              removeLink={removeLink}
              link={link}
              handleLinkInput={(value, key) => handleLinkInput(value, key, i)}
            />
          )
        ))}
      </div>

      <ButtonComponent
        label="Add"
        className="primary"
        iconLeft={<PlusIcon />}
        handleButton={addLink}
      />
    </div>
  );
};

const LinkItem = ({
  index, removeLink, link, handleLinkInput,
}) => {
  if (Object.keys(link)[0] === 'turn_points') return null;
  return (
    <div className="flex-group">
      <InputComponent
        label="Name"
        id={`link-name-${index}`}
        placeholder="Write here"
        value={Object.keys(link)[0]}
        handleInput={(value) => handleLinkInput(value, 'key', index)}
      />
      <InputComponent
        label="Link"
        id={`link-${index}`}
        placeholder="Write here"
        value={Object.values(link)[0]}
        handleInput={(value) => handleLinkInput(value, 'value', index)}
      />
      <DeleteIcon
        className="delete-icon"
        onClick={() => removeLink(index)}
      />
    </div>
  );
};

export const LinkItemView = ({ link }) => (
  <div className="flex-group one-link">
    <div className="file-name">
      <span>Name</span>
      <span>{Object.keys(link)[0]}</span>
    </div>
    <div className="file-name">
      <span>Link</span>
      <span>
        <a href={Object.values(link)[0]} target="_blank" rel="noreferrer">{Object.values(link)[0]}</a>
      </span>
    </div>
  </div>
);

export const GisLinksView = ({ links }) => {
  const isEmptyOrHasTurnPointsOnly = (arr) => (
    arr.length === 0
    || (arr.length === 1 && arr[0].hasOwnProperty('turn_points'))
  );
  if (isEmptyOrHasTurnPointsOnly(links)) {
    return (
      <div className="file-links">No files</div>
    );
  }
  return (
    <div className="file-links flex-column-group">
      {links?.map((link, i) => {
        if (link.turn_points) return null;
        return (
          <LinkItemView key={i} link={link} />
        );
      })}
    </div>
  );
};
