import axios from 'axios';

export const getEoreTemplates = async (page, dateFrom, dateTo, signal) => {
  const pageParam = page ? `?page=${page}` : '';
  const dateFromParam = dateFrom ? `&date_from=${dateFrom}` : '';
  const dateToParam = dateTo ? `&date_to=${dateTo}` : '';
  const url = `/reports/eore/templates/${pageParam}${dateFromParam}${dateToParam}`;
  const config = signal ? { signal } : {};
  const response = await axios.get(url, config);
  return response;
};

export const postEoreTemplate = async (body, templateName) => {
  const templateNameParam = templateName ? `?template_name=${templateName}` : '';
  const url = `/reports/eore/templates/${templateNameParam}`;
  const response = await axios.post(url, body);
  return response;
};

export const getEoreTemplateFile = async (fileId) => {
  const url = `/reports/eore/templates/${fileId}/download/`;
  const response = await axios.get(url, { responseType: 'blob' });
  return response;
};

export const deleteEoreTemplate = async (id) => {
  const url = `/reports/eore/templates/${id}/`;
  const response = await axios.delete(url);
  return response;
};

export const getEoreReports = async (page, dateFrom, dateTo, userId, signal) => {
  const pageParam = page ? `?page=${page}` : '';
  const dateFromParam = dateFrom ? `&date_from=${dateFrom}` : '';
  const dateToParam = dateTo ? `&date_to=${dateTo}` : '';
  const userIdParam = userId ? `&user_id=${userId}` : '';
  const url = `/reports/eore/${pageParam}${dateFromParam}${dateToParam}${userIdParam}`;
  const config = signal ? { signal } : {};
  const response = await axios.get(url, config);
  return response;
};

export const deleteEoreReport = async (id) => {
  const url = `/reports/eore/${id}/`;
  const response = await axios.delete(url);
  return response;
};

export const deleteAllUserHandouts = async (userId) => {
  const url = `/reports/eore/handouts/${userId}/user/`;
  const response = await axios.delete(url);
  return response;
};

export const deleteAllUserFiles = async (userId) => {
  const url = `/reports/clearance/files/${userId}/user/`;
  const response = await axios.delete(url);
  return response;
};

export const deleteAllUserMethods = async (userId) => {
  const url = `/reports/clearance/methods/${userId}/user/`;
  const response = await axios.delete(url);
  return response;
};

export const deleteHandoutById = async (handoutId) => {
  const url = `/reports/eore/handouts/${handoutId}/`;
  const response = await axios.delete(url);
  return response;
};

export const deleteMethodById = async (methodId) => {
  const url = `/reports/clearance/methods/${methodId}/`;
  const response = await axios.delete(url);
  return response;
};

export const deleteFileById = async (fileId) => {
  const url = `/reports/clearance/files/${fileId}/`;
  const response = await axios.delete(url);
  return response;
};

export const createOrUpdateEoreHandouts = async (handouts) => {
  const url = '/reports/eore/handouts/';
  const response = await axios.put(url, handouts);
  return response;
};

export const createOrUpdateClearanceMethods = async (methods) => {
  const url = '/reports/clearance/methods/';
  const response = await axios.put(url, methods);
  return response;
};

export const createOrUpdateClearanceFiles = async (files) => {
  const url = '/reports/clearance/files/';
  const response = await axios.put(url, files);
  return response;
};

export const getHandouts = async () => {
  const url = '/reports/eore/handouts/';
  const response = await axios.get(url);
  return response;
};

export const postEoreReport = async (body) => {
  const url = '/reports/eore/';
  const response = await axios.post(url, body);
  return response;
};

export const getEoreCurrentTemplateVersion = async () => {
  const url = '/reports/eore/templates/version/';
  const response = await axios.get(url);
  return response;
};

export const getEoreReportById = async (id) => {
  const url = `/reports/eore/${id}/`;
  const response = await axios.get(url);
  return response;
};

export const updateEoreReportById = async (id, body) => {
  const url = `/reports/eore/${id}/`;
  const response = await axios.patch(url, body);
  return response;
};

export const updateEoreReportPhotos = async (id, body) => {
  const url = `/reports/eore/${id}/images/`;
  const response = await axios.patch(url, body);
  return response;
};

export const updateReportAttachments = async (id, body) => {
  const url = `/reports/eore/${id}/files/`;
  const response = await axios.patch(url, body);
  return response;
};

export const getEOREGeojson = async (options = {}) => {
  const url = `/reports/eore/global-map/`;
  const response = await axios.get(url, options);
  return response;
};