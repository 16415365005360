import React from 'react';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { CommentDisplay } from 'component/CommentBlock';
import { AccordionHeaderTitle } from 'component/Accordion';
import { AntiPersonnelMineView } from './AntiPersonnelMine';
import { AntiVehicleMineView } from './AntiVehicleMine';
import { UnexploadedOrdnanceView } from './UnexploadedOrdance';
import { AbandonedView } from './Abandoned';
import { ClusterMunitionView } from './ClusterMunition';
import { ImprovisedView } from './Improvised';
import { SmallAmmunitionView } from './SmallAmmunition';
import { ExplosivesView } from './Explosives';
import { DescriptionView } from './Description';

export const ContaminationView = (props) => {
  const {
    formData,
    comments,
    translatedFormData,
    translateState,
  } = props;

  return (
    <AccordionItem uuid="contamination">
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="3"
            titleUA="Тип забруднення"
            titleEN="Type of contamination"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="custom-accordion-item-panel">
        <AntiPersonnelMineView formData={formData} />
        <AntiVehicleMineView formData={formData} />
        <UnexploadedOrdnanceView formData={formData} />
        <AbandonedView formData={formData} />
        <ClusterMunitionView formData={formData} />
        <ImprovisedView formData={formData} />
        <SmallAmmunitionView formData={formData} />
        <ExplosivesView formData={formData} />
        <DescriptionView
          formData={formData}
          translatedFormData={translatedFormData}
          translateState={translateState}
        />
        <CommentDisplay comments={comments} block="contamination" />
      </AccordionItemPanel>
    </AccordionItem>
  );
};
