import React from 'react';
import { Map } from 'component/Map';
import { IMSMAID, IMSMAIDView } from './IMSMAID';
import { InternalId, InternalIdView } from './InternalId';
import { PolygonName, PolygonNameView } from './PolygonName';
import {
  DistrictViewWithoutSubId,
  LocalCommunityViewWithoutSubId,
  RegionViewWithoutSubId,
  SettlementViewWithoutSubId,
} from './Location';
import { Area } from './Area';

export const HABlock = ({
  formData, setFormData, map, setMap,
}) => (
  <div className="flex-column-group">
    <div className="flex-group gap-40">
      <IMSMAID
        formData={formData}
        setFormData={setFormData}
      />
      {formData && (
      <DistrictViewWithoutSubId
        formData={formData}
      />
      )}
    </div>
    <div className="flex-group gap-40">
      <InternalId
        formData={formData}
        setFormData={setFormData}
      />
      {formData && (
      <LocalCommunityViewWithoutSubId
        formData={formData}
      />
      )}
    </div>
    <div className="flex-group gap-40">
      <PolygonName
        formData={formData}
        setFormData={setFormData}
      />
      {formData && (
      <SettlementViewWithoutSubId
        formData={formData}
      />
      )}
    </div>
    <div className="flex-group gap-40">
      {formData && (
      <>
        <RegionViewWithoutSubId
          formData={formData}
        />
        <Area
          formData={formData}
        />
      </>
      )}
    </div>
    {formData && (
    <Map
      map={map}
      setMap={setMap}
      turnPointsPolygon={formData.info.turn_points.geojson}
      hazardAreaType={formData.info.general.hazard_area_type}
    />
    )}
  </div>
);

export const HABlockView = ({ formData }) => (
  <div className="flex-column-group">
    <div className="flex-group gap-40 column-350">
      <IMSMAIDView
        formData={formData}
      />
      {formData && (
      <DistrictViewWithoutSubId
        formData={formData}
      />
      )}
    </div>
    <div className="flex-group gap-40 column-350">
      <InternalIdView
        formData={formData}
      />
      {formData && (
      <LocalCommunityViewWithoutSubId
        formData={formData}
      />
      )}
    </div>
    <div className="flex-group gap-40 column-350">
      <PolygonNameView
        formData={formData}
      />
      {formData && (
      <SettlementViewWithoutSubId
        formData={formData}
      />
      )}
    </div>
    <div className="flex-group gap-40 column-350">
      {formData && (
      <>
        <RegionViewWithoutSubId
          formData={formData}
        />
        <Area
          formData={formData}
        />
      </>
      )}
    </div>
    {formData && (
    <Map
      turnPointsPolygon={formData.info.turn_points.geojson}
      hazardAreaType={formData.info.general.hazard_area_type}
    />
    )}
  </div>
);
