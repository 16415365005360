/* eslint-disable no-shadow */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/no-unstable-nested-components */
import {
  React, useEffect, useState, useRef, useCallback,
} from 'react';
import { useNavigate, useParams } from 'react-router';
import { Loader } from 'component/Loader';
import { ToastContainer, toast } from 'react-toastify';
import ArrowIcon from 'assets/images/icons/arrow_left.svg';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import 'assets/styles/tabs.scss';
import { URLS } from 'routes/Urls';
import { ReactComponent as CloseSvg } from 'assets/images/icons/close_24px.svg';
import { ReactComponent as PencilSvg } from 'assets/images/icons/mode_edit_24px.svg';
import { ReactComponent as SendSvg } from 'assets/images/icons/send.svg';
import 'react-tabs/style/react-tabs.css';
import { axiosWrapper, sortedPhotos, updateValidationForm } from 'utils/Helper';
import { getHAById } from 'api/HaApi';
import axios from 'axios';
import { getPhotoByName } from 'api/ImagesApi';
import { ValidationHAInputs } from 'consts/ValidationInputForm';
import { useBeforeUnload } from 'react-router-dom';
import { CheckTab } from '../tabs/Check';
import { PhotosTab } from '../tabs/Photos';
import { HAformData } from '../../../consts/Forms';
import { GisTab } from '../tabs/Gis';
import { AttributesTab } from '../tabs/Attributes';
import { ButtonComponent } from '../../../component/Button';

export const EditHAReportPage = () => {
  const [formData, setFormData] = useState(HAformData);
  const [activeTab, setActiveTab] = useState(0);
  const [btnState, setBtnState] = useState(null);
  const [loader, setLoader] = useState(false);
  const [filesNames, setFilesNames] = useState(formData.info.files || null);
  const [files, setFiles] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [annexImages, setAnnexImages] = useState([]);
  const [loaderAttributes, setLoaderAttributes] = useState(true);
  const [loaderGis, setLoaderGis] = useState(true);
  const [loaderPhotos, setLoaderPhotos] = useState(true);
  const [map, setMap] = useState(null);
  const [map1, setMap1] = useState(null);
  const [map2, setMap2] = useState(null);
  const [turnPointsMap, setTurnPointsMap] = useState(null);
  const [loaderAnnexPhotos, setLoaderAnnexPhotos] = useState(true);

  const [validationForm, setValidationForm] = useState(ValidationHAInputs);
  const [errors, setErrors] = useState({});
  const generalRef = useRef(null);
  const [sendHAButtonDisabled, setSendHAButtonDisabled] = useState(false);

  const { reportId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Edit HA report - ${formData.info.general.hazard_name || 'Unknown'}`;
  }, [formData]);

  useEffect(() => {
    const formDataClone = JSON.parse(JSON.stringify(formData));
    const updatedValidationForm = updateValidationForm(
      formDataClone,
      validationForm,
    );
    setValidationForm(updatedValidationForm);
  }, [formData]);

  useBeforeUnload(
    useCallback((e) => {
      e.preventDefault();
      e.returnValue = '';
    }, []),
  );

  useEffect(() => {
    localStorage.removeItem('reportName');
  }, []);

  const getAnnexImage = (reportId, imageFile) => {
    if (!imageFile || !imageFile.file_name) {
      return Promise.resolve(); // Resolve immediately if no image file
    }
    return new Promise((resolve, reject) => {
      axiosWrapper(() => {
        getPhotoByName(reportId, imageFile.file_name, true)
          .then((res) => {
            if (res.status === 200) {
              const image = new File([res.data], imageFile.file_name, {
                type: 'image/jpeg',
              });

              setAnnexImages((prevPhotos) => [
                ...prevPhotos,
                {
                  file: image,
                  data_url: URL.createObjectURL(res.data),
                  file_name: imageFile.file_name,
                  is_main: imageFile.is_main,
                },
              ]);

              resolve(); // Resolve the Promise when photo is processed
            } else {
              reject('Annex maps request failed');
            }
          })
          .catch((err) => {
            toast.error('Annex maps not loaded');
            reject(err); // Reject the promise if there's an error
          });
      });
    });
  };

  const getPhoto = (reportId, imageFile, dependency, item) => {
    // Check if imageFile is valid
    if (!imageFile || !imageFile.file_name) {
      return Promise.resolve(); // Resolve immediately if no image file
    }

    // Return a Promise for the photo retrieval and processing
    return new Promise((resolve, reject) => {
      axiosWrapper(() => {
        getPhotoByName(reportId, imageFile.file_name)
          .then((res) => {
            if (res.status === 200) {
              const image = new File([res.data], imageFile.file_name, {
                type: 'image/jpeg',
              });

              setPhotos((prevPhotos) => sortedPhotos([
                ...prevPhotos,
                {
                  file: image,
                  data_url: URL.createObjectURL(res.data),
                  name: imageFile.file_name,
                  dependency,
                  item,
                  description: imageFile.description || '',
                  lat: imageFile.lat || '',
                  long: imageFile.long || '',
                  date: imageFile.date || '',
                  order: imageFile.order || '',
                  send_to_imsma: imageFile.send_to_imsma || false,
                },
              ]));

              resolve(); // Resolve the Promise when photo is processed
            } else {
              reject('Photo request failed');
            }
          })
          .catch((err) => {
            toast.error(
              `Photo with file name ${imageFile.file_name} not loaded`,
            );
            setLoaderPhotos(false);
            reject(err); // Reject the promise if there's an error
          });
      });
    });
  };

  const setLoaders = (bool) => {
    setLoaderAttributes(bool);
    setLoaderGis(bool);
    setLoaderPhotos(bool);
    setLoaderAnnexPhotos(bool);
  };

  const getAnnexImages = (res) => new Promise((resolve) => {
    const promises = [];

    res.data.info.annex?.items?.forEach((item) => {
      promises.push(getAnnexImage(res.data.id, item));
    });

    Promise.all(promises)
      .then(() => {
        resolve('ok');
        setLoaderAnnexPhotos(false);
      })
      .catch(() => {
        toast.error('Annex maps not loaded');
        setLoaderAnnexPhotos(false);
      });
  });

  const getAllPhotos = (res) => new Promise((resolve) => {
    const promises = [];

    res.data.info.general_photos.items?.forEach((item) => {
      promises.push(getPhoto(res.data.id, item, 'general', null));
    });

    res.data.info.evidences.direct_evidences.items?.forEach((item, index) => {
      if (!item.photo || item.photo?.length === 0) return;
      if (!Array.isArray(item.photo)) return;
      item.photo?.forEach((photo) => {
        promises.push(
          getPhoto(res.data.id, photo, 'direct_evidence', index + 1),
        );
      });
    });

    res.data.info.evidences.indirect_evidences.items?.forEach(
      (item, index) => {
        if (!item.photo || item.photo?.length === 0) return;
        if (!Array.isArray(item.photo)) return;
        item.photo?.forEach((photo) => {
          promises.push(
            getPhoto(res.data.id, photo, 'indirect_evidence', index + 1),
          );
        });
      },
    );

    if (res.data.info.lm.photo.length > 0) {
      res.data.info.lm.photo.forEach((photo) => {
        promises.push(getPhoto(res.data.id, photo, 'lm', null));
      });
      res.data.info.bm.photo.forEach((photo) => {
        promises.push(getPhoto(res.data.id, photo, 'bm', null));
      });
    } else {
      promises.push(getPhoto(res.data.id, res.data.info.lm.photo, 'lm', null));
      promises.push(getPhoto(res.data.id, res.data.info.bm.photo, 'bm', null));
    }

    Promise.all(promises)
      .then(() => {
        resolve('ok');
        setLoaderAttributes(false);
        setLoaderPhotos(false);
      })
      .catch(() => {
        toast.error('Photos not loaded');
        setLoaderAttributes(false);
        setLoaderPhotos(false);
      });
  });

  const sendAnnexPhotos = (reportId) => new Promise((resolve, reject) => {
    const formData = new FormData();
    annexImages.forEach((file) => {
      if (file.file) {
        formData.append('images', file.file);
      } else {
        formData.append('images', file);
      }
    });
    axiosWrapper(() => {
      axios
        .patch(
          `${URLS.CREATE_REPORT_HA}${reportId}/images/millimeter-map/`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then(() => {
          setLoaderAnnexPhotos(false);
          resolve(); // Resolve the promise when photos are uploaded successfully
        })
        .catch((err) => {
          setLoaderAnnexPhotos(false);
          reject(err); // Reject the promise if there's an error
        });
    });
  });

  const sendPhotos = (reportId) => new Promise((resolve, reject) => {
    const files = photos.map((photo) => photo.file);
    const formData = new FormData();
    files.forEach((file) => {
      if (file.file) {
        formData.append('images', file.file);
      } else {
        formData.append('images', file);
      }
    });
    axiosWrapper(() => {
      axios
        .patch(`${URLS.CREATE_REPORT_HA}${reportId}/images/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          setLoaderPhotos(false);
          resolve(); // Resolve the promise when photos are uploaded successfully
        })
        .catch((err) => {
          console.error(err);
          setLoaderPhotos(false);
          reject(err); // Reject the promise if there's an error
        });
    });
  });

  const setGeneralPhotos = () => {
    const photoObj = (photo) => ({
      file_name: photo.photo_name || photo.name,
      description: photo.description,
      lat: photo.lat,
      long: photo.long,
      date: photo.date,
      order: photo.order,
      send_to_imsma: photo.send_to_imsma || false,
    });

    const generalPhotos = photos.filter((photo) => photo.dependency === 'general');
    const directEvidencePhotos = photos.filter(
      (photo) => photo.dependency === 'direct_evidence',
    );
    const indirectEvidencePhotos = photos.filter(
      (photo) => photo.dependency === 'indirect_evidence',
    );
    const lm = photos.filter((photo) => photo.dependency === 'lm');
    const bm = photos.filter((photo) => photo.dependency === 'bm');
    const updatedGeneralPhotos = generalPhotos.map((photo) => photoObj(photo));

    setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        general_photos: {
          ...prevFormData.info.general_photos,
          items: updatedGeneralPhotos,
        },
        evidences: {
          ...prevFormData.info.evidences,
          direct_evidences: {
            ...prevFormData.info.evidences.direct_evidences,
            items: prevFormData.info.evidences.direct_evidences.items.map(
              (item, index) => {
                const photos = [];
                for (const photo of directEvidencePhotos) {
                  if (index === photo.item - 1) {
                    photos.push(photoObj(photo));
                  }
                }
                item.photo = photos;
                return item;
              },
            ),
          },
          indirect_evidences: {
            ...prevFormData.info.evidences.indirect_evidences,

            items: prevFormData.info.evidences.indirect_evidences.items.map(
              (item, index) => {
                const photos = [];
                for (const photo of indirectEvidencePhotos) {
                  if (index === photo.item - 1) {
                    photos.push(photoObj(photo));
                  }
                }
                item.photo = photos;
                return item;
              },
            ),
          },
        },
        lm: {
          ...prevFormData.info.lm,
          photo: lm.map((photo) => photoObj(photo)),
        },
        bm: {
          ...prevFormData.info.bm,
          photo: bm.map((photo) => photoObj(photo)),
        },
      },
    }));
  };

  const scrollToGeneral = () => {
    if (generalRef.current) {
      generalRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const handleCancelButton = (e) => {
    e.preventDefault();
    navigate(URLS.DASHBOARD);
  };

  const handleDraftButton = (e) => {
    e.preventDefault();

    setFormData((prevFormData) => ({
      ...prevFormData,
      draft: true,
    }));

    setBtnState('draft');
  };

  const handleSaveButton = (e) => {
    e.preventDefault();
    setFormData((prevFormData) => ({
      ...prevFormData,
      draft: false,
    }));

    setBtnState('send');
  };

  const sendHAReport = (callback) => {
    setSendHAButtonDisabled(true);
    axiosWrapper(() => {
      axios
        .put(`${URLS.CREATE_REPORT_HA}${formData.id}/`, formData)
        .then((res) => {
          if (res.status === 200) {
            callback(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
          if (err.response.status === 400) {
            setErrors(err.response.data);
            scrollToGeneral();
            setSendHAButtonDisabled(false);
          }
          setLoaderAttributes(false);
          setLoaderGis(false);
          setLoaderPhotos(false);
          setLoaderAnnexPhotos(false);
          toast.error(`Error! HA report not created.
            Check attribute information, LM and BM photos.`);
        });
    });
  };

  const checkFilesExist = () => {
    if (!formData.info.files?.lm_bm || !formData.info.files?.turn_points) {
      toast.warning('You need to upload gis files');
      return false;
    }
    return true;
  };

  const checkAnnexMapExist = () => {
    if (formData.info.annex?.items?.length < 1) {
      toast.warning('You need to upload annex map files');
      return false;
    }
    return true;
  };

  const PageTitle = (props) => (
    <div className="page-title">
      <img
        src={ArrowIcon}
        alt="Back"
        onClick={() => navigate(-1)}
        style={{ cursor: 'pointer' }}
      />
      <div>{props.title}</div>
      <span className="blue-text">
        |
        {props.name}
      </span>
    </div>
  );

  useEffect(() => {
    setGeneralPhotos();
  }, [photos]);

  useEffect(() => {
    if (formData.draft && btnState === 'draft') {
      setLoaders(true);
      sendHAReport(async (res) => {
        try {
          setFormData(res);
          await sendPhotos(res.id);
          await sendAnnexPhotos(res.id);
          setLoaders(false);
          setSendHAButtonDisabled(false);
          toast.success('HA report drafted');
        } catch (error) {
          console.error(error);
          setSendHAButtonDisabled(false);
          setLoaders(false);
          // Handle any errors that occurred during sending photos or setting form data.
        }
      });
    }
    if (!formData.draft && btnState === 'send') {
      if (checkFilesExist() && checkAnnexMapExist()) {
        setLoaders(true);
        sendHAReport(async (res) => {
          try {
            setFormData(res);
            await sendPhotos(res.id);
            await sendAnnexPhotos(res.id);
            setLoaders(false);
            toast.success('HA report was sent');
            navigate('/nts-ha-report');
          } catch (error) {
            console.error(error);
            setLoaders(false);
          }
        });
      }
    }
    setBtnState(null);
  }, [formData.draft, btnState]);

  useEffect(() => {
    axiosWrapper(() => {
      getHAById(reportId)
        .then((res) => {
          if (res.status === 200) {
            setFormData(res.data);
            getAllPhotos(res);
            getAnnexImages(res);
            setLoaderGis(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoaderAttributes(false);
          setLoaderGis(false);
          setLoaderPhotos(false);
          setLoaderAnnexPhotos(false);
        });
    });
  }, []);

  return (

    <section>
      <PageTitle
        title="Edit HA Report"
        name={formData.info.general.hazard_name || 'Unknown'}
        navigate={navigate}
      />
      <Tabs selectedIndex={activeTab} onSelect={handleTabChange}>
        <div className="flex-right">
          <TabList>
            <Tab>
              <span className={`${errors.general
                || errors.geographic_and_terrain_description
                || errors.hazard_type
                || errors.next_landrelease_activity
                || errors.lm
                || errors.bm ? 'error-text' : ''}`}
              >
                Attributes
              </span>
            </Tab>
            <Tab><span className={`${errors.lm || errors.bm ? 'error-text' : ''}`}>Gis</span></Tab>
            <Tab><span className={`${errors.annex ? 'error-text' : ''}`}>Photo</span></Tab>
            <Tab><span>Check</span></Tab>
          </TabList>
        </div>

        <TabPanel>
          <div ref={generalRef}>
            <Loader visible={loaderPhotos} />
            <Loader visible={loaderAttributes} />
            <form
              className="form-wrapper"
              style={{ minHeight: 'calc(100vh - 215px)' }}
            >
              <AttributesTab
                formData={formData}
                setFormData={setFormData}
                handleTabChange={handleTabChange}
                setLoaderAttributes={setLoaderAttributes}
                photos={photos}
                setPhotos={setPhotos}
                map1={map1}
                setMap1={setMap1}
                map2={map2}
                setMap2={setMap2}
                turnPointsMap={turnPointsMap}
                setTurnPointsMap={setTurnPointsMap}
                validationForm={validationForm}
                errors={errors}
                setLoaderPhotos={setLoaderPhotos}
              />
            </form>
          </div>
        </TabPanel>
        <TabPanel>
          <Loader visible={loaderGis} />
          <GisTab
            formData={formData}
            setFormData={setFormData}
            handleTabChange={handleTabChange}
            filesNames={filesNames}
            setFilesNames={setFilesNames}
            files={files}
            setFiles={setFiles}
            setLoaderGis={setLoaderGis}
            map={map}
            setMap={setMap}
            errors={errors}
          />
        </TabPanel>
        <TabPanel>
          <Loader visible={loaderPhotos} />
          <Loader visible={loaderAnnexPhotos} />
          <PhotosTab
            formData={formData}
            setFormData={setFormData}
            handleTabChange={handleTabChange}
            photos={photos}
            setPhotos={setPhotos}
            loader={loader}
            loaderPhotos={loaderPhotos}
            setLoader={setLoader}
            setLoaderPhotos={setLoaderPhotos}
            annexImages={annexImages}
            setAnnexImages={setAnnexImages}
            errors={errors}
          />
        </TabPanel>
        <TabPanel>
          <Loader visible={loaderAttributes} />
          <Loader visible={loader} />
          <CheckTab
            formData={formData}
            setFormData={setFormData}
            loader={loader}
            setLoader={setLoader}
            handleTabChange={handleTabChange}
            photos={photos}
            setPhotos={setPhotos}
            map={map}
            setMap={setMap}
            annexImages={annexImages}
            setAnnexImages={setAnnexImages}
            map1={map1}
            setMap1={setMap1}
            map2={map2}
            setMap2={setMap2}
          />
        </TabPanel>
      </Tabs>

      <div className="form-buttons buttons-group py-10">
        <ButtonComponent
          iconLeft={<PencilSvg />}
          handleButton={handleDraftButton}
          label="Draft"
          disabled={false}
          className="warning"
        />
        <ButtonComponent
          iconLeft={<SendSvg />}
          handleButton={handleSaveButton}
          label="Send"
          disabled={sendHAButtonDisabled}
        />
        <ButtonComponent
          iconLeft={<CloseSvg />}
          handleButton={handleCancelButton}
          label="Cancel"
          disabled={false}
          className="danger"
        />
      </div>
      <ToastContainer />
    </section>
  );
};
