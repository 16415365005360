import React from 'react';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { CommentDisplay } from 'component/CommentBlock';
import { AccordionHeaderTitle } from 'component/Accordion';
import { ResidentialAreasView } from './ResidentialAreas';
import { SocialServicesView } from './SocialServices';
import { IndustrialView } from './Industrial';
import { AccessView } from './Access';
import { AgriculturalView } from './Agricultural';
import { NaturalResoursesView } from './NaturalResourses';
import { DirectBeneficiariesView } from './DirectBeneficiaries';
import { IndirectBeneficiariesView } from './IndirectBeneficiaries';

export const BeneficiariesView = (props) => {
  const { formData, comments } = props;
  return (
    <AccordionItem uuid="beneficiaries">
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="8"
            titleUA="Тип землекористування та прямі бенефіціари"
            titleEN="Landuse and beneficiaries"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="custom-accordion-item-panel">
        <ResidentialAreasView formData={formData} />
        <SocialServicesView formData={formData} />
        <IndustrialView formData={formData} />
        <AccessView formData={formData} />
        <AgriculturalView formData={formData} />
        <NaturalResoursesView formData={formData} />
      </AccordionItemPanel>
      <AccordionItemPanel>
        <div className="text text-center">
          <div>*В сумі має дорівнювати 100%</div>
          <div>*Total must equal 100%</div>
        </div>
        <DirectBeneficiariesView formData={formData} />
        <IndirectBeneficiariesView formData={formData} />
        <CommentDisplay comments={comments} block="landuse_and_beneficiaries" />
      </AccordionItemPanel>

    </AccordionItem>
  );
};
