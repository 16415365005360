import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';

export const LocalCommunityView = (props) => {
  const { formData, subId } = props;

  return (
    <AccordionBodyItemView
      accordionSubId={subId ? subId : 2.3}
      titleUA="Територіальна громада"
      titleEN="Local community"
      children={formData.info?.location?.local_community || '-'}
    />
  );
};
