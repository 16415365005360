import { Table, TableHeader, TableRow } from 'component/Table';
import { ReactComponent as DeleteSvg } from 'assets/images/icons/delete_24px.svg';
import { ReactComponent as PlusSvg } from 'assets/images/icons/add_24px.svg';
import React, { useEffect, useState } from 'react';
import MarkingIcon from 'assets/images/icons/marking-icon.png';
import { convert3857To4326 } from 'utils/Helper';
import { feature, featureCollection } from '@turf/helpers';
import { ADD_MARKING_COORDINATE, DELETE_MARKING_COORDINATE, UPDATE_MARKING_COORDINATES } from 'reducers/dailyFormActions';

export const Coordinates = ({
  formData,
  dispatch,
  map,
}) => {
  const [geojsonMarking, setGeojsonMarking] = useState(null);

  const validateCoordinate = (value) => {
    const normalizedValue = value.replace(',', '.');

    const regex = /^-?\d*\.?\d{0,6}$/;

    return value === '' || regex.test(normalizedValue) ? normalizedValue : false;
  };

  const updateGeojsonMarking = () => {
    const geojson = featureCollection(
      formData?.info?.marking?.coordinates?.items
        .map((item) => {
          const parsedLong = parseFloat(item.long);
          const parsedLat = parseFloat(item.lat);
          if (!parsedLong || !parsedLat) return null;

          return feature({
            type: 'Point',
            coordinates: [parsedLong, parsedLat],
          });
        })
        .filter(Boolean),
    );

    if (!geojson || !geojson?.features?.length) {
      setGeojsonMarking(featureCollection([]));
      return;
    }
    setGeojsonMarking(geojson);
  };

  const addMarkingIconsToMap = (data) => {
    if (map && data) {
      if (map.getLayer('marking-tcp-layer')) {
        map.getSource('marking-tcp').setData(data);
      } else {
        map.addSource('marking-tcp', {
          type: 'geojson',
          data,
          generateId: true,
        });

        [{ 'marking-icon': MarkingIcon }].forEach((icon) => {
          map.loadImage(Object.values(icon)[0], (error, image) => {
            if (error) throw error;
            map.addImage(Object.keys(icon)[0], image);
          });
        });

        map.addLayer({
          id: 'marking-tcp-layer',
          type: 'symbol',
          source: 'marking-tcp',
          layout: {
            'icon-image': 'marking-icon',
            'icon-size': 0.5,
            'icon-offset': [0, 0],
            'icon-allow-overlap': true,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (!geojsonMarking) return;
    addMarkingIconsToMap(convert3857To4326(geojsonMarking));
  }, [geojsonMarking]);

  useEffect(() => {
    if (map && formData?.info?.marking?.coordinates?.items) {
      updateGeojsonMarking();
    }
  }, [map, formData?.info?.marking?.coordinates?.items]);

  const addNewCoordinates = () => {
    dispatch({
      type: ADD_MARKING_COORDINATE,
      payload: { long: 0, lat: 0 }
    });
  };

  const handleCoordinates = (e, index) => {
    const { name, value } = e.target;

    const validValue = validateCoordinate(value);
    if (validValue !== false) {
      dispatch({
        type: UPDATE_MARKING_COORDINATES,
        payload: {
          index,
          field: name,
          value: validValue
        }
      });
    }
  };
  const handleDeleteCoords = (index) => {
    dispatch({
      type: DELETE_MARKING_COORDINATE,
      payload: index
    });
  };

  const handleOnBlur = () => {
    if (document.activeElement.tagName === 'INPUT') {
      updateGeojsonMarking();
    }
  };

  return (
    <div className="without-bg">
      <Table className="table-lines-between">
        <TableHeader>
          <th
            className="double-text"
            style={{ flex: '100px' }}
          >
            <div>Номер</div>
            <div>Number</div>
          </th>
          <th aria-label="line"><div className="line" /></th>
          <th>Long/UTM X</th>
          <th aria-label="line"><div className="line" /></th>
          <th>Lat/UTM Y</th>
          <th aria-label="line"><div className="line" /></th>
          <th
            aria-label="button"
            onClick={addNewCoordinates}
            className="text-right"
            style={{
              flex: '100px',
              justifyContent: 'end',
              display: 'flex',
            }}
          >
            <span className="plus-table">
              <PlusSvg />
            </span>
          </th>
        </TableHeader>
        <tbody>
          {!formData?.info?.marking?.coordinates?.items?.length
            && (
              <TableRow>
                <td className="empty">Empty</td>
              </TableRow>
            )}
          {formData?.info?.marking?.coordinates
            && formData?.info?.marking?.coordinates?.items?.map((coords, index) => (
              <TableRow key={index}>
                <td
                  className="flex-group"
                  style={{ flex: '100px' }}
                >
                  <span>{index + 1}</span>
                </td>
                <td aria-label="line"><div className="line" /></td>
                <td aria-label="input">
                  <input
                    id={`${index}-long`}
                    className="text-center"
                    name="long"
                    placeholder="0"
                    value={coords.long}
                    autoComplete="off"
                    onChange={(e) => {
                      handleCoordinates(e, index);
                    }}
                    onBlur={() => handleOnBlur()}
                  />
                </td>
                <td aria-label="line"><div className="line" /></td>
                <td aria-label="input">
                  <input
                    id={`${index}-lat`}
                    className="text-center"
                    placeholder="0"
                    name="lat"
                    value={coords.lat}
                    autoComplete="off"
                    onChange={(e) => {
                      handleCoordinates(e, index);
                    }}
                    onBlur={() => handleOnBlur(index)}
                  />
                </td>
                <td aria-label="line"><div className="line" /></td>
                <td
                  aria-label="delete"
                  style={{ flex: '100px' }}
                >
                  <DeleteSvg
                    className="delete-icon"
                    onClick={() => {
                      handleDeleteCoords(index);
                    }}
                  />
                </td>
              </TableRow>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export const CoordinatesView = ({ coordinates, map }) => {
  const [geojsonMarking, setGeojsonMarking] = useState(null);

  const updateGeojsonMarking = () => {
    const geojson = featureCollection(
      coordinates?.items
        .map((item) => {
          const parsedLong = parseFloat(item.long);
          const parsedLat = parseFloat(item.lat);
          if (!parsedLong || !parsedLat) return null;
          return feature({
            type: 'Point',
            coordinates: [parsedLong, parsedLat],
          });
        })
        .filter(Boolean),
    );
    if (!geojson || !geojson?.features?.length) {
      setGeojsonMarking(featureCollection([]));
      return;
    }
    setGeojsonMarking(geojson);
  };

  const addMarkingIconsToMap = (data) => {
    if (map && data) {
      if (map.getLayer('marking-tcp-layer')) {
        map.getSource('marking-tcp').setData(data);
      } else {
        map.addSource('marking-tcp', {
          type: 'geojson',
          data,
          generateId: true,
        });
        [{ 'marking-icon': MarkingIcon }].forEach((icon) => {
          map.loadImage(Object.values(icon)[0], (error, image) => {
            if (error) throw error;
            map.addImage(Object.keys(icon)[0], image);
          });
        });
        map.addLayer({
          id: 'marking-tcp-layer',
          type: 'symbol',
          source: 'marking-tcp',
          layout: {
            'icon-image': 'marking-icon',
            'icon-size': 0.5,
            'icon-offset': [0, 0],
            'icon-allow-overlap': true,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (!geojsonMarking) return;
    addMarkingIconsToMap(convert3857To4326(geojsonMarking));
  }, [geojsonMarking]);

  useEffect(() => {
    if (map && coordinates?.items) {
      console.log('View: Map and coordinates are ready:', {
        map: !!map,
        coordinates: coordinates.items
      });
      updateGeojsonMarking();
    }
  }, [map, coordinates?.items]);

  return (
    <>
      <Table>
        <TableHeader>
          <th
            className="double-text"
            style={{ flex: '100px' }}
          >
            <div>Номер</div>
            <div>№</div>
          </th>
          <th>Long/UTM X</th>
          <th>Lat/UTM Y</th>
        </TableHeader>
        <tbody>
          {!coordinates?.items?.length && (
            <TableRow>
              <td className="empty">Empty</td>
            </TableRow>
          )}
          {coordinates?.items?.map((coords, index) => (
            <div key={index}>
              <TableRow >
                <td
                  className="flex-group"
                  style={{ flex: '100px' }}
                >
                  <span>{index + 1}</span>
                </td>
                <td aria-label="input">
                  <span>{coords?.long}</span>
                </td>
                <td aria-label="input">
                  <span>{coords?.lat}</span>
                </td>
              </TableRow>
              <div className="line" />
              </div>
          ))}
        </tbody>
      </Table>
    </>
  );
};