import axios from 'axios';
import { ButtonComponent } from 'component/Button';
import { CommentBlock, handleReportValidation } from 'component/CommentBlock';
import { Loader } from 'component/Loader';
import { REPORT_TYPES } from 'consts/ReportValidation';
import useProgressMap from 'hooks/useProgressMap';
import { useValidationComments } from 'hooks/useValidationComments';
import { DMSBlockView } from 'pages/TaskClearance/Daily/Blocks/DMSBlock/DMSBlock';
import { FieldWorkBlockView } from 'pages/TaskClearance/Daily/Blocks/FieldWorkBlock/FieldWorkBlock';
import React, { useEffect, useState } from 'react';
import { Accordion, AccordionItem } from 'react-accessible-accordion';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

const WeeklyValidatePage = () => {
  const { clearanceId, weeklyId } = useParams();

  const [map, setMap] = useState(null);
  const [formData, setFormData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [gridGeojson, mapLoader, setGridGeojson] = useProgressMap(
    clearanceId,
    null,
    weeklyId,
    !!formData
  );

  useEffect(() => {
    const getWeeklyFormData = async () => {
      setLoader(true);
      try {
        const response = await axios.get(`/reports/clearance/weekly/${weeklyId}/`);
        setFormData(response.data);
      } catch (error) {
        console.error('Failed to fetch weekly data:', error);
      } finally {
        setLoader(false);
      }
    };

    if (weeklyId) {
      getWeeklyFormData();
    }
  }, [weeklyId]);

  return (
    <section>
      <div className="flex-column-group">
        <WeeklyValidateForm
          formData={formData}
          setFormData={setFormData}
          gridGeojson={gridGeojson}
          setGridGeojson={setGridGeojson}
          mapLoader={mapLoader}
          setLoader={setLoader}
          map={map}
        />
      </div>
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};

export const WeeklyValidateForm = ({
  formData,
  setFormData,
  gridGeojson,
  setGridGeojson,
  mapLoader,
  setLoader,
  map,
}) => {
  const { weeklyId } = useParams();
  const commentsData = useValidationComments(REPORT_TYPES.WEEKLY, weeklyId);
  const {
    comments,
    commentForm,
    savedComment,
    commentEntered,
    onSaveComment,
    onDeleteComment,
    handleComments,
    resetCommentForm,
  } = commentsData;

  const navigate = useNavigate();

  const handleValidation = (status, event) => {
    handleReportValidation(
      status,
      event,
      `/validations/weekly-reports/${weeklyId}/`,
      '/task-clearance/list',
      setLoader,
      savedComment,
      resetCommentForm,
      navigate
    );
  };

  return (
    <div className="daily-form">
      <div className="form-container flex-column-group">
        <WeeklyFormHeader />
        <Accordion
          allowMultipleExpanded
          allowZeroExpanded
          preExpanded={['dms', 'fieldWork', 'marking', 'battle-area-clearance', 'eoFound']}
          className="custom-accordion"
        >
          <AccordionItem uuid="dms">
            <DMSBlockView formData={formData} setFormData={setFormData} />
            <CommentBlock
              comments={comments}
              savedComment={savedComment}
              commentForm={commentForm}
              commentEntered={commentEntered}
              block="dms"
              onDeleteComment={onDeleteComment}
              handleComments={handleComments}
              onSaveComment={onSaveComment}
            />
          </AccordionItem>
          {(formData?.info?.dms?.team_activity === 'field_work' ||
            formData?.info?.dms?.team_activity?.includes('field_work')) && (
            <AccordionItem uuid="fieldWork">
              <FieldWorkBlockView
                formData={formData}
                setFormData={setFormData}
                gridGeojson={gridGeojson}
                setGridGeojson={setGridGeojson}
                loader={mapLoader}
                mode="validation"
                commentsData={commentsData}
                map={map}
              />
              <CommentBlock
                comments={comments}
                savedComment={savedComment}
                commentForm={commentForm}
                commentEntered={commentEntered}
                block="field_work"
                onDeleteComment={onDeleteComment}
                handleComments={handleComments}
                onSaveComment={onSaveComment}
              />
            </AccordionItem>
          )}
          <div className="form-buttons-end buttons-group py-10">
            <ButtonComponent
              handleButton={(event) => handleValidation('rejected', event)}
              label="Reject"
              disabled={false}
              className="danger"
              type="button"
            />
            <ButtonComponent
              handleButton={(event) => handleValidation('accepted', event)}
              label="Accept"
              disabled={false}
              type="button"
            />
          </div>
        </Accordion>
      </div>
    </div>
  );
};

const WeeklyFormHeader = () => (
  <div className="form-header">
    <span style={{ width: '250px' }}>Validate a weekly</span>
    <div className="line" />
  </div>
);
export default WeeklyValidatePage;
