import React from 'react';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { AccordionHeaderTitle } from 'component/Accordion';
import { CommentDisplay } from 'component/CommentBlock';
import { PriorityLevelView } from './PriorityLevel';
import { AppropriateOperationView } from './AppropriateOperation';
import { ReasonsView } from './Reasons';
import { ClearanceDepthView } from './ClearanceDepth';
import { AdditionalCommentsView } from './AdditionalComments';

export const LandRelease = (props) => {
  const {
    formData,
    comments,
    translatedFormData,
    translateState,
  } = props;
  return (
    <AccordionItem uuid="landrelease">
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="7"
            titleUA="Зауваження щодо подальшого розблокування ділянки *"
            titleEN="Information about next landrelease activity"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <PriorityLevelView formData={formData} />
        <AppropriateOperationView formData={formData} />
        <ReasonsView
          formData={formData}
          translatedFormData={translatedFormData}
          translateState={translateState}
        />
        <ClearanceDepthView
          formData={formData}
          translatedFormData={translatedFormData}
          translateState={translateState}
        />
        <AdditionalCommentsView
          formData={formData}
          translatedFormData={translatedFormData}
          translateState={translateState}
        />
        <CommentDisplay comments={comments} block="next_landrelease_activity" />
      </AccordionItemPanel>
    </AccordionItem>
  );
};
