import { AccordionBodyItem, AccordionBodyItemView2 } from 'component/Accordion';
import { TextareaComponent } from 'component/Textarea';
import React from 'react';
import { UPDATE_DMS_FIELD } from 'reducers/dailyFormActions';

export const Remarks = ({ formData, dispatch, hasError }) => {
  const handleRemarks = (value) => {
    dispatch({
      type: UPDATE_DMS_FIELD,
      payload: {
        field: 'remarks',
        value: value
      }
    });
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>1.8</span>
        </div>
      )}
      titleUA="Коментарі"
      titleEN="Coments"
      className={hasError ? 'error error-text' : ''}
      children={(
        <div className="flex-group no-wrap">
          <TextareaComponent
            value={formData?.info?.dms?.remarks || ''}
            handleTextarea={handleRemarks}
            placeholder="Type here"
          />
        </div>
      )}
    />
  );
};

export const RemarksView = ({ remarks }) => (
  <AccordionBodyItemView2
    accordionSubId={1.8}
    titleUA="Коментарі"
    titleEN="Coments"
    children={(
      <div className='flex-column-group-with-gap'>
        {Array.isArray(remarks)
          ? remarks?.map((remark, index) => (
              <div key={index}>{remark}</div>
            ))
          : remarks
        }
      </div>
    )}
  />
);
