import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'assets/styles/pages/task-clearance-plan.scss';
import { Accordion, AccordionItem } from 'react-accessible-accordion';
import { TCPNavigationStatuses } from 'consts/TCP';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as CloseSvg } from 'assets/images/icons/close_24px.svg';
import { ReactComponent as PencilSvg } from 'assets/images/icons/mode_edit_24px.svg';
import { ReactComponent as SendSvg } from 'assets/images/icons/send.svg';
import { Loader } from 'component/Loader';
import { useDailyFormManagement } from 'hooks/useDailyFormManagement';
import { useFormActions } from 'hooks/useDailyFormActions';
import { useDailyFormErrors } from 'hooks/useDailyFormErrors';
import { getHAById } from 'api/HaApi';
import { getTCPFormById } from 'api/TCPApi';
import { SET_HA_REPORT, SET_LOCATION_FROM_HA } from 'reducers/dailyFormActions';
import { NavigationTabLinks } from '../../Tabs/NavigationLinks';
import { DMSBlock } from '../Blocks/DMSBlock/DMSBlock';
import { FieldWorkBlock } from '../Blocks/FieldWorkBlock/FieldWorkBlock';

export const DailyCreatePage = () => {
  const { clearanceId } = useParams();
  const [navigationStatuses, setNavigationStatuses] = useState(TCPNavigationStatuses);
  const [errorResponse, setErrorResponse] = useState(null);
  const [currentDailyId, setCurrentDailyId] = useState(null);
  const [isLocationLoaded, setIsLocationLoaded] = useState(false);

  const {
    formData,
    loader,
    gridGeojson,
    originalGridGeojson,
    haReport,
    mapLoader,
    coords3857,
    zone,
    setGridGeojson,
    dispatch,
  } = useDailyFormManagement(clearanceId);

  const { errors, hasError, validateForm } = useDailyFormErrors(errorResponse, formData, dispatch);

  const { sendButtonDisabled, handleSaveButton, handleDraftButton, handleCancelButton } =
    useFormActions(
      clearanceId,
      setErrorResponse,
      currentDailyId,
      dispatch,
      validateForm,
      setCurrentDailyId
    );

  useEffect(() => {
    const loadLocationData = async () => {
      try {
        const tcpResponse = await getTCPFormById(clearanceId);

        if (tcpResponse?.data?.ha_report) {
          const haResponse = await getHAById(tcpResponse.data.ha_report);

          if (haResponse?.data?.info?.location) {
            dispatch({
              type: SET_LOCATION_FROM_HA,
              payload: haResponse.data.info.location,
            });
          }
        }
      } catch (error) {
        console.error('Error loading location data:', error);
      } finally {
        setIsLocationLoaded(true);
      }
    };

    loadLocationData();

    setNavigationStatuses((prev) => ({
      ...prev,
      tcpCreate: 'completed',
      daily: 'active',
      weekly: 'inactive',
      completed: 'inactive',
      done: 'inactive',
    }));

    document.title = 'Create daily';
  }, [clearanceId, dispatch]);

  return (
    <section>
      <div className="flex-column-group">
        <NavigationTabLinks statuses={navigationStatuses} mode="view" id={clearanceId} />
        <DailyCreateForm
          formData={formData}
          dispatch={dispatch}
          coords3857={coords3857}
          zone={zone}
          gridGeojson={gridGeojson}
          mapLoader={mapLoader}
          setGridGeojson={setGridGeojson}
          haReportPolygon={haReport?.info?.turn_points?.geojson}
          errorResponse={errorResponse}
          errors={errors}
          hasError={hasError}
          isLocationLoaded={isLocationLoaded}
        />
        <div className="form-buttons buttons-group py-10">
          <ButtonComponent
            iconLeft={<PencilSvg />}
            handleButton={() => handleDraftButton(formData, gridGeojson, originalGridGeojson)}
            label="Draft"
            disabled={sendButtonDisabled}
            className="warning"
          />
          <ButtonComponent
            iconLeft={<SendSvg />}
            handleButton={() => handleSaveButton(formData, gridGeojson, originalGridGeojson)}
            label="Send"
            disabled={sendButtonDisabled}
          />
          <ButtonComponent
            iconLeft={<CloseSvg />}
            handleButton={handleCancelButton}
            label="Cancel"
            disabled={false}
            className="danger"
          />
        </div>
      </div>
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};

const DailyCreateForm = ({
  formData,
  dispatch,
  coords3857,
  zone,
  gridGeojson,
  mapLoader,
  haReportPolygon,
  errorResponse,
  errors,
  hasError,
  setGridGeojson,
  isLocationLoaded,
}) => (
  <div className="daily-form">
    <div className="form-container flex-column-group">
      <DailyFormHeader />
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        preExpanded={['dms', 'fieldWork', 'marking', 'battle-area-clearance', 'eoFound']}
        className="custom-accordion"
      >
        <AccordionItem uuid="dms">
          <DMSBlock formData={formData} dispatch={dispatch} hasError={hasError} />
        </AccordionItem>
        {formData?.info?.dms?.team_activity === 'field_work' && (
          <AccordionItem uuid="fieldWork">
            {isLocationLoaded ? (
              <FieldWorkBlock
                formData={formData}
                dispatch={dispatch}
                coords3857={coords3857}
                zone={zone}
                gridGeojson={gridGeojson}
                loader={mapLoader}
                haReportPolygon={haReportPolygon}
                errorResponse={errorResponse}
                errors={errors}
                hasError={hasError}
                setGridGeojson={setGridGeojson}
              />
            ) : (
              <div className="loading-field-work">
                <Loader visible />
              </div>
            )}
          </AccordionItem>
        )}
      </Accordion>
    </div>
  </div>
);

const DailyFormHeader = () => (
  <div className="form-header">
    <span>Create a daily</span>
    <div className="line" />
  </div>
);
