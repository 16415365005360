import moment from 'moment';

export const tcpForm = {
  ha_report: null,
  donor: null,
  related_users: [],
  internal_id: null,
  imsma_id: null,
  start_date: moment(new Date()).format('DD.MM.YYYY'),
  estimate_days: null,
  deminers_polygon: null,
  deminers_files_links: [],
  files: [],
  draft: true,
  pdf_file_link: null,
  user: null,
  methods: [],
};
