import React, { useState } from 'react';
import { formatArea, formatAreaToOneDecimal, ValidationStatusComponent } from 'utils/Helper';
import { Table, TableHeader, TableRow } from 'component/Table';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as AddSvg } from 'assets/images/icons/add_24px.svg';
import { ReportActions } from '../ReportActions';
import { CLEARANCE_TASK_HEADERS } from 'consts/ReportHeaders';
import { ReactComponent as SortByIcon } from 'assets/images/icons/Sort-by2.svg';

const ClearanceTaskTable = ({
  clearanceTaskList,
  setLoader,
  setReportsPageList,
  currentPage,
  onPageChange,
  fetchClearanceTaskList,
  statuses,
}) => {
  const [rotations, setRotations] = useState({});
  const [sortDirection, setSortDirection] = useState({});
  const [lastClickedIcon, setLastClickedIcon] = useState(null);

  const getBackgroundColor = (filesProgress) => {
    if (!filesProgress || filesProgress === '-') return '#F1484847';
    const [completed, total] = filesProgress.split('/').map(Number);
    return completed === total ? '#48F15647' : '#F1484847';
  };

  const handleSortIconClick = async (key) => {
    try {
      setLoader(true);
      setReportsPageList([]);
      onPageChange(1);

      const currentDirection = sortDirection[key] || false;
      const newDirection = !currentDirection;
      setSortDirection(prev => ({
        ...prev,
        [key]: newDirection,
      }));

      const updatedStatuses = {
        ...statuses,
        ordering: newDirection ? key : `-${key}`,
      };

      await fetchClearanceTaskList(1, updatedStatuses);

      const currentRotation = rotations[key] || 0;
      const newRotation = currentRotation + 180;

      if (lastClickedIcon && lastClickedIcon !== key) {
        setRotations(prevRotations => ({
          ...prevRotations,
          [lastClickedIcon]: 0,
        }));
      }

      setRotations(prevRotations => ({
        ...prevRotations,
        [key]: newRotation,
      }));

      setLastClickedIcon(key);
    } catch (error) {
      console.error('Error fetching clearance task list:', error);
    } finally {
      setLoader(false);
    }
  };

  const handleClickOpen = () => {
    window.location.href = '/task-clearance/create/';
  };

  return (
    <div className="eore-table">
      <Table>
        <TableHeader>
          {CLEARANCE_TASK_HEADERS?.map((header, index) => (
            <th
              key={index}
              className={`text-with-icon ${header.style ? '' : ''}`}
              style={header.style || {}}
            >
              {header.key && header.condition && (
                <SortByIcon
                  className="sort-icon"
                  style={{ transform: `rotate(${rotations[header.key] || 0}deg)` }}
                  onClick={() => handleSortIconClick(header.key)}
                />
              )}
              {header.label}
            </th>
          ))}
        </TableHeader>
        <tbody>
          {clearanceTaskList?.results?.length === 0 && (
            <TableRow>
              <td>Дані відсутні</td>
            </TableRow>
          )}
          {clearanceTaskList?.results?.map((clearanceTask) => (
            <TableRow key={clearanceTask.id}>
              <td>{clearanceTask?.ha_hazard_name || ''}</td>
              <td>{clearanceTask?.internal_id || '-'}</td>
              <td>{clearanceTask?.start_date || '-'}</td>
              <td>{clearanceTask?.donor || '-'}</td>
              <td>{clearanceTask.eo_found || '-'}</td>
              <td style={{ padding: '0' }}>
                {formatArea(clearanceTask?.ha_area, clearanceTask?.cleared_area)  || '-'}
              </td>
              <td style={{
                backgroundColor: getBackgroundColor(clearanceTask?.files_progress),
                display: 'inline-table',
                flex: '50px',
                marginLeft: '30px',
                marginRight: '30px',
              }}>
                {clearanceTask?.files_progress || '-'}
              </td>
              <td>
                {clearanceTask.validation_status?.value && (
                  <ValidationStatusComponent status={clearanceTask.validation_status} />
                )}
              </td>
              <td aria-label="actions">
                <ReportActions
                  reportId={clearanceTask.id}
                  setLoader={setLoader}
                  setReportsPageList={setReportsPageList}
                  validationStatus={clearanceTask.validation_status.value}
                  isCurrentValidationRole={clearanceTask.is_current_validation_role}
                />
              </td>
            </TableRow>
          ))}
          {clearanceTaskList?.next && (
            <div className="loadMore-div">
            <button
              className="loadMore-button"
              onClick={() => {
                const newPage = currentPage + 1;
                onPageChange(newPage);
                setLoader(true);
                fetchClearanceTaskList(newPage, statuses)
                  .finally(() => {
                    setLoader(false);
                  });
              }}
            >
              Load more...
            </button>
          </div>
          )}
          <div className="drone-footer">
            <ButtonComponent
              iconLeft={<AddSvg />}
              label="Clearance Task"
              handleButton={handleClickOpen}
            />
          </div>
        </tbody>

      </Table>
    </div>
  );
};

const ClearanceTaskTab = ({
  setLoader,
  clearanceTaskList,
  setClearanceTaskList,
  currentPage,
  onPageChange,
  statuses,
  onFetch
}) => {
  return (
    <ClearanceTaskTable
      clearanceTaskList={clearanceTaskList}
      setLoader={setLoader}
      setReportsPageList={setClearanceTaskList}
      currentPage={currentPage}
      onPageChange={onPageChange}
      fetchClearanceTaskList={onFetch}
      statuses={statuses}
    />
  );
};

export default ClearanceTaskTab;