import React from 'react';
import { formatAreaToOneDecimal } from 'utils/Helper';

export const Area = ({ formData }) => (
  <div className="flex-column-group">
    <div className="double-text">
      <span>Площа (кв.м)</span>
      <span>Area (sq.m)</span>
    </div>
    <div className="text-grey">
      {formatAreaToOneDecimal(formData.info?.turn_points?.total_area_meters) || '-'}
    </div>
  </div>
);
