import React from 'react';
import { ReactComponent as ChatIcon } from 'assets/images/icons/chat.svg';

export const CommentsFromImsmaView = (props) => {
  const { comments } = props;
  if (!comments) return null;

  const filteredImsmaComments = comments
    .filter((item) => item?.comments?.hasOwnProperty('imsma'))
    .reverse();

  return (
    filteredImsmaComments?.map((comment, index) => {
      const isLastComment = index === 0;
      const commentClassName = isLastComment
        ? 'block-with-comment-green-imsma'
        : 'block-with-comment-gray-imsma';
      return (
        <div key={index} className={commentClassName}>
          <div className="icon-with-title">
            MAC
            {' '}
            <ChatIcon
              style={{
                width: '20px',
                color: '#868686',
              }}
            />
          </div>
          {comment?.comments?.imsma}
        </div>
      );
    })
  );
};
