import { AccordionHeaderTitle } from 'component/Accordion';
import React from 'react';
import { AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { IMSMAID } from 'pages/EORE/Reports/Blocks/Create/GeneralInfo/IMSMAID';
import { useUser } from 'routes/UserContext';
import { InternalHazardId } from './InternalHazardId';
import { IMSMASwither } from './IMSMASwitcher';
import { ReportDate, ReportDateString } from './ReportDate';
import { ReporterOrganization } from './ReporterOrganization';
import { Donor } from './Donor';
import { TeamId } from './TeamId';
import { ReporterPosition } from './ReporterPosition';
import { ReporterName } from './ReporterName';

export const GeneralInfo = (props) => {
  const { formData, setFormData, errors } = props;
  const { user } = useUser();
  return (
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="1"
            block="general"
            titleUA={(
              <div className="flex-group">
                <span>Загальна інформація</span>
                <span className="star">*</span>
              </div>
            )}
            titleEN="General Information"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <InternalHazardId
          formData={formData}
          setFormData={setFormData}
          errors={errors}
        />
        <IMSMAID formData={formData} />
        <IMSMASwither
          formData={formData}
          setFormData={setFormData}
          errors={errors}
        />
        {user?.change_eore_report_date && (
          <ReportDate
            formData={formData}
            setFormData={setFormData}
            errors={errors}
          />
        )}
        {!user?.change_eore_report_date && (
          <ReportDateString
            formData={formData}
          />
        )}
        <ReporterOrganization
          formData={formData}
          setFormData={setFormData}
          errors={errors}
        />
        <Donor
          formData={formData}
          setFormData={setFormData}
          errors={errors}
        />
        <TeamId
          formData={formData}
          setFormData={setFormData}
          errors={errors}
        />
        <ReporterPosition
          formData={formData}
          errors={errors}
        />
        <ReporterName
          formData={formData}
          errors={errors}
        />
      </AccordionItemPanel>
    </>
  );
};
