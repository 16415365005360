import { ButtonComponent } from 'component/Button';
import { CheckboxInput } from 'component/Checkbox';
import { ImageDeleteDialog } from 'component/Dialog';
import { React, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as ImageIcon } from 'assets/images/icons/Photo.svg';
import { createOrUpdateFile, getBase64 } from 'utils/Helper';
import { ReactComponent as TurnIcon } from 'assets/images/icons/update2.svg';
import { ReactComponent as SaveSvg } from 'assets/images/icons/save-1.svg';
import { ReactComponent as CancelSvg } from 'assets/images/icons/close_24px.svg';

export const AnnexMapDetailInfo = (props) => {
  const photo = props.annexImages[props.currentImageIndex];
  const [details, setDetails] = useState(photo);

  useEffect(() => {
    setDetails(photo);
  }, [props.currentImageIndex]);

  const savePhoto = () => {
    props.setAnnexImages((prevPhotos) => {
      const updatedPhotos = [...prevPhotos];
      updatedPhotos.forEach((_, index) => {
        updatedPhotos[index].is_main = false;
      });
      updatedPhotos[props.currentImageIndex].is_main = details.is_main;
      return updatedPhotos;
    });
    toast.success('Photo was updated');
  };

  const handleRotate = (blob) => {
    const updatedFile = createOrUpdateFile(blob, details.file.name);
    props.setAnnexImages((prevPhotos) => {
      const updatedPhotos = [...prevPhotos];
      updatedPhotos[props.currentImageIndex].data_url = URL.createObjectURL(blob);
      updatedPhotos[props.currentImageIndex].file = updatedFile;
      return updatedPhotos;
    });
  };

  return (
    <div className="file-info">
      <div className="header">
        <span>Image information</span>
        <CancelSvg onClick={props.closeModal} />
      </div>
      <div className="body">
        <div className="flex-between">
          <div className="flex-group">
            <ImageIcon className="text-hover" />
            <span>Rotate photo</span>
          </div>
          <div className="icon text-hover">
            <TurnIcon
              onClick={() => {
                props.setLoaderPhotos(true);
                getBase64(details.data_url)
                  .then((res) => {
                    handleRotate(res);
                    props.setLoaderPhotos(false);
                  })
                  .catch((err) => {
                    console.error(err);
                    props.setLoaderPhotos(false);
                  });
              }}
            />
          </div>
        </div>

        <div className="flex-group-column">
          <CheckboxInput
            id="mainAnnexMap"
            value="mainAnnexMap"
            label="Main annex map"
            name="mainAnnexMap"
            margin="no-y-margin"
            checked={details?.is_main}
            handleInput={(value, isChecked) => {
              setDetails((prevDetails) => ({
                ...prevDetails,
                is_main: isChecked,
              }));
            }}
          />
        </div>
      </div>
      <div className="footer">
        <ImageDeleteDialog
          svgOnly={false}
          deleteImage={props.deletePhoto}
          closeModal={props.closeModal}
        />
        <div className="line" />
        <div className="flex-group flex-center">
          <ButtonComponent
            handleButton={(e) => {
              savePhoto(e);
              props.closeModal();
            }}
            className="outline"
            iconLeft={<SaveSvg />}
            label="Save"
          />
          <ButtonComponent
            handleButton={props.closeModal}
            className="danger outline"
            iconLeft={<CancelSvg />}
            label="Cancel"
          />
        </div>
      </div>
    </div>
  );
};
