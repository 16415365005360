import React from 'react';
import { InputComponent } from 'component/Input';
import { getEOREIndirectBeneficiariesSum } from 'utils/Helper';

export const IndirectBeneficiaries = (props) => {
  const { formData, handleBeneficiaries } = props;

  const totalInDirectBeneficiaries = getEOREIndirectBeneficiariesSum(formData
    ?.info?.session_information?.indirect_beneficiaries);

  return (
    <div className="flex-group total-beneficiaries-container">
      <NGCAMale
        formData={formData}
        handleBeneficiaries={handleBeneficiaries}
      />
      <NGCAFemale
        formData={formData}
        handleBeneficiaries={handleBeneficiaries}
      />
      <NGCABoys
        formData={formData}
        handleBeneficiaries={handleBeneficiaries}
      />
      <NGCAGirls
        formData={formData}
        handleBeneficiaries={handleBeneficiaries}
      />
      <div className="total-beneficiaries">
        <div>
          Total:
        </div>
        <div>
          {totalInDirectBeneficiaries}
        </div>
      </div>
    </div>
  );
};

export const NGCAMale = (props) => {
  const { formData, handleBeneficiaries } = props;

  return (
    <>
      <div className="double-text">
        <span>NGCA Чоловік</span>
        <span>NGCA Male</span>
      </div>
      <InputComponent
        className="number"
        value={formData.info.session_information.indirect_beneficiaries
          .male_adult_direct_additional_count}
        handleInput={(value) => {
          handleBeneficiaries('indirect_beneficiaries', 'male_adult_direct_additional_count', value.replace(/^0+/, ''));
        }}
      />
    </>
  );
};

export const NGCAFemale = (props) => {
  const { formData, handleBeneficiaries } = props;

  return (
    <>
      <div className="double-text">
        <span>NGCA Жінка</span>
        <span>NGCA Female</span>
      </div>
      <InputComponent
        className="number"
        value={formData.info.session_information.indirect_beneficiaries
          .female_adult_direct_additional_count}
        handleInput={(value) => {
          handleBeneficiaries('indirect_beneficiaries', 'female_adult_direct_additional_count', value.replace(/^0+/, ''));
        }}
      />
    </>
  );
};

export const NGCABoys = (props) => {
  const { formData, handleBeneficiaries } = props;

  return (
    <>
      <div className="double-text">
        <span>NGCA Хлопці</span>
        <span>NGCA Boys</span>
      </div>
      <InputComponent
        className="number"
        value={formData.info.session_information.indirect_beneficiaries
          .male_child_direct_additional_count}
        handleInput={(value) => {
          handleBeneficiaries('indirect_beneficiaries', 'male_child_direct_additional_count', value.replace(/^0+/, ''));
        }}
      />
    </>
  );
};

export const NGCAGirls = (props) => {
  const { formData, handleBeneficiaries } = props;

  return (
    <>
      <div className="double-text">
        <span>NGCA Дівчата</span>
        <span>NGCA Girls</span>
      </div>
      <InputComponent
        className="number"
        value={formData.info.session_information.indirect_beneficiaries
          .female_child_direct_additional_count}
        handleInput={(value) => {
          handleBeneficiaries('indirect_beneficiaries', 'female_child_direct_additional_count', value.replace(/^0+/, ''));
        }}
      />
    </>
  );
};
