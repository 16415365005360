import { React, useEffect, useState } from 'react';
import ImageUploading from 'react-images-uploading';
import ImageIcon from 'assets/images/icons/Photo.svg';
import ImageGallery from 'react-image-gallery';
import Modal from 'react-modal';
import { ImageDeleteDialog } from 'component/Dialog';
import { toast } from 'react-toastify';
import { AnnexMapDetailInfo } from './AnnexMapDetailInfo';

const ImageItem = (props) => (
  <div className="image-item">
    <img src={props.data_url} alt="" />
    <div className="image-item__btn-wrapper">
      <ImageDeleteDialog
        deleteImage={props.deleteImage}
        svgOnly
      />
      <span
        className="zoom"
        onClick={props.openModal}
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M14.76 13.27L20.49 19L19 20.49L13.27 14.76C12.2 15.53 10.91 16 9.5 16C5.91 16 3 13.09 3 9.5C3 5.91 5.91 3 9.5 3C13.09 3 16 5.91 16 9.5C16 10.91 15.53 12.2 14.76 13.27ZM9.5 5C7.01 5 5 7.01 5 9.5C5 11.99 7.01 14 9.5 14C11.99 14 14 11.99 14 9.5C14 7.01 11.99 5 9.5 5Z" fill="#868686" />
        </svg>
      </span>
      <span
        className="check"
      >
        <svg className={props.is_main ? 'active' : ''} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.99387 20L2 12.8898L3.74847 11.1123L8.99387 16.4449L20.2515 5L22 6.77755L8.99387 20Z" fill="#868686" />
        </svg>
      </span>
    </div>
  </div>
);

export const AnnexMapBlock = (props) => {
  const [images, setImages] = useState(props.annexImages || []);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setCurrentImageIndex(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!props.annexImages.length) return;
    props.setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      if (!updatedFormData.info.hasOwnProperty('annex')) {
        updatedFormData.info.annex = {
          items: [],
        };
      }
      updatedFormData.info.annex.items = props.annexImages;
      return updatedFormData;
    });
  }, [props.annexImages]);

  const onChangePhoto = async (imageList, addUpdateIndex) => {
    let openGallery = true;
    const photoPromises = imageList.map(async (image) => {
      const { file } = image;
      const objectURL = URL.createObjectURL(file);

      return {
        file_name: file.name,
        data_url: objectURL,
        file,
        description: '',
        is_main: false,
      };
    });

    const newPhotos = await Promise.all(photoPromises);

    if (addUpdateIndex) {
      addUpdateIndex.forEach((index) => {
        const photoToAdd = newPhotos[index];
        if (!props.annexImages?.some((photo) => photo.file_name === photoToAdd.file_name
          || photo.name === photoToAdd.file_name)) {
          props.setAnnexImages((prevPhotos) => ([...prevPhotos, photoToAdd]));
          openGallery = true;
        } else {
          toast.warning('You cannot upload annex map with same name');
          openGallery = false;
        }
      });
    }

    setImages(imageList);
    if (openGallery) {
      openModal(props.annexImages.length);
    }
  };

  const deleteImageByIndex = (index) => {
    props.setAnnexImages((prevPhotos) => {
      if (index >= 0 && index < prevPhotos.length) {
        const updatedPhotos = prevPhotos.filter((_, i) => i !== index);
        return updatedPhotos;
      }

      return prevPhotos;
    });
  };

  const mainImage = props.annexImages.find((image) => image.is_main);
  const otherImages = props.annexImages.filter((image) => !image.is_main);

  return (
    <div>
      <ImageUploading
        multiple
        value={images}
        onChange={onChangePhoto}
        dataURLKey="data_url"
        acceptType={['jpg', 'jpeg']}
      >
        {({
          onImageUpload,
          isDragging,
          dragProps,
        }) => (
          <div>
            <div className={`upload-image-wrapper ${props.imageError ? 'error' : ''}`}>
              {mainImage && (
                <ImageItem
                  deleteImage={() => deleteImageByIndex(props.annexImages.indexOf(mainImage))}
                  openModal={() => openModal(props.annexImages.indexOf(mainImage))}
                  data_url={mainImage.data_url}
                  is_main={mainImage.is_main}
                />
              )}
              {otherImages && otherImages.map((image, index) => (
                <ImageItem
                  key={index}
                  data_url={image.data_url}
                  deleteImage={() => deleteImageByIndex(props.annexImages.indexOf(image))}
                  openModal={() => openModal(props.annexImages.indexOf(image))}
                  is_main={image.is_main}
                />
              ))}

              <div
                className="new-image-item"
                style={isDragging ? { color: 'red' } : null}
              >
                <img src={ImageIcon} alt="icon" />
                <button
                  className="outline"
                  onClick={(e) => {
                    e.preventDefault();
                    onImageUpload();
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...dragProps}
                >
                  + Add image
                </button>
              </div>
            </div>
          </div>
        )}
      </ImageUploading>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="react-inner-modal-class"
        overlayClassName="react-modal-class"
      >
        {currentImageIndex !== null && (
          <div className="image-gallery-wrapper">
            <ImageGallery
              items={props.annexImages.map((image) => ({
                original: image.data_url,
                thumbnail: image.data_url,
                thumbnailHeight: '60px',
                thumbnailWidth: '60px',
              }))}
              startIndex={currentImageIndex}
              onSlide={(index) => setCurrentImageIndex(index)}
            />
            <AnnexMapDetailInfo
              annexImages={props.annexImages}
              setAnnexImages={props.setAnnexImages}
              currentImageIndex={currentImageIndex}
              closeModal={closeModal}
              deletePhoto={() => deleteImageByIndex(currentImageIndex)}
              setLoaderPhotos={props.setLoaderPhotos}
            />
          </div>
        )}
      </Modal>
    </div>
  );
};
