import React, { useState } from 'react';
import '../assets/styles/search.scss';
import { TextField, InputAdornment } from '@mui/material'; // Changed import
import { styled } from '@mui/material/styles'; // Added for v5 styling
import { ReactComponent as SearchIcon } from '../assets/images/icons/Search.svg';

const SearchComponent = ({ onSearch }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInputValue(value);
    if (value === '') {
      onSearch(value);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSearch(inputValue);
    }
  };

  const handleSearchIconClick = () => {
    onSearch(inputValue);
  };

  return (
    <TextField
      id="outlined-basic"
      label="Search"
      variant="outlined"
      size="small"
      sx={{
        '& .MuiOutlinedInput-root': {
          fontSize: '14px',
          fontWeight: '400',
          borderRadius: '12px',
          color: '#8B8B8B',
          '&:hover input': {
            border: 'none',
            borderRadius: '12px',
          },
          '& fieldset': {
            borderColor: '#8B8B8B',
          },
          '&:hover fieldset': {
            borderColor: '#8B8B8B',
            borderRadius: '12px',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#00B1B7',
            borderRadius: '12px',
          },
        },
        '& .Mui-focused .search-icon': {
          color: '#00B1B7',
        },
        '& .MuiInputBase-root': {
          color: '#8B8B8B',
          '&.Mui-focused': {
            color: '#8B8B8B',
          },
        }
      }}
      InputLabelProps={{
        sx: {
          color: '#8B8B8B',
          fontSize: '15px',
          '&.Mui-focused': {
            color: '#8B8B8B'
          }
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon
              sx={{
                color: '#8B8B8B',
                cursor: 'pointer',
                '&.search-icon': {
                  color: '#8B8B8B',
                }
              }}
              className="search-icon"
              onClick={handleSearchIconClick}
            />
          </InputAdornment>
        ),
      }}
      value={inputValue}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
    />
  );
};

export default SearchComponent;