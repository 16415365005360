export const REPORT_TYPES = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  TASK_CLEARANCE: 'task-clearance',
  EORE: 'eore',
  NTS: 'nts',
  HA: 'ha',
};

export const BLOCK_TYPES = {
  [REPORT_TYPES.DAILY]: {
    blocks: ['dms', 'field_work', 'marking', 'eo_found'],
    endpoint: (id) => `/validations/daily-reports/${id}`,
  },
  [REPORT_TYPES.WEEKLY]: {
    blocks: ['dms', 'field_work', 'marking', 'eo_found'],
    endpoint: (id) => `/validations/weekly-reports/${id}`,
  },
  [REPORT_TYPES.TASK_CLEARANCE]: {
    blocks: ['ha', 'tcp', 'team', 'gis', 'progress_map', 'files'],
    endpoint: (id) => `/validations/clearance-task/${id}`,
  },
  [REPORT_TYPES.EORE]: {
    blocks: ['general', 'session_information', 'final_indicators'],
    endpoint: (id) => `/validations/eore/${id}`,
  },
  [REPORT_TYPES.NTS]: {
    blocks: ['general', 'location', 'nto_result'],
    endpoint: (id) => `/validations/nts/${id}`,
  },
  [REPORT_TYPES.HA]: {
    blocks: ['general', 'contamination', 'evidences',
      'geographic_and_terrain_description',
      'informators', 'landuse_and_beneficiaries', 'location',
      'next_landrelease_activity', 'polygon_info', 'map'],
    endpoint: (id) => `/validations/ha/${id}`,
  },
};
