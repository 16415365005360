import { featureCollection } from '@turf/helpers';
import { getProgressMap } from 'api/DailyApi';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const useProgressMap = (clearanceId, dailyId = null, weeklyId = null, startLoading = false) => {
  const [gridGeojson, setGridGeojson] = useState(featureCollection([]));
  const [mapLoader, setMapLoader] = useState(false);

  useEffect(() => {
    if (!startLoading || !clearanceId) {
      return;
    }

    const abortController = new AbortController();
    const { signal } = abortController;

    const fetchAllProgressMapPages = async () => {
      setMapLoader(true);
      try {
        let allFeatures = [];
        let pageNum = 1;
        let hasMorePages = true;

        while (hasMorePages) {
          const params = {
            page: pageNum,
          };

          if (dailyId) {
            params.daily_report_id = dailyId;
          } else if (weeklyId) {
            params.weekly_report_id = weeklyId;
          }

          const res = await getProgressMap(clearanceId, params, abortController.signal);

          if (signal.aborted) {
            break;
          }

          if (res.data.status === 'error') {
            toast.error('Failed to load progress map data');
            break;
          }

          if (res.data.results && Array.isArray(res.data.results)) {
            allFeatures = [...allFeatures, ...res.data.results];
          }

          hasMorePages = !!res.data.next;
          pageNum++;
        }

        if (!signal.aborted) {
          const finalGeoJson = featureCollection(allFeatures);
          setGridGeojson(finalGeoJson);
        }
      } catch (err) {
        if (err.name === 'AbortError') {
          console.log('Progress map request aborted');
        } else {
          console.error(err);
          if (!signal.aborted) {
            toast.error('Failed to load progress map data');
          }
        }
      } finally {
        if (!signal.aborted) {
          setMapLoader(false);
        }
      }
    };

    fetchAllProgressMapPages();

    return () => {
      abortController.abort();
    };
  }, [clearanceId, dailyId, weeklyId, startLoading]);

  return [gridGeojson, mapLoader, setGridGeojson];
};

export default useProgressMap;
