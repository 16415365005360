import { AccordionBodyItem, AccordionBodyItemView } from 'component/Accordion';
import { SelectComponent } from 'component/Select';
import React from 'react';
import { UPDATE_TASK_TYPE } from 'reducers/dailyFormActions';

const optionsTaskType = [
  { value: 'manual_clearance', label: 'Manual Clearance' },
  { value: 'battle_area_clearance', label: 'Battle Area Clearance' },
  { value: 'technical_survey', label: 'Technical survey' },
  { value: 'marking', label: 'Marking' },
];

export const TaskType = ({ formData, dispatch, hasError }) => {
  const handleTaskType = (value) => {
    dispatch({
      type: UPDATE_TASK_TYPE,
      payload: value,
    });
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>2.2</span>
        </div>
      )}
      titleUA="Тип завдання"
      titleEN="Task type"
      className={hasError ? 'error error-text' : ''}
      children={(
        <SelectComponent
          options={optionsTaskType}
          value={optionsTaskType.find((option) => option.value === formData
            ?.info?.field_work?.task_type)}
          handleSelect={handleTaskType}
        />
      )}
    />
  );
};

export const TaskTypeView = ({ taskType }) => (
  <AccordionBodyItemView
    accordionSubId={2.2}
    titleUA="Тип завдання"
    titleEN="Task type"
    children={
      <div className="flex-column-group-with-gap">
        {Array.isArray(taskType)
          ? taskType.map((type, index) => (
              <div key={index}>
                {optionsTaskType.find((option) => option.value === type)?.label}
              </div>
            ))
          : <div>
              {optionsTaskType.find((option) => option.value === taskType)?.label}
            </div>
        }
      </div>
    }
  />
);
