import { AsyncSelectComponent, SelectComponent } from 'component/Select';
import { AccordionBodyItem } from 'component/Accordion';
import { React, useEffect, useState } from 'react';
import {
  getDistrict, getLocalCommunities, getRegion, getSettlement,
} from 'api/LocationAPI';
import { axiosWrapper, convertCoords3857To4326 } from 'utils/Helper';
import axios from 'axios';
import { UPDATE_LOCATION_FIELD, UPDATE_LOCATION_POINT } from 'reducers/dailyFormActions';

export const LocationComponent = (props) => {
  const [districtOptions, setDistrictOptions] = useState([]);
  const [communityOptions, setCommunityOptions] = useState([]);
  const [settlementOptions, setSettlementOptions] = useState([]);

  const [region, setRegion] = useState(props.formData?.info[props.fieldBlock]?.region || '');
  const [district, setDistrict] = useState(props.formData?.info[props.fieldBlock]?.district || '');
  const [localCommunity, setLocalCommunity] = useState(props.formData?.info[props.fieldBlock]?.local_community || '');
  const [settlementName, setSettlementName] = useState(props.formData?.info[props.fieldBlock]?.settlement_name || '');

  const updateFormField = (field, value) => {
    if (props.dispatch) {
      props.dispatch({
        type: UPDATE_LOCATION_FIELD,
        payload: {
          fieldBlock: props.fieldBlock,
          field,
          value
        }
      });
    } else {
      props.setFormData((prevFormData) => ({
        ...prevFormData,
        info: {
          ...prevFormData.info,
          [props.fieldBlock]: {
            ...prevFormData.info[props.fieldBlock],
            [field]: value,
          },
        },
      }));
    }
  };

  useEffect(() => {
    setRegion(props.formData.info[props.fieldBlock]?.region);
    setDistrict(props.formData.info[props.fieldBlock]?.district);
    setLocalCommunity(props.formData.info[props.fieldBlock]?.local_community);
    setSettlementName(props.formData.info[props.fieldBlock]?.settlement_name);
  }, [props.formData.id, props.formData?.info?.general?.hazard_name]);

  const getLocationFunction = (selectName) => {
    switch (selectName) {
      case 'region':
        return getRegion();
      case 'district':
        return getDistrict(region);
      case 'local_community':
        return getLocalCommunities(region, district);
      case 'settlement_name':
        return getSettlement(region, district, localCommunity);
      default:
        return null;
    }
  };

  const loadOptions = (selectName, inputValue) => new Promise((resolve, reject) => {
    axiosWrapper(async () => {
      try {
        const response = await getLocationFunction(selectName);
        const data = await response?.data;

        const options = data?.map((item) => ({
          value: `${item.name} / ${item.eng_name}`,
          label: `${item.name} / ${item.eng_name}`,
        }));

        if (inputValue) {
          const filteredOptions = options.filter((option) => option.label
            .toLowerCase().includes(inputValue.toLowerCase()));

          resolve(filteredOptions);
        } else {
          resolve(options);
        }
      } catch (error) {
        console.error('Error fetching options:', error);
        reject(error);
      }
    });
  });

  useEffect(() => {
    const fetchDistrictOptions = async () => {
      try {
        const options = await loadOptions('district');
        setDistrictOptions(options);
      } catch (error) {
        console.error('Error loading district options:', error);
      }
    };
    if (!region) return;

    fetchDistrictOptions();
  }, [region]);

  useEffect(() => {
    const fetchLocalCommunityOptions = async () => {
      try {
        const options = await loadOptions('local_community');
        setCommunityOptions(options);
      } catch (error) {
        console.error('Error loading district options:', error);
      }
    };
    if (!district) return;
    fetchLocalCommunityOptions();
    // setLocalCommunity('');
    // setSettlementName('');
  }, [district]);

  useEffect(() => {
    const fetchSettlementOptions = async () => {
      try {
        const options = await loadOptions('settlement_name');
        setSettlementOptions(options);
      } catch (error) {
        console.error('Error loading district options:', error);
      }
    };
    if (!localCommunity) return;
    fetchSettlementOptions();
    // setSettlementName('');
  }, [localCommunity]);

  return (
    <>
      <Region
        setRegion={setRegion}
        setFormData={props.setFormData}
        dispatch={props.dispatch}
        updateFormField={updateFormField}
        region={region}
        loadOptions={loadOptions}
        errors={props.errors}
        fieldBlock={props.fieldBlock}
        startSubNumber={props.startSubNumber || 1}
      />
      <Disctrict
        setDistrict={setDistrict}
        dispatch={props.dispatch}
        setFormData={props.setFormData}
        updateFormField={updateFormField}
        districtOptions={districtOptions}
        setDistrictOptions={setDistrictOptions}
        loadOptions={loadOptions}
        region={region}
        district={district}
        errors={props.errors}
        fieldBlock={props.fieldBlock}
        startSubNumber={props.startSubNumber + 1 || 2}
      />
      <LocalCommunity
        setLocalCommunity={setLocalCommunity}
        setFormData={props.setFormData}
        dispatch={props.dispatch}
        updateFormField={updateFormField}
        communityOptions={communityOptions}
        setCommunityOptions={setCommunityOptions}
        loadOptions={loadOptions}
        region={region}
        district={district}
        localCommunity={localCommunity}
        errors={props.errors}
        fieldBlock={props.fieldBlock}
        startSubNumber={props.startSubNumber + 2 || 3}
      />
      <SettlementName
        setSettlementName={setSettlementName}
        setFormData={props.setFormData}
        dispatch={props.dispatch}
        updateFormField={updateFormField}
        settlementOptions={settlementOptions}
        setSettlementOptions={setSettlementOptions}
        loadOptions={loadOptions}
        region={region}
        district={district}
        localCommunity={localCommunity}
        settlementName={settlementName}
        setCoords3857={props.setCoords3857}
        setZone={props.setZone}
        pointExist={props.pointExist}
        setPointExist={props.setPointExist}
        errors={props.errors}
        fieldBlock={props.fieldBlock}
        startSubNumber={props.startSubNumber + 3 || 4}
        customSelectClassName={props.customSelectClassName}
        customStyles={props.customStyles}
      />
    </>
  );
};

export const Region = (props) => {
  const handleRegion = async (newValue) => {
    props.setRegion(newValue);
    props.updateFormField('region', newValue);
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>{`2.${props.startSubNumber}`}</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Область"
      titleEN="Region"
      className={props.errors?.general?.[props.fieldBlock]?.settlement_name ? 'error' : ''}
      children={(
        <AsyncSelectComponent
          defaultOptions
          promiseOptions={(inputValue) => props.loadOptions('region', inputValue)}
          placeholder="Region"
          handleSelect={handleRegion}
          value={props.region
            ? {
              label: props.region,
              value: props.region,
            }
            : null}
        />
      )}
    />
  );
};

export const Disctrict = (props) => {
  const handleDistrict = async (newValue) => {
    props.setDistrict(newValue);
    props.updateFormField('district', newValue);
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>{`2.${props.startSubNumber}`}</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Район"
      titleEN="District"
      className={props.errors?.general?.[props.fieldBlock]?.settlement_name ? 'error' : ''}
      children={(
        <SelectComponent
          options={props.districtOptions}
          placeholder="District"
          handleSelect={handleDistrict}
          value={props.district
            ? {
              label: props.district,
              value: props.district,
            }
            : null}
          isDisabled={Boolean(!props.region)}
        />
      )}
    />
  );
};

export const LocalCommunity = (props) => {
  const handleCommunity = async (newValue) => {
    props.setLocalCommunity(newValue);
    props.updateFormField('local_community', newValue);
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>{`2.${props.startSubNumber}`}</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Територіальна громада"
      titleEN="Local community"
      className={props.errors?.general?.[props.fieldBlock]?.settlement_name ? 'error' : ''}
      children={(
        <SelectComponent
          options={props.communityOptions}
          placeholder="Local community"
          handleSelect={handleCommunity}
          value={props.localCommunity
            ? {
              label: props.localCommunity,
              value: props.localCommunity,
            }
            : null}
          isDisabled={Boolean(!props.district)}
        />
      )}
    />
  );
};

export const SettlementName = (props) => {
  const updateSettlementCode = (value) => {
    props.updateFormField('settlement_code', value);
  };


  const updateLocationPoint = (x, y, zone) => {
    if (!x || !y) return;
    props.setCoords3857([Number(x), Number(y)]);

    if (props.dispatch) {
      props.dispatch({
        type: UPDATE_LOCATION_POINT,
        payload: {
          coordinates: convertCoords3857To4326([Number(x), Number(y)], zone),
          zone
        }
      });
    } else {
      props.setFormData((prevFormData) => ({
        ...prevFormData,
        info: {
          ...prevFormData.info,
          point: {
            type: 'Point',
            coordinates: convertCoords3857To4326([Number(x), Number(y)], zone),
          },
        },
      }));
    }
    props.setZone(zone);
  };

  const getSettlementCode = () => {
    if (props.region
      && props.district
      && props.localCommunity
      && props.settlementName) {
      const regionUkr = `${props.region.split('/')[0]}`.trim();
      const districtUkr = `${props.district.split('/')[0]}`.trim();
      const communityUkr = `${props.localCommunity.split('/')[0]}`.trim();
      const settlementNameUkr = `${props.settlementName.split('/')[0]}`.trim();
      axiosWrapper(() => {
        axios.get(`/reports/locations/code/${regionUkr}/${districtUkr}/${communityUkr}/${settlementNameUkr}`)
          .then((res) => {
            updateSettlementCode(res.data.code);
            if (!props.pointExist) {
              if (props.setCoords3857) {
                updateLocationPoint(res.data.x, res.data.y, res.data.zone);
              }
            }
          })
          .catch((error) => {
            console.error(error);
            updateSettlementCode('-');
            updateLocationPoint(null);
          });
      });
    }
  };

  useEffect(() => {
    getSettlementCode();
  }, [props.settlementName]);

  const handleSettlement = (newValue) => {
    props.setSettlementName(newValue);
    props.updateFormField('settlement_name', newValue);
    if (props.setPointExist) {
      props.setPointExist(false);
    }
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>{`2.${props.startSubNumber}`}</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Населений пункт"
      titleEN="Locality"
      className={props.errors?.general?.[props.fieldBlock]?.settlement_name ? 'error' : ''}
      children={(
        <SelectComponent
          options={props.settlementOptions}
          placeholder="Settlement name"
          handleSelect={handleSettlement}
          value={props.settlementName
            ? {
              label: props.settlementName,
              value: props.settlementName,
            }
            : null}
          isDisabled={Boolean(!props.localCommunity)}
          className={props?.customSelectClassName || ''}
          customStyles={props?.customStyles}
          />
      )}
    />
  );
};
