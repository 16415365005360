import React from 'react';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { CommentDisplay } from 'component/CommentBlock';
import { AccordionHeaderTitle } from 'component/Accordion';
import { DirectEvidencesView } from './DirectEvidences';
import { IndirectEvidencesView } from './IndirectEvidences';

export const EvidencesView = (props) => {
  const {
    formData, comments,
    translatedFormData,
    translateState,
  } = props;
  return (
    <AccordionItem uuid="evidence">
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="4"
            titleUA="Докази *"
            titleEN="Evidence"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <DirectEvidencesView
          formData={formData}
          map={props.map}
          setMap={props.setMap}
          translatedFormData={translatedFormData}
          translateState={translateState}
        />
        <IndirectEvidencesView
          formData={formData}
          map={props.map}
          setMap={props.setMap}
          translatedFormData={translatedFormData}
          translateState={translateState}
        />
        <CommentDisplay comments={comments} block="evidences" />
      </AccordionItemPanel>
    </AccordionItem>
  );
};
