import { Card } from 'component/Card';
import { ReactComponent as FileDownloadIcon } from 'assets/images/icons/file_download.svg';
import { ReactComponent as FileIcon } from 'assets/images/icons/Files.svg';
import React from 'react';
import { Map } from 'component/Map';
import { TurnPointsFile } from './TurnPointsFile';
import { GisLinks, GisLinksView } from './Links';

export const GisBlock = ({
  turnPointsFiles,
  setTurnPointsFiles,
  map,
  setMap,
  turnPointsFromShape,
  setTurnPointsFromShape,
  gisLinks,
  setGisLinks,
  setTcpFormData,
  errors,
}) => (
  <div className="flex-column-group">
    <div className="download-cards flex-center">
      <Card
        header={(
          <div className="flex-group flex-center">
            <div className="icon-wrapper">
              <FileDownloadIcon />
            </div>
            <span className={errors?.deminers_polygon
              ? 'error-text'
              : ''}
            >
              Turn points
            </span>
          </div>
        )}
        body={(
          <TurnPointsFile
            files={turnPointsFiles}
            setFiles={setTurnPointsFiles}
            setTurnPointsFromShape={setTurnPointsFromShape}
            links={gisLinks}
            setLinks={setGisLinks}
          />
        )}
      />
    </div>
    <GisLinks
      links={gisLinks}
      setLinks={setGisLinks}
      setTcpFormData={setTcpFormData}
    />
    <Map
      map={map}
      setMap={setMap}
      turnPointsPolygon={turnPointsFromShape}
    />
  </div>
);

export const GisBlockView = ({
  map,
  setMap,
  turnPointsFromShape,
  links,
}) => (
  <>
    <GisViewHeader />
    <GisLinksView links={links} />
    <Map
      map={map}
      setMap={setMap}
      turnPointsPolygon={turnPointsFromShape}
    />
  </>
);

const GisViewHeader = () => (
  <div className="gis-header">
    <FileIcon />
    <span>File information</span>
    <div className="line" />
  </div>
);
