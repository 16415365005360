import React from 'react';

export const RegionViewWithoutSubId = ({ formData }) => (
  <div className="flex-column-group w-300">
    <div className="double-text">
      <span>Область</span>
      <span>Region</span>
    </div>
    <div className="text-grey">{formData.info?.location?.region || '-'}</div>
  </div>
);

export const DistrictViewWithoutSubId = ({ formData }) => (
  <div className="flex-column-group">
    <div className="double-text">
      <span>Район</span>
      <span>District</span>
    </div>
    <div className="text-grey">{formData.info?.location?.district || '-'}</div>
  </div>
);

export const LocalCommunityViewWithoutSubId = ({ formData }) => (
  <div className="flex-column-group">
    <div className="double-text">
      <span>Громада</span>
      <span>Local community</span>
    </div>
    <div className="text-grey">{formData.info?.location?.local_community || '-'}</div>
  </div>
);

export const SettlementViewWithoutSubId = ({ formData }) => (
  <div className="flex-column-group">
    <div className="double-text">
      <span>Населений пункт</span>
      <span>Settlement</span>
    </div>
    <div className="text-grey">{formData.info?.location?.settlement_name || '-'}</div>
  </div>
);
