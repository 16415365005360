import { React, useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import { Loader } from 'component/Loader';
import { ReactComponent as DroneIcon } from 'assets/images/icons/drone.svg';
import { ReactComponent as UpdateIcon } from 'assets/images/icons/update2.svg';
import 'assets/styles/pages/drone-data.scss';
import { DatePickerWrapper } from 'component/DataPicker';
import { getDroneData } from 'api/DroneDataApi';
import { axiosWrapper, getPageValue } from 'utils/Helper';
import { useUser } from 'routes/UserContext';
import { DroneTable } from './DroneTable';
import { UploadNewDroneData } from './UploadNewDroneData';

export const DroneDataPage = () => {
  const [loader, setLoader] = useState(false);
  const [dateFromFilter, setDateFromFilter] = useState(null);
  const [dateToFilter, setDateToFilter] = useState(null);
  const [droneData, setDroneData] = useState([]);
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(false);
  const { user } = useUser();

  const abortControllerRef = useRef(null);

  const isInitialMount = useRef(true);


  useEffect(() => {
    document.title = 'Drone data';
  }, []);

  const getDroneInfo = (pageAfterCreate = false) => {

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();

    axiosWrapper(() => {
      const dronePage = pageAfterCreate ? pageAfterCreate : page;
      getDroneData(
        dronePage,
        dateFromFilter,
        dateToFilter,
        false,
        abortControllerRef.current.signal
      )
        .then((res) => {
          if (pageAfterCreate) {
            setDroneData(res.data.results);
          } else {
            setDroneData((prevData) => [...prevData, ...res.data.results]);
          }
          setNext(res.data.next);
          if (res.data.next) {
            setPage(Number(getPageValue(res.data.next)));
          }
          setLoader(false);
        })
        .catch((error) => {
          if (error.name !== 'CanceledError' && error.name !== 'AbortError') {
            toast.error('Drone data is not loaded');
            setDroneData([]);
          }
          setLoader(false);
        });
    });
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      setLoader(true);
      getDroneInfo();
    }

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  useEffect(() => {
    if (!isInitialMount.current) {
      setLoader(true);
      getDroneInfo(1);
    }

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [dateFromFilter, dateToFilter]);

  return (
    <section>
      <div className="drone-header">
        <div className="title">
          <DroneIcon className="header-icon" />
          Drone data
        </div>
        <div className="header-datepicker">
          <span>Date:</span>
          <DatePickerWrapper
            handleDatePicker={(value) => setDateFromFilter(moment(value, 'DD.MM.YYYY').format('L'))}
            value={dateFromFilter ? moment(dateFromFilter, 'DD.MM.YYYY') : null}
          />
          <span>-</span>
          <DatePickerWrapper
            handleDatePicker={(value) => setDateToFilter(moment(value, 'DD.MM.YYYY').format('L'))}
            value={dateToFilter ? moment(dateToFilter, 'DD.MM.YYYY') : null}
          />
          <div className="text-hover p-1">
            <UpdateIcon onClick={() => {
              setDateFromFilter(null);
              setDateToFilter(null);
            }}
            />
          </div>

        </div>
      </div>
      <DroneTable
        droneData={droneData}
        setLoader={setLoader}
        next={next}
        getDroneInfo={getDroneInfo}
      />
      {user?.permissions?.add_drones && (
        <div className="drone-footer">
          <UploadNewDroneData
            setPage={setPage}
            setLoader={setLoader}
            getDroneInfo={getDroneInfo}
          />
        </div>
      )}
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};
