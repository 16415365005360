import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { featureCollection } from '@turf/helpers';
import moment from 'moment';
import { Loader } from 'component/Loader';
import { Map } from 'component/Map';
import { axiosWrapper, fetchWithInterceptors } from 'utils/Helper';
import { MapModal } from 'component/MapModal';
import { getDroneData } from 'api/DroneDataApi';
import { MapLayers } from './MapLayers';
import 'assets/styles/pages/global-map.scss';
import { FilterCheckboxes } from './FilterCheckboxes';
import { RightBox } from './RightBox';
import { setMapboxWMSLayers } from './Layers';
import { getEOREGeojson } from 'api/EOREApi';
import eorePoint from 'assets/images/icons/eore-icon.png';

export const GlobalMapPage = () => {
  const [loader, setLoader] = useState(false);
  const [map, setMap] = useState(null);
  const [legendActive, setLegendActive] = useState(null);
  const [filterCheckbox, setFilterCheckbox] = useState('by-hazard_area_type');
  const [mapModalData, setMapModalData] = useState(null);
  const [mapPopupData, setMapPopupData] = useState(null);
  const [mapModalOpen, setMapModalOpen] = useState(false);

  const [lmGeojson, setLmGeojson] = useState(featureCollection([]));
  const [bmGeojson, setBmGeojson] = useState(featureCollection([]));
  const [turnPointsGeojson, setTurnPointsGeojson] = useState(featureCollection([]));
  const [indirectEvidenceGeojson, setIndirectEvidenceGeojson] = useState(featureCollection([]));
  const [directEvidenceGeojson, setDirectEvidenceGeojson] = useState(featureCollection([]));
  const [eoreGeojson, setEoreGeojson] = useState(featureCollection([]));

  const [teamFilter, setTeamFilter] = useState(null);
  const [dateFrom, setDateFromFilter] = useState(null);
  const [dateTo, setDateToFilter] = useState(null);

  const [internalIdFilter, setInternalIdFilter] = useState(null);
  const [imsmaIdFilter, setImsmaIdFilter] = useState(null);
  const [validStatusFilter, setValidStatusFilter] = useState(null);
  const [areaTypeFilter, setAreaTypeFilter] = useState(null);
  const [hazardTypeFilter, setHazardTypeFilter] = useState(null);
  const [regionFilter, setRegionFilter] = useState(null);
  const [districtFilter, setDistrictFilter] = useState(null);
  const [localCommunityFilter, setLocalCommunityFilter] = useState(null);
  const [settlementFilter, setSettlementFilter] = useState(null);
  const [resetFilter, setResetFilter] = useState(false);
  const [droneData, setDroneData] = useState([]);
  const [contentSize, setContentSize] = useState('standart');

  const clickHandlerRef = useRef(null);
  const [mapLayersLoads, setMapLayerLoads] = useState(false);
  const abortControllerRef = useRef(new AbortController());

  const mapLegendControllerRef = useRef(new AbortController());
  const mapGeojsonControllerRef = useRef(new AbortController());
  const eoreGeojsonControllerRef = useRef(new AbortController());
  const droneDataControllerRef = useRef(new AbortController());
  const wmsLayersControllerRef = useRef(new AbortController());

  useEffect(() => {
    document.title = 'Global Map';
    const handleVisibilityChange = () => {
      if (document.hidden) {
        abortAllRequests();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      abortAllRequests();
    };
  }, []);

  useEffect(() => {
    if (!map) return;
    wmsLayersControllerRef.current = new AbortController();
    setMapboxWMSLayers(map, wmsLayersControllerRef.current.signal)
      .then(() => {
        setMapLayerLoads(true);
      })
      .catch((error) => {
        if (error.name !== 'AbortError') {
          console.error('Error loading WMS layers:', error);
        }
      });
    return () => {
      wmsLayersControllerRef.current.abort();
    };
  }, [map]);


  const abortAllRequests = () => {
    try {
      mapLegendControllerRef.current.abort();
      mapGeojsonControllerRef.current.abort();
      eoreGeojsonControllerRef.current.abort();
      droneDataControllerRef.current.abort();
      wmsLayersControllerRef.current.abort();

      mapLegendControllerRef.current = new AbortController();
      mapGeojsonControllerRef.current = new AbortController();
      eoreGeojsonControllerRef.current = new AbortController();
      droneDataControllerRef.current = new AbortController();
      wmsLayersControllerRef.current = new AbortController();
    } catch (e) {
      console.error('Помилка при скасуванні запитів:', e);
    }
  };


  useEffect(() => {
    if (!map) return;
    if (clickHandlerRef.current) {
      map.off('click', 'turnPointsPolygon-layer', clickHandlerRef.current);
      map.off('click', 'imsma-all-hz_polygons-layer', clickHandlerRef.current);
      map.off('click', 'imsma-fsd-hz_polygons-layer', clickHandlerRef.current);
      map.off('click', 'feodal-polygon-polygons_field-layer', clickHandlerRef.current);
    }

    const newClickHandler = (e, sizeContent) => {
      setMapPopupData(e.features[0].properties);
      setMapModalOpen(true);
      setContentSize(sizeContent);
    };

    map.on('click', () => {
      setMapModalOpen(false);
    });
    map.on('click', 'turnPointsPolygon-layer', (e) => newClickHandler(e, 'standart'));
    map.on('click', 'imsma-all-hz_polygons-layer', (e) => newClickHandler(e, 'full'));
    map.on('click', 'imsma-fsd-hz_polygons-layer', (e) => newClickHandler(e, 'full'));
    map.on('click', 'feodal-polygon-polygons_field-layer', (e) => newClickHandler(e, 'full'));

    clickHandlerRef.current = newClickHandler;
  }, [map, turnPointsGeojson]);

  const getUrlForGlobalMap = (legend) => {
    const checkboxFilter = filterCheckbox.split('-')[1];
    const colorParam = `color_by=${checkboxFilter}&`;
    const legendPath = legend ? 'legend/' : '';
    const teamParam = () => {
      if (teamFilter?.length === 0) return '';
      let param = '';
      teamFilter?.forEach((option) => {
        param += `&team_id=${option.value}`;
      });
      return param;
    };
    const dateFromParam = dateFrom
      ? `date_from=${moment(dateFrom, 'DD.MM.YYYY').format('DD.MM.YYYY')}&`
      : '';
    const dateToParam = dateTo
      ? `date_to=${moment(dateTo, 'DD.MM.YYYY').format('DD.MM.YYYY')}&`
      : '';
    const internalIdParam = () => {
      if (internalIdFilter?.length === 0) return '';
      let param = '';
      internalIdFilter?.forEach((option) => {
        param += `&internal_id=${option.value}`;
      });
      return param;
    };
    const imsmaIdParam = () => {
      if (imsmaIdFilter?.length === 0) return '';
      let param = '';
      imsmaIdFilter?.forEach((option) => {
        param += `&imsma_id=${option.value}`;
      });
      return param;
    };
    const validStatusParam = () => {
      if (validStatusFilter?.length === 0) return '';
      let param = '';
      validStatusFilter?.forEach((option) => {
        param += `&validation_status=${option.value}`;
      });
      return param;
    };
    const areaTypeParam = () => {
      if (areaTypeFilter?.length === 0) return '';
      let param = '';
      areaTypeFilter?.forEach((option) => {
        param += `&hazard_area_type=${option.value}`;
      });
      return param;
    };
    const hazardTypeParam = () => {
      if (hazardTypeFilter?.length === 0) return '';
      let param = '';
      hazardTypeFilter?.forEach((option) => {
        param += `&hazard_type=${option.value}`;
      });
      return param;
    };
    const regionParam = () => {
      if (!regionFilter) return '';
      return `&region=${regionFilter}`;
    };
    const districtParam = () => {
      if (!districtFilter) return '';
      return `&district=${districtFilter}`;
    };
    const localCommunityParam = () => {
      if (!localCommunityFilter) return '';
      return `&local_community=${localCommunityFilter}`;
    };
    const settlementParam = () => {
      if (!settlementFilter) return '';
      return `&settlement_name=${settlementFilter}`;
    };
    const url = `/reports/global-map/${legendPath}?${colorParam}${teamParam()}${dateFromParam}${dateToParam}${internalIdParam()}${imsmaIdParam()}${validStatusParam()}${areaTypeParam()}${hazardTypeParam()}${regionParam()}${districtParam()}${localCommunityParam()}${settlementParam()}`;
    return url;
  };

  const getGlobalMapLegend = () => {
    mapLegendControllerRef.current.abort();
    mapLegendControllerRef.current = new AbortController();

    axiosWrapper(() => {
      axios.get(getUrlForGlobalMap(true), { signal: mapLegendControllerRef.current.signal })
        .then((res) => {
          setMapModalData(res.data);
        })
        .catch((err) => {
          setMapModalData([]);
          if (err.name !== 'AbortError') {
            console.error('Error loading legend:', err);
            toast.error('Error! Legend is not loaded.');
          }
        });
    });
  };

  const getGlobalMapGeojson = () => {
    mapGeojsonControllerRef.current.abort();
    mapGeojsonControllerRef.current = new AbortController();

    fetchWithInterceptors(`${process.env.REACT_APP_API_ENDPOINT}${getUrlForGlobalMap(false)}`,
    { signal: mapGeojsonControllerRef.current.signal }
  )
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let jsonText = '';

        reader.read().then(function process({ done, value }) {
          if (done) {
            const res = JSON.parse(jsonText);

            setLmGeojson(res.lm || featureCollection([]));
            setBmGeojson(res.bm || featureCollection([]));
            setTurnPointsGeojson(res.polygon || featureCollection([]));
            setIndirectEvidenceGeojson(res.indirect_evidences || featureCollection([]));
            setDirectEvidenceGeojson(res.direct_evidences || featureCollection([]));
            setLoader(false);
            return;
          }

          jsonText += decoder.decode(value, { stream: true });
          return reader.read().then(process);
        });
      })
      .catch((err) => {
        toast.error('Error loading geojson.');
        setLoader(false);
        if (err.name !== 'AbortError') {
          console.error('Error loading geojson:', err);
          setLmGeojson(featureCollection([]));
          setBmGeojson(featureCollection([]));
          setTurnPointsGeojson(featureCollection([]));
          setIndirectEvidenceGeojson(featureCollection([]));
          setDirectEvidenceGeojson(featureCollection([]));

        }
      });
  };

  const getDroneInfo = () => {
    droneDataControllerRef.current.abort();
  droneDataControllerRef.current = new AbortController();

  axiosWrapper(() => {
    getDroneData(null, null, null, true, { signal: droneDataControllerRef.current.signal })
      .then((res) => {
        setDroneData(res.data);
      })
        .catch((err) => {
          if (err.name !== 'AbortError') {
            console.error('Error loading drone data:', err);
            toast.error('Drone data is not loaded');
            setDroneData([]);
          }
        });
    });
  };

  useEffect(() => {
    if (resetFilter) {
      getGlobalMapGeojson();
      getGlobalMapLegend();
      setResetFilter(false);
    }
  }, [resetFilter]);

  useEffect(() => {
    setLoader(true);
    getGlobalMapGeojson();
    getGlobalMapLegend();
    getDroneInfo();
  }, [filterCheckbox]);

  const fetchEOREGeojson = async () => {
    eoreGeojsonControllerRef.current.abort();
    eoreGeojsonControllerRef.current = new AbortController();

    try {
      const response = await getEOREGeojson({ signal: eoreGeojsonControllerRef.current.signal });
      setEoreGeojson(response.data);
    }  catch (error) {
      toast.error('Error fetching EORE geojson.');
      console.error('Error fetching EORE geojson:', error);
    }
  };

  useEffect(() => {
    fetchEOREGeojson();
  }, []);

  const addEoreGeojson = () => {
    if (!map.getSource('eoreSource')) {
      map.addSource('eoreSource', {
        type: 'geojson',
        data: eoreGeojson,
        generateId: true,
      });

      [{ 'eore-icon': eorePoint }].forEach((icon) => {
        map.loadImage(Object.values(icon)[0], (error, image) => {
          if (error) throw error;
          map.addImage(Object.keys(icon)[0], image);
        });
      });

      map.addLayer({
        id: 'eoreSource-layer',
        type: 'symbol',
        source: 'eoreSource',
        layout: {
          'icon-image': 'eore-icon',
          'icon-size': 1,
          'icon-offset': [0, 0],
          'icon-allow-overlap': true,
        },
        filter: ['==', '$type', 'Point'],
      });
    } else {
      map.getSource('eoreSource').setData(eoreGeojson);
    }

    map.on('click', 'eoreSource-layer', (e) => {
      setMapPopupData(e.features[0].properties);
      setMapModalOpen(true);
      setContentSize('standart');
    });
  };

  useEffect(() => {
    if (!map) return;
    if (!eoreGeojson) return;
    addEoreGeojson();
  }, [map, eoreGeojson]);

  return (
    <>
      <section className="no-padding">
        <Map
          map={map}
          setMap={setMap}
          height="full"
          turnPointsPolygon={turnPointsGeojson}
          evidenceGeojson={directEvidenceGeojson}
          indirectEvidenceGeojson={indirectEvidenceGeojson}
          lmGeojson={lmGeojson}
          bmGeojson={bmGeojson}
          googleWms
        />
        <MapModal
          visible={mapModalOpen}
          data={mapPopupData}
          contentSize={contentSize}
        />
        <FilterCheckboxes
          filter={filterCheckbox}
          setFilter={setFilterCheckbox}
          legendActive={legendActive}
          setLegendActive={setLegendActive}
        />
        <MapLayers
          map={map}
          setMapPopupData={setMapModalData}
          setMapModalOpen={setMapModalOpen}
          mapLayersLoads={mapLayersLoads}
        />
        <RightBox
          filter={filterCheckbox}
          legendActive={legendActive}
          setLegendActive={setLegendActive}
          legendData={mapModalData}
          teamFilter={teamFilter}
          setTeamFilter={setTeamFilter}
          dateTo={dateTo}
          dateFrom={dateFrom}
          setDateFromFilter={setDateFromFilter}
          setDateToFilter={setDateToFilter}
          internalIdFilter={internalIdFilter}
          setInternalIdFilter={setInternalIdFilter}
          imsmaIdFilter={imsmaIdFilter}
          setImsmaIdFilter={setImsmaIdFilter}
          validStatusFilter={validStatusFilter}
          setValidStatusFilter={setValidStatusFilter}
          areaTypeFilter={areaTypeFilter}
          setAreaTypeFilter={setAreaTypeFilter}
          hazardTypeFilter={hazardTypeFilter}
          setHazardTypeFilter={setHazardTypeFilter}
          regionFilter={regionFilter}
          setRegionFilter={setRegionFilter}
          districtFilter={districtFilter}
          setDistrictFilter={setDistrictFilter}
          localCommunityFilter={localCommunityFilter}
          setLocalCommunityFilter={setLocalCommunityFilter}
          settlementFilter={settlementFilter}
          setSettlementFilter={setSettlementFilter}
          updateGeojson={getGlobalMapGeojson}
          updateLegend={getGlobalMapLegend}
          resetFilter={resetFilter}
          setResetFilter={setResetFilter}
          setLoader={setLoader}
          droneData={droneData}
          map={map}
        />
      </section>
      <Loader visible={loader} />
      <ToastContainer />
    </>
  );
};
