import axios from 'axios';

export const getProgressMap = async (clearanceTaskId, params = {}, signal = undefined) => {
  let url = `/reports/clearance/progress-map/${clearanceTaskId}/`;
  const queryParams = new URLSearchParams();
  if (params.daily_report_id) {
    queryParams.append('daily_report_id', params.daily_report_id);
  }
  if (params.status) {
    queryParams.append('status', params.status);
  }
  if (params.weekly_report_id) {
    queryParams.append('weekly_report_id', params.weekly_report_id);
  }
  if (params.page) {
    queryParams.append('page', params.page);
  }

  const queryString = queryParams.toString();
  if (queryString) {
    url += `?${queryString}`;
  }
  const config = signal ? { signal } : {};

  const response = await axios.get(url, config);
    return response;
};

export const getProgressMapStatus = async (clearanceTaskId, params = {}, signal = undefined) => {
  return getProgressMap(clearanceTaskId, { ...params, status: true }, signal);
};

export const getDailyFormData = async (id) => {
  const url = `/reports/clearance/daily/${id}/`;
  const response = await axios.get(url);
  return response;
};

export const postDailyReport = async (data) => {
  try {
    const url = '/reports/clearance/daily/';
    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const patchDailyReport = async (id, data) => {
  try {
    const url = `/reports/clearance/daily/${id}/`;
    const response = await axios.patch(url, data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const updateProgressMap = async (clearanceTaskId, dailyReportId, polygonData) => {
  try {
    const url = `/reports/clearance/progress-map/${clearanceTaskId}/progress-polygon/${dailyReportId}/`;
    const response = await axios.patch(url, polygonData);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const getDailyList = async (params = {}) => {
  try{
    const url = '/reports/clearance/daily/';
    const response = await axios.get(url, { params });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
}

export const getWeeklyList = async (params = {}) => {
  try{
    const url = '/reports/clearance/weekly/';
    const response = await axios.get(url, { params });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
}