import { Loader } from 'component/Loader';
import React, { useEffect, useRef, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { ToastContainer, toast } from 'react-toastify';
import { DatePickerWrapper } from 'component/DataPicker';
import { FilterForClearanceDialog } from 'pages/NTS-HAReport/Filters/FilterReportList';
import SearchComponent from 'component/Search';
import axios from 'axios';
import moment from 'moment';
import DailyTab from './Tabs/DailyTab';
import ClearanceTaskTab from './Tabs/ClearanceTaskTab';
import WeeklyTab from './Tabs/WeeklyTab';
import 'assets/styles/pages/lists.scss';

const List = () => {
  const [loader, setLoader] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [clearanceTaskList, setClearanceTaskList] = useState(null);
  const [dailyList, setDailyList] = useState(null);
  const [weeklyList, setWeeklyList] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [dates, setDates] = useState({
    clearanceTask: { startDate: null, endDate: null },
    daily: { startDate: null, endDate: null },
    weekly: { startDate: null, endDate: null },
  });
  const [pages, setPages] = useState({
    clearanceTask: 1,
    daily: 1,
    weekly: 1,
  });
  const [filters, setFilters] = useState({ clearanceTask: {}, daily: {}, weekly: {} });

  const prevSearchTerm = useRef(searchTerm);
  const prevStatuses = useRef(filters);
  const isFirstRender = useRef(true);

  const clearanceTaskAbortControllerRef = useRef(null);
  const dailyAbortControllerRef = useRef(null);
  const weeklyAbortControllerRef = useRef(null);

  const getTabName = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return 'clearanceTask';
      case 1:
        return 'daily';
      case 2:
        return 'weekly';
      default:
        return 'clearanceTask';
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  //   if (!currentList) {
  //     setLoader(true);
  //     switch (tabName) {
  //       case 'clearanceTask':
  //         fetchClearanceTaskList(pages.clearanceTask, filters.clearanceTask);
  //         break;
  //       case 'daily':
  //         fetchDailyList(pages.daily);
  //         break;
  //       case 'weekly':
  //         fetchWeeklyList(pages.weekly);
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // };

  const handlePageChange = (tabName, newPage) => {
    setPages((prev) => ({
      ...prev,
      [tabName]: newPage,
    }));
  };

  const resetPage = (tabName) => {
    setPages((prev) => ({
      ...prev,
      [getTabName(activeTab)]: 1,
    }));
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleStartDateChange = (date) => {
    const currentTabName = getTabName(activeTab);
    setDates((prev) => ({
      ...prev,
      [currentTabName]: {
        ...prev[currentTabName],
        startDate: date,
      },
    }));
    setPages((prev) => ({
      ...prev,
      [currentTabName]: 1,
    }));
  };

  const handleEndDateChange = (date) => {
    const currentTabName = getTabName(activeTab);
    setDates((prev) => ({
      ...prev,
      [currentTabName]: {
        ...prev[currentTabName],
        endDate: date,
      },
    }));
    setPages((prev) => ({
      ...prev,
      [currentTabName]: 1,
    }));
  };

  const fetchClearanceTaskList = async (page, filters) => {

    if (clearanceTaskAbortControllerRef.current) {
      clearanceTaskAbortControllerRef.current.abort();
    }

    clearanceTaskAbortControllerRef.current = new AbortController();

    try {
      const baseUrl = '/reports/clearance/clearance-tasks/';
      const params = new URLSearchParams();
      params.append('page', page);

      if (filters.ordering) {
        params.append('ordering', filters.ordering);
      }

      const currentDates = dates.clearanceTask;
      if (currentDates.startDate) {
        params.append('date_from', moment(currentDates.startDate).format('DD.MM.YYYY'));
      }
      if (currentDates.endDate) {
        params.append('date_to', moment(currentDates.endDate).format('DD.MM.YYYY'));
      }

      if (filters.selectedStatus?.length) {
        filters.selectedStatus.forEach((status) => {
          params.append('validation_status', status.value);
        });
      }

      if (filters.selectedDonors?.length) {
        filters.selectedDonors.forEach((donor) => {
          params.append('donor', donor.value);
        });
      }

      if (filters.selectedMethodTypes?.length) {
        filters.selectedMethodTypes.forEach((method) => {
          params.append('method', method.value);
        });
      }

      if (searchTerm) {
        params.append('internal_id', searchTerm);
      }

      const response = await axios.get(`${baseUrl}?${params.toString()}`, {
        signal: clearanceTaskAbortControllerRef.current.signal,
      });

      setClearanceTaskList((prevList) =>
        page === 1
          ? response.data
          : {
              ...response.data,
              results: [...(prevList?.results || []), ...response.data.results],
            }
      );

      return response.data;
    }  catch (error) {
      if (error.name !== 'CanceledError' && error.name !== 'AbortError') {
        toast.error('Error loading clearance tasks');
      }
      return null;
    }
  };

  const fetchDailyList = async (page, ordering = null) => {

    if (dailyAbortControllerRef.current) {
      dailyAbortControllerRef.current.abort();
    }

    dailyAbortControllerRef.current = new AbortController();

    try {
      const params = new URLSearchParams();
      params.append('page', page);

      if (ordering) {
        params.append('ordering', ordering);
      }

      const currentDates = dates.daily;
      if (currentDates.startDate && currentDates.startDate.isValid()) {
        params.append('date_from', currentDates.startDate.format('DD.MM.YYYY'));
      }
      if (currentDates.endDate && currentDates.endDate.isValid()) {
        params.append('date_to', currentDates.endDate.format('DD.MM.YYYY'));
      }

      if (searchTerm) {
        params.append('imsma_id', searchTerm);
      }

      const response = await axios.get(`reports/clearance/daily/?${params.toString()}`, {
        signal: dailyAbortControllerRef.current.signal,
      });

      setDailyList((prevList) =>
        page === 1
          ? response.data
          : {
              ...response.data,
              results: [...(prevList?.results || []), ...response.data.results],
            }
      );

      return response.data;
    } catch (error) {
      if (error.name !== 'CanceledError' && error.name !== 'AbortError') {
        toast.error('Error loading daily reports');
      }
      return null;
    }
  };

  const fetchWeeklyList = async (page, ordering = null) => {

    if (weeklyAbortControllerRef.current) {
      weeklyAbortControllerRef.current.abort();
    }

    weeklyAbortControllerRef.current = new AbortController();

    try {
      const params = new URLSearchParams();
      params.append('page', page);

      if (ordering) {
        params.append('ordering', ordering);
      }

      const currentDates = dates.weekly;
      if (currentDates.startDate && currentDates.startDate.isValid()) {
        params.append('date_from', currentDates.startDate.format('DD.MM.YYYY'));
      }
      if (currentDates.endDate && currentDates.endDate.isValid()) {
        params.append('date_to', currentDates.endDate.format('DD.MM.YYYY'));
      }

      if (searchTerm) {
        params.append('internal_id', searchTerm);
      }

      const response = await axios.get(`reports/clearance/weekly/?${params.toString()}`, {
        signal: weeklyAbortControllerRef.current.signal,
      });

      setWeeklyList((prevList) =>
        page === 1
          ? response.data
          : {
              ...response.data,
              results: [...(prevList?.results || []), ...response.data.results],
            }
      );

      return response.data;
    } catch (error) {
      if (error.name !== 'CanceledError' && error.name !== 'AbortError') {
        toast.error('Error loading weekly reports');
      }
      return null;
    }
  };

  const handleFiltersSelection = (selectedFilters) => {
    const tabName = getTabName(activeTab);
    setFilters((prev) => ({
      ...prev,
      [tabName]: selectedFilters,
    }));
  };

  useEffect(() => {
    document.title = 'Clearance';
    const currentTabName = getTabName(activeTab);
    const currentList = {
      clearanceTask: clearanceTaskList,
      daily: dailyList,
      weekly: weeklyList,
    }[currentTabName];

    const fetchData = async () => {
      setLoader(true);
      try {
        const currentPage = pages[currentTabName];

        switch (currentTabName) {
          case 'clearanceTask':
            await fetchClearanceTaskList(currentPage, filters.clearanceTask);
            break;
          case 'daily':
            await fetchDailyList(currentPage);
            break;
          case 'weekly':
            await fetchWeeklyList(currentPage);
            break;
        }
      } finally {
        setLoader(false);
      }
    };

    if (!currentList) {
      fetchData();
      return;
    }

    if (
      prevSearchTerm.current !== searchTerm ||
      prevStatuses.current !== filters ||
      dates[currentTabName].startDate ||
      dates[currentTabName].endDate
    ) {
      setPages((prev) => ({
        ...prev,
        [currentTabName]: 1,
      }));
      fetchData();
      prevSearchTerm.current = searchTerm;
      prevStatuses.current = filters;
    }

    return () => {
      if (clearanceTaskAbortControllerRef.current) {
        clearanceTaskAbortControllerRef.current.abort();
      }
      if (dailyAbortControllerRef.current) {
        dailyAbortControllerRef.current.abort();
      }
      if (weeklyAbortControllerRef.current) {
        weeklyAbortControllerRef.current.abort();
      }
    };

  }, [activeTab, searchTerm, filters, dates]);

  useEffect(() => {
    return () => {
      if (clearanceTaskAbortControllerRef.current) {
        clearanceTaskAbortControllerRef.current.abort();
      }
      if (dailyAbortControllerRef.current) {
        dailyAbortControllerRef.current.abort();
      }
      if (weeklyAbortControllerRef.current) {
        weeklyAbortControllerRef.current.abort();
      }
    };
  }, []);

  return (
    <section>
      <Tabs selectedIndex={activeTab} onSelect={handleTabChange}>
        <div className="lists-header">
          <div className="title">
            {activeTab !== 2 && <SearchComponent onSearch={handleSearch} searchTerm={searchTerm} />}
            <div className="datePicker">
              <div className="flex-group flex-between" style={{ width: '220px' }}>
                <span>From</span>
                <DatePickerWrapper
                  value={dates[getTabName(activeTab)].startDate}
                  handleDatePicker={handleStartDateChange}
                />
              </div>
              <div className="flex-group flex-between" style={{ width: '200px' }}>
                <span>To</span>
                <DatePickerWrapper
                  value={dates[getTabName(activeTab)].endDate}
                  handleDatePicker={handleEndDateChange}
                />
              </div>
            </div>
            {activeTab === 0 && (
              <FilterForClearanceDialog
                activeTabIndex={activeTab}
                onFiltersChange={handleFiltersSelection}
                resetPage={() => resetPage(getTabName(activeTab))}
              />
            )}
          </div>
          <div className="flex-right">
            <TabList>
              <Tab>Clearance task</Tab>
              <Tab>Daily</Tab>
              <Tab>Weekly</Tab>
            </TabList>
          </div>
        </div>

        <TabPanel>
          <ClearanceTaskTab
            setLoader={setLoader}
            clearanceTaskList={clearanceTaskList}
            setClearanceTaskList={setClearanceTaskList}
            currentPage={pages.clearanceTask}
            onPageChange={(newPage) => handlePageChange('clearanceTask', newPage)}
            searchTerm={searchTerm}
            statuses={filters.clearanceTask}
            startDate={dates.clearanceTask.startDate}
            endDate={dates.clearanceTask.endDate}
            onFetch={fetchClearanceTaskList}
          />
        </TabPanel>

        <TabPanel>
          <DailyTab
            setLoader={setLoader}
            dailyList={dailyList}
            setDailyList={setDailyList}
            currentPage={pages.daily}
            onPageChange={(newPage) => handlePageChange('daily', newPage)}
            searchTerm={searchTerm}
            startDate={dates.daily.startDate}
            endDate={dates.daily.endDate}
            onFetch={fetchDailyList}
          />
        </TabPanel>

        <TabPanel>
          <WeeklyTab
            weeklyList={weeklyList}
            setLoader={setLoader}
            setWeeklyList={setWeeklyList}
            currentPage={pages.weekly}
            onPageChange={(newPage) => handlePageChange('weekly', newPage)}
            searchTerm={searchTerm}
            startDate={dates.weekly.startDate}
            endDate={dates.weekly.endDate}
            onFetch={fetchWeeklyList}
          />
        </TabPanel>
      </Tabs>
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};

export default List;
