import React from 'react';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { CommentDisplay } from 'component/CommentBlock';
import { AccordionHeaderTitle } from 'component/Accordion';
import { VegetationView } from './Vegetation';
import { GroundProfileView } from './GroundProfile';
import { SlopeGradientView } from './SlopeGradient';
import { SeasonalAccessibilityView } from './SeasonalAccessibility';
import { CurrentMarkingView } from './CurrentMarking';

export const GeographicView = (props) => {
  const {
    formData,
    comments,
    translatedFormData,
    translateState,
  } = props;

  return (
    <AccordionItem uuid="geographic">
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="6"
            titleUA="Географічний опис підтвердженої/підозрюваної території *"
            titleEN="CHA/SHA geographic & terrain description"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <VegetationView
          formData={formData}
          translatedFormData={translatedFormData}
          translateState={translateState}
        />
        <GroundProfileView
          formData={formData}
          translatedFormData={translatedFormData}
          translateState={translateState}
        />
        <SlopeGradientView formData={formData} />
        <SeasonalAccessibilityView formData={formData} />
        <CurrentMarkingView
          formData={formData}
          translatedFormData={translatedFormData}
          translateState={translateState}
        />
        <CommentDisplay comments={comments} block="geographic_and_terrain_description" />
      </AccordionItemPanel>
    </AccordionItem>
  );
};
