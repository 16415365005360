import { DailyForm, EOFoundCategories } from 'consts/Daily';
import * as Actions from './dailyFormActions';
import moment from 'moment';

export const initialState = {
  formData: {
    ...DailyForm
  },
  loader: true,
  originalGridGeojson: null,
  coords3857: null,
  haReport: null,
};

const dailyFormReducer = (state = initialState, action) => {
  const shouldHaveEOCategories = (state) => {
    return state.formData?.info?.dms?.team_activity === 'field_work';
  };

  switch (action.type) {
    case Actions.SET_TEAM_ACTIVITY:
  if (action.payload === 'field_work') {
    return {
      ...state,
      formData: {
        ...state.formData,
        info: {
          ...state.formData.info,
          dms: {
            ...state.formData.info.dms,
            team_activity: action.payload,
            remarks: null,
          },
          eo_found_categories: {...EOFoundCategories},
          field_work: {
            manual: false,
            clearance_task_imsma_id: null,
            task_id: null,
            task_id_other: null,
            task_type: null,
            number_of_deminers: 0,
            number_of_deminers_on_the_field: 0,
            cleared_area: null,
            eo_found: false,
            remarks: '',
          }
        }
      }
    }
  } else {
    const { field_work, ...restInfo } = state.formData.info;
    return {
      ...state,
      formData: {
        ...state.formData,
        info: {
          ...restInfo,
          dms: {
            ...state.formData.info.dms,
            team_activity: action.payload,
            remarks: null,
          },
          eo_found_categories: null,
        }
      }
    };
  }

  case Actions.SET_VALIDATION_ERRORS:
    return {
      ...state,
      validationErrors: action.payload
    };

  case Actions.CLEAR_ADDITIONAL_INFO:
  return {
    ...state,
    formData: {
      ...state.formData,
      info: {
        ...state.formData.info,
        field_work: null,
        marking: null,
        eo_found_categories: null,
        hand_over_block: null,
        photos_block: null,
        draft: false,
      }
    }
  };

  case Actions.UPDATE_MARKING_AREA:
  return {
    ...state,
    formData: {
      ...state.formData,
      info: {
        ...state.formData.info,
        marking: {
          ...state.formData.info.marking,
          area: action.payload
        }
      }
    }
  };

  case Actions.UPDATE_MARKING_COORDINATES:
    const updatedCoordinates = state.formData.info.marking.coordinates.items.map(
      (item, index) => index === action.payload.index
        ? { ...item, [action.payload.field]: action.payload.value }
        : item
    );
    return {
      ...state,
      formData: {
        ...state.formData,
        info: {
          ...state.formData.info,
          marking: {
            ...state.formData.info.marking,
            coordinates: {
              ...state.formData.info.marking.coordinates,
              items: updatedCoordinates
            }
          }
        }
      }
    };

    case Actions.ADD_MARKING_COORDINATE:
  return {
    ...state,
    formData: {
      ...state.formData,
      info: {
        ...state.formData?.info,
        marking: {
          ...state.formData.info?.marking,
          coordinates: {
            ...state.formData.info?.marking?.coordinates,
            items: [
              ...(state.formData.info.marking?.coordinates?.items || []),
              { long: '', lat: '' }
            ]
          }
        }
      }
    }
  };

  case Actions.TOGGLE_HAND_OVER:
    return {
      ...state,
      formData: {
        ...state.formData,
        info: {
          ...state.formData.info,
          hand_over: action.payload,
          hand_over_block: action.payload === false ? null : {
            date: "",
            name: "",
            remarks: "",
            files: []
          },
          eo_found_categories: shouldHaveEOCategories(state) ? {...EOFoundCategories} : null,
        }
      }
    };

case Actions.DELETE_MARKING_COORDINATE:
  return {
    ...state,
    formData: {
      ...state.formData,
      info: {
        ...state.formData.info,
        marking: {
          ...state.formData.info.marking,
          coordinates: {
            ...state.formData.info.marking.coordinates,
            items: state.formData.info.marking.coordinates.items.filter(
              (_, index) => index !== action.payload
            )
          }
        }
      }
    }
  };

  case Actions.UPDATE_MARKING_FILES:
    const updatedFiles = state.formData.info.marking.files.map(
      (file, index) => index === action.payload.index
        ? { ...file, [action.payload.field]: action.payload.value }
        : file
    );
    return {
      ...state,
      formData: {
        ...state.formData,
        info: {
          ...state.formData.info,
          marking: {
            ...state.formData.info.marking,
            files: updatedFiles
          }
        }
      }
    };

    case Actions.ADD_MARKING_FILE:
  return {
    ...state,
    formData: {
      ...state.formData,
      info: {
        ...state.formData.info,
        marking: {
          ...state.formData.info.marking,
          files: [
            ...(state.formData.info.marking.files || []),
            { name: '', link: '' }
          ]
        }
      }
    }
  };

  case Actions.DELETE_MARKING_FILE:
  return {
    ...state,
    formData: {
      ...state.formData,
      info: {
        ...state.formData.info,
        marking: {
          ...state.formData.info.marking,
          files: state.formData.info.marking.files.filter(
            (_, index) => index !== action.payload
          )
        }
      }
    }
  };

case Actions.UPDATE_MARKING_REMARKS:
  return {
    ...state,
    formData: {
      ...state.formData,
      info: {
        ...state.formData.info,
        marking: {
          ...state.formData.info.marking,
          remarks: action.payload
        }
      }
    }
  };

  case Actions.UPDATE_HAND_OVER:
  return {
    ...state,
    formData: {
      ...state.formData,
      info: {
        ...state.formData.info,
        hand_over: !state.formData?.info?.hand_over,
      },
    },
  };

  case Actions.UPDATE_HAND_OVER_PERSON:
    const { value: personValue } = action.payload;
    return {
      ...state,
      formData: {
        ...state.formData,
        info: {
          ...state.formData.info,
          hand_over_block: {
            ...state.formData.info.hand_over_block || {},
            name: personValue || ""
          }
        }
      }
    };

    case Actions.ADD_HANDOVER_FILE:
      return {
        ...state,
        formData: {
          ...state.formData,
          info: {
            ...state.formData.info,
            hand_over_block: {
              ...state.formData.info.hand_over_block,
              files: [
                ...(state.formData.info.hand_over_block?.files || []),
                { name: '', link: '' }
              ]
            }
          }
        }
      };

      case Actions.REMOVE_HANDOVER_FILE:
  return {
    ...state,
    formData: {
      ...state.formData,
      info: {
        ...state.formData.info,
        hand_over_block: {
          ...state.formData.info.hand_over_block,
          files: state.formData.info.hand_over_block.files.filter(
            (_, index) => index !== action.payload
          )
        }
      }
    }
  };


  case Actions.UPDATE_HANDOVER_FILE:
  return {
    ...state,
    formData: {
      ...state.formData,
      info: {
        ...state.formData.info,
        hand_over_block: {
          ...state.formData.info.hand_over_block,
          files: state.formData.info.hand_over_block.files.map(
            (file, index) => index === action.payload.index
              ? { ...file, [action.payload.field]: action.payload.value }
              : file
          )
        }
      }
    }
  };

    case Actions.UPDATE_CATEGORY_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          info: {
            ...state.formData.info,
            eo_found_categories: {
              ...state.formData.info.eo_found_categories,
              [action.payload.category]: {
                ...state.formData.info.eo_found_categories[action.payload.category],
                items: action.payload.items,
                evidences: action.payload.evidences,
                links: state.formData.info.eo_found_categories[action.payload.category]?.links || []
              }
            }
          }
        }
      };

    case Actions.UPDATE_HAND_OVER_REMARKS:
      const { value: remarksValue } = action.payload;
      return {
        ...state,
        formData: {
          ...state.formData,
          info: {
            ...state.formData.info,
            hand_over_block: {
              ...state.formData.info.hand_over_block || {},
              remarks: remarksValue || ""
            }
          }
        }
      };



  case Actions.SET_DRAFT_STATUS:
    return {
      ...state,
      formData: {
        ...state.formData,
        info: {
          ...state.formData.info,
          draft: action.payload
        }
      }
    };

    case Actions.CLEAR_IMSMA_ID:
  return {
    ...state,
    formData: {
      ...state.formData,
      info: {
        ...state.formData.info,
        field_work: state.formData.info.field_work
          ? {
              ...state.formData.info.field_work,
              clearance_task_imsma_id: null
            }
          : null
      }
    }
  };

    case Actions.SET_USER_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          info: {
            ...state.formData.info,
            dms: {
              ...state.formData.info.dms,
              report_date: action.payload.report_date,
              team_id: action.payload.team_id,
              reporter_name: action.payload.reporter_name,
              reporter_position: action.payload.reporter_position,
            },
            clearance_task_id: action.payload.clearance_task_id
          }
        }
      };

    case Actions.SET_LOADER:
      return {
        ...state,
        loader: action.payload
      };

    case Actions.SET_GRID_GEOJSON:
      return {
        ...state,
        originalGridGeojson: action.payload
      };

      case Actions.SET_TCP_DATA: {

        if (action.payload.preserveExisting) {
          return {
            ...state,
            formData: {
              ...state.formData,
              info: {
                ...state.formData?.info,
                eo_found_categories: state.formData?.info?.eo_found_categories,
                clearance_task_imsma_id: action.payload.clearance_task_imsma_id,
                donor: action.payload.donor
              }
            }
          };
        }

        return {
          ...state,
          formData: {
            ...state.formData,
            info: {
              ...state.formData?.info,
              clearance_task_imsma_id: action.payload.clearance_task_imsma_id,
              donor: action.payload.donor
            }
          }
        };
      }

    case Actions.UPDATE_DMS_FIELD:
      return {
        ...state,
        formData: {
          ...state.formData,
          info: {
            ...state.formData.info,
            dms: {
              ...state.formData.info.dms,
              [action.payload.field]: action.payload.value
            }
          }
        }
      };

      case Actions.UPDATE_LOCATION_FIELD:
        return {
          ...state,
          formData: {
            ...state.formData,
            info: {
              ...state.formData.info,
              [action.payload.fieldBlock]: {
                ...state.formData.info[action.payload.fieldBlock],
                [action.payload.field]: action.payload.value,
              },
            },
          },
        };

      case Actions.UPDATE_LOCATION_POINT:
  return {
    ...state,
    formData: {
      ...state.formData,
      info: {
        ...state.formData.info,
        point: {
          type: 'Point',
          coordinates: action.payload.coordinates,
        },
      },
    },
  };

      case Actions.UPDATE_NUMBER_OF_DEMINERS:
        const numValue = Number(action.payload);
        if (isNaN(numValue)) return state;
        return {
          ...state,
          formData: {
            ...state.formData,
            info: {
              ...state.formData.info,
              field_work: {
                ...state.formData.info.field_work,
                number_of_deminers: numValue,
              },
            },
          },
        };

        case Actions.SET_LOCATION_FROM_HA:
  return {
    ...state,
    formData: {
      ...state?.formData,
      info: {
        ...state?.formData?.info,
        location: {
          region: action?.payload?.region || "",
          district: action?.payload?.district || "",
          local_community: action?.payload?.local_community || "",
          settlement_name: action?.payload?.settlement_name || "",
          settlement_code: action?.payload?.settlement_code || "",
          nearest_settlement_name: action?.payload?.nearest_settlement_name || {}
        }
      }
    }
  };


    case Actions.SET_HA_REPORT:
      return {
        ...state,
        haReport: action.payload
      };

    case Actions.SET_ZONE:
      return {
        ...state,
        zone: action.payload,
        formData: {
          ...state.formData,
          info: {
            ...state.formData.info,
            utm_zone: action.payload
          }
        }
      };

      case Actions.UPDATE_REMARKS:
        return {
          ...state,
          formData: {
            ...state.formData,
            info: {
              ...state.formData.info,
              field_work: {
                ...state.formData.info.field_work,
                remarks: action.payload,
              },
            },
          },
        };

      case Actions.UPDATE_CLEARED_AREA:
        return {
          ...state,
          formData: {
            ...state.formData,
            info: {
              ...state.formData.info,
              field_work: {
                ...state.formData.info.field_work,
                cleared_area: action.payload,
              },
            },
          },
        };

        case Actions.UPDATE_CURRENT_DATE:
          return {
            ...state,
            formData: {
              ...state.formData,
              info: {
                ...state.formData.info,
                hand_over_block: {
                  ...(state.formData.info.hand_over_block || {}),
                  date: moment().format('DD.MM.YYYY'),
                  name: state.formData.info.hand_over_block?.name || '',
                  remarks: state.formData.info.hand_over_block?.remarks || '',
                  files: state.formData.info.hand_over_block?.files || []
                }
              }
            }
          };

        case Actions.UPDATE_TASK_TYPE:
          if (['battle_area_clearance', 'manual_clearance', 'technical_survey'].includes(action.payload)) {
            return {
              ...state,
              formData: {
                ...state.formData,
                info: {
                  ...state.formData.info,
                  field_work: {
                    ...state.formData.info.field_work,
                    task_type: action.payload,

                  },
                  marking: undefined,
                  eo_found_categories: shouldHaveEOCategories ? {...EOFoundCategories} : null
                },
              },
            };
          } else {
            return {
              ...state,
              formData: {
                ...state.formData,
                info: {
                  ...state.formData.info,
                  field_work: {
                    ...state.formData.info.field_work,
                    task_type: action.payload,
                    cleared_area: null,
                    remarks: "",
                  },
                  marking: action.payload === 'marking' ? {
                    area: null,
                    coordinates: {
                      items: []
                    },
                    files: [],
                    remarks: null
                  } : undefined,
                  eo_found_categories: {...EOFoundCategories}
                }
              },
            };
          }

          case Actions.UPDATE_EO_FOUND_CATEGORIES:
            return {
              ...state,
              formData: {
                ...state?.formData,
                info: {
                  ...state?.formData?.info,
                  eo_found_categories: action?.payload
                }
              }
            };

            case Actions.TOGGLE_EO_CATEGORY_ITEM:

              const updatedItems = state?.formData?.info?.eo_found_categories[action?.payload?.category]?.items?.map((item) => ({
                ...item,
                count: item?.name === action?.payload?.itemName ? item?.count + (action?.payload?.checked ? 1 : -1) : item?.count
              }));

              return {
                ...state,
                formData: {
                  ...state?.formData,
                  info: {
                    ...state?.formData?.info,
                    eo_found_categories: {
                      ...state?.formData?.info?.eo_found_categories,
                      [action.payload.category]: {
                        ...state?.formData?.info?.eo_found_categories[action?.payload?.category],
                        items: updatedItems
                      }
                    }
                  }
                }
              };

  case Actions.SET_EO_FOUND:
  return {
    ...state,
    formData: {
      ...state?.formData,
      info: {
        ...state?.formData?.info,
        field_work: {
          ...state?.formData?.info?.field_work || {},
          eo_found: action?.payload,
        },
        eo_found_categories: action?.payload ?
          (state?.formData?.info?.eo_found_categories || {...EOFoundCategories}) :
          state?.formData?.info?.eo_found_categories,
        hand_over: action?.payload ? state?.formData?.info?.hand_over : false,
        hand_over_block: action?.payload ? state?.formData?.info?.hand_over_block : null
      }
    },
  };

  case Actions.SET_CATEGORY_CHECKED:
  return {
    ...state,
    formData: {
      ...state.formData,
      info: {
        ...state.formData.info,
        eo_found_categories: {
          ...state.formData.info.eo_found_categories,
          [action.payload.category]: {
            ...state.formData.info.eo_found_categories[action.payload.category],
            isChecked: action.payload.isChecked
          }
        }
      }
    }
  };


        case Actions.RESET_CLEARED_AREA:
          return {
            ...state,
            formData: {
              ...state.formData,
              info: {
                ...state.formData.info,
                field_work: {
                  ...state.formData.info.field_work,
                  cleared_area: null,
                },
              },
            },
          };

          case Actions.SET_FORM_DATA:
            const transformPayloadData = (formData) => {
              if (formData?.info?.field_work?.number_of_deminers_on_the_field) {
                return {
                  ...formData,
                  info: {
                    ...formData.info,
                    field_work: {
                      ...formData.info.field_work,
                      number_of_deminers: formData.info.field_work.number_of_deminers_on_the_field
                    }
                  }
                };
              }
              return formData;
            };

            return {
              ...state,
              formData: transformPayloadData(action.payload)
            };


case Actions.UPDATE_EVIDENCE_SWITCHER:
  const { category: switchCategory, index: switchIndex, isChecked } = action.payload;
  const updatedSwitchEvidences = state.formData?.info?.eo_found_categories?.[switchCategory]?.evidences?.map(
    (evidence, i) => i === switchIndex ? { ...evidence, save_to_move: isChecked } : evidence
  );
  return {
    ...state,
    formData: {
      ...state.formData,
      info: {
        ...state.formData.info,
        eo_found_categories: {
          ...state.formData.info.eo_found_categories,
          [switchCategory]: {
            ...state.formData.info.eo_found_categories[switchCategory],
            evidences: updatedSwitchEvidences,
          },
        },
      },
    },
  };

case Actions.UPDATE_EVIDENCE_UNIT:
  const { category: unitCategory, index: unitIndex, unit } = action.payload;
  const updatedUnitEvidences = state.formData?.info?.eo_found_categories?.[unitCategory]?.evidences?.map(
    (evidence, i) => i === unitIndex ? { ...evidence, unit } : evidence
  );
  return {
    ...state,
    formData: {
      ...state.formData,
      info: {
        ...state.formData.info,
        eo_found_categories: {
          ...state.formData.info.eo_found_categories,
          [unitCategory]: {
            ...state.formData.info.eo_found_categories[unitCategory],
            evidences: updatedUnitEvidences,
          },
        },
      },
    },
  };

  // case Actions.UPDATE_EVIDENCE_COORDINATES:
  //   const {
  //     category: evidenceCoordCategory,
  //     index: evidenceCoordIndex,
  //     name: evidenceCoordName,
  //     value: evidenceCoordValue
  //   } = action.payload;
  //   const updatedEvidenceCoords = state.formData?.info?.eo_found_categories?.[evidenceCoordCategory]?.evidences?.map(
  //     (evidence, i) => i === evidenceCoordIndex ? { ...evidence, [evidenceCoordName]: evidenceCoordValue } : evidence
  //   );
  //   return {
  //     ...state,
  //     formData: {
  //       ...state.formData,
  //       info: {
  //         ...state.formData.info,
  //         eo_found_categories: {
  //           ...state.formData.info.eo_found_categories,
  //           [evidenceCoordCategory]: {
  //             ...state.formData.info.eo_found_categories[evidenceCoordCategory],
  //             evidences: updatedEvidenceCoords,
  //           },
  //         },
  //       },
  //     },
  //   };
    case Actions.UPDATE_EVIDENCE_COORDINATES:
      const { category: coordCategory, index: coordIndex, name, value } = action.payload;
      const updatedCoordEvidences = state.formData?.info?.eo_found_categories?.[coordCategory]?.evidences?.map(
        (evidence, i) => i === coordIndex ? { ...evidence, [name]: value } : evidence
      );
      return {
        ...state,
        formData: {
          ...state.formData,
          info: {
            ...state.formData.info,
            eo_found_categories: {
              ...state.formData.info.eo_found_categories,
              [coordCategory]: {
                ...state.formData.info.eo_found_categories[coordCategory],
                evidences: updatedCoordEvidences,
              },
            },
          },
        },
      };

      case Actions.UPDATE_EO_CATEGORY_FILES:
        const { category: filesCategory, subCategory: filesSubCategory, files } = action.payload;
        const currentLinks = state.formData.info?.eo_found_categories?.[filesCategory]?.links || [];
        const otherCategoryFiles = currentLinks.filter((link) => link.item !== filesSubCategory);
        return {
          ...state,
          formData: {
            ...state.formData,
            info: {
              ...state.formData.info,
              eo_found_categories: {
                ...state.formData.info.eo_found_categories,
                [filesCategory]: {
                  ...state.formData.info.eo_found_categories?.[filesCategory],
                  links: [...otherCategoryFiles, ...files],
                },
              },
            },
          },
        };

      case Actions.ADD_EO_CATEGORY_FILE:
        const { category: addCategory, subCategory: addSubCategory } = action.payload;
        const newFile = {
          item: addSubCategory,
          name: '',
          link: '',
        };
        const currentAddLinks = state.formData.info?.eo_found_categories?.[addCategory]?.links || [];
        return {
          ...state,
          formData: {
            ...state.formData,
            info: {
              ...state.formData.info,
              eo_found_categories: {
                ...state.formData.info.eo_found_categories,
                [addCategory]: {
                  ...state.formData.info.eo_found_categories?.[addCategory],
                  links: [...currentAddLinks, newFile],
                },
              },
            },
          },
        };

      case Actions.DELETE_EO_CATEGORY_FILE:
        const { category: removeCategory, subCategory: removeSubCategory, index } = action.payload;
        const currentRemoveLinks = state.formData.info?.eo_found_categories?.[removeCategory]?.links || [];
        const updatedLinks = currentRemoveLinks.filter((link, i) =>
          !(link.item === removeSubCategory && i === index)
        );
        return {
          ...state,
          formData: {
            ...state.formData,
            info: {
              ...state.formData.info,
              eo_found_categories: {
                ...state.formData.info.eo_found_categories,
                [removeCategory]: {
                  ...state.formData.info.eo_found_categories?.[removeCategory],
                  links: updatedLinks,
                },
              },
            },
          },
        };

    default:
      return state;
  }
};

export default dailyFormReducer;