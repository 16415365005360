import { InputComponent } from 'component/Input';
import { TextareaComponent } from 'component/Textarea';
import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';
import { AccordionItemPanel } from 'react-accessible-accordion';
import { HandOverFiles, HandOverFilesView } from './Files';
import { UPDATE_HAND_OVER_PERSON, UPDATE_HAND_OVER_REMARKS } from 'reducers/dailyFormActions';

export const HandOverBlock = ({ formData, dispatch }) => (
  <div className="hand-over-block">
    <DateBlock formData={formData} />
    <HandOverPerson formData={formData} dispatch={dispatch} />
    <HandOverFiles formData={formData} dispatch={dispatch} />
    <Remarks formData={formData} dispatch={dispatch} />
  </div>
);

const DateBlock = ({ formData }) => (
  <div className="flex-between">
    <div className="double-text">
      <span>Дата</span>
      <span>Date</span>
    </div>
    <div className="line" />
    <div>{formData.info?.hand_over_block?.date}</div>
  </div>
);

const HandOverPerson = ({ formData, dispatch }) => {
  const handlePerson = (value) => {
    dispatch({
      type: UPDATE_HAND_OVER_PERSON,
      payload: {
        value: value
      }
    });
  };
  return (
    <div className="flex-between">
      <div className="double-text">
        <span>Ім&apos;я людини, яка прийняла ВНП</span>
        <span>Name of a person accepted EO</span>
      </div>
      <div className="line" />
      <InputComponent
        value={formData.info?.hand_over_block?.name}
        handleInput={handlePerson}
        id="accepted_person_name"
        name="accepted_person_name"
      />
    </div>
  );
};

const Remarks = ({ formData, dispatch }) => {
  const handleRemarks = (value) => {
    dispatch({
      type: UPDATE_HAND_OVER_REMARKS,
      payload: {
        value: value
      }
    });
  };
  return (
    <div className="flex-between">
      <div className="double-text">
        <span>Коментарі</span>
        <span>Remarks</span>
      </div>
      <div className="line" />
      <TextareaComponent
        value={formData.info?.hand_over_block?.remarks}
        handleTextarea={handleRemarks}
        id="remarks"
        name="remarks"
      />
    </div>
  );
};

export const HandOverBlockView = ({ handOverBlock }) => {
  if (Array.isArray(handOverBlock)) {
    return (
      <>
        {handOverBlock.map((block, index) => (
          <div key={index} className="hand-over-block">
            <div className="flex-group">
              <div className="double-text">
                <span className="no-white-space">
                  Hand over {handOverBlock?.length > 1 ? `#${index + 1}` : ''}
                </span>
              </div>
              <span className="line" />
            </div>
            <AccordionItemPanel className="custom-accordion-item-panel">
              <DateBlockView date={block?.date} />
              <HandOverPersonView
                acceptedPersonName={block?.name}
              />
              <HandOverFilesView
                handOverBlock={block}
              />
              <RemarksView
                remarks={block?.remarks}
              />
            </AccordionItemPanel>
          </div>
        ))}
      </>
    );
  }

  return (
    <div className="hand-over-block">
      <div className="flex-group">
        <div className="double-text">
          <span className="no-white-space">Hand over</span>
        </div>
        <span className="line" />
      </div>
      <AccordionItemPanel className="custom-accordion-item-panel">
        <DateBlockView date={handOverBlock?.date} />
        <HandOverPersonView
          acceptedPersonName={handOverBlock?.name}
        />
        <HandOverFilesView
          handOverBlock={handOverBlock}
        />
        <RemarksView
          remarks={handOverBlock?.remarks}
        />
      </AccordionItemPanel>
    </div>
  );
};

export const HandOverPersonView = ({ acceptedPersonName }) => (
  <AccordionBodyItemView
    titleUA="Ім'я людини, яка прийняла ВНП"
    titleEN="Name of a person accepted EO"
    children={acceptedPersonName}
  />
);

export const DateBlockView = ({ date }) => (
  <AccordionBodyItemView
    titleUA="Дата"
    titleEN="Date"
    children={date}
  />
);

export const RemarksView = ({ remarks }) => (
  <AccordionBodyItemView
    titleUA="Коментарі"
    titleEN="Remarks"
    children={remarks}
  />
);
