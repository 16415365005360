import { Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ButtonComponent } from 'component/Button';
import { SelectComponent } from 'component/Select';
import { ReactComponent as FilterSvg } from 'assets/images/icons/filter.svg';
import { ReactComponent as CancelSvg } from 'assets/images/icons/close_24px.svg';
import { getValidationStatuses, getOptionsForClearance } from 'api/FilterApi';

export const FilterByStatusDialog = (props) => {
  const [open, setOpen] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);

  const [selectedValues, setSelectedValues] = useState({
    nts: {
      selectedStatus: [],
      selectedAreaType: [],
      selectedFilters: [],
    },
    ha: {
      selectedStatus: [],
      selectedAreaType: [],
      selectedFilters: [],
    },
    all: {
      selectedStatus: [],
      selectedAreaType: [],
      selectedFilters: [],
    },
  });
  const fetchValidationStatus = (type) => getValidationStatuses(type)
    .then((response) => {
      setStatusOptions((prevStatusOptions) => ({
        ...prevStatusOptions,
        [type]: response.data,
      }));
    })
    .catch(() => {
      console.log('Error fetching validation statuses');
    });

  const handleClickOpen = () => {
    setOpen(true);
    const type = props.activeTabIndex === 0 ? 'nts' : 'ha';
    fetchValidationStatus(type);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const applyFilters = () => {
    setOpen(false);
    props.handleSelectedStatusChange(
      selectedValues.nts.selectedStatus,
      selectedValues.ha.selectedStatus,
      selectedValues.all.selectedStatus,
    );
  };

  const handleFilterSelection = (selected) => {
    const type = props.activeTabIndex === 0 ? 'nts' : props.activeTabIndex === 1 ? 'ha' : 'all';
    setSelectedValues((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        selectedFilters: selected,
      },
    }));
  };

  const handleStatusSelection = (selected) => {
    const type = props.activeTabIndex === 0 ? 'nts' : props.activeTabIndex === 1 ? 'ha' : 'all';
    setSelectedValues((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        selectedStatus: selected,
      },
    }));
  };

  const handleAreaTypeSelection = (selected) => {
    const type = props.activeTabIndex === 0 ? 'nts' : props.activeTabIndex === 1 ? 'ha' : 'all';
    setSelectedValues((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        selectedAreaType: selected,
      },
    }));
  };

  const resetFilteredData = () => {
    const type = props.activeTabIndex === 0 ? 'nts' : props.activeTabIndex === 1 ? 'ha' : 'all';
    setSelectedValues((prevState) => ({
      ...prevState,
      [type]: {
        selectedStatus: [],
        selectedAreaType: [],
        selectedFilters: [],
      },
    }));
  };

  useEffect(() => {
    if (props.resetFiltersStatus === true) {
      resetFilteredData();
    }
  }, [props.resetFiltersStatus]);

  const currentStatusOptions = props.activeTabIndex === 0
    ? statusOptions.nts
    : props.activeTabIndex === 1
      ? statusOptions.ha
      : statusOptions.all || [];

  const currentSelectedValues = props.activeTabIndex === 0
    ? selectedValues.nts
    : props.activeTabIndex === 1
      ? selectedValues.ha
      : selectedValues.all;

  return (
    <div className="text-center">
      <ButtonComponent
        className="filter-btn"
        configuration="filled"
        state="enabled"
        iconRight={<FilterSvg />}
        label="Filter"
        handleButton={handleClickOpen}
      />
      <Dialog
        className="filter-dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="confirm-popup">
          <div className="header">
            <span className="title">Filter</span>
            <CancelSvg className="closeBtn" onClick={handleClose} />
          </div>
          <div className="select-wrapper w-100" style={{ gap: '0' }}>
            <span className="label">Select filters</span>
            <SelectComponent
              placeholder="Select..."
              className="w-100"
              value={currentSelectedValues.selectedFilters}
              options={
                props.activeTabIndex !== 0
                  ? [
                    { value: 'status', label: 'Status' },
                    { value: 'areaType', label: 'Area type' },
                  ]
                  : [{ value: 'status', label: 'Status' }]
              }
              handleSelect={handleFilterSelection}
              isMulti
            />
          </div>

          {currentSelectedValues.selectedFilters.some((item) => item.value === 'areaType')
            && props.activeTabIndex === 1 && (
              <div className="select-wrapper w-100" style={{ gap: '0' }}>
                <span className="label">Select area type</span>
                <SelectComponent
                  placeholder="Select..."
                  className="w-100"
                  value={currentSelectedValues.selectedAreaType}
                  options={[
                    { value: 'I3T|SHA', label: 'I3T|SHA' },
                    { value: '3T|CHA', label: '3T|CHA' },
                  ]}
                  handleSelect={handleAreaTypeSelection}
                  isMulti
                />
              </div>
            )}
          {currentSelectedValues.selectedFilters.some((item) => item.value === 'status') && (
            <div className="select-wrapper w-100" style={{ gap: '0' }}>
              <span className="label">Select status</span>
              <SelectComponent
                placeholder="Select..."
                className="filter-status"
                value={currentSelectedValues.selectedStatus}
                options={currentStatusOptions}
                handleSelect={handleStatusSelection}
                isMulti
                menuPortalTarget={document.body}
              />
            </div>
          )}
          <div className="buttons-group">
            <ButtonComponent
              iconLeft={<CancelSvg />}
              className="danger"
              handleButton={resetFilteredData}
              label="Reset"
            />
            <ButtonComponent
              iconLeft={<FilterSvg />}
              handleButton={() => {
                applyFilters();
              }}
              label="Apply"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export const FilterForClearanceDialog = (props) => {
  const [open, setOpen] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);
  const [donorOptions, setDonorOptions] = useState([]);
  const [methodTypeOptions, setMethodTypeOptions] = useState([]);

  const [selectedValues, setSelectedValues] = useState({
    clearanceTask: {
      selectedStatus: [],
      selectedDonors: [],
      selectedMethodTypes: [],
    },
    daily: {
      selectedStatus: [],
      selectedInternalIds: [],
      selectedReporterNames: [],
    },
    weekly: {
      selectedStatus: [],
      selectedInternalIds: [],
      selectedReporterNames: [],
    },
  });

  const fetchValidationStatus = (type) => getOptionsForClearance('validation_status')
    .then((response) => {
      setStatusOptions((prevStatusOptions) => ({
        ...prevStatusOptions,
        [type]: response.data,
      }));
    })
    .catch(() => {
      console.log('Error fetching validation statuses');
    });

  const fetchDonorOptions = (type) => getOptionsForClearance('donor')
    .then((response) => {
      setDonorOptions((prevDonorOptions) => ({
        ...prevDonorOptions,
        [type]: response.data,
      }));
    })
    .catch(() => {
      console.log('Error fetching donors options');
    });

  const fetchMethodTypeOptions = (type) => getOptionsForClearance('method')
    .then((response) => {
      setMethodTypeOptions((prevMethodTypeOptions) => ({
        ...prevMethodTypeOptions,
        [type]: response.data,
      }));
    })
    .catch(() => {
      console.log('Error fetching method type options');
    });

  const handleClickOpen = () => {
    setOpen(true);
    if (props.activeTabIndex === 0) {
      fetchValidationStatus('clearanceTask');
      fetchDonorOptions('donor');
      fetchMethodTypeOptions('methodType');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFilterSelection = (selected) => {
    let type;

    switch (props.activeTabIndex) {
      case 0:
        type = 'clearanceTask';
        break;
      case 1:
        type = 'daily';
        break;
      case 2:
        type = 'weekly';
        break;
      default:
        type = 'clearanceTask';
    }

    setSelectedValues((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        selectedFilters: selected,
      },
    }));
  };

  const handleStatusSelection = (selected) => {
    let type;

    switch (props.activeTabIndex) {
      case 0:
        type = 'clearanceTask';
        break;
      case 1:
        type = 'daily';
        break;
      case 2:
        type = 'weekly';
        break;
      default:
        type = 'clearanceTask';
    }

    setSelectedValues((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        selectedStatus: selected,
      },
    }));
  };

  const handleInternalIdSelection = (selected) => {
    let type;

    switch (props.activeTabIndex) {
      case 0:
        type = 'clearanceTask';
        break;
      case 1:
        type = 'daily';
        break;
      case 2:
        type = 'weekly';
        break;
      default:
        type = 'clearanceTask';
    }

    setSelectedValues((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        selectedInternalIds: selected,
      },
    }));
  };
  const handleReporterNamesSelection = (selected) => {
    let type;

    switch (props.activeTabIndex) {
      case 0:
        type = 'clearanceTask';
        break;
      case 1:
        type = 'daily';
        break;
      case 2:
        type = 'weekly';
        break;
      default:
        type = 'clearanceTask';
    }

    setSelectedValues((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        selectedReporterNames: selected,
      },
    }));
  };
  const handleMethodTypesSelection = (selected) => {
    let type;

    switch (props.activeTabIndex) {
      case 0:
        type = 'clearanceTask';
        break;
      case 1:
        type = 'daily';
        break;
      case 2:
        type = 'weekly';
        break;
      default:
        type = 'clearanceTask';
    }

    setSelectedValues((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        selectedMethodTypes: selected,
      },
    }));
  };

  const handleDonorSelection = (selected) => {
    let type;

    switch (props.activeTabIndex) {
      case 0:
        type = 'clearanceTask';
        break;
      case 1:
        type = 'daily';
        break;
      case 2:
        type = 'weekly';
        break;
      default:
        type = 'clearanceTask';
    }

    setSelectedValues((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        selectedDonors: selected,
      },
    }));
  };

  const resetFilteredData = () => {
    let type;

    switch (props.activeTabIndex) {
      case 0:
        type = 'clearanceTask';
        break;
      case 1:
        type = 'daily';
        break;
      case 2:
        type = 'weekly';
        break;
      default:
        type = 'clearanceTask';
    }

    setSelectedValues((prevState) => ({
      ...prevState,
      [type]: {
        selectedStatus: [],
        selectedInternalIds: [],
        selectedFilters: [],
        selectedDonors: [],
        selectedMethodTypes: [],
        selectedReporterNames: [],
      },
    }));
    props.resetPage();
  };

  const currentStatusOptions = props.activeTabIndex === 0
    ? statusOptions.clearanceTask
    : props.activeTabIndex === 1
      ? statusOptions.daily
      : statusOptions.weekly || [];

  const currentSelectedValues = props.activeTabIndex === 0
    ? selectedValues.clearanceTask
    : props.activeTabIndex === 1
      ? selectedValues.daily
      : selectedValues.weekly;

  const currentSelectedMethodTypes = props.activeTabIndex === 0
    ? methodTypeOptions.methodType
    : props.activeTabIndex === 1
      ? methodTypeOptions.daily
      : methodTypeOptions.weekly;

  const currentSelectedDonors = props.activeTabIndex === 0
    ? donorOptions.donor
    : props.activeTabIndex === 1
      ? donorOptions.daily
      : donorOptions.weekly;

  const applyFilters = () => {
    let type;

    switch (props.activeTabIndex) {
      case 0:
        type = 'clearanceTask';
        break;
      case 1:
        type = 'daily';
        break;
      case 2:
        type = 'weekly';
        break;
      default:
        type = 'clearanceTask';
    }

    props.onFiltersChange(selectedValues[type]);
    setOpen(false);
  };

  return (
    <div className="text-center">
      <ButtonComponent
        className="filter-btn"
        configuration="filled"
        state="enabled"
        iconRight={<FilterSvg />}
        label="Filter"
        handleButton={handleClickOpen}
      />
      <Dialog
        className="filter-dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="confirm-popup">
          <div className="header">
            <span className="title">Filter</span>
            <CancelSvg className="closeBtn" onClick={handleClose} />
          </div>
          <div className="select-wrapper w-100" style={{ gap: '0' }}>
            <span className="label">Select filters</span>
            <SelectComponent
              placeholder="Select..."
              className="w-100"
              value={currentSelectedValues?.selectedFilters}
              options={
                props.activeTabIndex !== 0
                  ? [
                    { value: 'validation_status', label: 'Status' },
                    { value: 'internalId', label: 'Internal Id' },
                    { value: 'reporterName', label: 'Reporter name' },
                  ]
                  : [
                    { value: 'validation_status', label: 'Status' },
                    { value: 'donor', label: 'Donor' },
                    { value: 'method', label: 'Method type' },
                  ]
              }
              handleSelect={handleFilterSelection}
              isMulti
            />
          </div>

          {currentSelectedValues?.selectedFilters?.some((item) => item.value === 'internalId')
            && props.activeTabIndex === 1 && (
              <div className="select-wrapper w-100" style={{ gap: '0' }}>
                <span className="label">Select Internal Id</span>
                <SelectComponent
                  placeholder="Select..."
                  className="w-100"
                  value={currentSelectedValues?.selectedInternalIds}
                  options={[
                    { value: 'I3T|SHA', label: 'I3T|SHA' },
                    { value: '3T|CHA', label: '3T|CHA' },
                  ]}
                  handleSelect={handleInternalIdSelection}
                  isMulti
                />
              </div>
            )}
          {currentSelectedValues?.selectedFilters?.some((item) => item.value === 'reporterName')
            && props.activeTabIndex === 1 && (
              <div className="select-wrapper w-100" style={{ gap: '0' }}>
                <span className="label">Select Reporter name</span>
                <SelectComponent
                  placeholder="Select..."
                  className="w-100"
                  value={currentSelectedValues?.selectedReporterNames}
                  options={[
                    { value: 'I3T|SHA', label: 'I3T|SHA' },
                    { value: '3T|CHA', label: '3T|CHA' },
                  ]}
                  handleSelect={handleReporterNamesSelection}
                  isMulti
                />
              </div>
            )}
          {currentSelectedValues?.selectedFilters?.some((item) => item.value === 'validation_status') && (
            <div className="select-wrapper w-100" style={{ gap: '0' }}>
              <span className="label">Select status</span>
              <SelectComponent
                placeholder="Select..."
                className="filter-status"
                value={currentSelectedValues?.selectedStatus}
                options={currentStatusOptions}
                handleSelect={handleStatusSelection}
                isMulti
                menuPortalTarget={document.body}
              />
            </div>
          )}
          {currentSelectedValues?.selectedFilters?.some((item) => item.value === 'donor') && (
            <div className="select-wrapper w-100" style={{ gap: '0' }}>
              <span className="label">Select donor</span>
              <SelectComponent
                placeholder="Select..."
                className="filter-status"
                value={currentSelectedValues?.selectedDonors}
                options={currentSelectedDonors}
                handleSelect={handleDonorSelection}
                isMulti
                menuPortalTarget={document.body}
              />
            </div>
          )}
          {currentSelectedValues?.selectedFilters?.some((item) => item.value === 'method') && (
            <div className="select-wrapper w-100" style={{ gap: '0' }}>
              <span className="label">Select method type</span>
              <SelectComponent
                placeholder="Select..."
                className="filter-status"
                value={currentSelectedValues?.selectedMethodTypes}
                options={currentSelectedMethodTypes}
                handleSelect={handleMethodTypesSelection}
                isMulti
                menuPortalTarget={document.body}
              />
            </div>
          )}
          <div className="buttons-group">
            <ButtonComponent
              iconLeft={<CancelSvg />}
              className="danger"
              handleButton={resetFilteredData}
              label="Reset"
            />
            <ButtonComponent
              iconLeft={<FilterSvg />}
              handleButton={applyFilters}
              label="Apply"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export const FilterForDailyAndWeeklyDialog = (props) => {
  const [open, setOpen] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);
  const [taskIdOptions, setTaskIdOptions] = useState([]);
  const [taskTypeOptions, setTaskTypeOptions] = useState([]);
  const [teamActivityOptions, setTeamActivityOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);

  const [selectedValues, setSelectedValues] = useState({
    dailyAndWeekly: {
      selectedFilters: [],
      selectedStatus: [],
      selectedTaskIds: [],
      selectedTaskTypes: [],
      selectedTeamActivities: [],
      selectedTeams: [],
    },
  });

  const fetchValidationStatus = () => getOptionsForClearance('validation_status')
    .then((response) => {
      setStatusOptions(response.data);
    })
    .catch(() => {
      console.log('Error fetching validation statuses');
    });

  const fetchTaskIdOptions = () => getOptionsForClearance('task_id')
    .then((response) => {
      setTaskIdOptions(response.data);
    })
    .catch(() => {
      console.log('Error fetching task id options');
    });

  const fetchTaskTypeOptions = () => getOptionsForClearance('task_type')
    .then((response) => {
      setTaskTypeOptions(response.data);
    })
    .catch(() => {
      console.log('Error fetching task type options');
    });

  const fetchTeamActivityOptions = () => getOptionsForClearance('team_activities')
    .then((response) => {
      setTeamActivityOptions(response.data);
    })
    .catch(() => {
      console.log('Error fetching team activity options');
    });

  const fetchTeamOptions = () => getOptionsForClearance('team')
    .then((response) => {
      setTeamOptions(response.data);
    })
    .catch(() => {
      console.log('Error fetching team options');
    });

  const handleClickOpen = () => {
    setOpen(true);
    fetchValidationStatus();
    fetchTaskIdOptions();
    fetchTaskTypeOptions();
    fetchTeamActivityOptions();
    fetchTeamOptions();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFilterSelection = (selected) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      dailyAndWeekly: {
        ...prevState.dailyAndWeekly,
        selectedFilters: selected,
      },
    }));
  };

  const handleStatusSelection = (selected) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      dailyAndWeekly: {
        ...prevState.dailyAndWeekly,
        selectedStatus: selected,
      },
    }));
  };

  const handleTaskIdSelection = (selected) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      dailyAndWeekly: {
        ...prevState.dailyAndWeekly,
        selectedTaskIds: selected,
      },
    }));
  };

  const handleTaskTypesSelection = (selected) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      dailyAndWeekly: {
        ...prevState.dailyAndWeekly,
        selectedTaskTypes: selected,
      },
    }));
  };

  const handleTeamActivitiesSelection = (selected) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      dailyAndWeekly: {
        ...prevState.dailyAndWeekly,
        selectedTeamActivities: selected,
      },
    }));
  };

  const handleTeamSelection = (selected) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      dailyAndWeekly: {
        ...prevState.dailyAndWeekly,
        selectedTeams: selected,
      },
    }));
  };

  const resetFilteredData = () => {
    setSelectedValues({
      dailyAndWeekly: {
        selectedFilters: [],
        selectedStatus: [],
        selectedTaskIds: [],
        selectedTaskTypes: [],
        selectedTeamActivities: [],
        selectedTeams: [],
      },
    });
    props.resetPage?.();
  };

  const applyFilters = () => {
    props.onFiltersChange?.(selectedValues.dailyAndWeekly);
    setOpen(false);
  };

  const filterOptions = [
    { value: 'validation_status', label: 'Status' },
    { value: 'taskId', label: 'Task ID' },
    { value: 'task_type', label: 'Task Type' },
    { value: 'team_activity', label: 'Team Activity' },
    { value: 'team', label: 'Team' },
  ];

  return (
    <div className="text-center">
      <ButtonComponent
        className="filter-btn"
        configuration="filled"
        state="enabled"
        iconRight={<FilterSvg />}
        label="Filter"
        handleButton={handleClickOpen}
      />
      <Dialog
        className="filter-dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="confirm-popup">
          <div className="header">
            <span className="title">Filter</span>
            <CancelSvg className="closeBtn" onClick={handleClose} />
          </div>

          <div className="select-wrapper w-100" style={{ gap: '0' }}>
            <span className="label">Select filters</span>
            <SelectComponent
              placeholder="Select..."
              className="w-100"
              value={selectedValues.dailyAndWeekly.selectedFilters}
              options={filterOptions}
              handleSelect={handleFilterSelection}
              isMulti
            />
          </div>

          {selectedValues.dailyAndWeekly.selectedFilters?.some(
            (item) => item.value === 'validation_status'
          ) && (
              <div className="select-wrapper w-100" style={{ gap: '0' }}>
                <span className="label">Status</span>
                <SelectComponent
                  placeholder="Select status..."
                  className="w-100"
                  value={selectedValues.dailyAndWeekly.selectedStatus}
                  options={statusOptions}
                  handleSelect={handleStatusSelection}
                  isMulti
                  menuPortalTarget={document.body}
                />
              </div>
            )}

          {selectedValues.dailyAndWeekly.selectedFilters?.some(
            (item) => item.value === 'taskId'
          ) && (
              <div className="select-wrapper w-100" style={{ gap: '0' }}>
                <span className="label">Task ID</span>
                <SelectComponent
                  placeholder="Select task ID..."
                  className="w-100"
                  value={selectedValues.dailyAndWeekly.selectedTaskIds}
                  options={taskIdOptions}
                  handleSelect={handleTaskIdSelection}
                  isMulti
                  menuPortalTarget={document.body}
                />
              </div>
            )}

          {selectedValues.dailyAndWeekly.selectedFilters?.some(
            (item) => item.value === 'task_type'
          ) && (
              <div className="select-wrapper w-100" style={{ gap: '0' }}>
                <span className="label">Task Type</span>
                <SelectComponent
                  placeholder="Select task type..."
                  className="w-100"
                  value={selectedValues.dailyAndWeekly.selectedTaskTypes}
                  options={taskTypeOptions}
                  handleSelect={handleTaskTypesSelection}
                  isMulti
                  menuPortalTarget={document.body}
                />
              </div>
            )}

          {selectedValues.dailyAndWeekly.selectedFilters?.some(
            (item) => item.value === 'team_activity'
          ) && (
              <div className="select-wrapper w-100" style={{ gap: '0' }}>
                <span className="label">Team Activity</span>
                <SelectComponent
                  placeholder="Select team activity..."
                  className="w-100"
                  value={selectedValues.dailyAndWeekly.selectedTeamActivities}
                  options={teamActivityOptions}
                  handleSelect={handleTeamActivitiesSelection}
                  isMulti
                  menuPortalTarget={document.body}
                />
              </div>
            )}

          {selectedValues.dailyAndWeekly.selectedFilters?.some(
            (item) => item.value === 'team'
          ) && (
              <div className="select-wrapper w-100" style={{ gap: '0' }}>
                <span className="label">Team</span>
                <SelectComponent
                  placeholder="Select team..."
                  className="w-100"
                  value={selectedValues.dailyAndWeekly.selectedTeams}
                  options={teamOptions}
                  handleSelect={handleTeamSelection}
                  isMulti
                  menuPortalTarget={document.body}
                />
              </div>
            )}

          <div className="buttons-group">
            <ButtonComponent
              iconLeft={<CancelSvg />}
              className="danger"
              handleButton={resetFilteredData}
              label="Reset"
            />
            <ButtonComponent
              iconLeft={<FilterSvg />}
              handleButton={applyFilters}
              label="Apply"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
