import React, { useEffect, useState, useRef } from 'react';
import { SwitherComponent } from 'component/Swither';
import { ColorItem } from './ColorItem';

export const Legend = ({
  colors, gridGeojson, setGridGeojson, methodTypes,
}) => {
  const labels = methodTypes?.map((method) => method.name);
  const [colorStates, setColorStates] = useState({});

  const colorToFeaturesMapRef = useRef({});

  const originalMapCreatedRef = useRef(false);

  useEffect(() => {
    if (!gridGeojson?.features?.length || !colors?.length) {
      return;
    }

    if (!originalMapCreatedRef.current) {
      const colorMap = {};
      colors.forEach(color => {
        colorMap[color] = [];
      });

      gridGeojson.features.forEach(feature => {
        const featureId = feature.id || feature.properties.id;
        if (featureId && feature.properties.colors) {
          feature.properties.colors.forEach(color => {
            if (!colorMap[color]) colorMap[color] = [];
            if (!colorMap[color].includes(featureId)) {
              colorMap[color].push(featureId);
            }
          });
        }
      });

      let hasData = false;
      for (const color in colorMap) {
        if (colorMap[color].length > 0) {
          hasData = true;
          break;
        }
      }

      if (!hasData) {
        return;
      }

      colorToFeaturesMapRef.current = colorMap;
      originalMapCreatedRef.current = true;

      const newColorStates = {};
      colors.forEach(color => {
        newColorStates[color] = true;
      });
      setColorStates(newColorStates);
    }
  }, [gridGeojson, colors]);

  const handleSwitcherChange = (checked, color) => {

    setColorStates(prev => {
      const newState = {
        ...prev,
        [color]: checked
      };
      return newState;
    });

    const featureIds = colorToFeaturesMapRef.current[color] || [];

    setGridGeojson(prevGridGeojson => {
      if (!prevGridGeojson?.features) {
        console.error('Previous gridGeojson is invalid');
        return prevGridGeojson;
      }

      const updatedFeatures = prevGridGeojson.features.map(feature => {
        const featureId = feature.id || feature.properties.id;
        if (!featureId) return feature;

        const currentColors = [...(feature.properties.colors || [])];

        if (checked) {
          if (featureIds.includes(featureId) && !currentColors.includes(color)) {
            return {
              ...feature,
              properties: {
                ...feature.properties,
                colors: [...currentColors, color]
              }
            };
          }
        } else {
          if (currentColors.includes(color)) {
            return {
              ...feature,
              properties: {
                ...feature.properties,
                colors: currentColors.filter(c => c !== color)
              }
            };
          }
        }
        return feature;
      });

      return {
        ...prevGridGeojson,
        features: updatedFeatures
      };
    });
  };

  if (!originalMapCreatedRef.current) {
    return (
      <div className="legend">
        <div className="title">Legend</div>
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <div className="legend">
      <div className="title">Legend</div>
      {colors.map((color, index) => (
        <div className="legend-item" key={index}>
          <SwitherComponent
            labelRight={(
              <div className="flex-group">
                <ColorItem color={color} />
                <span>{labels?.[index] || ''}</span>
              </div>
            )}
            checked={colorStates[color] !== false}
            handleSwitcher={(checked) => handleSwitcherChange(checked, color)}
          />
        </div>
      ))}
    </div>
  );
};