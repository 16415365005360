import { InputComponent } from 'component/Input';
import { CategoryTranslations } from 'consts/Daily';
import React from 'react';
import { UPDATE_CATEGORY_DATA } from 'reducers/dailyFormActions';

export const CategoryEvidences = ({ formData, dispatch, category }) => {
   const handleCategoryInput = (value, name) => {
    const currentCategory = formData?.info?.eo_found_categories?.[category];

    const updatedItems = currentCategory.items.map(item =>
      item.name === name ? { ...item, count: value === '' ? 0 : Number(value) } : item
    );

    const existingEvidences = currentCategory.evidences || [];
    const itemEvidences = existingEvidences.filter(ev => ev.item === name);
    const otherEvidences = existingEvidences.filter(ev => ev.item !== name);

    const newCount = value === '' ? 0 : Number(value);
    let finalEvidences;

    if (newCount > itemEvidences.length) {
      const additionalEvidences = Array.from({ length: newCount - itemEvidences.length })
        .map(() => ({
          item: name,
          long: '',
          lat: '',
          count: 0,
          save_to_move: false,
          unit: 'kg',
        }));
      finalEvidences = [...otherEvidences, ...itemEvidences, ...additionalEvidences];
    } else {
      finalEvidences = [...otherEvidences, ...itemEvidences.slice(0, newCount)];
    }

    dispatch({
      type: UPDATE_CATEGORY_DATA,
      payload: {
        category,
        items: updatedItems,
        evidences: finalEvidences
      }
    });

  };


  return (
    <div className="eo-found-categories">
      {formData?.info?.eo_found_categories?.[category]?.items?.map((item, index) => (
        <div className="category" key={index}>
          <span>{CategoryTranslations[category][item.name]}</span>
          <InputComponent
            className="number-medium"
            value={item.count}
            name={item.name}
            id={item.name}
            handleInput={(value) => handleCategoryInput(value, item.name)}
          />
        </div>
      ))}
    </div>
  );
};

export const CategoryEvidencesView = ({ formData, category }) => (
  <div className="eo-found-categories">
    {formData?.info?.eo_found_categories?.[category]?.items?.map((item, index) => (
      <div className="category" key={index}>
        <span>{CategoryTranslations[category][item.name]}</span>
        <span className="border-bottom-rounded">{item.count}</span>
      </div>
    ))}
  </div>
);
