import { ButtonComponent } from 'component/Button';
import { ImageDeleteDialog } from 'component/Dialog';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close_24px.svg';
import { ReactComponent as TurnIcon } from 'assets/images/icons/update2.svg';
import { ReactComponent as ImageIcon } from 'assets/images/icons/Photo.svg';
import { ReactComponent as SaveSvg } from 'assets/images/icons/save-1.svg';
import React from 'react';
import { createOrUpdateFile, getBase64 } from 'utils/Helper';

export const EOREImageDetailInfo = (props) => {
  const {
    photos, setPhotos,
    currentImageIndex,
    closeImageModal,
    deleteImageByIndex,
  } = props;
  const currentPhoto = photos[currentImageIndex];

  const handleRotate = (value) => {
    const updatedFile = createOrUpdateFile(value, currentPhoto.file.name);
    setPhotos((prevPhotos) => {
      const updatedPhotos = [...prevPhotos];
      updatedPhotos[currentImageIndex].data_url = value;
      updatedPhotos[currentImageIndex].file = updatedFile;
      return updatedPhotos;
    });
  };
  return (
    <div className="file-info">
      <div className="header">
        <span>Image information</span>
        <div onClick={() => closeImageModal(false)}>
          <CloseIcon />
        </div>
      </div>
      <div className="body">
        <div className="flex-between">
          <div className="flex-group">
            <ImageIcon className="text-hover" />
            <span>Rotate photo</span>
          </div>
          <div className="icon text-hover">
            <TurnIcon
              onClick={() => {
                getBase64(currentPhoto.data_url)
                  .then((res) => {
                    handleRotate(res);
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            />
          </div>
        </div>
      </div>
      <div className="footer">
        <ImageDeleteDialog
          svgOnly={false}
          deleteImage={() => deleteImageByIndex(currentImageIndex)}
          closeModal={props.closeModal}
        />
        <div className="line" />
        <div className="flex-group flex-center">
          <ButtonComponent
            handleButton={() => closeImageModal(false)}
            className="outline"
            iconLeft={<SaveSvg />}
            label="Save"
          />
          <ButtonComponent
            handleButton={() => closeImageModal(false)}
            className="danger outline"
            label={(
              <div className="flex-group">
                <CloseIcon />
                <span>Cancel</span>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};
