import * as React from 'react';
import 'assets/styles/popup.scss';
import Dialog from '@mui/material/Dialog';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as AddSvg } from 'assets/images/icons/add_24px.svg';
import { ReactComponent as DeleteSvg } from 'assets/images/icons/delete_24px.svg';
import { ReactComponent as CancelSvg } from 'assets/images/icons/close_24px.svg';
import { ReactComponent as ShedulleIcon } from 'assets/images/icons/Shedulle.svg';

import { createSettings } from 'api/SettingsApi';
import {
  getAllUsersForClearanceFiles,
  getAllUsersForClearanceMethodType,
  getAllUsersForEOREDetails,
  getAllUsersForTokens,
} from 'api/UserAPI';
import { UserLabel } from 'pages/Profile/Notifications/Table';
import { ClipLoader } from 'react-spinners';
import {
  createOrUpdateClearanceFiles,
  createOrUpdateClearanceMethods,
  createOrUpdateEoreHandouts,
}
  from 'api/EOREApi';
import { InputComponent } from './Input';
import { ButtonComponent } from './Button';
import { SelectComponent } from './Select';

export const CreateReportDialog = (props) => {
  const {
    confirmPopup,
  } = props;

  const [reportName, setReportName] = useState('');
  const [open, setOpen] = useState(false);

  const handleReportName = (value) => {
    setReportName(value);
  };

  const containsOnlySpaces = (str) => /^\s*$/.test(str);

  const showReportNameError = () => {
    toast.warning('Report name is required');
  };

  const handleClose = () => {
    setReportName('');
    setOpen(false);
  };

  const checkReportName = () => {
    if (reportName && !containsOnlySpaces(reportName)) {
      localStorage.setItem('reportName', reportName);
      confirmPopup();
      handleClose();
    } else {
      showReportNameError();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <div className="text-center">
      <ButtonComponent
        configuration="filled"
        state="enabled"
        iconLeft={<AddSvg />}
        label="NTS/HA Report"
        className="menu-btn"
        handleButton={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="confirm-popup">
          <div className="header">
            <span className="title">Create report</span>
            <CancelSvg className="closeBtn" onClick={handleClose} />
          </div>
          <div>
            <InputComponent
              type="text"
              id="input-report-name"
              name="report-name"
              placeholder="Enter report name"
              value={reportName}
              handleInput={handleReportName}
              iconLeft={<ShedulleIcon />}
              autoComplete="off"
              blueLink
            />
          </div>
          <div className="buttons-group">
            <ButtonComponent
              iconLeft={<CancelSvg />}
              className="danger"
              handleButton={handleClose}
              label="Cancel"
            />
            <ButtonComponent
              iconLeft={<AddSvg />}
              handleButton={() => {
                checkReportName();
              }}
              label="Create"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export const CreateTokenDialog = ({ onUpdate }) => {
  const [open, setOpen] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [user, setUser] = useState('');
  const [imsmaUserName, setImsmaUserName] = useState('');
  const [imsmaPassword, setImsmaPassword] = useState('');
  const [imsmaToken, setImsmaToken] = useState('');
  const [deeplToken, setDeeplToken] = useState('');
  const [loader, setLoader] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const convertObjectToArrayUsers = (value) => Object.keys(value)?.map((key) => {
    const transformUserToLabel = {
      email: value[key].email,
      id: value[key].id,
      name: value[key].name,
      surname: value[key].surname,
      short_role_name: value[key].role.short_name,
      role_color: value[key].role.color,
    };
    return {
      value: value[key].id,
      label: <UserLabel user={transformUserToLabel} />,
    };
  });

  const handleCreate = async () => {
    setLoader(true);
    if (!imsmaUserName) {
      toast.error('IMSMA username is required');
      setLoader(false);
      return;
    }
    if (!imsmaPassword) {
      toast.error('IMSMA password is required');
      setLoader(false);
      return;
    }
    if (!user) {
      toast.error('User is required');
      setLoader(false);
      return;
    }

    try {
      const data = {
        username: imsmaUserName,
        password: imsmaPassword,
        imsma_token: imsmaToken,
        deepl_token: deeplToken,
        user: user?.value,
      };
      await createSettings(data);
      toast.success('Settings created successfully');
      handleClose();
      onUpdate();
    } catch (error) {
      setLoader(false);
      toast.error('Error creating settings');
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getAllUsersForTokens().then((response) => {
      setAllUsers(response.data);
    });
  }, []);

  return (
    <div className="text-center">
      <ButtonComponent
        iconLeft={<AddSvg />}
        label="Add new tokens"
        handleButton={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="confirm-token-popup">
          <div className="header">
            <span className="title">Create token</span>
            <CancelSvg className="closeBtn" onClick={handleClose} />
          </div>
          <div className="middle-section">
            <InputComponent
              classNameWrapper="w-100"
              style={{ width: '-webkit-fill-available' }}
              id="imsma-username"
              name="imsma-username"
              handleInput={setImsmaUserName}
              value={imsmaUserName}
              label="IMSMA username"
            />
            <InputComponent
              classNameWrapper="w-100"
              style={{ width: '-webkit-fill-available' }}
              id="imsma-password"
              name="imsma-password"
              handleInput={setImsmaPassword}
              value={imsmaPassword}
              label="IMSMA password"
            />
            <InputComponent
              classNameWrapper="w-100"
              style={{ width: '-webkit-fill-available' }}
              id="imsma-token"
              name="imsma-token"
              handleInput={setImsmaToken}
              value={imsmaToken}
              label="IMSMA Token"
            />
            <InputComponent
              classNameWrapper="w-100"
              style={{ width: '-webkit-fill-available' }}
              id="deepl-token"
              name="deepl-token"
              handleInput={setDeeplToken}
              value={deeplToken}
              label="Deepl Token"
            />

            <div className="select-wrapper w-100" style={{ gap: '0', width: '100%' }}>
              <div className="label">
                User
              </div>
              <SelectComponent
                id="reports"
                name="reports"
                label="Type"
                value={user?.value
                  ? { value: user?.value, label: user?.label }
                  : null}
                options={convertObjectToArrayUsers(allUsers)}
                handleSelect={(value, label) => {
                  setUser({ value, label });
                }}
                menuPortalTarget={document.body}
              />
            </div>
          </div>
          <div className="buttons-group">
            <ButtonComponent
              iconLeft={<CancelSvg />}
              className="danger"
              handleButton={handleClose}
              label="Cancel"
            />
            <ButtonComponent
              iconLeft={loader ? (
                <ClipLoader
                  color="rgba(0, 177, 183, 1)"
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : <AddSvg />}
              handleButton={handleCreate}
              label="Create"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export const AddNewDetailsDialog = ({ refreshData, title, type }) => {
  const [open, setOpen] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [user, setUser] = useState('');
  const [loader, setLoader] = useState(false);
  const [inputValues, setInputValues] = useState({ 'new-0': '' });

  const handleClose = () => {
    setOpen(false);
    setInputValues({ 'new-0': '' });
    setUser('');
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const convertObjectToArrayUsers = (value) => Object.keys(value)?.map((key) => {
    const transformUserToLabel = {
      email: value[key].email,
      id: value[key].id,
      name: value[key].name,
      surname: value[key].surname,
      short_role_name: value[key].role.short_name,
      role_color: value[key].role.color,
    };
    return {
      value: value[key].id,
      label: <UserLabel user={transformUserToLabel} />,
    };
  });

  useEffect(() => {
    if (type === 'clearance_files') {
      getAllUsersForClearanceFiles(false)
        .then((response) => {
          setAllUsers(response.data);
        });
    }
    if (type === 'eore_materials') {
      getAllUsersForEOREDetails(false)
        .then((response) => {
          setAllUsers(response.data);
        });
    }
    if (type === 'clearance_method_type') {
      getAllUsersForClearanceMethodType(false)
        .then((response) => {
          setAllUsers(response.data);
        });
    }
  }, []);

  const handleAddInput = () => {
    const newInputId = `new-${Date.now()}`;
    setInputValues((prevValues) => ({
      ...prevValues,
      [newInputId]: '',
    }));
  };

  const handleInputChange = (id, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const handleCreateHandouts = async () => {
    if (!user || !user.value) {
      toast.error('Please select a user');
      return;
    }

    const payload = Object.keys(inputValues).map((handoutId) => ({
      name: inputValues[handoutId],
      user: user.value,
    }));
    try {
      setLoader(true);
      if (type === 'eore_materials') {
        await createOrUpdateEoreHandouts(payload);
      } else if (type === 'clearance_files') {
        await createOrUpdateClearanceFiles(payload);
      } else if (type === 'clearance_method_type') {
        await createOrUpdateClearanceMethods(payload);
      } else {
        throw new Error('Invalid type provided');
      }

      toast.success(`${title} created successfully`);
      handleClose();
      setInputValues({ 'new-0': '' });
      if (refreshData) refreshData();
    } catch (error) {
      console.error('Error creating:', error);
      toast.error(`Failed to create ${title}`);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="text-center">
      <ButtonComponent
        iconLeft={<AddSvg />}
        label={`Add ${title}`}
        handleButton={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="confirm-token-popup">
          <div className="header">
            <span className="title">{`Add ${title}`}</span>
            <CancelSvg className="closeBtn" onClick={handleClose} />
          </div>
          <div className="middle-section">
            {Object.keys(inputValues).map((inputId) => (
              <div
                key={inputId}
                className="input-for-materials"
              >
                <InputComponent
                  classNameWrapper="w-100"
                  style={{ width: '-webkit-fill-available' }}
                  id={inputId}
                  name={inputId}
                  value={inputValues[inputId]}
                  handleInput={(value) => handleInputChange(inputId, value)}
                />
                <DeleteSvg
                  className="delete-icon"
                  style={{ color: '#868686' }}
                  onClick={() => {
                    setInputValues((prevValues) => {
                      const { [inputId]: _, ...rest } = prevValues;
                      return rest;
                    });
                  }}
                />
              </div>
            ))}
            <ButtonComponent
              iconLeft={<AddSvg />}
              label="Add"
              handleButton={handleAddInput}
            />
            <div className="select-wrapper w-100" style={{ gap: '0', width: '100%' }}>
              <div className="label">
                User
              </div>
              <SelectComponent
                id="reports"
                name="reports"
                label="Type"
                value={user?.value
                  ? { value: user?.value, label: user?.label }
                  : null}
                options={convertObjectToArrayUsers(allUsers)}
                handleSelect={(value, label) => {
                  setUser({ value, label });
                }}
                menuPortalTarget={document.body}
              />
            </div>
          </div>
          <div className="buttons-group">
            <ButtonComponent
              iconLeft={<CancelSvg />}
              className="danger"
              handleButton={handleClose}
              label="Cancel"
            />
            <ButtonComponent
              iconLeft={loader ? (
                <ClipLoader
                  color="rgba(0, 177, 183, 1)"
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : <AddSvg />}
              handleButton={handleCreateHandouts}
              label="Create"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export const DeleteReportDialog = (props) => {
  const {
    deleteNtsReport,
    deleteHaReport,
    deleteEoreReport,
    deleteClearanceTaskReport,
    deleteDailyreport,
    deleteWeeklyReport,
    isDisabled,
  } = props;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    if (!isDisabled) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <DeleteSvg
        className="delete-icon"
        onClick={handleClickOpen}
        style={{
          color: isDisabled ? '#d3d3d3' : '#868686',
          pointerEvents: isDisabled ? 'none' : 'auto',
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="confirm-popup">
          <div className="header">
            <span className="title">Delete report</span>
            <span
              className="closeBtn"
              onClick={handleClose}
            />
          </div>

          <div className="message">
            Do you really want to delete the report?
          </div>
          <div className="buttons-group">
            <ButtonComponent
              iconLeft={<DeleteSvg />}
              className="danger"
              handleButton={() => {
                if (deleteNtsReport) {
                  deleteNtsReport();
                }
                if (deleteHaReport) {
                  deleteHaReport();
                }
                if (deleteEoreReport) {
                  deleteEoreReport();
                }
                if (deleteClearanceTaskReport) {
                  deleteClearanceTaskReport();
                }
                if(deleteDailyreport){
                  deleteDailyreport();
                }
                if(deleteWeeklyReport){
                  deleteWeeklyReport();
                }
                handleClose();
              }}
              label="Delete"
            />
            <ButtonComponent
              iconLeft={<CancelSvg />}
              handleButton={handleClose}
              label="Cancel"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export const DeleteRoleDialog = (props) => {
  const {
    deleteUserRole,
    role,
  } = props;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <DeleteSvg className="delete-icon" onClick={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="confirm-popup">
          <div className="header">
            <span className="title">Delete role</span>
            <span className="closeBtn" onClick={handleClose} />
          </div>

          <div className="message">
            {`Do you really want to delete the  "${role?.name}" role?`}
          </div>
          <div className="buttons-group">
            <ButtonComponent
              iconLeft={<DeleteSvg />}
              className="danger"
              handleButton={() => {
                deleteUserRole(role?.id);
                handleClose();
              }}
              label="Delete"
            />
            <ButtonComponent
              iconLeft={<CancelSvg />}
              handleButton={handleClose}
              label="Cancel"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export const DeleteUserDialog = (props) => {
  const {
    deleteUser,
    user,
  } = props;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <DeleteSvg className="delete-icon" onClick={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="confirm-popup">
          <div className="header">
            <span className="title">Delete user</span>
            <span className="closeBtn" onClick={handleClose} />
          </div>

          <div className="message">{`Do you really want to delete the '${props?.user?.name} ${props?.user?.surname}' user?`}</div>
          <div className="buttons-group">
            <ButtonComponent
              iconLeft={<DeleteSvg />}
              className="danger"
              handleButton={() => {
                handleClose();
                deleteUser(user.id);
              }}
              label="Delete"
            />
            <ButtonComponent
              iconLeft={<CancelSvg />}
              handleButton={handleClose}
              label="Cancel"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export const ImageDeleteDialog = (props) => {
  const {
    deleteImage,
    svgOnly,
    closeModal,
  } = props;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {svgOnly && (
        <span className="delete" onClick={handleClickOpen}>
          <DeleteSvg />
        </span>
      )}
      {!svgOnly && (
        <ButtonComponent
          handleButton={handleClickOpen}
          className="danger outline"
          label={(
            <div className="flex-group">
              <DeleteSvg />
              <span>Delete photo</span>
            </div>
          )}
        />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="confirm-popup">
          <div className="header">
            <span className="title">Delete image</span>
            <span className="closeBtn" onClick={handleClose} />
          </div>
          <div>
            <div>Do you really want to delete the image?</div>
          </div>
          <div className="buttons-group">
            <ButtonComponent
              iconLeft={<DeleteSvg />}
              className="danger"
              handleButton={() => {
                deleteImage();
                if (closeModal) {
                  closeModal();
                }
                handleClose();
              }}
              label="Delete"
            />
            <ButtonComponent
              iconLeft={<CancelSvg />}
              handleButton={handleClose}
              label="Cancel"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};
export const DeleteValidationFlowCardDialog = ({ onDelete, validation }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    onDelete(validation.order);
    handleClose();
  };

  return (
    <>
      <DeleteSvg onClick={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="confirm-popup">
          <div className="header">
            <span className="title">Delete Validation</span>
            <span className="closeBtn" onClick={handleClose} />
          </div>
          <div className="message">
            {`Do you really want to delete the validation ${validation.order}?`}
          </div>
          <div className="buttons-group">
            <ButtonComponent
              iconLeft={<DeleteSvg />}
              className="danger"
              handleButton={handleDelete}
              label="Delete"
            />
            <ButtonComponent
              iconLeft={<CancelSvg />}
              handleButton={handleClose}
              label="Cancel"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};
export const DeleteValidationFlowDialog = ({ onDelete, reportType }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    onDelete();
    handleClose();
  };

  return (
    <>
      <DeleteSvg onClick={handleClickOpen} className="delete-icon" />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="confirm-popup">
          <div className="header">
            <span className="title">Delete Validation</span>
            <span className="closeBtn" onClick={handleClose} />
          </div>
          <div className="message">
            {`Do you really want to delete the validation ${Object.values(reportType)[0] || ''}?`}
          </div>
          <div className="buttons-group">
            <ButtonComponent
              iconLeft={<DeleteSvg />}
              className="danger"
              handleButton={handleDelete}
              label="Delete"
            />
            <ButtonComponent
              iconLeft={<CancelSvg />}
              handleButton={handleClose}
              label="Cancel"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export const DeleteAllDetailsItemsDialog = (props) => {
  const {
    handleDeleteAllDetailsItems,
    user,
    type,
  } = props;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ButtonComponent
        iconLeft={<DeleteSvg />}
        className="danger"
        label="Delete"
        handleButton={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="confirm-popup">
          <div className="header">
            <span className="title">{`Delete ${type}`}</span>
            <span className="closeBtn" onClick={handleClose} />
          </div>

          <div className="message">
            {`Do you really want to delete all ${type}?`}
          </div>
          <div className="buttons-group">
            <ButtonComponent
              iconLeft={<DeleteSvg />}
              className="danger"
              handleButton={() => {
                handleDeleteAllDetailsItems(user?.id);
                handleClose();
              }}
              label="Delete"
            />
            <ButtonComponent
              iconLeft={<CancelSvg />}
              handleButton={handleClose}
              label="Cancel"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export const DeleteAllHandoutsDialog = (props) => {
  const {
    handleDeleteAllHandouts,
    user,
  } = props;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ButtonComponent
        iconLeft={<DeleteSvg />}
        className="danger"
        label="Delete"
        handleButton={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="confirm-popup">
          <div className="header">
            <span className="title">Delete all handouts</span>
            <span className="closeBtn" onClick={handleClose} />
          </div>

          <div className="message">
            Do you really want to delete all handouts?
          </div>
          <div className="buttons-group">
            <ButtonComponent
              iconLeft={<DeleteSvg />}
              className="danger"
              handleButton={() => {
                handleDeleteAllHandouts(user?.id);
                handleClose();
              }}
              label="Delete"
            />
            <ButtonComponent
              iconLeft={<CancelSvg />}
              handleButton={handleClose}
              label="Cancel"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export const DeleteTemplateDialog = (props) => {
  const {
    deleteTemplate,
    template,
  } = props;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <DeleteSvg className="delete-icon" onClick={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="confirm-popup">
          <div className="header">
            <span className="title">Delete template</span>
            <span className="closeBtn" onClick={handleClose} />
          </div>

          <div className="message">{`Do you really want to delete the '${template?.template_name}' template?`}</div>
          <div className="buttons-group">
            <ButtonComponent
              iconLeft={<DeleteSvg />}
              className="danger"
              handleButton={() => {
                handleClose();
                deleteTemplate(template?.id);
              }}
              label="Delete"
            />
            <ButtonComponent
              iconLeft={<CancelSvg />}
              handleButton={handleClose}
              label="Cancel"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};
