import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';

export const LocalityView = (props) => {
  const { formData, subId } = props;

  return (
    <AccordionBodyItemView
      accordionSubId={subId ? subId : 2.4}
      titleUA="Населений пункт"
      titleEN="Locality"
      children={formData.info.location?.settlement_name || '-'}
    />
  );
};
