import { getHAById } from 'api/HaApi';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';

export const IMSMAID = ({
  formData,
  setFormData,
}) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (selectedValue?.value === formData?.id) return;
    setSelectedValue(() => (formData ? {
      label: formData.info?.general?.imsma_id,
      value: formData.id,
    } : null));
  }, [formData?.id]);

  useEffect(() => {
    if (selectedValue) {
      if (selectedValue?.value === formData?.id) return;
      getHAById(selectedValue.value)
        .then((res) => {
          setFormData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [selectedValue]);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    try {
      const query = searchQuery || inputValue;

      if (query && query.trim() !== '') {
        const encodedQuery = encodeURIComponent(query.trim());
        const response = await axios.get(`/reports/ha/?by_hierarchy=true&imsma_id=${encodedQuery}&pagination=false`);

        if (Array.isArray(response.data)) {
          return {
            options: response.data.map((item) => ({
              label: item.info?.general?.imsma_id || `IMSMA ID not available (ID: ${item.id})`,
              value: item.id,
            })),
            hasMore: false,
            additional: { page: 1 },
          };
        } else if (response.data.results) {
          return {
            options: response.data.results.map((item) => ({
              label: item.info?.general?.imsma_id || `IMSMA ID not available (ID: ${item.id})`,
              value: item.id,
            })),
            hasMore: false,
            additional: { page: 1 },
          };
        } else {
          return {
            options: [],
            hasMore: false,
            additional: { page: 1 },
          };
        }
      } else {
        const response = await axios.get(`/reports/ha/?global_search=true&page=${page}`);

        if (response.data.results) {
          return {
            options: response.data.results.map((item) => ({
              label: item.info?.general?.imsma_id || `IMSMA ID not available (ID: ${item.id})`,
              value: item.id,
            })),
            hasMore: Boolean(response.data.next),
            additional: { page: page + 1 },
          };
        } else {
          return {
            options: [],
            hasMore: false,
            additional: { page: 1 },
          };
        }
      }
    } catch (error) {
      console.error('Error loading options:', error);
      return {
        options: [],
        hasMore: false,
        additional: { page: 1 },
      };
    }
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    return newValue;
  };

  return (
    <div className="flex-column-group">
      <div className="double-text">
        <span>Ідентифікаційний номер IMSMA</span>
        <span>IMSMA ID</span>
      </div>
      <AsyncPaginate
        value={selectedValue}
        className="custom-select-container"
        loadOptions={loadOptions}
        onChange={setSelectedValue}
        onInputChange={handleInputChange}
        additional={{ page: 1 }}
        placeholder="Select..."
        cacheOptions={false}
        defaultOptions={false}
        isClearable={true}
        noOptionsMessage={({ inputValue }) =>
          inputValue ? "Nothing found" : "Enter text to search"
        }
      />
    </div>
  );
};

export const IMSMAIDView = ({ formData }) => (
  <div className="flex-column-group">
    <div className="double-text">
      <span>Ідентифікаційний номер IMSMA</span>
      <span>IMSMA ID</span>
    </div>
    <div className="text-grey">
      {formData?.info?.general?.imsma_id}
    </div>
  </div>
);
