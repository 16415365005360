import { AccordionBodyItem, AccordionBodyItemView } from 'component/Accordion';
import { InputComponent } from 'component/Input';
import React from 'react';
import { UPDATE_MARKING_AREA } from 'reducers/dailyFormActions';

export const MarkingArea = ({ formData, dispatch }) => {
  const validateNumericInput = (value) => {
    const normalizedValue = value.replace(',', '.');

    const regex = /^\d*\.?\d{0,4}$/;

    return value === '' || regex.test(normalizedValue) ? normalizedValue : false;
  };

  const handleInput = (value) => {
    const validValue = validateNumericInput(value);
    if (validValue !== false) {
      dispatch({
        type: UPDATE_MARKING_AREA,
        payload: validValue
      });
    }
  };
  return (
    <AccordionBodyItem
      titleUA="Маркована область"
      titleEN="Area Marked (m)"
      // className={props.errors?.general?.report_date ? 'error' : ''}
      children={(
        <InputComponent
          className="number"
          placeholder="0"
          name="marked_area"
          value={formData?.info?.marking?.area || ''}
          handleInput={handleInput}
        />
      )}
    />
  );
};

export const MarkingAreaView = ({ markedArea }) => (
  <AccordionBodyItemView
    titleUA="Маркована область"
    titleEN="Area Marked (m)"
    children={markedArea}
  />
);
