/* eslint-disable */
import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl';

import 'mapbox-gl/dist/mapbox-gl.css';
import 'assets/styles/map.scss';

import turfBbox from '@turf/bbox';
import axios from 'axios';
import proj4 from 'proj4';
import { featureCollection } from '@turf/helpers';
import { getHeaders } from 'utils/Headers';
import BmIcon from 'assets/images/icons/bmPng.png';
import LmIcon from 'assets/images/icons/lmPng.png';
import EvidenceImageIcon from 'assets/images/icons/Photo.png';
import EvidenceIcon from 'assets/images/icons/evidence-icon1.png';
import IndirectEvidenceIcon from 'assets/images/icons/evidence-icon.png';
import { useMapbox } from 'utils/MapboxContext';
import { SwitherComponent } from './Swither';

proj4.defs('EPSG:3857', '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs');
proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');

export const Map = React.memo((props) => {
  const { token } = useMapbox();
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);

  const [googleCheckbox, setGoogleCheckbox] = useState(false);
  const [mapboxStyleCheckbox, setMapboxStyleCheckbox] = useState(false);

  function transformMaxarTilesUrl(url) {
    const coordsParam = url.split('?')[1].split('&').find((param) => param.startsWith('coords='));

    if (coordsParam) {
      const coords = coordsParam.substring('coords='.length).split(',');
      const [west, south, east, north] = coords.map(parseFloat);

      const transformedCoordinates1 = proj4('EPSG:3857', 'EPSG:4326', [west, south]);
      const transformedCoordinates2 = proj4('EPSG:3857', 'EPSG:4326', [east, north]);

      const coordsNew = `${transformedCoordinates1[0]},
                ${transformedCoordinates1[1]},
                ${transformedCoordinates2[0]},
                ${transformedCoordinates2[1]}`;

      const updatedUrl = url.replace(coordsParam, `coords=${coordsNew}`);

      return updatedUrl;
    }

    return url;
  }

  function transformRequest(url, resourceType) {
    if (url.includes('maxar-tiles')) {
      const updatedUrl = transformMaxarTilesUrl(url);

      return {
        url: updatedUrl,
        headers: { Authorization: getHeaders() },
        credentials: 'include',
      };
    }

    if (resourceType === 'Tile' && url.indexOf(axios.defaults.baseURL) > -1) {
      return {
        url,
        headers: { Authorization: getHeaders() },
        credentials: 'include',
      };
    }
    return false;
  }


  useEffect(() => {
    mapboxgl.accessToken = token;
    if (!map && token) {
      const newMap = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/light-v10',
        center: [32, 49],
        zoom: 3.5,
        transformRequest: (url, resourceType) => transformRequest(url, resourceType),
      });

      newMap.on('load', () => {
        setMap(newMap);
        props.setMap(newMap);
      });
    }
  }, [props.setMap, map, token]);

  useEffect(() => {
    if (map && !map?.getSource('wms-google')) {
      map.addSource('wms-google', {
        type: 'raster',
        tiles: ['https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'],
        tileSize: 256,
      });

      map.addLayer({
        id: 'wms-google-layer',
        type: 'raster',
        source: 'wms-google',
        layout: {
          visibility: 'none',
        },
      });
    }

    if (map && !map.getSource('mapbox-satellite')) {
      map.addSource('mapbox-satellite', {
        type: 'raster',
        url: 'mapbox://mapbox.satellite',
      });
      map.addLayer({
        id: 'mapbox-satellite-layer',
        type: 'raster',
        source: 'mapbox-satellite',
        layout: {
          visibility: 'none',
        },
      });
    }
  });

  const updateSource = (source, data) => {
    map.getSource(source).setData(data);

    if (!data?.features?.length) {
      return;
    }

    if (Array.isArray(data?.features[0]) && data?.features[0]?.length === 0) {
      return;
    }

    const firstFeature = data?.features[0];
    if (!firstFeature?.geometry?.coordinates?.length) {
      return;
    }

    map.fitBounds(turfBbox(data));
  };

  const setLmGeojson = (data) => {
    map.addSource('lm', {
      type: 'geojson',
      data,
      generateId: true,
    });

    [{ 'lm-icon': LmIcon }].forEach((icon) => {
      map.loadImage(Object.values(icon)[0], (error, image) => {
        if (error) throw error;
        map.addImage(Object.keys(icon)[0], image);
      });
    });

    map.addLayer({
      id: 'lm-layer',
      type: 'symbol',
      source: 'lm',
      layout: {
        'icon-image': 'lm-icon',
        'icon-size': 1,
        'icon-offset': [0, 0],
        'icon-allow-overlap': true,
      },
    });
  };

  const setBmGeojson = (data) => {
    map.addSource('bm', {
      type: 'geojson',
      data,
      generateId: true,
    });

    [{ 'bm-icon': BmIcon }].forEach((icon) => {
      map.loadImage(Object.values(icon)[0], (error, image) => {
        if (error) throw error;
        map.addImage(Object.keys(icon)[0], image);
      });
    });

    map.addLayer({
      id: 'bm-layer',
      type: 'symbol',
      source: 'bm',
      layout: {
        'icon-image': 'bm-icon',
        'icon-size': 1,
        'icon-offset': [0, 0],
        'icon-allow-overlap': true,
      },
    });
  };
  const setTurnPointsGeojson = (data) => {
    const getColor = () => {
      if (props.hazardAreaType) {
        return [
          'match',
          props.hazardAreaType,
          'I3T|SHA', '#ff0000',
          '3T|CHA', '#ffa500',
          '#808080',
        ];
      } if (props.typeInFeature) {
        return [
          'match',
          ['get', 'hazard_area_type'],
          'I3T|SHA', '#ff0000',
          '3T|CHA', '#ffa500',
          '#808080',
        ];
      }
      return ['get', 'color'];
    };
    map.addSource('turnPointsSource', {
      type: 'geojson',
      data,
      generateId: true,
    });

    map.addLayer({
      id: 'turnPointsPolygon-layer',
      type: 'fill',
      source: 'turnPointsSource',
      paint: {
        'fill-color': getColor(),
        'fill-opacity': 0.5,
      },
      filter: ['==', '$type', 'Polygon'],
    });

    map.addLayer({
      id: 'turnPoints-layer',
      type: 'circle',
      source: 'turnPointsSource',
      paint: {
        'circle-radius': 4,
        'circle-color': getColor(),
      },
      filter: ['==', '$type', 'Point'],
    });

    map.addLayer({
      id: 'turnPoints-text-layer',
      type: 'symbol',
      source: 'turnPointsSource',
      layout: {
        'text-field': ['get', 'name'],
        'text-size': 12,
        'text-offset': [0, -1.5],
      },
      filter: ['==', '$type', 'Point'],
    });
    const filteredPolygon = data.features?.filter((feature) => feature.geometry?.type === 'Polygon');
    if (!filteredPolygon && !filteredPolygon[0].geometry.coordinates.length) return;
    const bbox = turfBbox(featureCollection(filteredPolygon));

    if (bbox.some((coord) => !Number.isFinite(coord))) return;
    map.fitBounds(bbox);
  };

  const setEvidenceGeojson = (data) => {
    data.features.forEach((feature, index) => {
      feature.properties.text = `4.2.${index + 1}`;
      feature.properties.photoLength = props.evidences?.direct_evidences.items[index]
        ?.photo?.length;
    });
    map.addSource('evidence', {
      type: 'geojson',
      data,
      generateId: true,
    });

    [{ 'evidence-icon': EvidenceIcon },
      { 'evidence-image-icon': EvidenceImageIcon }].forEach((icon) => {
      map.loadImage(Object.values(icon)[0], (error, image) => {
        if (error) throw error;
        map.addImage(Object.keys(icon)[0], image);
      });
    });

    map.addLayer({
      id: 'evidence-layer',
      type: 'symbol',
      source: 'evidence',
      layout: {
        'icon-image': 'evidence-icon',
        'icon-size': 0.05,
        'icon-offset': [0, 0],
        'icon-allow-overlap': true,
      },
    });

    map.addLayer({
      id: 'evidence-text-layer',
      type: 'symbol',
      source: 'evidence',
      layout: {
        'text-field': ['get', 'text'],
        'text-size': 14, // Adjust the text size
        'text-offset': [0, -1.5], // Adjust the text offset (y-axis)
      },
    });
    map.addLayer({
      id: 'evidence-image-layer',
      type: 'symbol',
      source: 'evidence',
      layout: {
        'icon-image': 'evidence-image-icon',
        'icon-size': 0.5,
        'icon-offset': [50, -40],
        'icon-allow-overlap': true,
      },
      filter: ['>', 'photoLength', 0],
    });
  };

  const setIndirectEvidenceGeojson = (data) => {
    data.features.forEach((feature, index) => {
      feature.properties.text = `4.5.${index + 1}`;
      feature.properties.photoLength = props.evidences?.indirect_evidences.items[index]
        ?.photo?.length;
    });
    map.addSource('indirect-evidence', {
      type: 'geojson',
      data,
      generateId: true,
    });

    [{ 'indirect-evidence-icon': IndirectEvidenceIcon },
      { 'evidence-image-icon': EvidenceImageIcon }].forEach((icon) => {
      map.loadImage(Object.values(icon)[0], (error, image) => {
        if (error) throw error;
        map.addImage(Object.keys(icon)[0], image);
      });
    });

    map.addLayer({
      id: 'indirect-evidence-layer',
      type: 'symbol',
      source: 'indirect-evidence',
      layout: {
        'icon-image': 'indirect-evidence-icon',
        'icon-size': 0.03,
        'icon-offset': [0, 0],
        'icon-allow-overlap': true,
      },
    });

    map.addLayer({
      id: 'indirect-evidence-text-layer',
      type: 'symbol',
      source: 'indirect-evidence',
      layout: {
        'text-field': ['get', 'text'],
        'text-size': 14, // Adjust the text size
        'text-offset': [0, -1.5], // Adjust the text offset (y-axis)
      },
    });

    map.addLayer({
      id: 'indirect-evidence-image-layer',
      type: 'symbol',
      source: 'indirect-evidence',
      layout: {
        'icon-image': 'evidence-image-icon',
        'icon-size': 0.5,
        'icon-offset': [50, -40],
        'icon-allow-overlap': true,
      },
      filter: ['>', 'photoLength', 0],
    });
  };

  useEffect(() => {
    if (map && props.lmGeojson) {
      const data = props.lmGeojson;
      if (map.getSource('lm')) {
        updateSource('lm', data);
      } else {
        setLmGeojson(data);
      }
    }
  }, [map, props.lmGeojson]);

  useEffect(() => {
    if (map && props.bmGeojson) {
      const data = props.bmGeojson;
      if (map.getSource('bm')) {
        updateSource('bm', data);
      } else {
        setBmGeojson(data);
      }
    }
  }, [map, props.bmGeojson]);

  useEffect(() => {
    if (map && props.turnPointsPolygon) {
      const data = props.turnPointsPolygon;
      if (map.getSource('turnPointsSource')) {
        updateSource('turnPointsSource', data);
      } else {
        setTurnPointsGeojson(data);
      }
    }
  }, [map, props.turnPointsPolygon]);

  useEffect(() => {
    if (map && props.evidenceGeojson) {
      const data = props.evidenceGeojson;
      if (map.getSource('evidence')) {
        data.features.forEach((feature, index) => {
          feature.properties.text = `4.2.${index + 1}`;
          feature.properties.photoLength = props.evidences?.direct_evidences.items[index]
            ?.photo?.length;
        });
        updateSource('evidence', data);
      } else {
        setEvidenceGeojson(data);
      }
    }
  }, [map, props.evidenceGeojson, props.evidences]);

  useEffect(() => {
    if (map && props.indirectEvidenceGeojson) {
      const data = props.indirectEvidenceGeojson;
      if (map.getSource('indirect-evidence')) {
        data.features.forEach((feature, index) => {
          feature.properties.text = `4.5.${index + 1}`;
          feature.properties.photoLength = props.evidences?.indirect_evidences.items[index]
            ?.photo?.length;
        });
        updateSource('indirect-evidence', data);
      } else {
        setIndirectEvidenceGeojson(data);
      }
    }
  }, [map, props.indirectEvidenceGeojson]);

  const handleCheckboxLayer = (layer, isChecked) => {
    const visibleProp = isChecked ? 'visible' : 'none';
    map.setLayoutProperty(layer, 'visibility', visibleProp);
  };

  return (
    <div className="map-wrapper">
      {token && (
        <div
          ref={mapContainerRef}
          className={`map-container ${props.height} ${props.width}`}
        />
      )}
      {!token && (
        <div className="map-container error-text flex-center align-center">
          Неможливо завантажити карту
        </div>
      )}
      {props.googleWms && token
        && (
          <div className="mapbox-control flex-column-group">
            <SwitherComponent
              labelRight="Satellite (google)"
              checked={googleCheckbox}
              handleSwitcher={(isChecked) => {
                handleCheckboxLayer('wms-google-layer', isChecked);
                handleCheckboxLayer('mapbox-satellite-layer', false);
                setMapboxStyleCheckbox(false);
                setGoogleCheckbox(isChecked);
              }}
            />
            <SwitherComponent
              labelRight="Satellite (mapbox)"
              checked={mapboxStyleCheckbox}
              handleSwitcher={(isChecked) => {
                handleCheckboxLayer('wms-google-layer', false);
                handleCheckboxLayer('mapbox-satellite-layer', isChecked);
                setGoogleCheckbox(false);
                setMapboxStyleCheckbox(isChecked);
              }}
            />
          </div>
        )}
    </div>
  );
});
