import { AccordionBodyItem, AccordionBodyItemView } from 'component/Accordion';
import React from 'react';

export const Coordinates = ({ coordinates }) => {
  if (!coordinates) {
    return null;
  }

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>2.8</span>
        </div>
      )}
      titleUA="Координати"
      titleEN="Coordinates"
      children={(
        <div className="flex-column-group">
          <span>{`lat: ${coordinates[0]}`}</span>
          <span>{`lng: ${coordinates[1]}`}</span>
        </div>
      )}
    />
  );
};

export const CoordinatesLocationView = ({ coordinates }) => (
  <AccordionBodyItemView
    accordionSubId={2.8}
    titleUA="Координати"
    titleEN="Coordinates"
    children={(
      <div className="flex-column-group">
        <span>{`lat: ${coordinates[0]}`}</span>
        <span>{`lng: ${coordinates[1]}`}</span>
      </div>
    )}
  />
);
