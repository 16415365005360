import { Table, TableHeader, TableRow } from 'component/Table';
import React from 'react';
import moment from 'moment';
import { DeleteTemplateDialog } from 'component/Dialog';
import { toast } from 'react-toastify';
import { deleteEoreTemplate } from 'api/EOREApi';
import { DownloadEORETemplate } from './Download';

export const EORETable = (props) => {
  const deleteTemplate = (id) => {
    props.setLoader(true);
    deleteEoreTemplate(id)
      .then(() => {
        props.setTemplatesPageList((prevTemplates) => prevTemplates
          .filter((template) => template.id !== id));
        toast.success('Template deleted successfully');
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.message || 'Error deleting template';
        toast.error(errorMessage);
      })
      .finally(() => {
        props.setLoader(false);
      });
  };

  return (
    <div className="eore-table">
      <Table>
        <TableHeader>
          <th>Date</th>
          <th>Name</th>
          <th>File name</th>
          <th>Version</th>
          <th>Action</th>
        </TableHeader>
        <tbody>
          {props.templatesData.length === 0 && !props.isLoading &&
             (
              <TableRow>
                <td>Дані відсутні</td>
              </TableRow>
            )}
          {props.templatesData.map((data) => (
            <TableRow key={data.id}>
              <td>{moment(data.created_at).format('L')}</td>
              <td>{data.template_name || '-'}</td>
              <td>{data.file_name || '-'}</td>
              <td>{data.template_version || '-'}</td>
              <td>
                <div className="icons">
                  {data.file_name && (
                    <div className="flex-group">
                      <DownloadEORETemplate
                        id={data.id}
                        fileName={data.file_name}
                        setLoader={props.setLoader}
                        iconOnly
                      />
                      <DeleteTemplateDialog
                        deleteTemplate={() => deleteTemplate(data.id)}
                        setLoader={props.setLoader}
                        template={data}
                      />
                    </div>
                  )}
                </div>
              </td>
            </TableRow>
          ))}
          {props.next && (
            <div className="loadMore-div">
              <button
                className="loadMore-button"
                onClick={() => {
                  props.setTemplatesPageCount(props.templatesPageCount + 1);
                }}
              >
                Load more...
              </button>
            </div>
          )}
        </tbody>
      </Table>
    </div>
  );
};
