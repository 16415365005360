import { useReducer } from 'react';
import dailyFormReducer, { initialState } from 'reducers/dailyFormReducer';
import { DispatchContext } from 'context/DispatchConext';

export const DispatchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(dailyFormReducer, initialState);

  return (
    <DispatchContext.Provider value={dispatch}>
      {children}
    </DispatchContext.Provider>
  );
};