import { AccordionBodyItem, AccordionBodyItemView } from 'component/Accordion';
import { SwitherComponent } from 'component/Swither';
import { DailyForm } from 'consts/Daily';
import React from 'react';
import { SET_EO_FOUND, TOGGLE_HAND_OVER } from 'reducers/dailyFormActions';

export const EOFoundSwitcher = ({ formData, dispatch }) => {
  const isEOFound = formData?.info?.field_work?.eo_found || false;

  const handleEOFound = (isChecked) => {
    dispatch({
      type: SET_EO_FOUND,
      payload: isChecked,
    });
    if (!isChecked) {
      dispatch({
        type: TOGGLE_HAND_OVER,
        payload: false,
      });
    }
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>2.2.2</span>
        </div>
      )}
      titleUA="Знайдено ВНП"
      titleEN="EO found"
      // className={props.errors?.general?.report_date ? 'error' : ''}
      children={(
        <SwitherComponent
        checked={isEOFound}
          handleSwitcher={(isChecked) => {
            handleEOFound(isChecked);
          }}
        />
      )}
    />
  );
};

export const EOFoundSwitcherView = ({ formData }) => (
  <AccordionBodyItemView
    accordionSubId="2.2.2"
    titleUA="Знайдено ВНП"
    titleEN="EO found"
    children={formData?.info?.field_work?.eo_found ? 'Так' : 'Ні'}
  />
);
