import React from 'react';
import { Accordion } from 'react-accessible-accordion';
import { SessionInfo } from '../Blocks/View/SessionInfo/SessionInfo';
import GeneralInfo from '../Blocks/View/GeneralInfo/GeneralInfo';
import { FinalIndicatorsView } from '../Blocks/View/FinalIndicators/FinalIndicators';
import PhotosBlockView from '../Blocks/View/PhotosBlockView/PhotosBlockView';
import AttachmentsBlockView from '../Blocks/View/AttachmentsBlockView/AttachmentsBlockView';

const CheckEORETab = ({
  formData,
  zone,
  setZone,
  handouts,
  photos,
  attachments,
  setLoader,
  filteredFinalIndicatorsComments,
  filteredGeneralComments,
  filteredSessionInformationComments,
}) => (
  <>
    <div className="info_block_header">
      <div className="info_block_header_left">Атрибути</div>
      <span className="info_block_header_line" />
      <div className="info_block_header_right">Attributes</div>
    </div>
    {/* <TranslateWrapperComponent
      formData={formData}
    // setTranslatedFormData={setTranslatedFormData}
    // translationState={translateState}
    > */}
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      preExpanded={[
        'general',
        'sessionInformation',
        'finalIndicators',
      ]}
      className="custom-accordion"
    >
      <GeneralInfo
        formData={formData}
        mode="check"
        comments={filteredGeneralComments}
      />
      <SessionInfo
        formData={formData}
        comments={filteredSessionInformationComments}
        handouts={handouts}
        zone={zone}
        setZone={setZone}
        mode="check"
      />
      <FinalIndicatorsView
        formData={formData}
        comments={filteredFinalIndicatorsComments}
        mode="check"
      />
      <PhotosBlockView
        formData={formData}
        photos={photos}
        setLoader={setLoader}
      />
      <AttachmentsBlockView
        attachments={attachments}
        setLoader={setLoader}
        formData={formData}
      />
    </Accordion>
    {/* </TranslateWrapperComponent> */}
  </>
);

export default CheckEORETab;
