import { AccordionBodyItem, AccordionBodyItemView } from 'component/Accordion';
import React from 'react';

export const TeamId = ({ formData, hasError }) => (
  <AccordionBodyItem
    accordionSubId={(
      <div>
        <span>1.4</span>
        <span className="star" />
      </div>
      )}
    titleUA="ID команди"
    titleEN="Team ID"
    className={hasError ? 'error error-text' : ''}
    children={(
        formData?.info?.dms?.team_id || '-'
      )}
  />
);

export const TeamIdView = ({ teamId }) => (
  <AccordionBodyItemView
    accordionSubId={1.4}
    titleUA="ID команди"
    titleEN="Team ID"
    children={teamId || '-'}
  />
);
