/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowIcon } from 'assets/images/icons/Left.svg';
import { SelectComponent } from 'component/Select';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as CancelIcon } from 'assets/images/icons/close_24px.svg';
import { ReactComponent as SaveIcon } from 'assets/images/icons/save-1.svg';

import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {
  createValidationFlow,
  getAllUsers,
  getUserById,
  getValidationsReportTypesByUserId,
} from 'api/UserAPI';
import { UserLabel } from 'pages/Profile/Notifications/Table';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import SwiperValidation from './SwiperValidation';

const ValidationProcess = ({ toggleValidationProcess, mode }) => {
  const [validationTypes, setValidationTypes] = useState([]);
  const [type, setType] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [user, setUser] = useState('');
  const [loadingUserById, setLoadingUserById] = useState(false);
  const [loadingAllUsers, setLoadingAllUsers] = useState(false);
  const [validationData, setValidationData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getValidationTypes = () => {
    getValidationsReportTypesByUserId(mode?.user)
      .then((response) => {
        setValidationTypes(response?.data);
      }).catch(() => {
        console.error('Error fetching validations');
      });
  };

  const getUsers = () => {
    setLoadingAllUsers(true);
    getAllUsers().then((response) => {
      setAllUsers(response?.data);
      setLoadingAllUsers(false);
    }).catch(() => {
      setLoadingAllUsers(false);
      console.error('Error with fetching users');
    });
  };

  useEffect(() => {
    if (!mode?.user) {
      getUsers();
    }
    if (mode?.action !== 'view' && !mode?.type) {
      getValidationTypes();
    }
    if (mode?.user) {
      setLoadingUserById(true);

      getUserById(mode?.user)
        .then((response) => {
          const userData = response?.data;
          const transformedUser = {
            email: userData.email,
            id: userData.id,
            name: userData.name,
            surname: userData.surname,
            short_role_name: userData.role.short_name,
            role_color: userData.role.color,
          };
          setUser(transformedUser);

          setLoadingUserById(false);
        });
    }
    if (mode?.action === 'view' || mode?.action === 'edit') {
      setValidationTypes(mode?.type);
    }
  }, []);

  const convertObjectToArray = (value) => Object.keys(value)?.map((key) => ({
    value: key,
    label: value[key],
  }));
  const convertObjectToArrayUsers = (value) => Object.keys(value)?.map((key) => {
    const transformUserToLabel = {
      email: value[key].email,
      id: value[key].id,
      name: value[key].name,
      surname: value[key].surname,
      short_role_name: value[key].role.short_name,
      role_color: value[key].role.color,
    };
    return {
      value: value[key].id,
      label: <UserLabel user={transformUserToLabel} />,
    };
  });

  const handleStepsAndRolesUpdate = (updatedStepsAndRoles) => {
    const updatedData = updatedStepsAndRoles?.map((item) => ({
      order: item?.order,
      roles: item?.roles?.map((role) => role?.id),
    }));
    return setValidationData(updatedData);
  };

  const createValidation = (reportType, userId, updatedData) => {
    const payload = {
      user: userId || mode?.user,
      report_type: reportType || Object?.keys(mode?.type)[0],
      steps: updatedData.map((step, index) => ({
        roles: step.roles,
        trigger_imsma_send: false,
        order: index + 1,
      })),
    };
    createValidationFlow(payload)
      .then(() => {
        toast.success('Validation created');
        toggleValidationProcess();
      })
      .catch((error) => {
        toast.error('Error creating validation');
        console.error('Error creating validation:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSave = () => {
    if (!type?.value && !mode?.type) {
      toast.error('Please select a type.');
      return;
    }
    if (!user?.value && !mode?.user) {
      toast.error('Please select a user.');
      return;
    }

    let invalidStepIndex = -1;
    const isValid = validationData.every((step, index) => {
      if (!step.roles || step.roles.length === 0) {
        invalidStepIndex = index + 1;
        return false;
      }
      return true;
    });

    if (!isValid) {
      toast.error(`Please select role. Missing roles in Validation № ${invalidStepIndex}.`);
      return;
    }

    if (validationData.length === 0) {
      toast.error('Please select role. Missing roles at least one validation step.');
      return;
    }

    setIsLoading(true);
    createValidation(type?.value, user?.value, validationData);
  };

  return (
    <div className="validation-process-container">
      <div className="header">
        <div
          className="back-button"
        >
          <ArrowIcon className="back-icon" onClick={() => toggleValidationProcess()} />
          <div className="select-components">
            <div className="first-select">
              <div className="select-wrapper">
                <span className="label">
                  Type
                </span>
                {mode?.action === 'view' || mode?.action === 'edit' ? (
                  <div
                    className="type-label"
                  >
                    {Object.values(mode?.type) || '-'}
                  </div>
                ) : (
                  <SelectComponent
                    id="type"
                    name="type"
                    label="Type"
                    style={{ width: '160px' }}
                    value={type?.value ? { value: type?.value, label: type?.label } : null}
                    options={convertObjectToArray(validationTypes)}
                    handleSelect={(value, label) => {
                      setType({ value, label });
                    }}
                    placeholder={(
                      <div>
                        <span>Select type</span>
                      </div>
                    )}
                  />
                )}

              </div>
            </div>
            <div className="select-wrapper w-100">
              <span className="label">
                User
              </span>
              {mode?.action === 'view' || mode?.action === 'edit' || mode?.user ? (
                <div
                  className="user-label"
                >
                  {loadingUserById
                    ? (
                      <ClipLoader
                        color="rgba(0, 177, 183, 1)"
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    ) : <UserLabel user={user} />}
                </div>
              ) : (
                <SelectComponent
                  id="reports"
                  name="reports"
                  label="Type"
                  className="full"
                  value={user?.value
                    ? { value: user?.value, label: user?.label }
                    : null}
                  options={convertObjectToArrayUsers(allUsers)}
                  handleSelect={(value, label) => {
                    setUser({ value, label });
                  }}
                  placeholder={(
                    loadingAllUsers
                      ? (
                        <ClipLoader
                          color="rgba(0, 177, 183, 1)"
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      )
                      : (
                        <div>
                          <span>Select user</span>
                        </div>
                      )
                  )}
                />

              )}

            </div>
          </div>
        </div>
        <div className="title">
          Validation process
        </div>
      </div>
      <div className="carousel-section">
        <SwiperValidation
          mode={mode}
          onUpdateStepsAndRoles={handleStepsAndRolesUpdate}
        />
      </div>
      <div className="footer">
        <div className="buttons-group">
          {isLoading ? <ClipLoader color="rgba(0, 177, 183, 1)" size={20} aria-label="Loading Spinner" data-testid="loader" />
            : (
              <>
                <ButtonComponent
                  iconLeft={<CancelIcon />}
                  className="danger"
                  handleButton={() => toggleValidationProcess()}
                  label="Cancel"
                />
                <ButtonComponent
                  iconLeft={<SaveIcon />}
                  className={mode?.action === 'view' ? 'disabled-save' : ''}
                  handleButton={handleSave}
                  label="Save"
                  disabled={mode?.action === 'view'}
                />
              </>
            )}
        </div>
      </div>

    </div>
  );
};

export default ValidationProcess;
