import React, { useState } from 'react';
import { AccordionBodyItem, AccordionBodyItemView2 } from 'component/Accordion';
import { DailyMap } from '../Map/DailyMap';

export const MapBlock = ({
  methodTypes,
  formData,
  dispatch,
  gridGeojson,
  setGridGeojson,
  loader,
  zone,
  haReportPolygon,
}) => {
  const [map, setMap] = useState(null);


  return (
    <>
      <AccordionBodyItem
        accordionSubId={(
          <div>
            <span>2.2.3</span>
          </div>
        )}
        titleUA="Карта"
        titleEN="Map"
      />
      <DailyMap
        map={map}
        setMap={setMap}
        dispatch={dispatch}
        methodTypes={methodTypes}
        formData={formData}
        gridGeojson={gridGeojson}
        setGridGeojson={setGridGeojson}
        loader={loader}
        zone={zone}
        haReportPolygon={haReportPolygon}
      />
    </>
  );
};

export const MapBlockView = ({
  methodTypes,
  formData,
  gridGeojson,
  setGridGeojson,
  loader,
  haReportPolygon,
  zone,
  mode,
}) => {
  const [map, setMap] = useState(null);


  return (
    <div className="position-relative w-100" style={{marginBottom: '20px'}}>
      <AccordionBodyItemView2
        accordionSubId={(
          <div>
            <span>2.2.3</span>
          </div>
        )}
        titleUA="Карта"
        titleEN="Map"
      />
      <DailyMap
        methodTypes={methodTypes}
        map={map}
        setMap={setMap}
        formData={formData}
        gridGeojson={gridGeojson}
        setGridGeojson={setGridGeojson}
        loader={loader}
        haReportPolygon={haReportPolygon}
        zone={zone}
        mode={mode}
      />
    </div>
  );
};
