export const URLS = {
  REFRESH: 'users/token/refresh/',
  VERIFY: 'users/token/verify/',
  LOGIN: 'users/token/',

  LOGINPAGE: '/login/',
  DASHBOARD: '/dashboard/',
  USERS: '/users/',
  NTS_NA_REPORT: '/nts-ha-report/',
  EORE_LIST: '/reports/eore/',
  TCP: '/task-clearance/list/',
  TCP_VIEW: '/task-clearance/view/',
  CREATE_REPORT_NTS: '/reports/nts/',
  CREATE_REPORT_HA: '/reports/ha/',
  CREATE_NTS_REPORT_PAGE: '/reports/create-report/nts/',
  CREATE_HA_REPORT_PAGE: '/reports/create-report/ha/',

  POST_ARCHIVES: '/reports/ha/archives/validate/',

  GLOBAL_MAP: '/global-map/',
  DRONE_DATA: '/drone-data/',
  SECRETS_SETTINGS: '/secrets-settings/',
  EXPORT: '/export/',

  MACHINERY_TRACKING: '/machinery-tracking/',
};
