import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';

export const RegionView = (props) => {
  const { formData, subId } = props;

  return (
    <AccordionBodyItemView
      accordionSubId={subId ? subId : 2.1}
      titleUA="Область"
      titleEN="Region"
      children={formData.info?.location?.region || '-'}
    />
  );
};
