import { AccordionBodyItem, AccordionBodyItemView } from 'component/Accordion';
import React from 'react';

export const TaskId = ({ taskId }) => (
  <AccordionBodyItem
    accordionSubId={(
      <div>
        <span>2.1</span>
      </div>
    )}
    titleUA="ID завдання"
    titleEN="Task ID"
    // className={props.errors?.general?.report_date ? 'error' : ''}
    children={taskId}
  />
)

export const TaskIdView = ({ taskId }) => (
  <AccordionBodyItemView
    accordionSubId={2.1}
    titleUA="ID завдання"
    titleEN="Task ID"
    children={taskId || '-'}
  />
);
