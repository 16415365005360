import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-accessible-accordion';
import { Loader } from 'component/Loader';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { getEoreReportById, getHandouts } from 'api/EOREApi';
import { useValidationComments } from 'hooks/useValidationComments';
import { CommentBlock, handleReportValidation } from 'component/CommentBlock';
import { EOREFormData } from 'consts/Forms';
import { ButtonComponent } from 'component/Button';
import { REPORT_TYPES } from 'consts/ReportValidation';
import { rejectEOREReport } from 'api/HaApi';
import { useUser } from 'routes/UserContext';
import { FinalIndicatorsView } from './Blocks/View/FinalIndicators/FinalIndicators';
import { SessionInfo } from './Blocks/View/SessionInfo/SessionInfo';
import { PageTitle } from './Create';
import { getAttachments, getEorePhotos } from './Blocks/PhotosBlock';
import GeneralInfo from './Blocks/View/GeneralInfo/GeneralInfo';
import PhotosBlockView from './Blocks/View/PhotosBlockView/PhotosBlockView';
import AttachmentsBlockView from './Blocks/View/AttachmentsBlockView/AttachmentsBlockView';

export const ViewEOREReportPage = ({ mode }) => {
  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState(EOREFormData);
  const [templateVersion, setTemplateVersion] = useState('');
  const [handouts, setHandouts] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [zone, setZone] = useState('');
  const { reportId } = useParams();
  const commentsData = useValidationComments(REPORT_TYPES.EORE, reportId);
  const {
    comments,
    commentForm,
    savedComment,
    commentEntered,
    onSaveComment,
    onDeleteComment,
    handleComments,
    resetCommentForm,
  } = commentsData;

  const navigate = useNavigate();
  const user = useUser();

  useEffect(() => {
    getEoreReportById(reportId)
      .then((res) => {
        setFormData(res.data);
        getEorePhotos(res.data.id, res.data.info.general_photos, setPhotos);
        getAttachments(res.data, setAttachments);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  useEffect(() => {
    setTemplateVersion(formData.template_version);
  }, [formData.template_version]);

  useEffect(() => {
    getHandouts()
      .then((res) => {
        setHandouts(res.data?.map((handout) => ({
          handout_id: handout.id,
          name: handout.name,
          count: 0,
        })));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleValidation = (status, event) => handleReportValidation(
    status,
    event,
    `/validations/eore/${reportId}/`,
    '/reports/eore/',
    setLoader,
    savedComment,
    resetCommentForm,
    navigate,
  );

  const handleRejectButton = (status) => {
    setLoader(true);
    rejectEOREReport(formData.id, { status })
      .then(() => {
        toast.success('EORE report rejected from IMSMA');
        setLoader(false);
        setTimeout(() => {
          navigate('/reports/eore');
        }, 2000);
      })
      .catch(() => {
        toast.error('Error rejecting EORE report from IMSMA');
        setLoader(false);
      });
  };

  return (
    <section>
      <PageTitle
        title={`${mode === 'validation' ? 'Validation' : 'View'} EORE report`}
        formData={formData}
        templateVersion={templateVersion}
        setLoader={setLoader}
      />
      <div className="info_block_header">
        <div className="info_block_header_left">Атрибути</div>
        <span className="info_block_header_line" />
        <div className="info_block_header_right">Attributes</div>
      </div>
      {/* <TranslateWrapperComponent
      formData={formData}
    // setTranslatedFormData={setTranslatedFormData}
    // translationState={translateState}
    > */}
      <div className="form-wrapper">
        <Accordion
          allowMultipleExpanded
          allowZeroExpanded
          preExpanded={[
            'general',
            'sessionInformation',
            'finalIndicators',
          ]}
          className="custom-accordion"
        >
          <GeneralInfo
            formData={formData}
            setFormData={setFormData}
            mode={mode}
          />
          {mode === 'validation' && (
            <CommentBlock
              comments={comments}
              savedComment={savedComment}
              commentForm={commentForm}
              commentEntered={commentEntered}
              block="general"
              onDeleteComment={onDeleteComment}
              handleComments={handleComments}
              onSaveComment={onSaveComment}
            />
          )}
          <SessionInfo
            formData={formData}
            handouts={handouts}
            zone={zone}
            setZone={setZone}
            setFormData={setFormData}
            mode={mode}
          />
          {mode === 'validation' && (
            <CommentBlock
              comments={comments}
              savedComment={savedComment}
              commentForm={commentForm}
              commentEntered={commentEntered}
              block="session_information"
              onDeleteComment={onDeleteComment}
              handleComments={handleComments}
              onSaveComment={onSaveComment}
            />
          )}
          <FinalIndicatorsView
            formData={formData}
          />
          {mode === 'validation' && (
            <CommentBlock
              comments={comments}
              savedComment={savedComment}
              commentForm={commentForm}
              commentEntered={commentEntered}
              block="final_indicators"
              onDeleteComment={onDeleteComment}
              handleComments={handleComments}
              onSaveComment={onSaveComment}
            />
          )}
          <PhotosBlockView
            formData={formData}
            photos={photos}
            setLoader={setLoader}
          />
          <AttachmentsBlockView
            attachments={attachments}
            setLoader={setLoader}
            formData={formData}
          />
          {mode === 'validation' && (
            <div className="form-buttons-end buttons-group py-10">
              <ButtonComponent
                handleButton={(event) => handleValidation('rejected', event)}
                label="Reject"
                disabled={!Object.values(savedComment).some((comment) => comment && comment.trim() !== '')}
                className="danger"
                type="button"
              />
              <ButtonComponent
                handleButton={(event) => handleValidation('accepted', event)}
                label="Accept"
                disabled={false}
                type="button"
              />
            </div>
          )}
        </Accordion>
          {formData.validation_status?.value === 'validated'
            && user?.permissions?.imsma_reject_eore
            && (
              <div className="form-buttons buttons-group py-10">
              <ButtonComponent
                handleButton={() => {
                  handleRejectButton('rejected');
                }}
                label="Reject from IMSMA"
                disabled={false}
                className="danger"
                type="button"
              />
        </div>
            )}
      </div>
      {/* </TranslateWrapperComponent> */}
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};
