import { ButtonComponent } from 'component/Button';
import { Loader } from 'component/Loader';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import { ReactComponent as PencilSvg } from 'assets/images/icons/mode_edit_24px.svg';
import { ReactComponent as CloseSvg } from 'assets/images/icons/close_24px.svg';
import { ReactComponent as SendSvg } from 'assets/images/icons/send.svg';
import axios from 'axios';
import {
  getEoreReportById,
  getHandouts,
  updateEoreReportById,
  updateEoreReportPhotos,
  updateReportAttachments,
} from 'api/EOREApi';
import { EOREFormData } from 'consts/Forms';
import { axiosWrapper } from 'utils/Helper';
import { useNavigate, useParams } from 'react-router-dom';
import { PageTitle } from './Create';
import { AttributesTab } from './Tabs/Attributes';
import { PhotosTab } from './Tabs/Photos';
import CheckEORETab from './Tabs/Check';
import { getAttachments, getEorePhotos } from './Blocks/PhotosBlock';
import { checkFormMissingFields } from './FormHandling';

export const EditEOREReportPage = () => {
  const [loader, setLoader] = useState(true);
  const [errors, setErrors] = useState([]);
  const [formData, setFormData] = useState(EOREFormData);
  const [activeTab, setActiveTab] = useState(0);
  const [photos, setPhotos] = useState([]);
  const [btnState, setBtnState] = useState(false);
  const [photosLoader, setPhotosLoader] = useState(true);
  const [attachments, setAttachments] = useState([]);
  const [templateVersion, setTemplateVersion] = useState(null);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
  const [handouts, setHandouts] = useState(null);
  const [zone, setZone] = useState(null);
  const [comments, setComments] = useState([]);

  const { reportId } = useParams();
  const navigate = useNavigate();

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const handleCancelButton = (e) => {
    e.preventDefault();
    navigate('/reports/eore/');
  };
  const getAllComments = () => {
    axiosWrapper(() => {
      axios.get(`/validations/eore/${reportId}`).then((data) => {
        const allComments = data.data.iterations;
        setComments(allComments);
      });
    });
  };

  const filteredGeneralComments = comments
    .filter((item) => {
      const commentText = item?.comments?.general;
      return commentText && commentText.trim() !== '';
    })
    .reverse();

  const filteredSessionInformationComments = comments
    .filter((item) => {
      const commentText = item?.comments?.session_information;
      return commentText && commentText.trim() !== '';
    })
    .reverse();

  const filteredFinalIndicatorsComments = comments
    .filter((item) => {
      const commentText = item?.comments?.final_indicators;
      return commentText && commentText.trim() !== '';
    })
    .reverse();

  useEffect(() => {
    getAllComments();
  }, []);

  useEffect(() => {
    getEoreReportById(reportId)
      .then((res) => {
        setFormData(res.data);
        getEorePhotos(res.data.id, res.data.info.general_photos, setPhotos)
          .then(() => {
            setPhotosLoader(false);
          })
          .catch(() => {
            setPhotosLoader(false);
          });
        getAttachments(res.data, setAttachments);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  const handleDraftButton = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      draft: true,
    }));
    setBtnState('draft');
  };

  const sendFiles = (files, updateFunction, id, type) => new Promise((resolve, reject) => {
    const data = new FormData();
    files?.forEach((file) => {
      data.append(type, file.file || file);
    });
    updateFunction(id, data)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });

  const sendEorePhotos = (id) => sendFiles(photos, updateEoreReportPhotos, id, 'images');

  const sendAttachments = (id) => sendFiles(attachments, updateReportAttachments, id, 'files');

  const updateEoreReport = async () => {
    if (!checkFormMissingFields(formData, setErrors)) {
      setSendButtonDisabled(false);
      setBtnState(null);
      return;
    }
    setLoader(true);
    try {
      await updateEoreReportById(reportId, formData);
      await Promise.all([sendEorePhotos(reportId), sendAttachments(reportId)]);
      toast.success('Photos and attachments updated successfully');
      if (btnState === 'send') {
        navigate('/reports/eore/');
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to update report or its attachments');
      setSendButtonDisabled(false);
    } finally {
      setLoader(false);
      setBtnState(null);
      setSendButtonDisabled(false);
    }
  };

  useEffect(() => {
    if (btnState === 'draft' || btnState === 'send') {
      updateEoreReport();
    }
  }, [formData.draft, btnState]);

  const setGeneralPhotos = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        general_photos: [...photos],
      },
    }));
  };

  const setBeneficiariesFiles = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        beneficiaries: {
          files: attachments.map((attachment) => ({
            file_name: attachment.name,
          })),
        },
      },
    }));
  };

  const handleSaveButton = () => {
    setSendButtonDisabled(true);
    setFormData((prevFormData) => ({
      ...prevFormData,
      draft: false,
    }));
    setBtnState('send');
  };

  useEffect(() => {
    setGeneralPhotos();
  }, [photos]);

  useEffect(() => {
    setBeneficiariesFiles();
  }, [attachments]);

  useEffect(() => {
    setTemplateVersion(formData.template_version);
  }, [formData.template_version]);

  useEffect(() => {
    getHandouts()
      .then((res) => {
        setHandouts(res.data?.map((handout) => ({
          handout_id: handout.id,
          name: handout.name,
          count: 0,
        })));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <section>
      <PageTitle
        title="Edit EORE report"
        formData={formData}
        templateVersion={templateVersion}
        setLoader={setLoader}
      />
      <Tabs selectedIndex={activeTab} onSelect={handleTabChange}>
        <div className="flex-right">
          <TabList>
            <Tab>
              <span className={`${errors.length > 0 ? 'error-text' : ''}`}>
                Attributes
              </span>
            </Tab>
            <Tab>Photos</Tab>
            <Tab>Check</Tab>
          </TabList>
        </div>
        <form className="form-wrapper">
          <TabPanel>
            <AttributesTab
              formData={formData}
              setFormData={setFormData}
              handouts={handouts}
              errors={errors}
              zone={zone}
              setZone={setZone}
            />
          </TabPanel>
          <TabPanel>
            <Loader visible={photosLoader} />
            <PhotosTab
              formData={formData}
              setFormData={setFormData}
              photos={photos}
              setPhotos={setPhotos}
              attachments={attachments}
              setAttachments={setAttachments}
              setLoader={setPhotosLoader}
            />
          </TabPanel>
          <TabPanel>
            <CheckEORETab
              formData={formData}
              filteredFinalIndicatorsComments={filteredFinalIndicatorsComments}
              filteredGeneralComments={filteredGeneralComments}
              filteredSessionInformationComments={filteredSessionInformationComments}
              zone={zone}
              setZone={setZone}
              handouts={handouts}
              photos={photos}
              attachments={attachments}
              setLoader={setPhotosLoader}
            />
          </TabPanel>
        </form>

      </Tabs>

      <div className="form-buttons buttons-group py-10">
        <ButtonComponent
          iconLeft={<PencilSvg />}
          handleButton={handleDraftButton}
          label="Draft"
          disabled={false}
          className="warning"
        />
        <ButtonComponent
          iconLeft={<SendSvg />}
          handleButton={handleSaveButton}
          label="Send"
          disabled={sendButtonDisabled}
        />
        <ButtonComponent
          iconLeft={<CloseSvg />}
          handleButton={handleCancelButton}
          label="Cancel"
          disabled={false}
          className="danger"
        />
      </div>
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};
