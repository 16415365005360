import React, { useEffect, useState } from 'react';
import { Loader } from 'component/Loader';
import { toast, ToastContainer } from 'react-toastify';
import { TCPNavigationStatuses } from 'consts/TCP';
import { ReactComponent as SaveSvg } from 'assets/images/icons/save-1.svg';
import { ReactComponent as ArrowBackSvg } from 'assets/images/icons/arrow_back_24px.svg';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as CompletedDoneIcon } from 'assets/images/icons/completedDone.svg';
import { InputComponent } from 'component/Input';
import { CheckboxInput } from 'component/Checkbox';
import { NavigationTabLinks } from '../Tabs/NavigationLinks';
import 'assets/styles/pages/completed.scss';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const CompletedPage = () => {
  const [loader, setLoader] = useState(false);
  const [navigationStatuses, setNavigationStatuses] = useState(TCPNavigationStatuses);
  const [completionData, setCompletionData] = useState({
    imsma_id: '',
    completed: false,
  });
  const { clearanceId } = useParams();
  const navigate = useNavigate();
  const [existingRecordId, setExistingRecordId] = useState(null);

  const fetchCompletionData = async () => {
    try {
      setLoader(true);
      const response = await axios.get(`/reports/completion/${clearanceId}/clearance-task/`);
      const data = response.data;

      if (data) {
        setExistingRecordId(data.id);
        setCompletionData({
          imsma_id: data.imsma_id,
          completed: data.completed,
        });
      }
    } catch (error) {
      console.error('Error fetching completion data:', error);
      toast.error('Error loading completion data');
    } finally {
      setLoader(false);
    }
  };

  const handleInputChange = (value) => {
    setCompletionData((prev) => ({
      ...prev,
      imsma_id: value,
    }));
  };

  const handleCheckboxChange = (value, checked) => {
    setCompletionData((prev) => ({
      ...prev,
      completed: checked,
    }));
  };

  const handleSave = async () => {
    try {
      setLoader(true);
      const payload = {
        imsma_id: completionData.imsma_id,
        completed: completionData.completed,
        clearance_task: Number(clearanceId),
      };

      if (existingRecordId) {
        await axios.patch(`/reports/completion/${existingRecordId}/`, payload);
      } else {
        await axios.post('/reports/completion/', payload);
      }

      toast.success('Completion data saved successfully!');
    } catch (error) {
      console.error('Error saving completion data:', error);
      toast.error('Error saving completion data');
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    setNavigationStatuses((prev) => ({
      ...prev,
      tcpCreate: 'completed',
      daily: 'completed',
      weekly:'completed',
      completed: 'active',
    }));
    fetchCompletionData();
  }, []);

  return (
    <section>
      <div className="flex-column-group">
        <NavigationTabLinks
          mode="view"
          id={clearanceId}
          statuses={navigationStatuses}
        />
      </div>
      <div className="completed-container">
        <div className="completed-content">
          <CompletedDoneIcon />
          <div className="input-with-checkbox">
            <InputComponent
              className="w-100"
              id="imsma-id"
              name="imsma-id"
              handleInput={handleInputChange}
              value={completionData.imsma_id}
              label="IMSMA ID"
            />
            <CheckboxInput
              id="completed"
              value={completionData.completed}
              label=""
              name="completed"
              handleInput={(value, checked) => handleCheckboxChange(value, checked)}
              checked={completionData.completed}
            />
          </div>
        </div>
        <div className="completed-footer buttons-group py-10">
          <ButtonComponent
            iconLeft={<ArrowBackSvg style={{ width: '20px', height: '20px' }} />}
            className="warning"
            label="Previous"
            handleButton={() => { navigate(-1); }}
          />
          <ButtonComponent
            iconLeft={<SaveSvg />}
            label="Save"
            // disabled={!completionData.imsma_id || !completionData.completed}
            handleButton={handleSave}
          />
        </div>
      </div>

      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};

export default CompletedPage;
