import { React, useEffect, useState } from 'react';
import { getDistrict } from 'api/LocationAPI';
import { SelectComponent } from 'component/Select';

export const DistrictFilter = (props) => {
  const [options, setOptions] = useState([]);

  const getDistrictOptions = () => {
    getDistrict(props.regionFilter)
      .then((res) => {
        const resOptions = res.data.map((item) => ({
          value: `${item.name} / ${item.eng_name}`,
          label: `${item.name} / ${item.eng_name}`,
        }));

        setOptions(resOptions);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (props.regionFilter) {
      getDistrictOptions();
    }
  }, [props.regionFilter]);

  return (
    <div className="flex-column-group">
      <span>Sort by district</span>
      <SelectComponent
        className="full"
        value={props.districtFilter
          ? { value: props.districtFilter, label: props.districtFilter }
          : null}
        options={options}
        handleSelect={(e) => {
          props.setDistrictFilter(e);
          props.setLocalCommunityFilter(null);
          props.setSettlementFilter(null);
        }}
        isMulti={false}
        isDisabled={!props.regionFilter}
      />
    </div>
  );
};
