import React from 'react';
import { AccordionBodyItemView } from 'component/Accordion';

export const DistrictView = (props) => {
  const { formData, subId } = props;

  return (
    <AccordionBodyItemView
      accordionSubId={subId ? subId : 2.2}
      titleUA="Район"
      titleEN="District"
      children={formData.info?.location?.district || '-'}
    />
  );
};
