import React from 'react';

export const HandoutsView = (props) => {
  const { formData, handouts, type } = props;

  const totalHandouts = handouts?.reduce((total, handout) => {
    const currentHandout = formData.info?.session_information[type]?.handouts?.find(
      (item) => item.handout_id === handout.handout_id && item.count !== 0
    );
    return total + (currentHandout?.count || 0);
  }, 0);

  return (
    <>
      <div className="double-text">
        <span>Матеріали для розповсюдження</span>
        <span>Materials for distribution</span>
      </div>
      <div className="flex-group handouts flex-wrap">
        {!handouts?.length && (
          <span className="text-center">
            Немає матеріалів для розповсюдження
          </span>
        )}
        {handouts?.length !== 0 && handouts?.map((handout) => (
          <HandoutView
            key={handout.handout_id}
            handout={handout}
            formData={formData}
            type={type}
          />
        ))}
      </div>
      {handouts?.length !== 0 && <><div className='total-beneficiaries-container' style={{ justifyContent: 'end' }}>
        <div className='total-beneficiaries'>
          <div>
            Total:
          </div>
          <div>
          {totalHandouts}
          </div>
        </div>
      </div><div className='line'></div></>
      }
    </>
  );
};

export const HandoutView = (props) => {
  const { handout, formData, type } = props;

  const handouts = formData.info?.session_information[type]?.handouts || [];

  const filteredHandouts = handouts?.filter((handoutItem) => handoutItem?.count !== 0);

  const currentHandout = filteredHandouts
    ?.find((handoutItem) => handoutItem?.handout_id === handout?.handout_id);

  if (!currentHandout) return null;
  return (
    <div className="handout-item">
      <span className="text">{currentHandout?.name}</span>
      <span>{currentHandout?.count}</span>
    </div>
  );
};
