import React, { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import ImageUploading from 'react-images-uploading';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { ImageDeleteDialog } from 'component/Dialog';
import ImageIcon from 'assets/images/icons/Photo.svg';
import { getAttachmentsByName, getEorePhotoByName } from 'api/ImagesApi';
import { ReactComponent as ZoomSvg } from 'assets/images/icons/zoom_24px.svg';
import { ReactComponent as DownloadSvg } from 'assets/images/icons/file_download.svg';
import { EOREImageDetailInfo } from './ImageDetailInfo';

const ImageItem = (props) => (
  <div className="image-item">
    <img src={props.data_url} alt="" />
    <div className="image-item__btn-wrapper">
      <ImageDeleteDialog
        deleteImage={props.deleteImage}
        svgOnly
      />
      <span
        className="zoom"
        onClick={props.openModal}
      >
        <ZoomSvg />
      </span>

      <span
        className="download"
        onClick={() => {
          const link = document.createElement('a');
          link.href = props.data_url;
          link.download = props.file_name;
          link.click();
        }}
      >
        <DownloadSvg />
      </span>
    </div>
  </div>
);

export const PhotosBlock = (props) => {
  const [images, setImages] = useState(props.photos || []);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setCurrentImageIndex(null);
    setIsModalOpen(false);
  };

  const onChangePhoto = async (imageList, addUpdateIndex) => {
    let openGallery = true;
    const photoPromises = imageList.map(async (image) => {
      const { file } = image;
      return {
        file_name: file.name,
        data_url: image.data_url,
        file,
      };
    });

    const newPhotos = await Promise.all(photoPromises);

    if (addUpdateIndex) {
      addUpdateIndex.forEach((index) => {
        const photoToAdd = newPhotos[index];
        if (!props.photos?.some((photo) => photo.file_name === photoToAdd.file_name
          || photo.name === photoToAdd.file_name)) {
          props.setPhotos((prevPhotos) => ([...prevPhotos, photoToAdd]));
          openGallery = true;
        } else {
          toast.warning('You cannot upload images with same name');
          openGallery = false;
        }
      });
    }

    setImages(imageList);
    if (openGallery) {
      openModal(props.photos.length);
    }
  };

  const deleteImageByIndex = (index) => {
    props.setPhotos((prevPhotos) => {
      if (index >= 0 && index < prevPhotos.length) {
        const updatedPhotos = prevPhotos.filter((_, i) => i !== index);
        return updatedPhotos;
      }

      return prevPhotos;
    });
  };

  return (
    <div>
      <ImageUploading
        multiple
        value={images}
        onChange={onChangePhoto}
        dataURLKey="data_url"
        acceptType={['jpg']}
      >
        {({
          onImageUpload,
          isDragging,
          dragProps,
        }) => (
          <div>
            <div className={`upload-image-wrapper ${props.imageError ? 'error' : ''}`}>
              {props.photos && props.photos.map((image, index) => (
                <ImageItem
                  key={index}
                  data_url={image.data_url}
                  file_name={image.file_name}
                  deleteImage={() => deleteImageByIndex(props.photos.indexOf(image))}
                  openModal={() => openModal(props.photos.indexOf(image))}
                />
              ))}

              <div
                className="new-image-item"
                style={isDragging ? { color: 'red' } : null}
              >
                <img src={ImageIcon} alt="icon" />
                <button
                  className="outline"
                  onClick={(e) => {
                    e.preventDefault();
                    onImageUpload();
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...dragProps}
                >
                  + Add image
                </button>
              </div>
            </div>
          </div>
        )}
      </ImageUploading>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="react-inner-modal-class"
        overlayClassName="react-modal-class"
      >
        {currentImageIndex !== null && (
          <div className="image-gallery-wrapper">
            <ImageGallery
              items={props.photos?.map((image) => ({
                original: image.data_url,
                thumbnail: image.data_url,
                thumbnailHeight: '60px',
                thumbnailWidth: '60px',
              }))}
              startIndex={currentImageIndex}
              onSlide={(index) => setCurrentImageIndex(index)}
            />
            <EOREImageDetailInfo
              photos={props.photos}
              setPhotos={props.setPhotos}
              currentImageIndex={currentImageIndex}
              closeImageModal={closeModal}
              deleteImageByIndex={deleteImageByIndex}
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

const getSingleEorePhoto = (reportId, imageFile, setPhotos) => {
  if (!imageFile) {
    return Promise.resolve();
  }
  return new Promise((resolve, reject) => {
    getEorePhotoByName(reportId, imageFile)
      .then((res) => {
        if (res.status === 200) {
          const image = new File([res.data], imageFile, {
            type: 'image/jpeg',
          });

          setPhotos((prevPhotos) => [
            ...prevPhotos,
            {
              file: image,
              data_url: URL.createObjectURL(res.data),
              file_name: imageFile,
            },
          ]);

          resolve();
        } else {
          reject(new Error('Photo request failed'));
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getEorePhotos = (reportId, photos, callback) => {
  if (!photos || photos.length === 0) {
    return Promise.resolve([]);
  }

  const photoPromises = photos.map((photo) => new Promise((resolve, reject) => {
    getSingleEorePhoto(reportId, photo.file_name, callback)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  }));

  return Promise.all(photoPromises);
};

export const getAttachments = (formData, setAttachments) => {
  formData.info.beneficiaries.files.forEach((file) => {
    getAttachmentsByName(formData.id, file.file_name)
      .then((res) => {
        if (res.status === 200) {
          const attachment = new File([res.data], file.file_name, {
            type: res.data.type,
          });

          setAttachments((prevAttachments) => [
            ...prevAttachments,
            attachment,
          ]);
        }
      });
  });
};
