import { React, useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { URLS } from 'routes/Urls';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'assets/styles/popup.scss';
import 'assets/styles/select.scss';
import { axiosWrapper } from 'utils/Helper';
import './index.scss';
import { ReactComponent as CancelSvg } from 'assets/images/icons/cancel_24px.svg';
import { ReactComponent as SaveIcon } from 'assets/images/icons/save-1.svg';
import { ButtonComponent } from 'component/Button';
import { useUser } from 'routes/UserContext';
import { Avatar } from '../Users/Popup/Avatar';
import { UserAccessLevel } from '../Users/Popup/AccessLevel';
import { UserName } from '../Users/Popup/Name';
import { UserSurName } from '../Users/Popup/SurName';
import { UserMiddleName } from '../Users/Popup/MiddleName';
import { UserTeamId } from '../Users/Popup/TeamId';
import { UserDirectors } from '../Users/Popup/Directors';
import { UserFunction } from '../Users/Popup/Function';
import { UserPosition } from '../Users/Popup/Position';
import { UserPhoneNumber } from '../Users/Popup/PhoneNumber';
import { UserEmail } from '../Users/Popup/Email';
import { UserPassword } from '../Users/Popup/Password';
import { getUserById, updateUser } from '../../api/UserAPI';
import { handleUpdateUser } from '../Users/ValidateUser';
import { Loader } from '../../component/Loader';

export const ProfilePage = () => {
  const [loader, setLoader] = useState(false);
  const [avatarFile, setAvatarFile] = useState(null);
  const [role, setRole] = useState(null);
  const [permissions, setPermissions] = useState({});
  const [directors, setDirectors] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState('');
  const {
    user, avatar, setAvatar, isAvatarLoading, getUserInfo,
  } = useUser();
  const [userInfo, setUserInfo] = useState(user);

  const navigate = useNavigate();

  useEffect(() => {
    if (user?.id) {
      setLoader(true);
      getUserById(user.id)
        .then((res) => {
          setUserInfo({
            id: res.data.id,
            team_id: res.data.team_id,
            name: res.data.name,
            surname: res.data.surname,
            middle_name: res.data.middle_name,
            function: res.data.function,
            email: res.data.email,
            password: null,
            phone_number: res.data.phone_number,
            reporter_position: res.data.reporter_position,
            permissions: Object.keys(res.data.permissions),
            role: res.data.role.id,
            directors: res.data.directors?.map((director) => director.id),
          });
          setDirectors(res.data.directors?.map((director) => ({
            value: director.id,
            label: director.name,
          })));
          setRole({ value: res.data.role?.id, label: res.data.role?.name });
          setPermissions(res.data.permissions || {});
          setLoader(false);
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.response.data.message || 'Error loading user');
          setLoader(false);
        });
    }
  }, [user]);

  const updateAvatar = (userId) => {
    if (avatarFile) {
      const formData = new FormData();
      formData.append('avatar', avatarFile);

      return axios.patch(`${URLS.USERS}${userId}/avatar/`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    }

    return Promise.resolve();
  };

  const handleDeleteImage = () => {
    axiosWrapper(() => {
      axios
        .delete(`${URLS.USERS}${user.id}/avatar/`)
        .then(() => {
          setAvatar(null);
        })
        .catch((error) => {
          console.error('Error deleting avatar', error);
        });
    });
  };

  const handleClose = () => {
    navigate('/dashboard/');
  };

  useEffect(() => {
    document.title = 'Profile';
  }, []);

  const updateSelfUser = () => {
    if (userInfo.password === null) {
      delete userInfo.password;
    }
    handleUpdateUser(userInfo);
    setLoader(true);
    updateUser(userInfo.id, userInfo)
      .then((res) => {
        updateAvatar(res.data.id)
          .then(() => {
            toast.success('User changed successfully!');
            getUserInfo();
          })
          .catch(() => {
            toast.error('Error updating avatar');
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message || 'Error updating user');
      });
  };

  return (
    user && (
      <section>
        <div className="users-header">
          <div className="users-title">Profile</div>
        </div>
        <div className="profile-info">
          <div className="left-and-right">
            <div className="left-section">
              <Avatar
                avatarImage={avatar}
                setAvatarFile={setAvatarFile}
                avatarImageLoading={isAvatarLoading}
                setAvatarImage={setAvatar}
                handleDeleteAvatar={handleDeleteImage}
              />
            </div>
            <div className="right-section">
              <div className="column">
                <UserAccessLevel
                  role={role}
                  roleOption={role}
                  setUserInfo={setUserInfo}
                  hasEditPermission={permissions?.change_user === true}
                />
                <UserName
                  name={userInfo?.name}
                  setUserInfo={setUserInfo}
                />
                <UserSurName
                  surname={userInfo?.surname}
                  setUserInfo={setUserInfo}
                />
                <UserMiddleName
                  middlename={userInfo?.middle_name}
                  setUserInfo={setUserInfo}
                />
                <UserTeamId
                  teamId={userInfo?.team_id}
                  setUserInfo={setUserInfo}
                />
                <UserDirectors
                  directors={directors}
                  setDirectors={setDirectors}
                  setUserInfo={setUserInfo}
                  directorsOption={directors}
                  hasEditPermission={permissions?.change_user}
                />
              </div>
              <div className="column">
                <UserFunction
                  function={userInfo?.function}
                  setUserInfo={setUserInfo}
                />
                <UserPosition
                  reporterPosition={userInfo?.reporter_position}
                  setUserInfo={setUserInfo}
                />
                <UserPhoneNumber
                  phoneNumber={userInfo?.phone_number}
                  setUserInfo={setUserInfo}
                />
                <UserEmail
                  email={userInfo?.email}
                  setUserInfo={setUserInfo}
                />
                <UserPassword
                  password={null}
                  placeholder="New password"
                  setUserInfo={setUserInfo}
                  setConfirmPassword={setConfirmPassword}
                />
                <UserPassword
                  password={confirmPassword}
                  placeholder="Confirm password"
                  setConfirmPassword={setConfirmPassword}
                />
              </div>
            </div>
          </div>
          <div className="profile-footer">
            <ButtonComponent
              iconLeft={<CancelSvg />}
              className="danger"
              handleButton={() => {
                handleClose();
              }}
              label="Cancel"
            />
            {(user.permissions?.change_user || user.permissions?.change_user_self) && (
              <ButtonComponent
                iconLeft={<SaveIcon />}
                handleButton={updateSelfUser}
                label="Save"
              />
            )}
          </div>
        </div>
        <Loader visible={loader} />
        <ToastContainer />
      </section>
    )
  );
};
