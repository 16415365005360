import React, { useEffect, useState } from 'react';
import { AccordionHeaderUserSettings } from 'component/Accordion';
import { InputComponent } from 'component/Input';
import {
  AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel,
} from 'react-accessible-accordion';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as SaveIcon } from 'assets/images/icons/save-1.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/delete_24px.svg';
import { ReactComponent as AddIcon } from 'assets/images/icons/add_24px.svg';

import { getAllUsersForClearanceFiles, getUserById } from 'api/UserAPI';
import { ClipLoader } from 'react-spinners';
import { SelectComponent } from 'component/Select';
import { UserLabel } from 'pages/Profile/Notifications/Table';
import { toast } from 'react-toastify';

import {
  createOrUpdateClearanceFiles,
  deleteAllUserFiles,
  deleteFileById,
} from 'api/EOREApi';
import { DeleteAllDetailsItemsDialog } from 'component/Dialog';
import { useUser } from 'routes/UserContext';

const ReportRowClearanceFiles = ({ setLoader, refreshData }) => {
  const [usersWithFiles, setUsersWithFiles] = useState([]);
  const [activeAccordionId, setActiveAccordionId] = useState(null);
  const [userData, setUserData] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [deletedFiles, setDeletedFiles] = useState({});
  const [loadingFilesById, setLoadingFilesById] = useState({});
  const [userSettings, setUserSettings] = useState({});
  const [allUsersWithoutFiles, setAllUsersWithoutFiles] = useState([]);
  const { user } = useUser();

  const fetchAllUsersForClearanceFiles = async () => {
    setLoader(true);
    try {
      const res = await getAllUsersForClearanceFiles(true);
      setUsersWithFiles(res.data);
      await getAllUsersForClearanceFiles(false).then((result) => {
        setAllUsersWithoutFiles(result.data);
      });
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchAllUsersForClearanceFiles();
  }, [refreshData]);

  const handleAccordionToggle = (id) => {
    setActiveAccordionId(id);
    if (!userData[id]) {
      setLoadingFilesById((prev) => ({ ...prev, [id]: true }));
      getUserById(id).then((res) => {
        setUserData((prev) => ({
          ...prev,
          [id]: res.data,
        }));
        const initialValues = res.data.clearance_files?.reduce((acc, file) => {
          acc[file.id] = file.name;
          return acc;
        }, {});
        setInputValues((prevValues) => ({
          ...prevValues,
          [id]: initialValues,
        }));
        setLoadingFilesById((prev) => ({ ...prev, [id]: false }));
      });
    }
  };

  const handleInputChange = (userId, fileId, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [userId]: {
        ...prevValues[userId],
        [fileId]: value,
      },
    }));
  };

  const handleAddFile = (userId) => {
    const newFileId = `new-${Date.now()}`;
    setInputValues((prevValues) => ({
      ...prevValues,
      [userId]: {
        ...prevValues[userId],
        [newFileId]: '',
      },
    }));
    setUserData((prevData) => ({
      ...prevData,
      [userId]: {
        ...prevData[userId],
        clearance_files: [
          ...(prevData[userId]?.clearance_files || []),
          { id: newFileId, name: '' },
        ],
      },
    }));
  };

  const handleDeleteFile = async (userId, fileId) => {
    const fileIdStr = fileId.toString();
    if (!fileIdStr.startsWith('new-')) {
      try {
        setLoader(true);
        await deleteFileById(fileId);
        toast.success('File deleted successfully');
      } catch (error) {
        console.error('Error deleting file:', error);
        toast.error('Failed to delete file');
        return;
      } finally {
        setLoader(false);
      }
    }

    setInputValues((prevValues) => {
      const { [fileId]: _, ...rest } = prevValues[userId];
      return {
        ...prevValues,
        [userId]: rest,
      };
    });

    setDeletedFiles((prevValues) => ({
      ...prevValues,
      [userId]: {
        ...prevValues[userId],
        [fileId]: true,
      },
    }));

    setUserData((prevData) => ({
      ...prevData,
      [userId]: {
        ...prevData[userId],
        clearance_files: prevData[userId].clearance_files?.filter(
          (file) => file?.id !== fileId,
        ),
      },
    }));
  };

  const handleSave = async () => {
    const userId = activeAccordionId;
    const selectedUser = userSettings[userId]?.selectedUser;
    const selectedUserId = selectedUser ? selectedUser.value : userId;

    const payload = Object.keys(inputValues[userId]).map((fileId) => {
      const file = inputValues[userId][fileId];
      if (deletedFiles[userId] && deletedFiles[userId][fileId]) {
        return {
          id: fileId,
          name: '',
          user: selectedUserId,
        };
      }
      if (fileId.startsWith('new-')) {
        return {
          name: file,
          user: selectedUserId,
        };
      }
      return {
        id: fileId,
        name: file,
        user: selectedUserId,
      };
    });

    try {
      setLoader(true);
      await createOrUpdateClearanceFiles(payload);
      toast.success('Files saved successfully');
      setDeletedFiles({});
      await fetchAllUsersForClearanceFiles();
    } catch (error) {
      console.error('Error saving data:', error);
      toast.error('Failed to save files');
    } finally {
      setLoader(false);
    }
  };

  const convertObjectToArrayUsers = (usersWithoutFiles) => usersWithoutFiles
    ?.map((userObj) => {
      const transformUserToLabel = {
        email: userObj.email,
        id: userObj.id,
        name: userObj.name,
        surname: userObj.surname,
        short_role_name: userObj.role.short_name,
        role_color: userObj.role.color,
      };

      return {
        value: userObj.id,
        label: <UserLabel user={transformUserToLabel} />,
      };
    });

  const handleSelectChange = (userId, newUser) => {
    setUserSettings((prevSettings) => ({
      ...prevSettings,
      [userId]: {
        ...prevSettings[userId],
        selectedUser: newUser,
      },
    }));
  };

  const handleDeleteAllFiles = async (userId) => {
    try {
      setLoader(true);
      await deleteAllUserFiles(userId);
      toast.success('All files deleted successfully');
      await fetchAllUsersForClearanceFiles();
    } catch (error) {
      console.error('Error deleting all files:', error);
      toast.error('Failed to delete all files');
    } finally {
      setLoader(false);
    }
  };

  return (
    usersWithFiles?.map((userObj) => (
      <AccordionItem
        key={userObj.id}
        uuid={userObj.id}
        onClick={() => handleAccordionToggle(userObj.id)}
      >
        <AccordionItemHeading>
          <AccordionItemButton>
            <AccordionHeaderUserSettings
              title={userObj.role.short_name}
              userName={userObj.name}
              email={userObj.email}
              backgroundColor={userObj.role.color}
            />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className="accordion-validation-panel">
          {loadingFilesById[userObj.id] ? (
            <div className="block-with-cliploader">
              <ClipLoader
                color="rgba(0, 177, 183, 1)"
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <>
              <span className="validation-flow-title" />
              <div
                className="handouts-wrapper"
              >
                {userData[userObj.id]?.clearance_files?.map((file) => (
                  <div
                    key={file.id}
                    className="handout-item"
                  >
                    <InputComponent
                      classNameWrapper="w-100"
                      style={{ width: '-webkit-fill-available' }}
                      type="text"
                      id={`input-handout-${file.id}`}
                      name={`handout-name-${file.id}`}
                      value={inputValues[userObj.id]?.[file.id] || ''}
                      handleInput={(event) => handleInputChange(userObj.id, file.id, event)}
                    />
                    {user?.permissions?.add_clearance_file && (
                        <DeleteIcon
                          className="delete-icon"
                          style={{ color: '#868686' }}
                          onClick={() => handleDeleteFile(userObj.id, file.id)}
                        />
                    )}
                  </div>
                ))}
              </div>
              <div
                className="add-handout-wrapper"
              >
                {user?.permissions?.add_clearance_file && (
                    <ButtonComponent
                      iconLeft={<AddIcon />}
                      label="Add"
                      handleButton={() => handleAddFile(userObj.id)}
                    />
                )}
                <div
                  className="select-wrapper"
                  style={{
                    display: 'flex',
                    marginBottom: '20px',
                  }}
                >
                  <span className="label">User</span>
                  <SelectComponent
                    id="users"
                    name="users"
                    label="User"
                    value={userSettings[userObj.id]?.selectedUser || null}
                    options={convertObjectToArrayUsers(allUsersWithoutFiles)}
                    handleSelect={(val, label) => handleSelectChange(userObj.id, { val, label })}
                    placeholder={(
                      userSettings[userObj.id]?.selectedUser ? (
                        <UserLabel user={userSettings[userObj.id].selectedUser.user} />
                      ) : (
                        <UserLabel user={userObj} />
                      )
                    )}
                  />
                </div>
              </div>
              {user?.permissions?.add_clearance_file && (
                  <>
                    <div
                      className="line"
                      style={{
                        marginBottom: '20px',
                      }}
                    />
                    <div className="buttons-group-end">
                      <DeleteAllDetailsItemsDialog
                        handleDeleteAllDetailsItems={handleDeleteAllFiles}
                        user={userObj}
                        type="files"
                      />
                      <ButtonComponent
                        iconLeft={<SaveIcon />}
                        label="Save"
                        handleButton={handleSave}
                      />
                    </div>
                  </>
              )}
            </>
          )}
        </AccordionItemPanel>
      </AccordionItem>
    ))
  );
};

export default ReportRowClearanceFiles;
