import { CategoryTranslations } from 'consts/Daily';
import React from 'react';

export const Summary = ({ formData, category }) => (
  <div className="summary">
    <span className="title">Summary</span>
    <div className="categories">
      {formData?.info?.eo_found_categories?.[category]?.items?.map((item, index) => (
        <div className="category" key={index}>
          <span>{CategoryTranslations[category][item.name]}</span>
          <span>{item.count}</span>
        </div>
      ))}
    </div>
  </div>
);
