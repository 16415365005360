import { AccordionHeaderTitle } from 'component/Accordion';
import { CheckboxInput } from 'component/Checkbox';
import { CategoryTranslations, EOFoundCategories } from 'consts/Daily';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { ReactComponent as ArrowDownSvg } from 'assets/images/icons/down-small.svg';
import { ReactComponent as ArrowUpSvg } from 'assets/images/icons/up-small.svg';
import { CommentBlock, CommentDisplay } from 'component/CommentBlock';
import { CategoryEvidences, CategoryEvidencesView } from './Categories';
import { TableData, TableDataView } from './TableData';
import { Summary } from './Summary';
import { HandOverSwitcher } from './HandOverSwitcher';
import { HandOverBlock, HandOverBlockView } from './HandOver/HandOver';
import { SET_CATEGORY_CHECKED, TOGGLE_EO_CATEGORY_ITEM, UPDATE_EO_FOUND_CATEGORIES } from 'reducers/dailyFormActions';

const normalizeEOCategories = (categories) => {
  if (!categories) return {};

  const normalizedCategories = { ...categories };

  Object.keys(normalizedCategories).forEach((categoryKey) => {
    const category = normalizedCategories[categoryKey];
    if (category && category.items) {
      const existingItems = new Map(
        category.items.map((item) => [item.name, item.count]),
      );
      category.items = EOFoundCategories[categoryKey].items.map((defaultItem) => ({
        name: defaultItem.name,
        count: existingItems.get(defaultItem.name) || 0,
      }));
    }
  });

  return normalizedCategories;
};

export const EOFoundBlock = ({ formData, dispatch, hasError, errors }) => {
  const prevCategoriesRef = useRef(formData?.info?.eo_found_categories);

  useEffect(() => {
    const currentCategories = formData?.info?.eo_found_categories;
    const prevCategories = prevCategoriesRef.current;

    if (currentCategories && prevCategories !== currentCategories) {
      const normalizedCategories = normalizeEOCategories(currentCategories);
      prevCategoriesRef.current = normalizedCategories;

      dispatch({
        type: UPDATE_EO_FOUND_CATEGORIES,
        payload: normalizedCategories
      });
    }
  }, [formData?.info?.eo_found_categories, dispatch]);
  return (
    <div className="rounded-bg-grey">
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="3"
            block="battle-area-clearance"
            titleUA={(
              <div className="flex-group">
                <span className={hasError ? 'error-text' : ''}>Знайдено ВНП</span>
                <span className="star" />
              </div>
            )}
            titleEN="EO found"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        {['mines', 'uxos', 'ied', 'saa'].map((category, index) => (
          <EOCategory
            formData={formData}
            dispatch={dispatch}
            category={category}
            key={`${category}-${index}`}
          />
        ))}
        <HandOverSwitcher
          formData={formData}
          dispatch={dispatch}

        />
        {formData?.info?.hand_over && (
          <HandOverBlock
            formData={formData}
            dispatch={dispatch}
          />
        )}
      </AccordionItemPanel>
    </div>
  );
};

export const EOCategory = ({ formData, dispatch, category }) => {
  const [checkboxChecked, setCheckboxChecked] = useState(Boolean(formData
    ?.info?.eo_found_categories?.[category]?.evidences?.length) || false);

    const isChecked = formData?.info?.eo_found_categories?.[category]?.isChecked || false;

    useEffect(() => {
      const hasData = Boolean(formData?.info?.eo_found_categories?.[category]?.evidences?.length);
      if (hasData && !isChecked) {
        dispatch({
          type: SET_CATEGORY_CHECKED,
          payload: {
            category,
            isChecked: true
          }
        });
      }
    }, []);

    const handleCheckbox = () => {
      const newCheckedState = !isChecked;

      dispatch({
        type: SET_CATEGORY_CHECKED,
        payload: {
          category,
          isChecked: newCheckedState
        }
      });

      if (newCheckedState) {
        dispatch({
          type: TOGGLE_EO_CATEGORY_ITEM,
          payload: {
            category: category,
            checked: newCheckedState
          }
        });
      }
    };

  const subcategoriesWithData = useMemo(() => {
    const evidences = formData?.info?.eo_found_categories?.[category]?.evidences || [];
    const items = formData?.info?.eo_found_categories?.[category]?.items || [];

    const subcategoriesWithEvidence = new Set(
      evidences.map(evidence => evidence.item)
    );

    return items.filter(item =>
      subcategoriesWithEvidence.has(item.name) ||
      (item.count && item.count > 0)
    );
  }, [formData?.info?.eo_found_categories, category]);

  return (
    <div className="flex-column-group">
      <div className="flex-group">
        <CheckboxInput
          checked={isChecked}
          handleInput={handleCheckbox}
          label={CategoryTranslations[category][category]}
          value={category}
          id={category}
          name={category}
        />
        <span className="line" />
      </div>
      {isChecked && (
        <>
          <CategoryEvidences
            formData={formData}
            dispatch={dispatch}
            category={category}
          />
          {subcategoriesWithData?.map((item) => (
            <TableData
              key={item.name}
              formData={formData}
              dispatch={dispatch}
              category={category}
              subCategory={item.name}
            />
          ))}
          {formData?.info?.eo_found_categories?.[category] && (
            <Summary
              formData={formData}
              category={category}
            />
          )}
        </>
      )}
    </div>
  );
};

export const EOFoundBlockView = ({ formData, mode, commentsData }) => {
  const {
    comments,
    commentForm,
    savedComment,
    commentEntered,
    onSaveComment,
    onDeleteComment,
    handleComments,
  } = commentsData;

  return (
    <div className="w-100 flex-column-group">
      <div className="rounded-bg-grey">
        <AccordionItemHeading>
          <AccordionItemButton>
            <AccordionHeaderTitle
              accordionId="3"
              block="battle-area-clearance"
              titleUA={(
                <div className="flex-group">
                  <span>Знайдено ВНП</span>
                  <span className="star" />
                </div>
              )}
              titleEN="EO found"
            />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className="flex-column-group gap-10">
          {['mines', 'uxos', 'ied', 'saa'].map((category) => (
            <EOCategoryView
              formData={formData}
              category={category}
              key={category}
            />
          ))}
          {formData?.info.hand_over && (
            <HandOverBlockView
              handOverBlock={formData.info?.hand_over_block}
            />
          )}
        </AccordionItemPanel>
      </div>
      {mode === 'validation' && (
        <CommentBlock
          comments={comments}
          savedComment={savedComment}
          commentForm={commentForm}
          commentEntered={commentEntered}
          block="eo_found"
          onDeleteComment={onDeleteComment}
          handleComments={handleComments}
          onSaveComment={onSaveComment}
        />
      )}
          {mode === 'view' &&
            <CommentDisplay comments={commentsData?.comments} block="eo_found" />}
    </div>
  );
};

export const EOCategoryView = ({ formData, category }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpand = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const categoryData = formData?.info?.eo_found_categories?.[category] || {};
  const hasItems = categoryData?.items?.length > 0;
  const hasEvidences = categoryData?.evidences?.length > 0;
  const hasLinks = categoryData?.links?.length > 0;
  const hasData = hasItems || hasEvidences || hasLinks;

  return (
    <div className="flex-column-group">
    <div className="flex-group">
      <div className="double-text">
        <span>{CategoryTranslations[category][category]}</span>
      </div>
      <span className="line" />
      {hasData && (
        <span
          className="text-hover"
          onClick={toggleExpand}
        >
          {isExpanded ? <ArrowUpSvg /> : <ArrowDownSvg />}
        </span>
      )}
    </div>
    {isExpanded && (
      <>
        <CategoryEvidencesView
          formData={formData}
          category={category}
        />
        {formData?.info?.eo_found_categories?.[category]?.items?.map((item) => (
          <TableDataView
            key={item.name}
            formData={formData}
            category={category}
            subCategory={item.name}
          />
        ))}
        {hasData && formData?.info?.eo_found_categories?.[category] && (
          <Summary
            formData={formData}
            category={category}
          />
        )}
      </>
    )}
  </div>
  );
};
