import React from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as EditIcon } from 'assets/images/icons/mode_edit_24px.svg';
import { ReactComponent as ViewIcon } from 'assets/images/icons/Eye-on.svg';
import { useUser } from 'routes/UserContext';
import { DeleteUserDialog } from 'component/Dialog';
import { deleteUser } from 'api/UserAPI';
import { axiosWrapper } from 'utils/Helper';

const UserActions = ({
  userFromList,
  setOpenInfoPopup,
  setOpenUserPopup,
  setMode,
  setSelectedUserId,
  setAfterCreate,
  setLoader,
}) => {
  const { user } = useUser();

  const deleteUserById = (id) => {
    setLoader(true);
    axiosWrapper(() => {
      deleteUser(id)
        .then(() => {
          toast.success('User is successfully deleted');
          window.location.reload();
        })
        .catch((err) => {
          console.error(err);
          toast.error('User is not deleted');
        }).finally(() => {
          setLoader(false);
        });
    });
  };

  return (
    <>
      {(
        user?.permissions?.delete_user
        && (
          <DeleteUserDialog
            user={userFromList}
            setSelectedUserId={setSelectedUserId}
            deleteUser={deleteUserById}
          />
        )
      )}
      {(
        user?.permissions?.view_user
        && (
          <ViewIcon
            className="view-icon"
            onClick={() => {
              setOpenInfoPopup(true);
              setSelectedUserId(userFromList?.id);
            }}
          />
        )
      )}
      {(
        user?.permissions?.change_user
        && (
          <EditIcon
            className="edit-icon"
            onClick={() => {
              setOpenUserPopup(true);
              setMode('edit');
              setSelectedUserId(userFromList?.id);
              setAfterCreate(false);
            }}
          />
        )
      )}

    </>
  );
};

export default UserActions;
