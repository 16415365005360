import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'assets/styles/pages/task-clearance-plan.scss';
import { Accordion, AccordionItem } from 'react-accessible-accordion';
import { TCPNavigationStatuses } from 'consts/TCP';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as CloseSvg } from 'assets/images/icons/close_24px.svg';
import { ReactComponent as PencilSvg } from 'assets/images/icons/mode_edit_24px.svg';
import { ReactComponent as SendSvg } from 'assets/images/icons/send.svg';
import { Loader } from 'component/Loader';
import { useFormActions } from 'hooks/useDailyFormActions';
import { useDailyFormManagement } from 'hooks/useDailyFormManagement';
import { NavigationTabLinks } from '../../Tabs/NavigationLinks';
import { DMSBlock } from '../Blocks/DMSBlock/DMSBlock';
import { FieldWorkBlock } from '../Blocks/FieldWorkBlock/FieldWorkBlock';
import { useDailyFormErrors } from 'hooks/useDailyFormErrors';

export const DailyEditPage = () => {
  const { clearanceId, dailyId } = useParams();
  const [navigationStatuses, setNavigationStatuses] = useState(TCPNavigationStatuses);
  const [errorResponse, setErrorResponse] = useState(null);

  const {
    formData,
    loader,
    gridGeojson,
    originalGridGeojson,
    haReport,
    mapLoader,
    coords3857,
    setGridGeojson,
    zone,
    dispatch
  } = useDailyFormManagement(clearanceId, dailyId);

  const { errors, hasError, validateForm } = useDailyFormErrors(errorResponse, formData, dispatch);

  const {
    sendButtonDisabled,
    handleSaveButton,
    handleDraftButton,
    handleCancelButton,
  } = useFormActions(clearanceId, setErrorResponse, dailyId,dispatch,validateForm);

  useEffect(() => {
    setNavigationStatuses((prev) => ({
      ...prev,
      tcpCreate: 'completed',
      daily: 'active',
      weekly: 'inactive',
      completed: 'inactive',
      done: 'inactive',
    }));
    document.title = 'Update daily';
  }, []);

  return (
    <section>
      <div className="flex-column-group">
        <NavigationTabLinks
          statuses={navigationStatuses}
          mode="view"
          id={clearanceId}
        />
        <DailyEditForm
         formData={formData}
         dispatch={dispatch}
         coords3857={coords3857}
         zone={zone}
         setGridGeojson={setGridGeojson}
         gridGeojson={gridGeojson}
         mapLoader={mapLoader}
         haReportPolygon={haReport?.info?.turn_points?.geojson}
         errorResponse={errorResponse}
         errors={errors}
         hasError={hasError}
        />
        <div className="form-buttons buttons-group py-10">
          <ButtonComponent
            iconLeft={<PencilSvg />}
            handleButton={() => handleDraftButton(formData, gridGeojson, originalGridGeojson)}
            label="Draft"
            disabled={sendButtonDisabled}
            className="warning"
          />
          <ButtonComponent
            iconLeft={<SendSvg />}
            handleButton={() => handleSaveButton(formData, gridGeojson, originalGridGeojson)}
            label="Send"
            disabled={sendButtonDisabled}
          />
          <ButtonComponent
            iconLeft={<CloseSvg />}
            handleButton={handleCancelButton}
            label="Cancel"
            disabled={false}
            className="danger"
          />
        </div>
      </div>
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};

const DailyEditForm = ({
  formData,
  dispatch,
  coords3857,
  zone,
  gridGeojson,
  mapLoader,
  haReportPolygon,
  setGridGeojson,
  errorResponse,
  errors,
  hasError,
}) => {
  const isDataReady = formData && dispatch;

  return (<div className="daily-form">
    <div className="form-container flex-column-group">
      <DailyFormHeader />
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        preExpanded={[
          'dms', 'fieldWork', 'marking', 'battle-area-clearance', 'eoFound']}
        className="custom-accordion"
      >
        <AccordionItem uuid="dms">
          <DMSBlock
            formData={formData}
            dispatch={dispatch}
            hasError={hasError}
          />
        </AccordionItem>
        {isDataReady && formData?.info?.dms?.team_activity === 'field_work' && (
          <AccordionItem uuid="fieldWork">
            <FieldWorkBlock
            formData={formData}
            dispatch={dispatch}
            coords3857={coords3857}
            zone={zone}
            gridGeojson={gridGeojson}
            loader={mapLoader}
            setGridGeojson={setGridGeojson}
            haReportPolygon={haReportPolygon}
            errorResponse={errorResponse}
            errors={errors}
            hasError={hasError}
            />
          </AccordionItem>
        )}
      </Accordion>
    </div>
  </div>
  );
}


const DailyFormHeader = () => (
  <div className="form-header">
    <span>Update a daily</span>
    <div className="line" />
  </div>
);
