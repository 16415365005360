import { Map } from 'component/Map';
import React from 'react';

export const MarkingMap = ({
  formData,
  map,
  setMap,
}) => {
  return (
    <Map
    map={map}
    formData={formData}
    setMap={setMap}
    googleWms
    />
  )
}
