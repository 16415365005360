import { InputComponent } from 'component/Input';
import React, { useEffect, useState } from 'react';

export const FilesBlock = ({
  links,
  setLinks,
  errors,
  showValidation,
}) => {
  const handleLink = (index, value) => {
    const newLinks = [...links];
    newLinks[index].file_link = value;
    setLinks(newLinks);
  };

  return (
    <>
      {links?.map((link, index) => (
        <FilesLink
          key={index}
          link={link}
          handleLink={(value) => handleLink(index, value)}
          errors={errors.pdf_file_link}
          showValidation={showValidation}
        />
      ))}
      {links?.length === 0 && <div className="text-center">No files</div>}
    </>
  );
};

const FilesLink = ({
  link,
  handleLink,
  errors,
  showValidation,
}) => {
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if ((showValidation && link.file_link.length === 0) || errors) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [showValidation, link.file_link, errors]);

  return (
    <div className={`file-links ${isError ? 'error' : ''}`}>
      <div className="file-name">
        <span>Name</span>
        <span>{link?.clearance_file}</span>
      </div>
      <div className="file-link">
        <InputComponent
          id={`link-${link.id}`}
          name={`link-${link.id}`}
          label="Link"
          type="text"
          placeholder="Enter link"
          value={link.file_link}
          error={isError}
          errorText={errors}
          handleInput={(value) => {
            handleLink(value);
            if (showValidation) {
              setIsError(value.length === 0);
            }
          }}
        />
      </div>
    </div>
  );
};

export const FilesBlockView = ({ links }) => (
  <div className="file-links flex-column-group">
    {links?.map((link, i) => (
      <div className="flex-group one-link" key={i}>
        <div className="file-name">
          <span>Name</span>
          <span>{link?.clearance_file}</span>
        </div>
        <div className="file-name">
          <span>Link</span>
          <span>
            <a href={link?.file_link} target="_blank" rel="noreferrer">{link?.file_link}</a>
          </span>
        </div>
      </div>
    ))}
  </div>
);
