import React from 'react';
import { Link } from 'react-router-dom';

const TabLink = ({
  to, tabName, status, disabled,
}) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <Link
    to={disabled ? '#' : to}
    className={`tab-with-circle ${disabled ? 'disabled' : ''}`}
    onClick={(e) => disabled && e.preventDefault()}
  >
    <span className={`circle ${status}`} />
    <span>{tabName}</span>
  </Link>
);

export const NavigationTabLinks = ({ mode, id, statuses }) => {
  let createTabLink = '';
  if (mode !== 'create') {
    createTabLink = `/task-clearance/${id}/${mode}`;
  } else {
    createTabLink = '/task-clearance/create';
  }

  return (
    <ul className="tabs-with-circle">
      <li><TabLink to={createTabLink} tabName="Create" status={statuses.tcpCreate} /></li>
      <li><TabLink to={`/task-clearance/${id}/daily`} tabName="D" status={statuses.daily} disabled={!id} /></li>
      <li><TabLink to={`/task-clearance/${id}/weekly`} tabName="W" status={statuses.weekly} disabled={!id} /></li>
      <li><TabLink to={`/task-clearance/${id}/completed`} tabName="Completed" status={statuses.completed} disabled={!id} /></li>
    </ul>
  );
};
