import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { exportEOREDocx } from 'utils/Helper';
import { useUser } from 'routes/UserContext';
import { DeleteReportDialog } from 'component/Dialog';
import { ReactComponent as ViewSvg } from 'assets/images/icons/Eye-on.svg';
import { ReactComponent as PencilSvg } from 'assets/images/icons/mode_edit_24px.svg';
import { ReactComponent as CommentIcon } from 'assets/images/icons/chat.svg';
import { ReactComponent as ExportIcon } from 'assets/images/icons/export-icon.svg';
import useReportActions from 'hooks/useReportActions';

export const ReportActions = (props) => {
  const [disabledDeleteDialogs, setDisabledDeleteDialogs] = useState({});
  const [disabledButtons, setDisabledButtons] = useState({});
  const { user } = useUser();

  const { deleteReportByType } = useReportActions();
  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  const handleDownloadEOREDocxBtn = (itemId) => {
    exportEOREDocx(itemId);
  };

  const handleClick = (id) => {
    setDisabledButtons((prevState) => ({
      ...prevState,
      [id]: { validation: true, edit: true, delete: true },
    }));
    setDisabledDeleteDialogs((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const handleAuxClick = (event, id) => {
    if (event.button === 1) {
      handleClick(id);
    }
  };

  const draftOrRejectedStatus = props?.validationStatus === 'draft'
    || props?.validationStatus === 'draft_rejected';
  const validationStatus = props?.validationStatus?.startsWith('val_')
    || props?.validationStatus === 'imsma_rejected';

  const isEditDisabled = disabledButtons[props.reportId]?.edit;
  const isValidationDisabled = disabledButtons[props.reportId]?.validation;

  const getActionLinkForClearance = (action) => {
    if (props?.mode === 'daily') {
      return `/task-clearance/${props.clearanceId}/daily/${props.reportId}/${action}`;
    }
    if( props?.mode === 'weekly' || props?.mode === 'weeklyTab') {
      return `/task-clearance/${props.clearanceId}/weekly/${props.reportId}/${action}`;
    }
    return `/task-clearance/${props.reportId}/${action}`;
  };

  const getPermissions = () => {
    if (props.mode === 'daily') {
      return {
        view: user?.permissions?.view_daily_report,
        edit: user?.permissions?.change_daily_report,
        validate: user?.permissions?.validate_clearance_daily_reports,
        delete: user?.permissions?.delete_daily_report
      };
    } else if (props.mode === 'weekly' || props.mode === 'weeklyTab') {
      return {
        view: user?.permissions?.view_weekly_report,
        edit: user?.permissions?.change_weekly_report,
        validate: user?.permissions?.validate_clearance_weekly_reports,
        delete: user?.permissions?.delete_weekly_report
      };
    }
    else {
      return {
        view: user?.permissions?.view_clearance_report,
        edit: user?.permissions?.change_clearance_report,
        validate: user?.permissions?.validate_clearance_report,
        delete: user?.permissions?.delete_clearance_report
      };
    }
  };

  const permissions = getPermissions();


  return (
    <div className="all-icons" onContextMenu={handleContextMenu}>
      {permissions?.view && (
        <Link
          to={getActionLinkForClearance('view')}
          target="_blank"
          rel="noopener noreferrer"
          className="view-icon"
        >
          <ViewSvg />
        </Link>
      )}

      {permissions?.edit
        && draftOrRejectedStatus
        && (
          <Link
            to={getActionLinkForClearance('edit')}
            target="_blank"
            rel="noopener noreferrer"
            className="edit-icon"
            style={{ color: isEditDisabled ? '#d3d3d3' : '#868686', pointerEvents: isEditDisabled ? 'none' : 'auto' }}
            onClick={() => handleClick(props?.reportId)}
            onAuxClick={(event) => handleAuxClick(event, props?.reportId)}
          >
            <PencilSvg />
          </Link>
        )}

      {permissions?.validate
        && validationStatus
        && (
          <Link
            to={getActionLinkForClearance('validation')}
            target="_blank"
            rel="noopener noreferrer"
            className="view-icon"
            style={{ color: isValidationDisabled ? '#d3d3d3' : '#868686', pointerEvents: isValidationDisabled ? 'none' : 'auto' }}
            onClick={() => handleClick(props?.reportId)}
            onAuxClick={(event) => handleAuxClick(event, props?.reportId)}
          >
            <CommentIcon />
          </Link>
        )
      }

      {permissions?.delete
        && draftOrRejectedStatus
        && (
          <DeleteReportDialog
            deleteClearanceTaskReport={!props.mode ? () => deleteReportByType(props.reportId, 'clearance', props.setReportsPageList) : undefined}
            deleteDailyreport={props.mode === 'daily' ? () => deleteReportByType(props.reportId, 'daily', props.setReportsPageList) : undefined}
            deleteWeeklyReport={props.mode === 'weekly' ? () => deleteReportByType(props.reportId, 'weekly', props.setReportsPageList) : undefined}
            isDisabled={disabledDeleteDialogs[props.reportId]}
          />
        )
        }

    {/* {props.mode !== 'daily' && <div className="import-and-export-actions">
        <ExportIcon
          className="export-icon"
          onClick={() => handleDownloadEOREDocxBtn(props.reportId)}
        />
      </div>} */}
    </div>
  );
};
