import { React, useState, useEffect, useRef } from 'react';
import { Loader } from 'component/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { Tab } from 'component/Tab';
import { getEoreReports, getEoreTemplates } from 'api/EOREApi';
import { Link, useLocation } from 'react-router-dom';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as AddSvg } from 'assets/images/icons/add_24px.svg';
import { useUser } from 'routes/UserContext';
import { formatDateDataPicker } from 'utils/Helper';
import { NewEORETemplate } from './Templates/Create';
import { EORETable } from './Templates/List';
import { EoreDatapickers } from './Filter';
import { EOREReportsTable } from './Reports/List';
import 'assets/styles/pages/eore.scss';

export const EOREPage = () => {
  const [templatesPageList, setTemplatesPageList] = useState([]);
  const [reportsPageList, setReportsPageList] = useState([]);

  const [templatesPageCount, setTemplatesPageCount] = useState(1);
  const [reportsPageCount, setReportsPageCount] = useState(1);
  const [nextTemplates, setNextTemplates] = useState(false);
  const [nextReports, setNextReports] = useState(false);

  const location = useLocation();
  const [dateFromTemplates, setDateFromTemplates] = useState(null);
  const [dateToTemplates, setDateToTemplates] = useState(null);
  const [dateFromReports, setDateFromReports] = useState(() => formatDateDataPicker(
    location?.state?.startDate,
  ));
  const [dateToReports, setDateToReports] = useState(() => formatDateDataPicker(
    location?.state?.endDate,
  ));
  const [teamLeadId, setTeamLeadId] = useState(location.state?.teamLeadId || null);

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isTemplatesLoading, setIsTemplatesLoading] = useState(false);
  const [isEOREReportsLoading, setIsEOREReportsLoading] = useState(false);
  const [loader, setLoader] = useState(true);

  const [templatesRequested, setTemplatesRequested] = useState(false);
  const [reportsRequested, setReportsRequested] = useState(false);

  const templatesAbortControllerRef = useRef(null);
  const reportsAbortControllerRef = useRef(null);

  const { user } = useUser();

  const getTemplatesPermissions = () => user?.permissions?.eore_templates;
  const getReportsPermissions = () => user?.permissions?.create_eore_reports
    || user?.permissions?.view_eore_report
    || user?.permissions?.change_eore_report
    || user?.permissions?.delete_eore_report
    || user?.permissions?.validate_eore_report;

  const getEoreTepmlatesList = (pageAfterCreate = false) => {
    if (templatesAbortControllerRef.current) {
      templatesAbortControllerRef.current.abort();
    }
    templatesAbortControllerRef.current = new AbortController();

    setIsTemplatesLoading(true);

    const page = pageAfterCreate ? pageAfterCreate : templatesPageCount;

    getEoreTemplates(
      page,
      dateFromTemplates,
      dateToTemplates,
      templatesAbortControllerRef.current.signal
    )
      .then((response) => {
        if (pageAfterCreate) {
          setTemplatesPageList(response.data.results);
        } else {
          setTemplatesPageList([...templatesPageList, ...response.data.results]);
        }
        setNextTemplates(response.data.next);
        setTemplatesRequested(true);
      })
      .catch((error) => {
        if (error.name !== 'CanceledError' && error.name !== 'AbortError') {
          toast.error('Error loading templates');
        }
      })
      .finally(() => {
        setIsTemplatesLoading(false);
      });
  };

  const getEoreReportsList = (pageAfterCreate = false) => {
    if (reportsAbortControllerRef.current) {
      reportsAbortControllerRef.current.abort();
    }
    reportsAbortControllerRef.current = new AbortController();

    setIsEOREReportsLoading(true);

    const page = pageAfterCreate ? pageAfterCreate : reportsPageCount;

    getEoreReports(
      page,
      dateFromReports,
      dateToReports,
      teamLeadId,
      reportsAbortControllerRef.current.signal
    )
      .then((response) => {
        if (pageAfterCreate) {
          setReportsPageList(response.data.results);
        } else {
          setReportsPageList([...reportsPageList, ...response.data.results]);
        }
        setNextReports(response.data.next);
        setReportsRequested(true);
      })
      .catch((error) => {
        if (error.name !== 'CanceledError' && error.name !== 'AbortError') {
          toast.error('Error loading reports');
        }
      })
      .finally(() => {
        setIsEOREReportsLoading(false);
      });
  };

  const handleTemplateLoaderChange = (value) => {
    setIsTemplatesLoading(value);
  };

  const handleReportLoaderChange = (value) => {
    setIsEOREReportsLoading(value);
  };

  const handleTabChange = (index) => {
    setActiveTabIndex(index);

    if (index === 0 && getTemplatesPermissions() && !templatesRequested && !isTemplatesLoading) {
      getEoreTepmlatesList(1);
    } else if (index === 1 && getReportsPermissions() && !reportsRequested && !isEOREReportsLoading) {
      getEoreReportsList(1);
    }
  };

  useEffect(() => {
    setLoader(isTemplatesLoading || isEOREReportsLoading);
  }, [isTemplatesLoading, isEOREReportsLoading]);

  useEffect(() => {
    document.title = 'EORE';

    let initialTab = 0;
    if (getTemplatesPermissions()) {
      initialTab = 0;
    } else if (getReportsPermissions()) {
      initialTab = 1;
    }
    setActiveTabIndex(initialTab);

    if (initialTab === 0 && getTemplatesPermissions()) {
      getEoreTepmlatesList(1);
    } else if (initialTab === 1 && getReportsPermissions()) {
      getEoreReportsList(1);
    }

    return () => {
      if (templatesAbortControllerRef.current) {
        templatesAbortControllerRef.current.abort();
      }
      if (reportsAbortControllerRef.current) {
        reportsAbortControllerRef.current.abort();
      }
    };
  }, [user]);

  useEffect(() => {
    if ((dateFromTemplates !== null || dateToTemplates !== null) && getTemplatesPermissions() && activeTabIndex === 0) {
      getEoreTepmlatesList(1);
    }
  }, [dateFromTemplates, dateToTemplates, activeTabIndex]);

  useEffect(() => {
    if ((dateFromReports !== null || dateToReports !== null) && getReportsPermissions() && activeTabIndex === 1) {
      getEoreReportsList(1);
    }
  }, [dateFromReports, dateToReports, teamLeadId, activeTabIndex]);

  useEffect(() => {
    if (templatesPageCount > 1 && getTemplatesPermissions()) {
      getEoreTepmlatesList();
    }
  }, [templatesPageCount]);

  useEffect(() => {
    if (reportsPageCount > 1 && getReportsPermissions()) {
      getEoreReportsList();
    }
  }, [reportsPageCount]);

  return (
    <section>
      <div className="drone-header">
        <div className="title">
          EORE Reports
        </div>
        <div className="header-datepicker">
          <span>Date:</span>
          {(activeTabIndex === 0 && getTemplatesPermissions())
            && (
              <EoreDatapickers
                dateFrom={dateFromTemplates}
                setDateFrom={setDateFromTemplates}
                dateTo={dateToTemplates}
                setDateTo={setDateToTemplates}
                setTeamLeadId={setTeamLeadId}
                onResetDates={(from, to) => {
                  setIsTemplatesLoading(true);
                  setTemplatesPageList([]);
                  setTemplatesPageCount(1);
                  getEoreTemplates(1, from, to)
                    .then((response) => {
                      setTemplatesPageList(response.data.results);
                      setNextTemplates(response.data.next);
                      setTemplatesRequested(true);
                    })
                    .catch((error) => {
                      if (error.name !== 'CanceledError' && error.name !== 'AbortError') {
                        toast.error('Error loading templates');
                      }
                    })
                    .finally(() => {
                      setIsTemplatesLoading(false);
                    });
                }}
              />
            )}
          {(activeTabIndex === 1 && getReportsPermissions())
            && (
              <EoreDatapickers
                dateFrom={dateFromReports}
                setDateFrom={setDateFromReports}
                dateTo={dateToReports}
                setDateTo={setDateToReports}
                setTeamLeadId={setTeamLeadId}
                onResetDates={(from, to) => {
                  setIsEOREReportsLoading(true);
                  setReportsPageList([]);
                  setReportsPageCount(1);
                  getEoreReports(1, from, to)
                    .then((response) => {
                      setReportsPageList(response.data.results);
                      setNextReports(response.data.next);
                      setReportsRequested(true);
                    })
                    .catch((error) => {
                      if (error.name !== 'CanceledError' && error.name !== 'AbortError') {
                        toast.error('Error loading reports');
                      }
                    })
                    .finally(() => {
                      setIsEOREReportsLoading(false);
                    });
                }}
              />
            )}
        </div>
        <Tab>
          {getTemplatesPermissions() && (
            <div
              className={`tab-item-custom ${activeTabIndex === 0 ? 'active' : ''}`}
              onClick={() => handleTabChange(0)}
            >
              Templates
            </div>
          )}
          {getReportsPermissions()
            && (
              <div
                className={`tab-item-custom ${activeTabIndex === 1 ? 'active' : ''}`}
                onClick={() => handleTabChange(1)}
              >
                Reports
              </div>
            )}
        </Tab>
      </div>

      {activeTabIndex === 0 && (
        <>
          <EORETable
            templatesData={templatesPageList}
            setTemplatesPageList={setTemplatesPageList}
            next={nextTemplates}
            templatesPageCount={templatesPageCount}
            setTemplatesPageCount={setTemplatesPageCount}
            setLoader={handleTemplateLoaderChange}
            isLoading={isTemplatesLoading}
          />
          <div className="drone-footer">
            <NewEORETemplate
              setLoader={handleTemplateLoaderChange}
              getEoreTepmlatesList={getEoreTepmlatesList}
            />
          </div>
        </>
      )}

      {activeTabIndex === 1 && (
        <>
          <EOREReportsTable
            reportsData={reportsPageList}
            next={nextReports}
            reportsPageCount={reportsPageCount}
            setReportsPageCount={setReportsPageCount}
            setLoader={handleReportLoaderChange}
            setReportsPageList={setReportsPageList}
            isLoading={isEOREReportsLoading}
          />
          <div className="drone-footer">
            <Link to="/reports/eore/create">
              <ButtonComponent
                iconLeft={<AddSvg />}
                className="primary"
                handleButton={() => { }}
                label="Create report"
              />
            </Link>
          </div>
        </>
      )}

      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};