import { AccordionBodyItem, AccordionBodyItemView } from 'component/Accordion';
import React from 'react';

export const ReportDate = ({ date, hasError }) => (
  <AccordionBodyItem
    accordionSubId={(
      <div>
        <span>1.1</span>
      </div>
    )}
    titleUA="Дата звіту"
    titleEN="Report date"
    className={hasError ? 'error error-text' : ''}
    children={(
      <div>
        {date}
      </div>
    )}
  />
);

export const ReportDateView = ({ date }) => (
  <AccordionBodyItemView
    accordionSubId={1.1}
    titleUA="Дата звіту"
    titleEN="Report date"
    children={(
      <div className='flex-column-group-with-gap'>
        {Array.isArray(date)
          ? date.map((dateItem, index) => (
              <div key={index}>{dateItem}</div>
            ))
          : date
        }
      </div>
    )}
  />
);
