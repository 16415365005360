/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import './index.scss';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ViewIcon } from 'assets/images/icons/Eye-on.svg';
import { ButtonComponent } from 'component/Button';

const DashboardCard = ({
  reports, reportType, startDate, endDate,
}) => {
  const navigate = useNavigate();

  const data = reports?.map((report) => ({
    reportName: `${report?.name ?? ''} ${report?.surname ?? ''}`,
    team_lead_id: report?.id,
    created: {
      value: report?.created,
      color: '#dad9d9',
    },
    submitted: {
      value: report?.submitted,
      color: 'rgba(0, 188, 19, 1)',
    },
    rejected: {
      value: report?.rejected,
      color: 'rgba(210, 110, 110, 1)',
    },
  }));

  const size = {
    width: 200,
    height: 210,
  };

  const FirstTspan = styled('tspan')(() => ({
    fill: 'rgba(134, 134, 134, 1)',
    fontSize: 30,
    fontWeight: 600,
  }));

  const StyledText = styled('text')(() => ({
    fill: 'rgba(134, 134, 134, 1)',
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 18,
    fontWeight: 600,
  }));

  const PieCenterLabel = ({ children }) => {
    const {
      width, height, left, top,
    } = useDrawingArea();

    return (
      <StyledText x={left + width} y={top + height / 1.8}>
        <FirstTspan x={left + width} dy="-1em">
          {children}
        </FirstTspan>
        <tspan x={left + width} dy="2em">
          reports
        </tspan>
      </StyledText>
    );
  };

  const openNtsHaReports = (teamLeadId) => {
    navigate('/nts-ha-report/', {
      state: {
        teamLeadId,
        reportType,
        startDate: startDate?.toISOString() || null,
        endDate: endDate?.toISOString() || null,
      },
    });
  };
  const openEoreReports = (teamLeadId) => {
    navigate('/reports/eore/', {
      state: {
        teamLeadId,
        reportType,
        startDate: startDate?.toISOString() || null,
        endDate: endDate?.toISOString() || null,
      },
    });
  };

  return (
    data?.map((res,index) => (
      <div className="dashboardCard" key={res?.team_lead_id || index}>
        <div className="header">
          <div className="teamLead">TL</div>
          <div>
            {res?.reportName?.length > 28 ? (
              <div className="user-name truncated" title={res?.reportName}>
                {res?.reportName.slice(0, 28)}
                ...
              </div>
            ) : (
              <div
                className="user-name"
                style={{
                  height: '20px',
                }}
              >
                {res?.reportName}
              </div>
            )}
          </div>
        </div>
        <div className="middle">
          <div className="leftSection">
            <div className="created">
              <div className="circleWithSpan">
                <div className="circle" />
                <span>Created</span>
              </div>
              <p>
                {res?.created?.value}
                {' '}
                pcs
              </p>
            </div>
            <div className="submitted">
              <div className="circleWithSpan">
                <div className="circle" />
                <span>Submitted reports</span>
              </div>
              <p>
                {res?.submitted?.value}
                {' '}
                pcs
              </p>
            </div>
            <div className="rejected">
              <div className="circleWithSpan">
                <div className="circle" />
                <span>Rejected</span>
              </div>
              <p>
                {res?.rejected?.value}
                {' '}
                pcs
              </p>
            </div>
          </div>
          <PieChart
            series={[
              {
                data: [res.created, res.submitted, res.rejected],
                innerRadius: 85,
                outerRadius: 95,
                paddingAngle: 0,
                cornerRadius: 50,
                startAngle: -190,
                endAngle: 170,
                cx: 100,
                cy: 97,
              },
            ]}
            {...size}
          >
            <PieCenterLabel>
              {res?.created?.value
                + res?.submitted?.value
                + res?.rejected?.value}
            </PieCenterLabel>
          </PieChart>
        </div>
        <div className="footer">
          <ButtonComponent
            iconLeft={<ViewIcon />}
            handleButton={() => {
              reportType === 'eore' ? openEoreReports(res?.team_lead_id) : openNtsHaReports(res?.team_lead_id);
            }}
            label="View reports"
          />
        </div>
      </div>
    ))
  );
};

export default DashboardCard;
