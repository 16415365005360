import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { postDailyReport, patchDailyReport, updateProgressMap } from 'api/DailyApi';
import { comparePolygons } from 'utils/Helper';
import { CLEAR_ADDITIONAL_INFO, CLEAR_IMSMA_ID, SET_DRAFT_STATUS } from 'reducers/dailyFormActions';
import moment from 'moment';

export const useFormActions = (clearanceId, setErrorResponse, dailyId = null, dispatch,validateForm, updateDailyId) => {
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
  const navigate = useNavigate();

  const sendUpdates = async (originalGeojson, dailyId, currentGeojson) => {
    try {
      if (!originalGeojson || !currentGeojson) {
        console.warn('Missing GeoJSON data, skipping progress map update');
        return;
      }

      const polygonsToUpdate = comparePolygons(originalGeojson, currentGeojson);
      if (Object.keys(polygonsToUpdate).length > 0) {
        await updateProgressMap(clearanceId, dailyId, polygonsToUpdate);
      }
    } catch (error) {
      console.error('Failed to update progress map:', error);
    }
  };

  const handleSaveButton = async (formData, currentGeojson, originalGeojson, isDraft = false) => {
    let shouldNavigate = false;
    let navigateTo = '';

    try {
      setSendButtonDisabled(true);
      setErrorResponse(null);

      if (!validateForm()) {
        toast.error('Please fill in all required fields');
        return;
      }

      let dataToSend = {
        ...formData,
        info: {
          ...formData.info,
          hand_over_block: formData?.info?.hand_over_block ? {
            ...formData?.info?.hand_over_block,
            date: moment().format('DD.MM.YYYY'),
          } : null
        }
      };

      if (formData?.info?.dms?.team_activity !== 'field_work') {
        dispatch({
          type: CLEAR_ADDITIONAL_INFO
        });
        dataToSend = {
          ...dataToSend,
          info: {
            ...dataToSend.info,
            field_work: null,
            marking: null,
            eo_found_categories: null,
            photos_block: null,
            hand_over_block: null
          }
        };
      } else {
        if (formData?.info?.field_work && formData?.info?.field_work?.eo_found === false) {
          dataToSend = {
            ...dataToSend,
            info: {
              ...dataToSend?.info,
              eo_found_categories: null,
              hand_over: false,
              hand_over_block: null
            }
          };
        } else if (formData?.info?.field_work?.eo_found === true && formData?.info?.eo_found_categories) {
          const cleanedCategories = {};

          Object.keys(formData?.info?.eo_found_categories)?.forEach(category => {
            const categoryData = formData?.info?.eo_found_categories[category];

            if (categoryData?.isChecked === true) {
              const { isChecked, ...categoryDataWithoutChecked } = categoryData;
              cleanedCategories[category] = categoryDataWithoutChecked;
            }
          });

          dataToSend = {
            ...dataToSend,
            info: {
              ...dataToSend?.info,
              eo_found_categories: Object.keys(cleanedCategories)?.length > 0 ? cleanedCategories : null
            }
          };
        }

        if (formData?.info?.field_work?.task_type &&
            !['battle_area_clearance', 'manual_clearance', 'technical_survey'].includes(formData?.info?.field_work?.task_type)) {
          dataToSend = {
            ...dataToSend,
            info: {
              ...dataToSend?.info,
              field_work: {
                ...dataToSend?.info?.field_work,
                cleared_area: null
              }
            }
          };
        }

        if (formData?.info?.field_work?.task_type !== 'marking') {
          dataToSend = {
            ...dataToSend,
            info: {
              ...dataToSend?.info,
              marking: null
            }
          };
        }
      }

      dataToSend = {
        ...dataToSend,
        info: {
          ...dataToSend?.info,
          draft: isDraft
        }
      };

      dispatch({
        type: SET_DRAFT_STATUS,
        payload: isDraft
      });

      if (isDraft && formData.info.field_work?.clearance_task_imsma_id) {
        dispatch({
          type: CLEAR_IMSMA_ID
        });
      }

      const response = dailyId
        ? await patchDailyReport(dailyId, dataToSend)
        : await postDailyReport(dataToSend);

      if (response) {
        const responseId = response?.info?.id || dailyId;
        const clearanceTaskId = response?.info?.clearance_task_id || clearanceId;


        if (formData?.info?.dms?.team_activity === 'field_work' && originalGeojson && currentGeojson) {
          await sendUpdates(originalGeojson, responseId, currentGeojson);
        }
        toast.success(`Daily report ${isDraft ? 'saved as draft' : 'saved successfully'}`);

        if (!dailyId && responseId && typeof updateDailyId === 'function') {
          updateDailyId(responseId);
        }

        shouldNavigate = true;

        if (isDraft) {
          navigateTo = `/task-clearance/${clearanceTaskId}/daily/${responseId}/edit`;
        } else {
          navigateTo = `/task-clearance/${clearanceTaskId}/daily`;
        }
      }

    } catch (error) {
      console.error('Error details:', {
        error,
        response: error?.response,
        data: error?.response?.data,
        status: error?.response?.status
      });

      if (error?.response?.data) {
        setErrorResponse(error.response.data);
      } else {
        // toast.error('An unexpected error occurred');
        console.log('An unexpected error occurred:', error);
      }

      // toast.error(`Failed to ${isDraft ? 'save draft' : 'save'} daily report`);
      console.log(`Failed to ${isDraft ? 'save draft' : 'save'} daily report:`, error);
    } finally {
      setSendButtonDisabled(false);
      if (shouldNavigate && navigateTo) {
        navigate(navigateTo);
      }
    }
  };


  const handleDraftButton = async (formData, currentGeojson, originalGeojson,) => {
    await handleSaveButton(formData, currentGeojson, originalGeojson, true);
  };

  const handleCancelButton = () => {
    setErrorResponse(null);
    navigate(`/task-clearance/${clearanceId}/daily`);
  };

  return {
    sendButtonDisabled,
    handleSaveButton,
    handleDraftButton,
    handleCancelButton,
  };
};
