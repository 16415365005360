import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const useReportActions = () => {
const [loading, setLoading] = useState(false);

const deleteReportByType = (reportId, reportType, setData) => {
    const endpointMap = {
        nts: `/reports/nts/${reportId}`,
        ha: `/reports/ha/${reportId}`,
        eore: `/reports/eore/${reportId}`,
        clearance: `/reports/clearance/clearance-tasks/${reportId}`,
        daily: `/reports/clearance/daily/${reportId}`,
        weekly: `/reports/clearance/weekly/${reportId}`,
    };
    const endpoint   = endpointMap[reportType];
    if (endpoint) {
        setLoading(true);
        axios.delete(endpoint)
            .then(() => {
            setData((prevData) => {
                if (prevData?.results) {
                    return {
                        ...prevData,
                        results: prevData.results.filter(item => item.id !== reportId),
                    };
                }
                if (Array.isArray(prevData)) {
                    return prevData.filter(item => item.id !== reportId);
                }
                console.error("Unexpected data structure for `prevData`:", prevData);
                return prevData;
            });
            toast.success(`${reportType.toUpperCase()} report deleted successfully`);
        })
        .catch((err) => {
            console.error(err);
            toast.error(`Failed to delete ${reportType.toUpperCase()} report`);
        })
        .finally(() => setLoading(false));
        }
};

const editReportById = (reportId, reportType, updateData, setData) => {
    const endpointMap = {
        nts: `/edit-nts-report/${reportId}`,
        ha: `/edit-ha-report/${reportId}`,
        eore: `/reports/eore/${reportId}`,
        clearance: `/reports/clearance/clearance-tasks/${reportId}`,
        daily: `/task-clearance/:clearanceId/daily/:${reportId}/edit`,
    };
    const endpoint = endpointMap[reportType];

    if (endpoint) {
        setLoading(true);
        axios.patch(endpoint, updateData)
            .then((response) => {
                const updatedReport = response.data;
                setData((prevData) => {
                    if (prevData?.results) {
                        return {
                            ...prevData,
                            results: prevData.results.map(item =>
                                item.id === reportId ? updatedReport : item
                            ),
                        };
                    }
                    if (Array.isArray(prevData)) {
                        return prevData.map(item =>
                            item.id === reportId ? updatedReport : item
                        );
                    }
                    console.error("Unexpected data structure for `prevData`:", prevData);
                    return prevData;
                });
                toast.success(`${reportType.toUpperCase()} report updated successfully`);
            })
            .catch((err) => {
                console.error(err);
                toast.error(`Failed to update ${reportType.toUpperCase()} report`);
            })
            .finally(() => setLoading(false));
    }
};

    return { deleteReportByType, editReportById, loading };
};

export default useReportActions;
