import React from 'react';
import { InputComponent } from 'component/Input';
import { getEOREDirectBeneficiariesSum } from 'utils/Helper';

export const DirectBeneficiaries = (props) => {
  const { formData, handleBeneficiaries } = props;

  const totalDirectBeneficiaries = getEOREDirectBeneficiariesSum(formData
    ?.info?.session_information?.direct_beneficiaries);

  return (
    <div className="flex-group total-beneficiaries-container">
      <GCAMale
        formData={formData}
        handleBeneficiaries={handleBeneficiaries}
      />
      <GCAFemale
        formData={formData}
        handleBeneficiaries={handleBeneficiaries}
      />
      <GCABoys
        formData={formData}
        handleBeneficiaries={handleBeneficiaries}
      />
      <GCAGirls
        formData={formData}
        handleBeneficiaries={handleBeneficiaries}
      />
      <div className="total-beneficiaries">
        <div>
          Total:
        </div>
        <div>
          {totalDirectBeneficiaries}
        </div>
      </div>
    </div>
  );
};

export const GCAMale = (props) => {
  const { formData, handleBeneficiaries } = props;

  return (
    <>
      <div className="double-text">
        <span>GCA Чоловік</span>
        <span>GCA Male</span>
      </div>
      <InputComponent
        className="number"
        value={formData.info.session_information.direct_beneficiaries.male_adult_direct_count}
        handleInput={(value) => {
          handleBeneficiaries('direct_beneficiaries', 'male_adult_direct_count', value.replace(/^0+/, ''));
        }}
      />
    </>
  );
};

export const GCAFemale = (props) => {
  const { formData, handleBeneficiaries } = props;

  return (
    <>
      <div className="double-text">
        <span>GCA Жінка</span>
        <span>GCA Female</span>
      </div>
      <InputComponent
        className="number"
        value={formData.info.session_information.direct_beneficiaries.female_adult_direct_count}
        handleInput={(value) => {
          handleBeneficiaries('direct_beneficiaries', 'female_adult_direct_count', value.replace(/^0+/, ''));
        }}
      />
    </>
  );
};

export const GCABoys = (props) => {
  const { formData, handleBeneficiaries } = props;

  return (
    <>
      <div className="double-text">
        <span>GCA Хлопці</span>
        <span>GCA Boys</span>
      </div>
      <InputComponent
        className="number"
        value={formData.info.session_information.direct_beneficiaries.male_child_direct_count}
        handleInput={(value) => {
          handleBeneficiaries('direct_beneficiaries', 'male_child_direct_count', value.replace(/^0+/, ''));
        }}
      />
    </>
  );
};

export const GCAGirls = (props) => {
  const { formData, handleBeneficiaries } = props;

  return (
    <>
      <div className="double-text">
        <span>GCA Дівчата</span>
        <span>GCA Girls</span>
      </div>
      <InputComponent
        className="number"
        value={formData.info.session_information.direct_beneficiaries.female_child_direct_count}
        handleInput={(value) => {
          handleBeneficiaries('direct_beneficiaries', 'female_child_direct_count', value.replace(/^0+/, ''));
        }}
      />
    </>
  );
};
