import { Table, TableHeader, TableRow } from 'component/Table';
import React, { useEffect, useRef, useState } from 'react';
import { formatArea, formatEOFound, ValidationStatusComponent } from 'utils/Helper';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as ArrowBackSvg } from 'assets/images/icons/arrow_back_24px.svg';
import { ReactComponent as ArrowNextSvg } from 'assets/images/icons/Right.svg';
import { ReactComponent as SortByIcon } from 'assets/images/icons/Sort-by2.svg';
import { toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';
import { ReportActions } from '../Lists/ReportActions';
import { DAILY_WEEKLY_HEADERS } from 'consts/ReportHeaders';
import { useNavigate } from 'react-router-dom';

 export const ClearanceDailyTable = ({
  clearanceDailyList,
  setLoader,
  setClearanceDailyList,
  setCurrentPage,
  fetchClearanceDailyList,
  statuses,
  mode,
  clearanceId,
}) => {
  const [rotations, setRotations] = useState({});
  const [sortDirection, setSortDirection] = useState({});
  const [lastClickedIcon, setLastClickedIcon] = useState(null);
  const navigate = useNavigate();

  const handleSortIconClick = async (key) => {
    try {
      setClearanceDailyList([])
      setCurrentPage(1);

      const currentDirection = sortDirection[key] || false;
      const newDirection = !currentDirection;
      setSortDirection(prev => ({
        ...prev,
        [key]: newDirection,
      }));

      setLoader(true);
      const updatedStatuses = {
        ...statuses,
        ordering: newDirection ? key : `-${key}`,
      };

      await fetchClearanceDailyList(1, updatedStatuses);

      const currentRotation = rotations[key] || 0;
      const newRotation = currentRotation + 180;

      if (lastClickedIcon && lastClickedIcon !== key) {
        setRotations(prevRotations => ({
          ...prevRotations,
          [lastClickedIcon]: 0,
        }));
      }

      setRotations(prevRotations => ({
        ...prevRotations,
        [key]: newRotation,
      }));

      setLastClickedIcon(key);
    } catch (error) {
      console.error('Error fetching daily list:', error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="eore-table">
    <Table>
      <TableHeader>
      {DAILY_WEEKLY_HEADERS?.map((header, index) => (
  <th
    key={index}
    className={`text-with-icon ${header.style ? '' : ''}`}
    style={header.style || {}}
  >
    {header.key && header.condition && (
      <SortByIcon
        className="sort-icon"
        style={{ transform: `rotate(${rotations[header.key] || 0}deg)` }}
        onClick={() => handleSortIconClick(header.key)}
      />
    )}
    {header.label}
  </th>
))}
      </TableHeader>
      <tbody>
        {clearanceDailyList?.results?.length === 0
          && (
            <TableRow>
              <td>Дані відсутні</td>
            </TableRow>
          )}
        {clearanceDailyList?.results?.map((item) => (
          <TableRow key={item?.id}>
                <td>{item?.created_at ? new Date(item?.created_at).toLocaleDateString() : '-'}</td>
                <td>{item?.team_id || '-'}</td>
                <td>{item?.clearance_task_imsma_id || '-'}</td>
                <td>{item?.type || '-'}</td>
                {/* <td>10.01.2025 <br /> 18.01.2025</td> */}
                <td>
                {formatArea(item?.area, item?.cleared_area)}
                </td>
            <td style={{ textAlign: 'start'}}>{formatEOFound(item?.eo_found)}</td>
            <td>
              {item?.validation_status?.value
                && (<ValidationStatusComponent status={item?.validation_status} />)}
            </td>
            <td aria-label="actions">
              <ReportActions
                reportId={item?.id}
                setLoader={setLoader}
                setReportsPageList={setClearanceDailyList}
                validationStatus={item?.validation_status?.value}
                isCurrentValidationRole={item?.is_current_validation_role}
                mode={mode}
                clearanceId={clearanceId}
                />
            </td>
          </TableRow>
        ))}
        {clearanceDailyList?.next && (
          <div className="loadMore-div">
            <button
              className="loadMore-button"
              onClick={() => {
                setCurrentPage((prevPage) => {
                  const newPage = prevPage + 1;
                  fetchClearanceDailyList(newPage, statuses);
                  return newPage;
                });
              }}
              >
              Load more...
            </button>
          </div>
        )}
        <div className="drone-footer buttons-group py-10">
          <ButtonComponent
            iconLeft={<ArrowBackSvg style={{ width: '20px', height: '20px' }} />}
            className="warning"
            label="Previous"
            handleButton={() => { navigate(-1)}}
            />
          <ButtonComponent
            iconRight={<ArrowNextSvg style={{ width: '14px', height: '14px' }} />}
            label="Complete"
            handleButton={() => {navigate(`/task-clearance/${clearanceId}/completed`)}}
            />
        </div>
      </tbody>
    </Table>
  </div>

);

};

const ClearanceDaily = ({
  setLoader,
  clearanceDailyList,
  setClearanceDailyList,
  searchTerm,
  statuses,
  setCurrentPage,
  currentPage,
  startDate,
  endDate,
  clearanceId,
}) => {
  const fetchClearanceDailyList = async (page, filters) => {
    setLoader(true);
    try {

      const baseUrl = `/reports/clearance/daily/${clearanceId}/clearance-task/`;
      const params = new URLSearchParams();
      params.append('page', page);

      if (filters.ordering) {
        params.append('ordering', filters.ordering);
      }

      if (startDate) {
        const dateFrom = moment(startDate).format('DD.MM.YYYY');
        params.append('date_from', dateFrom);
      }

      if (endDate) {
        const dateTo = moment(endDate).format('DD.MM.YYYY');
        params.append('date_to', dateTo);
      }
      if (filters.selectedStatus && filters.selectedStatus.length > 0) {
        filters.selectedStatus.forEach((statusItem) => {
          params.append('validation_status', statusItem.value);
        });
      }

      if (filters.selectedDonors && filters.selectedDonors.length > 0) {
        filters.selectedDonors.forEach((donorItem) => {
          params.append('donor', donorItem.value);
        });
      }

      if (filters.selectedMethodTypes && filters.selectedMethodTypes.length > 0) {
        filters.selectedMethodTypes.forEach((methodItem) => {
          params.append('method', methodItem.value);
        });
      }
      if (searchTerm) {
        params.append('imsma_id', searchTerm);
      }

      const url = `${baseUrl}?${params.toString()}`;
      const response = await axios.get(url);

      setClearanceDailyList((prevList) => (page === 1
        ? response.data
        : {
          ...response.data,
          results: prevList
            ? [...prevList.results, ...response.data.results]
            : response.data.results,
        }));
    } catch (error) {
      toast.error('Error loading clearance daily list');
    } finally {
      setLoader(false);
    }
  };

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchClearanceDailyList(1, statuses);
      return;
    }

    const timer = setTimeout(() => {
      setCurrentPage(1);
      fetchClearanceDailyList(1, statuses);
    }, 300);

    return () => clearTimeout(timer);
  }, [searchTerm, startDate, endDate]);

  return (
    <ClearanceDailyTable
    clearanceDailyList={clearanceDailyList}
    setCurrentPage={setCurrentPage}
    currentPage={currentPage}
    fetchClearanceDailyList={fetchClearanceDailyList}
    statuses={statuses}
    setLoader={setLoader}
    setClearanceDailyList={setClearanceDailyList}
    mode={'daily'}
    clearanceId={clearanceId}
    />
  );
};

export default ClearanceDaily;
