import { useState, useEffect, useCallback } from 'react';

export const useDailyFormErrors = (errorResponse, formData, dispatch) => {
  const [errors, setErrors] = useState({});

  // Функція для валідації форми
  const validateForm = useCallback(() => {
    const validationErrors = [];

    // Функція-помічник для перевірки обов'язкових полів
    const validateRequired = (value, path, message) => {
      if (!value) {
        validationErrors.push({
          loc: path,
          msg: message || 'This field is required'
        });
      }
    };
    if (formData?.info?.dms) {
      validateRequired(formData?.info?.dms?.team_activity, ['dms', 'team_activity']);
      validateRequired(formData?.info?.dms?.organization, ['dms', 'organization']);
      validateRequired(formData?.info?.dms?.reporter_name, ['dms', 'reporter_name']);
      validateRequired(formData?.info?.dms?.reporter_position, ['dms', 'reporter_position']);
    }
    // Додаткові перевірки для field_work
    if (formData?.info?.dms?.team_activity === 'field_work') {
      validateRequired(formData.info?.field_work?.task_type, ['field_work', 'task_type']);
      validateRequired(
        formData.info?.field_work?.number_of_deminers,
        ['field_work', 'number_of_deminers']
      );

    }

    // Встановлюємо помилки
    setErrors(
      validationErrors.reduce((acc, error) => {
        acc[error.loc.join('.')] = error.msg;
        return acc;
      }, {})
    );

    return validationErrors.length === 0;

  }, [formData]);

  // Обробка помилок від API
  useEffect(() => {
    if (!errorResponse) {
      setErrors({});
      return;
    }

    const formattedErrors = {};
    const errorsArray = Array.isArray(errorResponse) ? errorResponse : [errorResponse];

    errorsArray.forEach((error) => {
      if (error?.loc) {
        const path = Array.isArray(error.loc) ? error.loc.join('.') : error.loc;
        formattedErrors[path] = error.msg;
      }
    });

    setErrors(formattedErrors);
  }, [errorResponse]);

  const hasError = useCallback((path) =>
    Object.keys(errors).some((key) => key?.startsWith(path))
  , [errors]);

  return {
    errors,
    hasError,
    validateForm,
  };
};