import { AccordionBodyItem, AccordionBodyItemView2 } from 'component/Accordion';
import { TextareaComponent } from 'component/Textarea';
import React from 'react';
import {  UPDATE_REMARKS } from 'reducers/dailyFormActions';

export const Remarks = ({ formData, dispatch }) => {
  const handleChange = (value) => {
    dispatch({
      type: UPDATE_REMARKS,
      payload: value,
    });
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>2.2.4</span>
        </div>
      )}
      titleUA="Коментарі"
      titleEN="Remarks"
      children={(
        <TextareaComponent
          value={formData.info?.field_work?.remarks}
          handleTextarea={handleChange}
          placeholder="Type here..."
        />
      )}
    />
  );
};

export const RemarksView = ({ remarks }) => (
  <AccordionBodyItemView2
    accordionSubId="2.2.4"
    titleUA="Коментарі"
    titleEN="Remarks"
    children={
      (<div className='flex-column-group-with-gap'>
      {Array.isArray(remarks)
        ? remarks?.map((remark, index) => (
            <div key={index}>{remark}</div>
          ))
        : remarks || '-'
      }
    </div>
  )}
  />
);
