import axios from 'axios';

export const sendClearanceShapeFile = async (body) => {
  const url = '/reports/clearance/shape-info/';
  const response = await axios.post(url, body);
  return response;
};

export const getRelatedUsers = async () => {
  const url = '/reports/clearance/related-users/';
  const response = await axios.get(url);
  return response;
};

export const getMethodTypes = async () => {
  const url = '/reports/clearance/methods/';
  const response = await axios.get(url);
  return response;
};

export const postTCPForm = async (body) => {
  const url = '/reports/clearance/clearance-tasks/';
  const response = await axios.post(url, body);
  return response;
};

export const updateTCPForm = async (id, body) => {
  const url = `/reports/clearance/clearance-tasks/${id}/`;
  const response = await axios.put(url, body);
  return response;
};

export const getTCPList = async (page, status, internalId) => {
  let url = '/reports/clearance/clearance-tasks/';

  if (internalId) {
    url += `?internal_id=${internalId}`;
  } else {
    const pageParam = page ? `?page=${page}` : '';
    const statusParam = status ? `&status=${status}` : '';
    url += `${pageParam}${statusParam}`;
  }
  const response = await axios.get(url);
  return response;
};

export const getTCPFormById = async (id) => {
  const url = `/reports/clearance/clearance-tasks/${id}/`;
  const response = await axios.get(url);
  return response;
};

export const postProgressMap = async (id, body) => {
  const url = `/reports/clearance/progress-map/${id}/`;
  const response = await axios.post(url, body);
  return response;
};

export const getTCPFilters = async (filter) => {
  const url = `/reports/clearance/clearance-tasks/filters/choices/?choice_name=${filter}`;
  const response = await axios.get(url);
  return response;
};

export const deleteClearanceReport = async (id) => {
  const url = `/reports/clearance/clearance-tasks/${id}/`;
  const response = await axios.delete(url);
  return response;
};
