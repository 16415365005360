import React from 'react';
import axios from 'axios';
import moment from 'moment';
import proj4 from 'proj4';
import { transliterate } from 'transliteration';
import { toast } from 'react-toastify';
import { URLS } from 'routes/Urls';
import { ReactComponent as RadioCheckedIcon } from 'assets/images/icons/radio_button_checked.svg';
import { getHeaders, setHeaders } from './Headers';
import { getCookie, removeCookie, setCookie } from './Cookies';

export const axiosWrapper = (callback) => {
  callback();
};

export const setTokens = (accessToken, refreshToken) => {
  setCookie('access_token', accessToken, {
    secure: false,
    'max-age': 5 * 60 * 1000,
  }); // 5 minutes
  setCookie('refresh_token', refreshToken, {
    secure: false,
    'max-age': 1440 * 7 * 60 * 1000,
  }); // 7 days
};

export const refreshToken = (callback) => {
  const data = {
    refresh: getCookie('refresh_token'),
  };
  axios
    .post(URLS.REFRESH, data)
    .then((res) => {
      if (res.status !== 200) {
        console.log('Виникла проблема авторизації.');
        removeCookie('access_token');
        removeCookie('refresh_token');
        return;
      }
      setTokens(res.data.access, res.data.refresh);
      setHeaders();
      callback();
    })
    .catch((err) => {
      console.error(err);
      console.log('Виникла проблема авторизації.');
      window.location = '/login';
      removeCookie('access_token');
      removeCookie('refresh_token');
    });
};

export const transformMomentDateToString = (momentObj) => {
  const mom = moment(momentObj);
  return `${mom.date()}.${mom.month()}.${mom.year()}`;
};

export const convert3857To4326 = (geojson, zone = 37) => {
  if (!geojson) return geojson;
  proj4.defs(
    'EPSG:32637',
    `+proj=utm +zone=${zone} +datum=WGS84 +units=m +no_defs +type=crs`,
  );
  proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');

  geojson.features?.forEach((feature) => {
    feature.properties = feature.properties ? feature.properties : {};
    if (feature.geometry.type === 'Polygon') {
      const { coordinates } = feature.geometry;
      const convertedCoordinates = coordinates.map((coord) => {
        const projectedCoord = proj4('EPSG:32637', 'EPSG:4326', coord);
        return projectedCoord;
      });
      feature.geometry.coordinates[0] = convertedCoordinates;
    }
    if (feature.geometry.type === 'Point') {
      const { coordinates } = feature.geometry;
      if (!coordinates.length) return;
      feature.geometry.coordinates = proj4(
        'EPSG:32637',
        'EPSG:4326',
        coordinates,
      );
    }
  });
  return geojson;
};

export const transliterateString = (string) => transliterate(string, {
  replacement: 'Latin',
  unknown: '`',
});

export const exportDocx = async (id) => {
  try {
    const res = await axios.get(`/reports/ha/${id}/export/docx/`, {
      method: 'GET',
      responseType: 'blob',
    });
    const fileName = res.headers['content-type'].split(';')[1];
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    toast.error('Export error!');
    console.error(err);
  }
};
export const exportEOREDocx = async (id) => {
  try {
    const res = await axios.get(`/reports/eore/${id}/export/docx/`, {
      method: 'GET',
      responseType: 'blob',
    });
    const fileName = res.headers['content-type'].split(';')[1];
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    toast.error('Export error!');
    console.error(err);
  }
};

export const exportPDF = async (id) => {
  try {
    const res = await axios.get(`/reports/ha/${id}/export/pdf/`, {
      method: 'GET',
      responseType: 'blob',
    });
    const fileName = res.headers['content-type'].split(';')[1];
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    toast.error('Export error!');
    console.error(err);
  }
};

export const updateValidationForm = (formData, validationForm) => {
  const checkEvidences = (evidences) => (
    evidences.items?.length > 0
    && evidences.items.every((item) => (
      item.photo
      && Array.isArray(item.photo)
      && item.photo.every((photo) => photo.file_name)
      && item.evidence_type
    ))
  );

  const checkLmBmInfo = (formDataInfo) => (
    Boolean(formDataInfo.photo?.file_name)
    && Boolean(formDataInfo.geometry?.coordinates?.length)
  );

  const checkTurnPointsInfo = (formDataInfo) => Boolean(formDataInfo.turn_points?.points?.length);

  const checkPolygonCountsInfo = (formDataInfo) => Boolean(formDataInfo.polygon_info.polygon_count);

  const updatedForm = { ...validationForm };

  Object.keys(validationForm).forEach((sectionKey) => {
    const section = validationForm[sectionKey];

    Object.keys(section).forEach((fieldKey) => {
      let fieldValue;

      if (sectionKey === 'evidences') {
        fieldValue = formData.info[sectionKey].direct_evidences;
        updatedForm[sectionKey][fieldKey] = checkEvidences(fieldValue)
          && checkEvidences(formData.info[sectionKey].indirect_evidences);
      } else if (sectionKey === 'file_info') {
        updatedForm[sectionKey].lm = checkLmBmInfo(formData.info.lm);
        updatedForm[sectionKey].bm = checkLmBmInfo(formData.info.bm);
        updatedForm[sectionKey].turn_points = checkTurnPointsInfo(formData.info);
        updatedForm[sectionKey].polygon_counts = checkPolygonCountsInfo(formData.info);
      } else {
        fieldValue = formData.info[sectionKey]?.[fieldKey];
        updatedForm[sectionKey][fieldKey] = Boolean(fieldValue) && checkEvidences(fieldValue);
      }
    });
  });

  return updatedForm;
};

export const preventPopState = (callback) => {
  window.onpopstate = (e) => {
    e.preventDefault();
    const isClose = window.confirm(
      'You have attempted to leave this page. Are you sure?',
    );
    if (!isClose) {
      callback();
    }
  };
};

export const getPageValue = (url) => {
  const urlObj = new URL(url);
  const { searchParams } = urlObj;
  const page = searchParams.get('page');
  return page;
};

export const getMapBbox4326 = (map) => {
  const sw = [map.getBounds()._sw.lng, map.getBounds()._sw.lat];
  const ne = [map.getBounds()._ne.lng, map.getBounds()._ne.lat];
  return [sw, ne];
};

export const convertCoords4326To3857 = (coords, zone) => {
  const lon = Number(coords[0]) || null;
  const lat = Number(coords[1]) || null;
  if (coords.length && coords[0] && coords[1]) {
    proj4.defs(
      'EPSG:32637',
      `+proj=utm +zone=${zone} +datum=WGS84 +units=m +no_defs +type=crs`,
    );
    proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
    return proj4(
      'EPSG:4326',
      'EPSG:32637',
      [lon, lat],
    );
  }
  return null;
};

export const convertCoords3857To4326 = (coords, zone) => {
  const lon = Number(coords[0]) || null;
  const lng = Number(coords[1]) || null;
  if (coords.length && coords[0] && coords[1]) {
    proj4.defs(
      'EPSG:32637',
      `+proj=utm +zone=${zone} +datum=WGS84 +units=m +no_defs +type=crs`,
    );
    proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');

    return proj4(
      'EPSG:32637',
      'EPSG:4326',
      [lon, lng],
    );
  }
  return null;
};

export const squareMetersToHectares = (squareMeters) => {
  if (!squareMeters) {
    return '';
  }
  const hectares = squareMeters / 10000;
  return `${hectares.toFixed(2)} Ha`;
};

export const alphaVal = (number) => {
  if (number >= 1) {
    let result = '';
    while (number > 0) {
      const remainder = (number - 1) % 26;
      result = String.fromCharCode(65 + remainder) + result;
      number = Math.floor((number - 1) / 26);
    }
    return result;
  }
  return 'Number out of range for conversion to letter.';
};

export const downloadXlsx = async (id) => {
  try {
    const res = await axios.get(`templates/filtered/${id}/export/xlsx`, {
      method: 'GET',
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'FSD_Export.xlsx');
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    toast.error('Error downloading file.');
    console.error(err);
  }
};

export const getBase64 = (imgUrl) => new Promise((resolve, reject) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const img = new Image();
  img.onload = () => {
    canvas.width = img.height;
    canvas.height = img.width;

    ctx.rotate((90 * Math.PI) / 180);
    ctx.drawImage(img, 0, -canvas.width);

    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
      } else {
        reject(new Error('Failed to convert canvas to Blob'));
      }
    }, 'image/jpeg');
  };

  img.onerror = reject;
  img.src = imgUrl;
});

export const createOrUpdateFile = (blob, fileName) => {
  const file = new File([blob], fileName, { type: blob.type });
  return file;
};

export const sortedPhotos = (arrayOfObjects) => {
  const priorityOrder = {
    lm: 1,
    bm: 2,
    direct_evidence: 3,
    indirect_evidence: 4,
    general: 5,
  };

  arrayOfObjects.sort((a, b) => {
    const priorityA = priorityOrder[a.dependency];
    const priorityB = priorityOrder[b.dependency];

    if (priorityA !== priorityB) {
      return priorityA - priorityB;
    }

    return a.order - b.order;
  });

  return arrayOfObjects;
};

export const setAxiosInterceptors = () => {
  axios.interceptors.request.use(
    (request) => {
      request.headers.Authorization = getHeaders();
      return request;
    },
    (error) => Promise.reject(error),
  );

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401
        && error.response?.data?.detail !== 'No active account found with the given credentials') {
        refreshToken();
      }
      if (error.response?.status === 403) {
        toast.error('You do not have permission to access this resource.');
      }
      return Promise.reject(error);
    },
  );
};

export const fetchWithInterceptors = async (url, options = {}) => {
  // Request Interceptor
  const modifiedOptions = {
    ...options,
    headers: {
      ...options.headers,
      Authorization: getHeaders(), // Add Authorization header
    },
  };

  try {
    // Perform the fetch request
    const response = await fetch(url, modifiedOptions);

    // Response Interceptor
    if (!response.ok) {
      // Handle specific status codes like in axios interceptor
      if (response.status === 401) {
        // Token expired or invalid, trigger token refresh
        await refreshToken();
      }
      if (response.status === 403) {
        toast.error('You do not have permission to access this resource.');
      }
    }

    // Return the response
    return response;
  } catch (error) {
    // Handle fetch errors (e.g., network issues)
    console.error('Fetch error:', error);
    throw error;
  }
};

export const removeLeadingZeros = (value) => parseInt(value, 10).toString();

export const convertColorToRgba = (color, opacity) => {
  color = color?.replace(/^#/, '');

  const r = parseInt(color?.substring(0, 2), 16);
  const g = parseInt(color?.substring(2, 4), 16);
  const b = parseInt(color?.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const ValidationStatusComponent = ({ status }) => (
  <div
    className="validation-status"
    style={{ color: `${status?.color}` }}
  >
    <RadioCheckedIcon
      style={{ color: `${status?.color}` }}
    />
    {status?.label}
  </div>
);

export const calculateBeneficiariesSum = (beneficiaries, field) => Object
  .values(beneficiaries[field])
  .reduce((acc, item) => Number(acc) + Number(item), 0);

export const getEOREDirectBeneficiariesSum = (directBeneficiaries) => (
  Number(directBeneficiaries?.male_adult_direct_count || 0)
  + Number(directBeneficiaries?.female_adult_direct_count || 0)
  + Number(directBeneficiaries?.male_child_direct_count || 0)
  + Number(directBeneficiaries?.female_child_direct_count || 0)
);

export const getEOREIndirectBeneficiariesSum = (indirectBeneficiaries) => (
  Number(indirectBeneficiaries?.male_adult_direct_additional_count || 0)
  + Number(indirectBeneficiaries?.female_adult_direct_additional_count || 0)
  + Number(indirectBeneficiaries?.male_child_direct_additional_count || 0)
  + Number(indirectBeneficiaries?.female_child_direct_additional_count || 0)
);

export const areGeoJsonEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

export const formatEOFound = (eoFound) => {
  if (!eoFound || eoFound.length === 0) {
    return (
      <>
        0 unit
        <br />
        0 kg
      </>
    );
  }

  const totals = eoFound.reduce(
    (acc, curr) => {
      acc.unit += curr.total_weight_unit || 0;
      acc.kg += curr.total_weight_kg || 0;
      return acc;
    },
    { unit: 0, kg: 0 },
  );

  return (
    <>
      {totals.unit}
      {' '}
      unit
      <br />
      {totals.kg}
      {' '}
      kg
    </>
  );
};

export const formatAreaToOneDecimal= (area)=> {
  if (area == null) {
    return '-';
}

const areaStr = area.toString();

if (!areaStr.includes('.') && !areaStr.includes(',')) {
    return areaStr;
}

return Number(area).toFixed(1);
}


export const formatArea = (area, clearedArea) => {
  if (!area && clearedArea !== 0 && !clearedArea) {
    return (
      <>
        -
        <br />
        -
      </>
    );
  }
  return (
    <>
      {formatAreaToOneDecimal(area) || '-'}
      <br />
      {clearedArea === 0 ? '-' : (formatAreaToOneDecimal(clearedArea) || '-')}
    </>
  );
};


export const formatPeriod = (period) => {
  if (!period || period.length === 0) {
    return (
      <>
        -
        <br />
        -
      </>
    );
  }
  return (
    <>
      {period[0] || '-'}
      <br />
      {period[1] || '-'}
    </>
  );
};

export const comparePolygons = (originalGrid, updatedGrid) => {
  const polygonsToUpdate = {};

  const originalFeatures = originalGrid.features.reduce((acc, feature) => {
    acc[feature.properties.id] = feature.properties;
    return acc;
  }, {});

  updatedGrid.features.forEach((updatedFeature) => {
    const { id } = updatedFeature.properties;
    const originalFeature = originalFeatures[id];

    if (
      originalFeature
      && (JSON.stringify(originalFeature.colors) !== JSON.stringify(updatedFeature
        .properties.colors)
        || JSON.stringify(originalFeature.methods) !== JSON.stringify(updatedFeature
          .properties.methods))
    ) {
      polygonsToUpdate[id] = {
        colors: updatedFeature.properties.colors,
        methods: updatedFeature.properties.methods,
        updated_date: new Date().toISOString(),
      };
    }
  });

  return polygonsToUpdate;
};

export const formatDateDataPicker = (date) => (date && moment(date).isValid() ? moment(date).format('DD.MM.YYYY') : null);

export const hasDailyOrWeeklyOrClearancePermissions = (permissions) => {
  if (!permissions) return false;

  const keywords = ['weekly', 'daily', 'clearance'];

  return Object.keys(permissions).some(permission =>
      keywords.some(keyword => permission.toLowerCase().includes(keyword))
  );
};