import { getUserById } from 'api/UserAPI';
import React, { useEffect, useState } from 'react';

export const Creator = ({ tcpFormData }) => {
  const [creator, setCreator] = useState(null);
  useEffect(() => {
    if (!tcpFormData?.user) return;
    getUserById(tcpFormData?.user)
      .then((res) => {
        setCreator(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="flex-column-group">
      <div className="double-text">
        <span>Хто створив</span>
        <span>Created</span>
      </div>
      <div className="text-grey">
        {creator && `${creator?.name} ${creator?.surname}`}
        {!creator && 'Unknown'}
      </div>
    </div>
  );
};

export const CreatorView = ({ creatorId }) => {
  const [creator, setCreator] = useState({});
  useEffect(() => {
    getUserById(creatorId)
      .then((res) => {
        setCreator(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="flex-column-group">
      <div className="double-text">
        <span>Хто створив</span>
        <span>Created</span>
      </div>
      <div className="text-grey">
        {creator && `${creator?.name} ${creator?.surname}`}
        {!creator && 'Unknown'}
      </div>
    </div>
  );
};
