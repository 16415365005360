import { InputComponent } from 'component/Input';
import React from 'react';

export const Duration = ({
  tcpFormData,
  setTcpFormData,
  errors,
}) => {
  const handleDuration = (value) => {
    if (/[^0-9]/.test(value)) return;
    if (Number.isNaN(value)) return;
    setTcpFormData((prevFormData) => ({
      ...prevFormData,
      estimate_days: Number(value),
    }));
  };

  return (
    <div className="flex-column-group">
      <div className={`double-text ${errors
        ? 'error'
        : ''}`}
      >
        <span>Очікувана тривалість, (дні)</span>
        <span>Expected Duration, (days)</span>
      </div>
      <div className="text-grey">
        <InputComponent
          id="duration"
          name="duration"
          value={tcpFormData?.estimate_days}
          handleInput={handleDuration}
        />
      </div>
    </div>
  );
};

export const DurationView = ({ duration }) => (
  <div className="flex-column-group">
    <div className="double-text">
      <span>Очікувана тривалість</span>
      <span>Expected Duration</span>
    </div>
    <div className="text-grey">
      {duration}
      {' '}
      days
    </div>
  </div>
);
