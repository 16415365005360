import { React, useEffect, useState } from 'react';
import { getRegion } from 'api/LocationAPI';
import { SelectComponent } from 'component/Select';

export const RegionFilter = (props) => {
  const [options, setOptions] = useState([]);

  const getRegionOptions = () => {
    getRegion()
      .then((res) => {
        const resOptions = res.data.map((item) => ({
          value: `${item.name} / ${item.eng_name}`,
          label: `${item.name} / ${item.eng_name}`,
        }));

        setOptions(resOptions);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getRegionOptions();
  }, []);

  return (
    <div className="flex-column-group">
      <span>Sort by region</span>
      <SelectComponent
        className="full"
        value={props.regionFilter
          ? { value: props.regionFilter, label: props.regionFilter }
          : null}
        options={options}
        handleSelect={(e) => {
          props.setRegionFilter(e);
          props.setDistrictFilter(null);
          props.setLocalCommunityFilter(null);
          props.setSettlementFilter(null);
        }}
        isMulti={false}
      />
    </div>
  );
};
