import React, { useState } from 'react';
import { formatArea, formatEOFound, formatPeriod, ValidationStatusComponent } from 'utils/Helper';
import { Table, TableHeader, TableRow } from 'component/Table';
import { ReportActions } from '../ReportActions';
import { WEEKLY_HEADERS } from 'consts/ReportHeaders';
import { ReactComponent as SortByIcon } from 'assets/images/icons/Sort-by2.svg';

export const WeeklyTable = ({
  reports,
  setLoader,
  setReportsPageList,
  onLoadMore,
  hasMore,
  onSort,
  currentPage,
  onPageChange
}) => {
  const [rotations, setRotations] = useState({});
  const [sortDirection, setSortDirection] = useState({});
  const [lastClickedIcon, setLastClickedIcon] = useState(null);

  const handleSortIconClick = async (key) => {
    try {
      setLoader(true);
      setReportsPageList([]);
      onPageChange(1);

      const currentDirection = sortDirection[key] || false;
      const newDirection = !currentDirection;
      setSortDirection(prev => ({
        ...prev,
        [key]: newDirection,
      }));

      const ordering = newDirection ? key : `-${key}`;
      await onSort(ordering);

      const currentRotation = rotations[key] || 0;
      const newRotation = currentRotation + 180;

      if (lastClickedIcon && lastClickedIcon !== key) {
        setRotations(prevRotations => ({
          ...prevRotations,
          [lastClickedIcon]: 0,
        }));
      }

      setRotations(prevRotations => ({
        ...prevRotations,
        [key]: newRotation,
      }));

      setLastClickedIcon(key);
    } catch (error) {
      console.error('Error during sorting:', error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="eore-table">
      <Table>
        <TableHeader>
          {WEEKLY_HEADERS?.map((header, index) => (
            <th
              key={index}
              className={`text-with-icon ${header.style ? '' : ''}`}
              style={header.style || {}}
            >
              {header.key && header.condition && (
                <SortByIcon
                  className="sort-icon"
                  style={{ transform: `rotate(${rotations[header.key] || 0}deg)` }}
                  onClick={() => handleSortIconClick(header.key)}
                />
              )}
              {header.label}
            </th>
          ))}
        </TableHeader>
        <tbody>
          {reports?.length === 0 && (
            <TableRow>
              <td>Дані відсутні</td>
            </TableRow>
          )}
          {reports?.map((item) => (
            <TableRow key={item?.id}>
              <td>{item?.created_at ? new Date(item?.created_at)?.toLocaleDateString() : '-'}</td>
              <td>{item?.internal_id || '-'}</td>
              <td>{formatPeriod(item?.period)}</td>
              <td>{item?.type || '-'}</td>
              <td>{formatArea(item?.area, item?.cleared_area)}</td>
              <td style={{ textAlign: 'start' }}>{formatEOFound(item?.eo_found)}</td>
              <td>
                {item?.validation_status?.value && (
                  <ValidationStatusComponent status={item?.validation_status} />
                )}
              </td>
              <td aria-label="actions">
                <ReportActions
                  reportId={item?.id}
                  clearanceId={item?.clearance_task_id}
                  setLoader={setLoader}
                  setReportsPageList={setReportsPageList}
                  validationStatus={item?.validation_status?.value}
                  isCurrentValidationRole={item?.is_current_validation_role}
                  mode="weeklyTab"
                />
              </td>
            </TableRow>
          ))}
          {hasMore && (
            <div className="loadMore-div">
              <button
                className="loadMore-button"
                onClick={onLoadMore}
              >
                Load more...
              </button>
            </div>
          )}
        </tbody>
      </Table>
    </div>
  );
};

const WeeklyTab = ({
  setLoader,
  weeklyList,
  setWeeklyList,
  currentPage,
  onPageChange,
  onFetch
}) => {
  const [sortParams, setSortParams] = useState('');

  const handleSort = (ordering) => {
    onPageChange(1);
    setSortParams(ordering);
    setLoader(true);
    return onFetch(1, ordering)
      .finally(() => {
        setLoader(false);
      });
  };

  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    onPageChange(nextPage);
    setLoader(true);
    onFetch(nextPage, sortParams)
    .finally(() => {
      setLoader(false);
    });
  };

  return (
    <WeeklyTable
      reports={weeklyList?.results || []}
      hasMore={!!weeklyList?.next}
      onLoadMore={handleLoadMore}
      setLoader={setLoader}
      setReportsPageList={setWeeklyList}
      onSort={handleSort}
      currentPage={currentPage}
      onPageChange={onPageChange}
    />
  );
};

export default WeeklyTab;