import { SwitherComponent } from 'component/Swither';
import React from 'react';
import { UPDATE_HAND_OVER } from 'reducers/dailyFormActions';

export const HandOverSwitcher = ({ formData, dispatch }) => {
  const handleSwitcher = () => {
    dispatch({
      type: UPDATE_HAND_OVER
    });
  };

  return (
    <div className="flex-group hand-over-switcher">
      <SwitherComponent
        labelRight="Hand over"
        checked={formData?.info?.hand_over}
        handleSwitcher={handleSwitcher}
      />
      <div className="line" />
    </div>
  );
};
