import React from 'react';
import { getEOREIndirectBeneficiariesSum } from 'utils/Helper';

export const IndirectBeneficiariesView = (props) => {
  const { formData } = props;
  const totalIndirectBeneficiaries = getEOREIndirectBeneficiariesSum(formData
    ?.info?.session_information?.indirect_beneficiaries);

  return (
    <div
      className="total-beneficiaries-container"
    >
      <NGCAMaleView formData={formData} />
      <NGCAFemaleView formData={formData} />
      <NGCABoysView formData={formData} />
      <NGCAGirlsView formData={formData} />
      <div className="total-beneficiaries">
        <div>
          Total:
        </div>
        <div>
          {totalIndirectBeneficiaries}
        </div>
      </div>
    </div>
  );
};

export const NGCAMaleView = (props) => {
  const { formData } = props;

  return (
    <div className="flex-column-group">
      <div className="double-text">
        <span>NGCA Чоловік</span>
        <span>NGCA Male</span>
      </div>
      {formData.info.session_information.indirect_beneficiaries
        .male_adult_direct_additional_count}
    </div>
  );
};

export const NGCAFemaleView = (props) => {
  const { formData } = props;

  return (
    <div className="flex-column-group">
      <div className="double-text">
        <span>NGCA Жінка</span>
        <span>NGCA Female</span>
      </div>
      {formData.info.session_information.indirect_beneficiaries
        .female_adult_direct_additional_count}
    </div>
  );
};

export const NGCABoysView = (props) => {
  const { formData } = props;

  return (
    <div className="flex-column-group">
      <div className="double-text">
        <span>GCA Хлопці</span>
        <span>GCA Boys</span>
      </div>
      {formData.info.session_information.indirect_beneficiaries
        .male_child_direct_additional_count}
    </div>
  );
};

export const NGCAGirlsView = (props) => {
  const { formData } = props;
  return (
    <div className="flex-column-group">
      <div className="double-text">
        <span>NGCA Дівчата</span>
        <span>NGCA Girls</span>
      </div>
      {formData.info.session_information.indirect_beneficiaries
        .female_child_direct_additional_count}
    </div>
  );
};
