export const DAILY_WEEKLY_HEADERS = [
  {
    label: 'Date',
    key: 'report_date',
    condition: () => true
  },
  {
    label: 'Team',
    key: 'team_id',
    condition: () => true
  },
  {
    label: 'Task ID',
    key: 'imsma_id',
    condition: () => true
  },
  {
    label: 'Task Type',
    key: 'task_type',
  },
  // {
  //   label: 'Period',
  //   key: 'type',
  //   condition: () => true
  // },
  {
    label: (
      <>
        Area (m²)
        <br />
        Cleared area (m²)
      </>
    ),
    key: 'area',
  },
  {
    label: 'EO found',
    key: 'imsma_id',
    style: { textAlign: 'start' }
  },
  {
    label: 'Status',
    condition: () => true
  },
  {
    label: 'Action',
    condition: () => true
  },
];


export const NTS_HA_HEADERS = [
  {
    label: 'Date',
    key: 'report_date',
    condition: () => true
  },
  {
    label: 'KOTTG',
    key: 'settlement_code',
    condition: () => true,
    style: { flex: '100px' },
  },
  {
    label: 'Type',
    condition: () => true
  },
  {
    label: 'Name',
    key: 'report_name',
    condition: () => true
  },
  {
    label: 'Area',
    key: 'report_area',
    condition: (activeTabIndex) => activeTabIndex === 1,
    style: { flex: '200px' },
  },
  {
    label: 'IMSMA ID',
    key: 'imsma_id',
    condition: () => true,
    style: { flex: '200px' },
  },
  {
    label: 'Status',
    condition: () => true
  },
  {
    label: 'Action',
    condition: () => true
  },
];

export const CLEARANCE_TASK_HEADERS = [
  { label: 'Name', key: 'hazard_name', condition: () => true },
  { label: 'Internal ID', key: 'internal_id', condition: () => true },
  { label: 'Start date', key: 'start_date',condition: () => true },
  { label: 'Donor', key: 'donor' ,condition: () => true},
  { label: 'EO found', key: 'eo_found' },
  {
    label: (
      <>
        Area (m²)<br />
        Cleared area (m²)
      </>
    ),
    key: 'ha_area'
  },
  { label: 'Files', key: 'files_progress' },
  { label: 'Status', key: 'validation_status' },
  { label: 'Action', key: 'actions' },
];

export const DAILY_HEADERS = [
  { label: 'Date', key: 'report_date',condition: () => true},
  { label: 'Team', key: 'team_id',condition: () => true },
  { label: 'Task Id', key: 'imsma_id', condition: () => true},
  { label: 'Task Type', key: 'task_type'},
  {
    label: (
      <>
        Area (m²)<br />
        Cleared area (m²)
      </>
    ),
    key: 'area_cleared_area'
  },
  { label: 'EO found', key: 'eo_found' },
  { label: 'Status', key: 'validation_status' },
  { label: 'Action', key: 'actions' },
];

export const WEEKLY_HEADERS = [
  { label: 'Date', key: 'created_at', condition: () => true },
  { label: 'Internal ID', key: 'internal_id', condition: () => true },
  { label: 'Period', key: 'earlier_report_date' ,condition: () => true},
  {label: 'Task Type', key: 'type'},
  {
    label: (
      <>
        Area (m²)<br />
        Cleared area (m²)
      </>
    ),
    key: 'area_cleared_area'
  },
  { label: 'EO found', key: 'eo_found' },
  { label: 'Status', key: 'validation_status' },
  { label: 'Action', key: 'actions' },
];