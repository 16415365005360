import {
  React,
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { axiosWrapper } from './Helper';
import { getCookie } from './Cookies';

const MapboxContext = createContext();

export const MapboxProvider = ({ children }) => {
  const [token, setToken] = useState(null);

  const getMapboxToken = () => {
    if (!getCookie('access_token')) return;
    axiosWrapper(() => {
      axios.get('/core/mapbox-token/')
        .then((res) => {
          setToken(res.data.mapbox_token);
        })
        .catch((err) => {
          toast.error('Error Mapbox token');
          console.error(err);
          setToken(null);
        });
    });
  };

  useEffect(() => {
    getMapboxToken();
  }, []);

  const mapboxValue = useMemo(() => ({
    token, getMapboxToken, setToken,
  }), [token, getMapboxToken, setToken]);

  return (
    <MapboxContext.Provider value={mapboxValue}>
      {children}
    </MapboxContext.Provider>
  );
};

export const useMapbox = () => useContext(MapboxContext);
