import React, { useEffect, useState } from 'react';
import 'assets/styles/pages/task-clearance-plan.scss';

import { ToastContainer } from 'react-toastify';
import { tcpForm } from 'consts/TCPForm';
import { getTCPFormById } from 'api/TCPApi';
import { useParams } from 'react-router-dom';
import { getHAById } from 'api/HaApi';
import { Loader } from 'component/Loader';
import { TCPNavigationStatuses } from 'consts/TCP';
import { TaskClearancePlanForm } from '../Create/TaskClearancePlan';
import { NavigationTabLinks } from '../Tabs/NavigationLinks';

export const TaskClearancePlanEditPage = () => {
  const [formData, setFormData] = useState(null);
  const [tcpFormData, setTcpFormData] = useState(tcpForm);
  const [originalCrosshair, setOriginalCrosshair] = useState(null);
  const [navigationStatuses, setNavigationStatuses] = useState(TCPNavigationStatuses);
  const [loader, setLoader] = useState(false);
  const { clearanceId } = useParams();

  useEffect(() => {
    setLoader(true);
    setNavigationStatuses((prev) => ({
      ...prev,
      tcpCreate: 'active',
      daily: 'inactive',
      weekly: 'inactive',
      completed: 'inactive',
      done: 'inactive',
    }));
  }, []);

  useEffect(() => {
    getTCPFormById(clearanceId)
      .then((response) => {
        response.data.methods = response.data.methods.map((method) => method?.id);
        response.data.related_users = response.data?.related_users?.map((user) => user?.id);
        setTcpFormData(response.data);
        setOriginalCrosshair(response.data?.crosshair);
        getHAById(response.data?.ha_report)
          .then((res) => {
            setFormData(res.data);
            setLoader(false);
          })
          .catch((error) => {
            console.error(error);
            setLoader(false);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <section>
      <div className="flex-column-group">
        <NavigationTabLinks
          mode="edit"
          id={tcpFormData.id}
          statuses={navigationStatuses}
        />
        <TaskClearancePlanForm
          formData={formData}
          setFormData={setFormData}
          tcpFormData={tcpFormData}
          setTcpFormData={setTcpFormData}
          setLoader={setLoader}
          originalCrosshair={originalCrosshair}
        />
      </div>
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};
