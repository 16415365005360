import { AccordionHeaderTitle } from 'component/Accordion';
import React from 'react';
import { AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { PercentBeforeSession } from './BeforeSession';
import { PercentAfterSession } from './AfterSession';

export const FinalIndicatorsInfo = (props) => {
  const { formData, setFormData } = props;

  const handlePercent = (key, value) => {
    if (Number(value) < 0 || Number(value) > 100) return;
    setFormData((prev) => ({
      ...prev,
      info: {
        ...prev.info,
        final_indicators: {
          ...prev.info.final_indicators,
          [key]: Number(value),
        },
      },
    }));
  };

  return (
    <>
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionHeaderTitle
            accordionId="3"
            validationForm={props.validationForm}
            block="sessionInfo"
            titleUA={(
              <div className="flex-group">
                <span>Фінальні показники</span>
                <span className="star"></span>
              </div>
            )}
            titleEN="Final indicators"
          />
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <PercentBeforeSession
          formData={formData}
          handlePercent={handlePercent}
        />
        <PercentAfterSession
          formData={formData}
          handlePercent={handlePercent}
        />
      </AccordionItemPanel>
    </>
  );
};
