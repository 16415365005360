import { SwitherComponent } from 'component/Swither';
import { Table, TableHeader, TableRow } from 'component/Table';
import { CategoryTranslations } from 'consts/Daily';
import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowDownSvg } from 'assets/images/icons/down-small.svg';
import { EoCategoryFiles, EoCategoryFilesView } from './Files';
import { UPDATE_EVIDENCE_COORDINATES, UPDATE_EVIDENCE_SWITCHER, UPDATE_EVIDENCE_UNIT } from 'reducers/dailyFormActions';

export const TableData = ({
  formData, dispatch, category, subCategory, map
}) => {

  const validateCoordinate = (value) => {
    const normalizedValue = value.replace(',', '.');

    const regex = /^-?\d*\.?\d{0,6}$/;

    return value === '' || regex.test(normalizedValue) ? normalizedValue : false;
  };

  const validateCount = (value) => {
    const normalizedValue = value.replace(',', '.');

    const regex = /^\d*\.?\d{0,2}$/;

    return value === '' || regex.test(normalizedValue) ? normalizedValue : false;
  };

  const handleCoordinates = (e, index) => {
    const { name, value } = e.target;
    let validValue;

    if (value.length > 1 && value.startsWith('0') && !value.startsWith('0.')) {
      const newValue = value.substring(1);

      if (name === 'count') {
        validValue = validateCount(newValue);
      } else {
        validValue = validateCoordinate(newValue);
      }
    } else {
      if (name === 'count') {
        validValue = validateCount(value);
      } else {
        validValue = validateCoordinate(value);
      }
    }

    if (validValue !== false) {
      dispatch({
        type: UPDATE_EVIDENCE_COORDINATES,
        payload: {
          category,
          index,
          name,
          value: validValue
        }
      });
    }
  };


  const handleOnBlur = () => { };
 useEffect(() => {
    if (map && formData?.info?.eo_found_categories?.[category]?.evidences) {
      handleOnBlur();
    }
  }, [map, formData?.info?.eo_found_categories?.[category]?.evidences]);
  const handleSwitcher = (isChecked, index) => {
    dispatch({
      type: UPDATE_EVIDENCE_SWITCHER,
      payload: {
        category,
        index,
        isChecked
      }
    });
  };

  const handleUnit = (unit, index) => {
    dispatch({
      type: UPDATE_EVIDENCE_UNIT,
      payload: {
        category,
        index,
        unit
      }
    });
  };

  return (
    <div className="rounded-bg-double-grey">
      <Table className="table-lines-between">
        <TableHeader>
          <th
            className="double-text"
            style={{
              flex: '100px',
            }}
          >
            <div>Тип доказу</div>
            <div>Type of evidence</div>
          </th>
          <th aria-label="line"><div className="line" /></th>
          <th>Long/UTM X</th>
          <th aria-label="line"><div className="line" /></th>
          <th>Lat/UTM Y</th>
          <th aria-label="line"><div className="line" /></th>
          <th
            style={{
              flex: '300px',
            }}
          >
            Безпека перевезення / Save to move
          </th>
        </TableHeader>
        <tbody>
          {formData?.info?.eo_found_categories?.[category]?.evidences
            && formData?.info?.eo_found_categories?.[category]?.evidences
              ?.map((evidence, index) => (
                evidence.item === subCategory
              && (
                <TableRow key={index}>
                  <td
                    className="flex-group"
                    style={{
                      flex: '100px',
                    }}
                  >
                    <span>{CategoryTranslations[category][subCategory]}</span>
                  </td>
                  <td aria-label="line"><div className="line" /></td>
                  <td aria-label="input">
                    <input
                      id={`${index}-long`}
                      className="text-center"
                      name="long"
                      placeholder="0"
                      value={evidence.long}
                      autoComplete="off"
                      onChange={(e) => {
                        handleCoordinates(e, index);
                      }}
                      onBlur={() => handleOnBlur()}
                    />
                  </td>
                  <td aria-label="line"><div className="line" /></td>
                  <td aria-label="input">
                    <input
                      id={`${index}-lat`}
                      className="text-center"
                      placeholder="0"
                      name="lat"
                      value={evidence.lat}
                      autoComplete="off"
                      onChange={(e) => {
                        handleCoordinates(e, index);
                      }}
                      onBlur={() => handleOnBlur(index)}
                    />
                  </td>
                  <td aria-label="line"><div className="line" /></td>
                  <td
                    aria-label="saveToMove"
                    style={{ flex: '300px' }}
                  >
                    <div className="flex-group">
                      <SwitherComponent
                        labelLeft="Marked"
                        labelRight="Moved"
                        checked={evidence.save_to_move}
                        handleSwitcher={(checked) => handleSwitcher(checked, index)}
                      />
                      <input
                        id={`${index}-count`}
                        className="number"
                        name="count"
                        placeholder="0"
                        value={evidence.count}
                        autoComplete="off"
                        onChange={(e) => {
                          handleCoordinates(e, index);
                        }}
                        onBlur={() => handleOnBlur()}
                      />
                      <UnitSelect
                        unit={evidence.unit}
                        changeUnit={(unit) => handleUnit(unit, index)}
                      />
                    </div>
                  </td>
                </TableRow>
              )
              ))}
        </tbody>
      </Table>
      <EoCategoryFiles
        formData={formData}
        dispatch={dispatch}
        category={category}
        subCategory={subCategory}
      />
    </div>
  );
};

const UnitSelect = ({ unit, changeUnit }) => {
  const [selectOpen, setSelectOpen] = useState(false);

  return (
    <div className="unit-block">
      <div className="flex-group">
        <span>{unit}</span>
        <ArrowDownSvg
          className={`arrow-down ${selectOpen ? 'open' : ''}`}
          onClick={() => setSelectOpen(!selectOpen)}
        />
        {selectOpen && (
          <div className="select-unit">
            <span
              onClick={() => {
                changeUnit('kg');
                setSelectOpen(false);
              }}
            >
              kg
            </span>
            <span
              onClick={() => {
                changeUnit('unit');
                setSelectOpen(false);
              }}
            >
              unit
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export const TableDataView = ({ formData, category, subCategory }) => (
  <div className="rounded-bg-double-grey blue-grey">
    <Table className="bg-one-ton">
      <TableHeader>
        <th
          className="double-text"
          style={{
            flex: '100px',
          }}
        >
          <div>Тип доказу</div>
          <div>Type of evidence</div>
        </th>
        <th className="double-text">Long/UTM X</th>
        <th className="double-text">Lat/UTM Y</th>
        <th className="double-text">
          <div>Промарковано</div>
          <div>Marked</div>
        </th>
        <th className="double-text">
          <div>Перевезено</div>
          <div>Moved</div>
        </th>
      </TableHeader>
      <tbody>
        {!formData?.info?.eo_found_categories?.[category]?.evidences
          ?.some((evidence) => evidence.item === subCategory) && (
            <TableRow>
              <td className="empty">
                No evidences for
                {' '}
                {CategoryTranslations[category][subCategory]}
              </td>
            </TableRow>
        )}
        {formData?.info?.eo_found_categories?.[category]?.evidences
          && formData?.info?.eo_found_categories?.[category]?.evidences?.map((evidence, index) => (
            evidence.item === subCategory
            && (
              <TableRow key={index}>
                <td
                  className="flex-group"
                  style={{
                    flex: '100px',
                  }}
                >
                  <span>{CategoryTranslations[category][subCategory]}</span>
                </td>

                <td aria-label="input">
                  <span className="text-center">{evidence.long || '-'}</span>
                </td>

                <td aria-label="input">
                  <span className="text-center">{evidence.lat || '-'}</span>
                </td>

                <td>
                  {evidence.save_to_move ? (
                    <span>{evidence.count}{evidence.unit ? ` ${evidence.unit}` : ''}</span>
                  ) : (
                    <span>0</span>
                  )}
                </td>
                <td>
                  {evidence.save_to_move ? (
                    <span>0</span>
                  ) : (
                    <span>{evidence.count}{evidence.unit ? ` ${evidence.unit}` : ''}</span>
                  )}
                </td>
              </TableRow>
            )))}
      </tbody>
    </Table>
    <EoCategoryFilesView
      files={formData?.info?.eo_found_categories?.[category]?.links}
      category={category}
      subCategory={subCategory}
    />
  </div>
);
