import { sendClearanceShapeFile } from 'api/TCPApi';
import { StatusArrows, StatusLoading } from 'component/FileUploadMultiple';
import { UploadButton } from 'component/UploadButton';
import React, { useEffect, useState } from 'react';

export const TurnPointsFile = ({
  files,
  setFiles,
  setTurnPointsFromShape,
  links,
  setLinks,
}) => {
  const [fileStatus, setFileStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (links[0]?.turn_points) {
      setFileStatus(true);
    }
  }, []);

  const handleFileChange = (e) => {
    setLoading(true);
    setFiles([...e.target.files]);
    const formData = new FormData();
    formData.append('turn_points', e.target.files[0]);
    sendClearanceShapeFile(formData)
      .then((res) => {
        if (res.status === 200) {
          setTurnPointsFromShape(res.data);
          setLinks((prev) => [...prev, { turn_points: e.target.files[0]?.name }]);
          setLoading(false);
          setFileStatus(true);
        }
      })
      .catch((error) => {
        console.error(error);
        setFileStatus(false);
        setLoading(false);
      });
  };

  return (
    <div id="turn-points-file">
      <ul className="files-wrapper">
        {!files?.length
          && !links?.find((item) => item.hasOwnProperty('turn_points'))?.turn_points
          && <span className="text no-files">Information about attached files</span>}
        {!files?.length
          && links?.find((item) => item.hasOwnProperty('turn_points'))?.turn_points
          && (
            <li
              style={{ color: '#00B1B7' }}
            >
              <StatusArrows color="#00B1B7" />
              <span className="cut-text">{links?.find((item) => item.hasOwnProperty('turn_points'))?.turn_points}</span>
            </li>
          )}
        {files?.map((file, i) => (
          <li
            key={i}
            style={{ color: fileStatus ? '#00B1B7' : '#D26E6E' }}
          >
            {fileStatus && !loading && <StatusArrows color="#00B1B7" />}
            {!fileStatus && !loading && <StatusArrows color="#D26E6E" />}
            {loading
              && <StatusLoading />}
            <span className="cut-text">{file.name}</span>
          </li>
        ))}
      </ul>
      <div className="text-center">
        <UploadButton
          id="turn-points-button"
          handleUploadButton={handleFileChange}
          accept=".zip, .rar, .7z"
        />
      </div>
    </div>
  );
};
