import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { RadioButton } from 'component/RadioButton';
import { SelectComponent } from 'component/Select';
import { months } from 'consts/Months';

export const SeasonalAccessibility = (props) => {
  const geographicDescription = props.formData.info.geographic_and_terrain_description;

  const handleGeographicDescription = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        geographic_and_terrain_description: {
          ...prevFormData.info.geographic_and_terrain_description,
          seasonal_accessibility: value,
          seasonal_accessibility_months: {
            from: '',
            to: '',
          },
        },
      },
    }));
  };

  const monthsOptions = months.map((item) => ({
    value: item,
    label: item,
  }));

  const getMonthOption = (month) => {
    if (!month) return null;
    return {
      value: month,
      label: month,
    };
  };

  const handleSeasonMonth = (value, type) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        geographic_and_terrain_description: {
          ...prevFormData.info.geographic_and_terrain_description,
          seasonal_accessibility_months: {
            ...prevFormData.info.geographic_and_terrain_description.seasonal_accessibility_months,
            [type]: value,
          },
        },
      },
    }));
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>6.4</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Сезонна доступність"
      titleEN="Seasonal accessibility"
      className={props.errors?.geographic_and_terrain_description?.seasonal_accessibility ? 'error' : ''}
      rightContentClassName="w-60"
      children={(
        <div className="flex-group flex-wrap">
          <div className="text-left">
            <RadioButton
              label="Сезонність з (місяць) по (місяць) \
                        Seasonal from (month) to (month)"
              checked={geographicDescription.seasonal_accessibility.includes('seasonal_from_month_to_month')}
              id="seasonal_from_month_to_month"
              value="seasonal_from_month_to_month"
              name="seasonal_accessibility"
              disabled={false}
              handleInput={handleGeographicDescription}
            />
            {geographicDescription.seasonal_accessibility.includes('seasonal_from_month_to_month')
              && (
                <div className="flex-group">
                  <SelectComponent
                    options={monthsOptions}
                    placeholder="Select..."
                    value={getMonthOption(geographicDescription
                      .seasonal_accessibility_months?.from)}
                    handleSelect={(value) => {
                      handleSeasonMonth(value, 'from');
                    }}
                  />
                  <SelectComponent
                    options={monthsOptions}
                    placeholder="Select..."
                    value={getMonthOption(geographicDescription.seasonal_accessibility_months?.to)}
                    handleSelect={(value) => {
                      handleSeasonMonth(value, 'to');
                    }}
                  />
                </div>
              )}
          </div>

          <RadioButton
            label="Позасезонність \ All-season"
            checked={geographicDescription.seasonal_accessibility.includes('all_season')}
            id="all_season"
            value="all_season"
            name="seasonal_accessibility"
            disabled={false}
            handleInput={handleGeographicDescription}
          />

        </div>
      )}
    />
  );
};
