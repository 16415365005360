import { Table, TableHeader, TableRow } from 'component/Table';
import 'assets/styles/pages/donors.scss';
import { React, useEffect, useState } from 'react';
import { ReactComponent as ViewIcon } from 'assets/images/icons/Eye-on.svg';
import { Link } from 'react-router-dom';
import { axiosWrapper, getPageValue } from 'utils/Helper';
import { getDonors } from 'api/DonorsApi';
import { toast } from 'react-toastify';
import { useUser } from 'routes/UserContext';
import { NewDonor } from './NewDonor';

export const Donors = ({ setLoader }) => {
  const [donorName, setDonorName] = useState(null);
  const [donorDescription, setDonorDescription] = useState(null);
  const [donors, setDonors] = useState([]);
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(false);
  const { user } = useUser();

  const getDonorsList = (pageAfterCreate = false) => {
    axiosWrapper(() => {
      const donorPage = pageAfterCreate ? pageAfterCreate : page;
      getDonors(donorPage)
        .then((res) => {
          if (pageAfterCreate) {
            setDonors(res.data.results);
          } else {
            setDonors((prevData) => [...prevData, ...res.data.results]);
          }
          setNext(res.data.next);
          if (res.data.next) {
            setPage(Number(getPageValue(res.data.next)));
          }
          setLoader(false);
        })
        .catch((err) => {
          console.error(err);
          toast.error('Donors are not loaded');
          setDonors([]);
        })
        .finally(() => {
          setLoader(false);
        });
    });
  };

  useEffect(() => {
    setLoader(true);
    getDonorsList();
  }, []);

  return (
    <div className="donors flex-column-group">
      <Table>
        <TableHeader>
          <th>Name</th>
          <th>Token</th>
          <th>Description</th>
          <th>Action</th>
        </TableHeader>
        {!donors.length
          && (
            <TableRow>
              <td>No data</td>
            </TableRow>
          )}
        {donors.map((donor, index) => (
          <TableRow key={index}>
            <td>{donor.name}</td>
            <td>{donor.token}</td>
            <td>{donor.description}</td>
            <td
              className="icons"
              aria-label="link"
            >
              <Link
                to={`/donors/${donor.token}`}
                target="_blank"
                className="donors-link"
              >
                <ViewIcon
                  className="view-icon"
                />
              </Link>
            </td>
          </TableRow>
        ))}
        {next && (
          <div className="loadMore-div">
            <button
              className="loadMore-button"
              onClick={() => {
                setLoader(true);
                getDonorsList();
              }}
            >
              Load more...
            </button>
          </div>
        )}
      </Table>
      {user?.permissions?.add_settings && (
        <div className="donors-footer">
          <NewDonor
            name={donorName}
            setName={setDonorName}
            description={donorDescription}
            setDescription={setDonorDescription}
            setLoader={setLoader}
            getDonorsList={getDonorsList}
          />
        </div>
      )}

    </div>
  );
};
