import moment from 'moment';

export const DailyForm = {
  info: {
    clearance_task_id: null,
    utm_zone: '',
    donor: null,
    id: null,
    draft: true,
    location: {
      region: null,
      district: null,
      local_community: null,
      settlement_name: null,
    },
    coordinates: [],
    // dms: {
    //   report_date: moment().format('DD.MM.YYYY'),
    //   organization: 'FSD',
    //   team_id: null,
    //   reporter_name: null,
    //   reporter_position: null,
    //   team_activity: null, // 'field_work' || 'maintance_day'
    //   remarks: null,
    // },
    // field_work: {
    //   manual: false,
    //   clearance_task_imsma_id: null, // tcp imsma_id
    //   task_id: null, // tcp imsma_id
    //   task_id_other: null,
    //   task_type: null, // 'battle_area_clearance' ||
    //   // 'manual_clearance' || 'technical_survey' || 'marking'
    //   number_of_deminers: 0,
    //   cleared_area: null,
    //   eo_found: false,
    //   remarks: '',
    // },
    // marking: {
    //   area: '',
    //   coordinates: {
    //     items: [],
    //   },
    //   files: [],
    //   remarks: '',
    // },
    // eo_found_categories: {
    //   mines: {
    //     items: [],
    //     evidences: [],
    //   },
    //   uxos: {
    //     items: [],
    //     evidences: [],
    //     links: [],
    //   },
    //   ied: {
    //     items: [],
    //     evidences: [],
    //     links: [],
    //   },
    //   saa: {
    //     items: [],
    //     evidences: [],
    //     links: [],
    //   },
    // },
    hand_over: false,
    hand_over_block: {
      date: moment().format('DD.MM.YYYY'),
      name: '',
      files: [],
      remarks: '',
    },
    photos_bool: false,
    photos_block: [],
  },
};

export const EOFoundCategories = {
  mines: {
    items: [
      {
        name: 'ap',
        count: 0,
      },
      {
        name: 'ap_controlled',
        count: 0,
      },
      {
        name: 'av',
        count: 0,
      },
      {
        name: 'bloody_trap',
        count: 0,
      },
      {
        name: 'anti_ship',
        count: 0,
      },
    ],
    evidences: [],
    links: [],
  },
  uxos: {
    items: [
      {
        name: 'mortar',
        count: 0,
      },
      {
        name: 'grenade',
        count: 0,
      },
      {
        name: 'projectile',
        count: 0,
      },
      {
        name: 'aircraft_bomb',
        count: 0,
      },
      {
        name: 'cluster_munition',
        count: 0,
      },
      {
        name: 'submunition',
        count: 0,
      },
      {
        name: 'fuse',
        count: 0,
      },
      {
        name: 'engineering_charges',
        count: 0,
      },
      {
        name: 'torpedo',
        count: 0,
      },
    ],
    evidences: [],
    links: [],
  },
  ied: {
    items: [
      {
        name: 'ap_ied',
        count: 0,
      },
      {
        name: 'av_ied',
        count: 0,
      },
    ],
    evidences: [],
    links: [],
  },
  saa: {
    items: [
      {
        name: 'explosives',
        count: 0,
      },
    ],
    evidences: [],
    links: [],
  },
};

export const CategoryTranslations = {
  mines: {
    mines: 'Mine',
    ap: 'AP',
    ap_controlled: 'AP (controlled)',
    av: 'AV',
    bloody_trap: 'Bloody trap',
    anti_ship: 'Anti-ship',
  },
  uxos: {
    uxos: 'Uxo&axo',
    mortar: 'Mortar',
    grenade: 'Grenade',
    projectile: 'Projectile',
    aircraft_bomb: 'Aircraft bomb',
    cluster_munition: 'Cluster munition',
    submunition: 'Submunition',
    fuse: 'Fuse',
    engeneering_charges: 'Engeneering charges',
    torpedo: 'Torpedo',
  },
  ied: {
    ied: 'IED',
    ap_ied: 'AP IED',
    av_ied: 'AV IED',
  },
  saa: {
    saa: 'SAA',
    explosives: 'Explosives',
  },
};
