import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { BLOCK_TYPES } from 'consts/ReportValidation';

export const useValidationComments = (reportType, reportId) => {
  const blockConfig = BLOCK_TYPES[reportType];

  const initializeState = (blocks) => blocks.reduce((acc, block) => ({ ...acc, [block]: '' }), {});

  const [comments, setComments] = useState([]);
  const [commentForm, setCommentForm] = useState(() => initializeState(blockConfig.blocks));
  const [savedComment, setSavedComment] = useState(() => initializeState(blockConfig.blocks));
  const [commentEntered, setCommentEntered] = useState(() => blockConfig.blocks
    ?.reduce((acc, block) => ({ ...acc, [block]: false }), {}));

  useEffect(() => {
    if (!reportId) return;

    const getAllComments = async () => {
      try {
        const endpoint = blockConfig.endpoint(reportId);
        const { data } = await axios.get(endpoint);
        setComments(data.iterations);
      } catch (error) {
        console.error('Error fetching comments:', error);
        toast.error('Error fetching comments');
      }
    };

    getAllComments();
  }, [reportId, blockConfig]);

  const onSaveComment = (event, block) => {
    const comment = commentForm[block]?.trim();
    if (comment) {
      setCommentEntered((prev) => ({ ...prev, [block]: true }));
      setSavedComment((prev) => ({ ...prev, [block]: comment }));
    } else {
      setCommentEntered((prev) => ({ ...prev, [block]: false }));
    }
  };

  const onDeleteComment = (event, block) => {
    event.preventDefault();
    if (window.confirm('Are you sure you want to delete this comment?')) {
      setSavedComment((prev) => ({ ...prev, [block]: '' }));
      setCommentEntered((prev) => ({ ...prev, [block]: false }));
      setCommentForm((prev) => ({ ...prev, [block]: '' }));
    }
  };

  const handleComments = (block, value) => {
    setCommentForm((prev) => ({ ...prev, [block]: value }));
  };

  const resetCommentForm = () => {
    setCommentForm(initializeState(blockConfig.blocks));
  };

  return {
    comments,
    commentForm,
    savedComment,
    commentEntered,
    onSaveComment,
    onDeleteComment,
    handleComments,
    resetCommentForm,
    blocks: blockConfig.blocks,
  };
};
