import { AccordionBodyItem, AccordionBodyItemView } from 'component/Accordion';
import { SelectComponent } from 'component/Select';
import { useDispatchWithFallback } from 'hooks/useDispatchWthFallback';
import React, { useEffect } from 'react';
import { UPDATE_DMS_FIELD } from 'reducers/dailyFormActions';

export const Organization = ({ formData, dispatch:propsDispatch, hasError }) => {
  const dispatch = useDispatchWithFallback(propsDispatch);

  const options = [
    { value: 'FSD', label: 'FSD' },
  ];
  useEffect(() => {
    if (!formData?.info?.dms?.organization) {
      dispatch({
        type: UPDATE_DMS_FIELD,
        payload: {
          field: 'organization',
          value: options[0]?.value
        }
      });
    }
  }, []);

  const handleInput = (value, label) => {
    dispatch({
      type: UPDATE_DMS_FIELD,
      payload: {
        field: 'organization',
        value: value
      }
    });
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>1.2</span>
        </div>
      )}
      titleUA="Організація"
      titleEN="Organization"
      className={hasError ? 'error error-text' : ''}
      children={(
        <SelectComponent
          options={options}
          value={options.find(
            (option) => option.value === formData?.info?.dms?.organization
          ) || options[0]}
          handleSelect={handleInput}
        />
      )}
    />
  );
};

export const OrganizationView = ({ organization }) => (
  <AccordionBodyItemView
    accordionSubId={1.2}
    titleUA="Організація"
    titleEN="Organization"
    children={organization || '-'}
  />
);
