import { InputComponent } from 'component/Input';
import React from 'react';

export const TaskId = ({
  tcpFormData,
  setTcpFormData,
  errors,
}) => {
  const handleInternalId = (value) => {
    setTcpFormData((prevFormData) => ({
      ...prevFormData,
      internal_id: value,
    }));
  };

  return (
    <div className="flex-column-group">
      <div className={`double-text ${errors
        ? 'error'
        : ''}`}
      >
        <span>ID завдання</span>
        <span>Task ID</span>
      </div>
      <div className="text-grey">
        <InputComponent
          id="task-id"
          name="task-id"
          value={tcpFormData?.internal_id}
          handleInput={handleInternalId}
        />
      </div>
    </div>
  );
};

export const TaskIdView = ({ taskId }) => (
  <div className="flex-column-group">
    <div className="double-text">
      <span>ID завдання</span>
      <span>Task ID</span>
    </div>
    <div className="text-grey">
      {taskId}
    </div>
  </div>
);
