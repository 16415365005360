import React, { useEffect, useState } from 'react';
import { AccordionHeaderUserSettings } from 'component/Accordion';
import { InputComponent } from 'component/Input';
import {
  AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel,
} from 'react-accessible-accordion';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as SaveIcon } from 'assets/images/icons/save-1.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/delete_24px.svg';
import { ReactComponent as AddIcon } from 'assets/images/icons/add_24px.svg';

import { getAllUsersForClearanceMethodType, getUserById } from 'api/UserAPI';
import { ClipLoader } from 'react-spinners';
import { SelectComponent } from 'component/Select';
import { UserLabel } from 'pages/Profile/Notifications/Table';
import { toast } from 'react-toastify';
import { useUser } from 'routes/UserContext';
import ReactColorPicker from 'react-input-color';

import { createOrUpdateClearanceMethods, deleteAllUserMethods, deleteMethodById } from 'api/EOREApi';
import { DeleteAllDetailsItemsDialog } from 'component/Dialog';
import 'assets/styles/colorPicker.scss';

const ReportRowClearanceMethodType = ({ setLoader, refreshData }) => {
  const [usersWithMethods, setUsersWithMethods] = useState([]);
  const [activeAccordionId, setActiveAccordionId] = useState(null);
  const [userData, setUserData] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [deletedMethods, setDeletedMethods] = useState({});
  const [loadingMethodsById, setLoadingMethodsById] = useState({});
  const [userSettings, setUserSettings] = useState({});
  const [allUsersWithoutMethods, setAllUsersWithoutMethods] = useState([]);
  const { user } = useUser();

  const [colors, setColors] = useState({});

  const handleColorChange = (userId, methodId, newColor) => {
    const sanitizedColor = newColor.hex.substring(0, 7);

    setColors((prevColors) => ({
      ...prevColors,
      [userId]: {
        ...(prevColors[userId] || {}),
        [methodId]: sanitizedColor,
      },
    }));
  };

  const fetchAllUsersForClearanceMethodType = async () => {
    setLoader(true);
    try {
      const res = await getAllUsersForClearanceMethodType(true);
      setUsersWithMethods(res.data);
      await getAllUsersForClearanceMethodType(false).then((result) => {
        setAllUsersWithoutMethods(result.data);
      });
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchAllUsersForClearanceMethodType();
  }, [refreshData]);

  const handleAccordionToggle = (id) => {
    setActiveAccordionId(id);
    if (!userData[id]) {
      setLoadingMethodsById((prev) => ({ ...prev, [id]: true }));
      getUserById(id).then((res) => {
        setUserData((prev) => ({
          ...prev,
          [id]: res.data,
        }));
        const initialValues = res.data.clearance_methods?.reduce((acc, method) => {
          acc[method.id] = method.name;
          return acc;
        }, {});
        setInputValues((prevValues) => ({
          ...prevValues,
          [id]: initialValues,
        }));
        const initialColors = res.data.clearance_methods?.reduce((acc, method) => {
          acc[method.id] = method.color || '#ffffff';
          return acc;
        }, {});
        setColors((prevColors) => ({
          ...prevColors,
          [id]: initialColors,
        }));
        setLoadingMethodsById((prev) => ({ ...prev, [id]: false }));
      });
    }
  };

  const handleInputChange = (userId, methodId, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [userId]: {
        ...prevValues[userId],
        [methodId]: value,
      },
    }));
  };

  const handleAddMethod = (userId) => {
    const newMethodId = `new-${Date.now()}`;
    setInputValues((prevValues) => ({
      ...prevValues,
      [userId]: {
        ...prevValues[userId],
        [newMethodId]: '',
      },
    }));
    setUserData((prevData) => ({
      ...prevData,
      [userId]: {
        ...prevData[userId],
        clearance_methods: [
          ...(prevData[userId]?.clearance_methods || []),
          { id: newMethodId, name: '' },
        ],
      },
    }));
  };

  const handleDeleteMethod = async (userId, methodId) => {
    const methodIdStr = methodId.toString();
    if (!methodIdStr.startsWith('new-')) {
      try {
        setLoader(true);
        await deleteMethodById(methodId);
        toast.success('Method deleted successfully');
      } catch (error) {
        console.error('Error deleting method:', error);
        toast.error('Failed to delete method');
        return;
      } finally {
        setLoader(false);
      }
    }

    setInputValues((prevValues) => {
      const { [methodId]: _, ...rest } = prevValues[userId];
      return {
        ...prevValues,
        [userId]: rest,
      };
    });

    setDeletedMethods((prevValues) => ({
      ...prevValues,
      [userId]: {
        ...prevValues[userId],
        [methodId]: true,
      },
    }));

    setUserData((prevData) => ({
      ...prevData,
      [userId]: {
        ...prevData[userId],
        clearance_methods: prevData[userId]?.clearance_methods.filter(
          (method) => method?.id !== methodId,
        ),
      },
    }));
  };

  const handleSave = async () => {
    const userId = activeAccordionId;
    const selectedUser = userSettings[userId]?.selectedUser;
    const selectedUserId = selectedUser ? selectedUser.value : userId;

    const payload = Object.keys(inputValues[userId]).map((methodId) => {
      const method = inputValues[userId][methodId];
      const color = colors[userId]?.[methodId] ? colors[userId][methodId].substring(0, 7) : '#ffffff';

      if (deletedMethods[userId] && deletedMethods[userId][methodId]) {
        return {
          id: methodId,
          name: '',
          user: selectedUserId,
          color,
        };
      }
      if (methodId.startsWith('new-')) {
        return {
          name: method,
          user: selectedUserId,
          color,
        };
      }
      return {
        id: methodId,
        name: method,
        user: selectedUserId,
        color,
      };
    });

    try {
      setLoader(true);
      await createOrUpdateClearanceMethods(payload);
      toast.success('Methods saved successfully');
      setDeletedMethods({});
      await fetchAllUsersForClearanceMethodType();
    } catch (error) {
      console.error('Error saving data:', error);
      toast.error('Failed to save methods');
    } finally {
      setLoader(false);
    }
  };

  const convertObjectToArrayUsers = (usersWithoutMethods) => usersWithoutMethods
    ?.map((userObj) => {
      const transformUserToLabel = {
        email: userObj.email,
        id: userObj.id,
        name: userObj.name,
        surname: userObj.surname,
        short_role_name: userObj.role.short_name,
        role_color: userObj.role.color,
      };

      return {
        value: userObj.id,
        label: <UserLabel user={transformUserToLabel} />,
      };
    });

  const handleSelectChange = (userId, newUser) => {
    setUserSettings((prevSettings) => ({
      ...prevSettings,
      [userId]: {
        ...prevSettings[userId],
        selectedUser: newUser,
      },
    }));
  };

  const handleDeleteAllMethods = async (userId) => {
    try {
      setLoader(true);
      await deleteAllUserMethods(userId);
      toast.success('All methods deleted successfully');
      await fetchAllUsersForClearanceMethodType();
    } catch (error) {
      console.error('Error deleting all methods:', error);
      toast.error('Failed to delete all methods');
    } finally {
      setLoader(false);
    }
  };

  return (
    usersWithMethods?.map((userObj) => (
      <AccordionItem
        key={userObj.id}
        uuid={userObj.id}
        onClick={() => handleAccordionToggle(userObj.id)}
      >
        <AccordionItemHeading>
          <AccordionItemButton>
            <AccordionHeaderUserSettings
              title={userObj.role.short_name}
              userName={userObj.name}
              email={userObj.email}
              backgroundColor={userObj.role.color}
            />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className="accordion-validation-panel">
        {loadingMethodsById[userObj.id] ? (
  <div className="block-with-cliploader">
    <ClipLoader
      color="rgba(0, 177, 183, 1)"
      size={20}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  </div>
) : (
  <>
    <span className="validation-flow-title" />
    <div className="handouts-wrapper">
      {userData[userObj.id]?.clearance_methods?.map((method) => (
        <div key={method.id} className="handout-item">
          <InputComponent
            classNameWrapper="w-100"
            style={{
              width: '-webkit-fill-available',
              ...((!method.id.toString().startsWith('new-')) && {
                borderColor: '#ccc',
                '&:hover': {
                  borderColor: '#ccc',
                  outline: 'none',
                  boxShadow: 'none'
                }
              })
            }}
            type="text"
            id={`input-handout-${method.id}`}
            name={`handout-name-${method.id}`}
            value={inputValues[userObj.id]?.[method.id] || ''}
            handleInput={(event) => handleInputChange(userObj.id, method.id, event)}
            disabled={!method.id.toString().startsWith('new-') || !user?.permissions?.add_clearance_method}
          />

          <div className="color-picker-with-input">
            {method.id.toString().startsWith('new-') && user?.permissions?.add_clearance_method ? (
              <ReactColorPicker
                initialValue={colors[userObj.id]?.[method.id] || method.color || '#ffffff'}
                onChange={(newCol) => handleColorChange(userObj.id, method.id, newCol)}
                className="color-picker"
              />
            ) : (
              <div
                className="color-picker color-picker-disabled"
                style={{
                  backgroundColor: colors[userObj.id]?.[method.id] || method.color || '#ffffff',
                  cursor: 'default',
                  border: '1px solid #ccc',
                  width: '40px',
                  height: '38px',
                  display: 'inline-block',
                  borderRadius: '4px',
                }}
              />
            )}
            <input
              type="text"
              value={colors[userObj.id]?.[method.id] || method.color || '#ffffff'}
              onChange={(e) => {
                if (method.id.toString().startsWith('new-') && user?.permissions?.add_clearance_method) {
                  const value = e.target.value.substring(0, 7);
                  handleColorChange(userObj.id, method.id, { hex: value });
                }
              }}
              className="color-picker-input"
              disabled={!method.id.toString().startsWith('new-') || !user?.permissions?.add_clearance_method}
            />
          </div>

          {user?.permissions?.add_clearance_method && (
            <div className="delete-icon">
              <DeleteIcon
                style={{ color: '#868686' }}
                onClick={() => handleDeleteMethod(userObj.id, method.id)}
              />
            </div>
          )}
                </div>
              ))}
              </div>
              <div
                className="add-handout-wrapper"
              >
                {user?.permissions?.add_clearance_method && (
                    <ButtonComponent
                      iconLeft={<AddIcon />}
                      label="Add"
                      handleButton={() => handleAddMethod(userObj.id)}
                    />
                  )}
                <div
                  className="select-wrapper"
                  style={{
                    display: 'flex',
                    marginBottom: '20px',
                  }}
                >
                  <span className="label">User</span>
                  <SelectComponent
                    id="users"
                    name="users"
                    label="User"
                    value={userSettings[userObj.id]?.selectedUser || null}
                    options={convertObjectToArrayUsers(allUsersWithoutMethods)}
                    handleSelect={(val, label) => handleSelectChange(userObj.id, { val, label })}
                    placeholder={(
                      userSettings[userObj.id]?.selectedUser ? (
                        <UserLabel user={userSettings[userObj.id].selectedUser.user} />
                      ) : (
                        <UserLabel user={userObj} />
                      )
                    )}
                  />
                </div>
              </div>
              {user?.permissions?.add_clearance_method && (
                  <>
                    <div
                      className="line"
                      style={{
                        marginBottom: '20px',
                      }}
                    />
                    <div className="buttons-group-end">
                      <DeleteAllDetailsItemsDialog
                        handleDeleteAllDetailsItems={handleDeleteAllMethods}
                        user={userObj}
                        type="methods"
                      />
                      <ButtonComponent
                        iconLeft={<SaveIcon />}
                        label="Save"
                        handleButton={handleSave}
                      />
                    </div>
                  </>
                )}
            </>
          )}
        </AccordionItemPanel>
      </AccordionItem>
    ))
  );
};

export default ReportRowClearanceMethodType;
