import React from 'react';
import { ReactComponent as ChatIcon } from 'assets/images/icons/chat.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/delete_24px.svg';
import { InputComponent } from 'component/Input';
import { toast } from 'react-toastify';
import axios from 'axios';

export const CommentBlock = ({
  comments,
  savedComment,
  commentForm,
  commentEntered,
  block,
  onDeleteComment,
  handleComments,
  onSaveComment,
}) => (
  <>
    <CommentDisplay comments={comments} block={block} />
    <div className="comment-view-block">
      {savedComment?.[block] && (
        <div className="block-with-comment">
          <div className="icon-with-comment">
            <ChatIcon style={{ width: '20px' }} />
            <div className="comment">{savedComment[block]}</div>
          </div>
          <div className="comment-buttons">
            <DeleteIcon
              className="delete-comment-button"
              style={{ cursor: 'pointer' }}
              onClick={(e) => onDeleteComment(e, block)}
            />
          </div>
        </div>
      )}

      {!commentEntered[block] && (
        <div className="input-with-button">
          <InputComponent
            classNameWrapper="w-100"
            style={{ width: '-webkit-fill-available' }}
            className="input-view"
            handleInput={(value) => handleComments(block, value)}
            onBlur={(e) => onSaveComment(e, block)}
            value={commentForm?.[block]}
            placeholder="Add comment"
          />
        </div>
      )}
    </div>
  </>
);

export const CommentDisplay = ({ comments, block }) => {
  const filteredComments = comments
    ?.filter((item) => {
      const commentText = item?.comments?.[block];
      return commentText && commentText?.trim() !== '';
    })
    ?.reverse();

  return (
    <>
      {filteredComments?.map((item, index) => (
        <div
          key={index}
          className={index === 0 ? 'block-with-comment-green' : 'block-with-comment-gray'}
        >
          <div className="icon">
            <ChatIcon style={{ width: '20px', color: '#868686' }} />
          </div>
          {item?.comments[block]}
        </div>
      ))}
    </>
  );
};

export const handleReportValidation = async (
  status,
  event,
  url,
  redirectPath,
  setLoader,
  savedComment,
  resetCommentForm,
  navigate,
  dispatch,
) => {
  event.preventDefault();

  if (dispatch) {
    dispatch({ type: 'SET_LOADER', payload: true });
  } else if (setLoader) {
    setLoader(true);
  }

  if (status === 'accepted') {
    resetCommentForm();
  }

  try {
    await axios.put(url, {
      status,
      comments: savedComment,
    });

    toast.success(status === 'accepted' ? 'Accepted' : 'Rejected');
    setTimeout(() => navigate(redirectPath), 1000);
  } catch (err) {
    if (err?.response?.status === 400) {
      toast.error(err?.response?.data.error);
    } else if (err?.response?.status === 500) {
      toast.error('Error');
      toast.success('Please try again');
    }
    toast.error(
      `Error ${status === 'accepted' ? 'accepting' : 'rejecting'
      } report. Please try again.`,
    );
  } finally {

    if (dispatch) {
      dispatch({ type: 'SET_LOADER', payload: false });
    } else if (setLoader) {
      setLoader(false);
    }
  };
};
