import { AccordionBodyItemView } from 'component/Accordion';
import { React, useEffect, useState } from 'react';
import { getDonorById } from 'api/DonorsApi';

export const DonorView = (props) => {
  const [donorName, setDonorName] = useState(null);
  const { formData } = props;

  const getDonorName = (donorId) => {
    getDonorById(donorId)
      .then((res) => {
        setDonorName(res?.data?.name);
      })
      .catch((err) => {
        console.error(err);
        setDonorName(null);
      });
  };

  useEffect(() => {
    const donorId = formData?.donor || formData?.info?.donor;
    if (!donorId) return;
    getDonorName(donorId);
  }, [formData?.donor, formData?.info?.donor]);


  return (
    <AccordionBodyItemView
      accordionSubId={(
        <div>
          {props.subId && <span>{props.subId}</span>}
          {!props.subId && <span />}
        </div>
      )}
      titleUA="Донор"
      titleEN="Donor"
      children={(
        <div className="flex-group no-wrap">
          {donorName || 'Відсутній'}
        </div>
      )}
    />
  );
};
