import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ButtonComponent } from 'component/Button';
import { toast } from 'react-toastify';
import { ReactComponent as CancelSvg } from 'assets/images/icons/close_24px.svg';
import { ReactComponent as SendSvg } from 'assets/images/icons/send.svg';
import { ReactComponent as AddSvg } from 'assets/images/icons/add_24px.svg';
import { Loader } from 'component/Loader';
import { Table, TableHeader, TableRow } from 'component/Table';
import { Dialog } from '@mui/material';
import { CheckboxInput } from 'component/Checkbox';
import './createPopup.scss';

const CreatePopup = ({ isMapReady , user, validationStatus}) => {
  const [allowedReports, setAllowedReports] = useState([]);
  const [selectedReports, setSelectedReports] = useState([]);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      fetchAllowedReports();
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const fetchAllowedReports = async () => {
    setLoader(true);
    try {
      const response = await axios.get(`/reports/clearance/weekly/allowed-reports/`);
      setAllowedReports(response?.data);
    } catch (error) {
      toast.error('Error loading allowed reports');
    } finally {
      setLoader(false);
    }
  };

  const handleCheckboxChange = (reportId) => {
    setSelectedReports(prev => {
      if (prev.includes(reportId)) {
        return prev.filter(id => id !== reportId);
      }
      return [...prev, reportId];
    });
  };

  const handleCreateWeekly = async () => {
    if (selectedReports.length === 0) {
      toast.warning('Please select at least one report');
      return;
    }

    setLoader(true);
    try {
      await axios.post(`/reports/clearance/weekly/`, {
        daily_report_ids: selectedReports ,
      });
      toast.success('Weekly report created successfully');
      handleClose();
    } catch (error) {
      toast.error('Error creating weekly report');
    } finally {
      setLoader(false);
    }
  };
  return (
    <div className="text-center">
      <ButtonComponent
        iconLeft={<AddSvg style={{ width: '20px', height: '20px' }} />}
        label="Create weekly"
        className="warning"
        handleButton={() => setOpen(true)}
        disabled={!isMapReady || !user?.permissions?.create_weekly_report || validationStatus !== 'validated'}
      />
     <Dialog
  open={open}
   className="weekly-report-dialog"
  onClose={handleClose}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
  PaperProps={{
    style: {
      borderRadius: '24px',
      padding: '24px',
      width: '600px',
      height: '400px',
      display: 'flex',
      flexDirection: 'column'
    }
  }}
>
        <div className="weekly-reports-popup">
          <Table>
            <TableHeader>
              <th>Date</th>
              <th>Type</th>
              <th>Team</th>
              <th>Task ID</th>
            </TableHeader>
            <tbody className="scrollable-tbody">
                {allowedReports?.length === 0
                  && (
                    <TableRow>
                      <td>Дані відсутні</td>
                    </TableRow>
                  )}
                {allowedReports?.map((report) => (
                  <TableRow key={report?.id}>
                <td style={{
                    display: 'flex',
                    alignItems: 'start',
                        }}>
                    <CheckboxInput
              id={`report-${report.id}`}
              name={`report-${report.id}`}
              value={report.id}
              checked={selectedReports.includes(report.id)}
              handleInput={(value, checked) => handleCheckboxChange(report.id)}
            />
                  {new Date(report.created_at).toLocaleDateString()}
                  </td>
                  <td>D</td>
                  <td>{report.team_id}</td>
                  <td>{report.clearance_task__internal_id}</td>
                </TableRow>
              ))}
            </tbody>
          </Table>
          </div>
          <div className="buttons-group-for-popup">
            <ButtonComponent
              iconLeft={<CancelSvg />}
              label="Cancel"
              className="danger"
              handleButton={handleClose}
            />
            <ButtonComponent
            iconLeft={<SendSvg />}
              label="Create weekly"
              handleButton={handleCreateWeekly}
            />
          </div>
      </Dialog>
      {loader && <Loader visible={loader} />}
    </div>
  );
};

export default CreatePopup;