import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './assets/fonts/fonts';
import './App.scss';
import { DashboardPage } from 'pages/Dashboard/Dashboard';
import ProtectedRoute from 'routes/ProtectedRoute';
import { CreateNTSReportPage } from 'pages/Reports/Create/NTS';
import { CreateHAReportPage } from 'pages/Reports/Create/HA';
import { NTS_HA_REPORT } from 'pages/NTS-HAReport/NTS-HAReport';
import { EditNTSReportPage } from 'pages/Reports/Edit/EditNTSReportComponent';
import { EditHAReportPage } from 'pages/Reports/Edit/EditHAReportComponent';
import ViewNTSReportPage from 'pages/Reports/View/ViewNTSReportComponent';
import ViewHAReportPage from 'pages/Reports/View/ViewHAReportComponent';
import { GlobalMapPage } from 'pages/GlobalMap/GlobalMapPage';
import { UsersPage } from 'pages/Users/Users';
import { DroneDataPage } from 'pages/DroneData/DroneDataPage';
import { SecretsSettingsPage } from 'pages/SecretsSettings/SecretsSettingsPage';
import { ExportPage } from 'pages/Export/ExportPage';
import { DonorMapPage } from 'pages/DonorMap/DonorMapPage';
import { ProfilePage } from 'pages/Profile/Profile';
import { MachineryTrackingPage } from 'pages/MachineryTracking/MachineryTrackingPage';
import { NotFoundPage } from 'pages/NotFound/NotFoundPage';
import { EOREPage } from 'pages/EORE/EOREPage';
import { CreateEOREReportPage } from 'pages/EORE/Reports/Create';
import { EditEOREReportPage } from 'pages/EORE/Reports/Edit';
import { ViewEOREReportPage } from 'pages/EORE/Reports/View';
import { TaskClearancePlanPage } from 'pages/TaskClearance/Create/TaskClearancePlan';
import { TaskClearancePlanPageView } from 'pages/TaskClearance/View/TaskClearancePlan';
import { TaskClearancePlanEditPage } from 'pages/TaskClearance/Edit/TaskClearancePlan';
import { DailyCreatePage } from 'pages/TaskClearance/Daily/Create/Daily';
import ClearanceDailyPage from 'pages/TaskClearance/Daily/ClearanceDailyPage';
import Lists from 'pages/TaskClearance/Lists/Lists';
import { DailyViewPage } from 'pages/TaskClearance/Daily/View/Daily';
import { DailyValidatePage } from 'pages/TaskClearance/Daily/Validation/Daily';
import { TaskClearancePlanPageValidation } from 'pages/TaskClearance/Validation/TaskClearancePlan';
import CompletedPage from 'pages/TaskClearance/Completed/CompletedPage';
import { Menu } from './layout/Menu';
import { LoginPage } from './pages/Login/Login';
import { DailyEditPage } from 'pages/TaskClearance/Daily/Edit/Daily';
import WeeklyViewPage from 'pages/TaskClearance/Weekly/View/Weekly';
import WeeklyValidatePage from 'pages/TaskClearance/Weekly/Validation/Weekly';
import ClearanceWeeklyPage from 'pages/TaskClearance/Daily/ClearanceWeeklyPage';

export const App = () => (
  <Router>
    <Routes>
      <Route exact path="/login" element={<LoginPage />} />
      <Route path="/donors/:token" element={<DonorMapPage />} />
      <Route exact path="*" element={<NotFoundPage />} />
      <Route element={(
        <main className="dashboard-main">
          <aside>
            <Menu />
          </aside>
          <ProtectedRoute />
        </main>
      )}
      >
        <Route
          path="/"
          element={<DashboardPage />}
        />
        <Route
          exact
          path="/dashboard/"
          element={<DashboardPage />}
        />
        <Route
          exact
          path="/profile"
          element={<ProfilePage />}
        />

        <Route
          exact
          path="/reports/create-report/nts"
          element={<CreateNTSReportPage />}
        />
        <Route
          exact
          path="/reports/eore"
          element={<EOREPage />}
        />
        <Route
          exact
          path="/reports/eore/create"
          element={<CreateEOREReportPage />}
        />
        <Route
          exact
          path="/reports/eore/edit/:reportId"
          element={<EditEOREReportPage />}
        />
        <Route
          exact
          path="/reports/eore/view/:reportId"
          element={<ViewEOREReportPage mode="view" />}
        />
        <Route
          exact
          path="/reports/eore/validation/:reportId"
          element={<ViewEOREReportPage mode="validation" />}
        />
        <Route
          exact
          path="/task-clearance/list/"
          element={<Lists />}
        />
        <Route
          exact
          path="/task-clearance/create"
          element={<TaskClearancePlanPage />}
        />
        <Route
          exact
          path="/task-clearance/:clearanceId/daily"
          element={<ClearanceDailyPage />}
        />
        <Route
          exact
          path="/task-clearance/:clearanceId/weekly"
          element={<ClearanceWeeklyPage />}
        />
        <Route
          exact
          path="/task-clearance/:clearanceId/completed"
          element={<CompletedPage />}
        />
        <Route
          path="/task-clearance/:clearanceId/edit/"
          element={<TaskClearancePlanEditPage />}
        />
        <Route
          exact
          path="/task-clearance/:clearanceId/view"
          element={<TaskClearancePlanPageView />}
        />
        <Route
          exact
          path="/task-clearance/:clearanceId/validation"
          element={<TaskClearancePlanPageValidation />}
        />
        <Route
          exact
          path="/task-clearance/:clearanceId/daily/create"
          element={<DailyCreatePage />}
        />
        <Route
          exact
          path="/task-clearance/:clearanceId/daily/:dailyId/edit"
          element={<DailyEditPage />}
        />
        <Route
          exact
          path="/task-clearance/:clearanceId/daily/:dailyId/view"
          element={<DailyViewPage />}
        />
        <Route
          exact
          path="/task-clearance/:clearanceId/daily/:dailyId/validation"
          element={<DailyValidatePage />}
        />
        <Route
        exact
        path="/task-clearance/:clearanceId/weekly/:weeklyId/view"
        element={<WeeklyViewPage/>}
        />
        <Route
        exact
        path='/task-clearance/:clearanceId/weekly/:weeklyId/validation'
        element={<WeeklyValidatePage/>}
        />
        <Route
          exact
          path="/users"
          element={<UsersPage />}
        />
        <Route
          exact
          path="/reports/create-report/ha"
          element={<CreateHAReportPage />}
        />
        <Route
          exact
          path="/nts-ha-report/"
          element={<NTS_HA_REPORT />}
        />
        <Route
          path="/edit-nts-report/:reportId"
          element={<EditNTSReportPage />}
        />
        <Route
          path="/edit-ha-report/:reportId"
          element={<EditHAReportPage />}
        />
        <Route
          path="/view-nts-report/:reportId"
          element={<ViewNTSReportPage mode="view" />}
        />
        <Route
          path="/view-ha-report/:reportId"
          element={<ViewHAReportPage mode="view" />}
        />
        <Route
          path="/validation-nts-report/:reportId"
          element={<ViewNTSReportPage mode="validation" />}
        />
        <Route
          path="/validation-ha-report/:reportId"
          element={<ViewHAReportPage mode="validation" />}
        />
        <Route
          path="/global-map/"
          element={<GlobalMapPage />}
        />
        <Route
          path="/drone-data/"
          element={<DroneDataPage />}
        />
        <Route
          path="/secrets-settings/"
          element={<SecretsSettingsPage />}
        />
        <Route
          path="/export/"
          element={<ExportPage />}
        />
        <Route
          path="/machinery-tracking/"
          element={<MachineryTrackingPage />}
        />
      </Route>
    </Routes>
  </Router>
);
