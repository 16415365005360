/* eslint-disable consistent-return */
import React from 'react';
import { AccordionHeaderTitle } from 'component/Accordion';
import { AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { FileUploadAttachments } from 'component/FileUploadAttachments';
import { ButtonComponent } from 'component/Button';
import { getAllAttachments } from 'api/ImagesApi';
import { ReactComponent as DownloadSvg } from 'assets/images/icons/file_download.svg';
import { toast } from 'react-toastify';

export const Attachments = (props) => (
  <>
    <AccordionItemHeading>
      <AccordionItemButton>
        <AccordionHeaderTitle
          titleUA="Додаткові документи"
          titleEN="Attached documents"
        />
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
      <div className="attachments-cards">
        <FileUploadAttachments
          formData={props.formData}
          attachments={props.attachments}
          setAttachments={props.setAttachments}
        />
      </div>
    </AccordionItemPanel>
  </>
);

export const DownloadAllAttachments = (props) => {
  if (!props.id) return;
  const downloadAllAttachments = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.setLoader(true);
    getAllAttachments(props.id)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'attachments.zip');
        document.body.appendChild(link);
        link.click();
        props.setLoader(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error while downloading attachments');
        props.setLoader(false);
      });
  };
  return (
    <div className="download-all-attachments">
      <ButtonComponent
        type="button"
        className="warning"
        iconLeft={<DownloadSvg />}
        label="Download all"
        handleButton={(e) => downloadAllAttachments(e)}
      />
    </div>
  );
};
