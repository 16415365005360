import React from 'react';
import '../assets/styles/table.scss';
import { convertColorToRgba } from 'utils/Helper';
import { ReactComponent as SortByIcon } from '../assets/images/icons/Sort-by2.svg';

const Table = (props) => (
  <table className={`table ${props.className ? props.className : ''}`}>
    {props.children}
  </table>
);

const TableHeader = (props) => <thead><tr className="table-header">{props.children}</tr></thead>;
const TableRow = (props) => (
  <tr className={`table-row ${props.className ? props.className : ''}`}>
    {props.children}
  </tr>
);

const TableRowForUsers = (props) => (
  <tr
    className="table-users"
    style={{
      backgroundColor: convertColorToRgba(props?.color, 0.7),
      width: props?.width,
      border: `2px solid ${props?.color}`,
    }}
  >
    {props.children}
  </tr>
);

const TableHeaderCell = ({
  item,
  isRotated,
  handleIconClick
}) => (
  <th
  className={`text-with-icon ${item.style ? '' : ''}`}
  style={item.style || {}}
>
  {item.isClickable && (
    <SortByIcon
      className="sort-icon"
      style={{ transform: `rotate(${isRotated ? 180 : 0}deg)` }}
      onClick={handleIconClick}
    />
  )}
  {item.label}
</th>
)

export {
  TableHeader, TableRow, Table, TableRowForUsers,TableHeaderCell
};
