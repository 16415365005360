import axios from 'axios';

export const getDroneData = async (page, dateFrom, dateTo, entire, signal) => {
  const pageParam = page ? `?page=${page}` : '';
  const dateFromParam = dateFrom ? `&date_from=${dateFrom}` : '';
  const dateToParam = dateTo ? `&date_to=${dateTo}` : '';
  const entireParam = entire ? `?entire=${true}` : '';
  const url = `/drones/${entireParam}${pageParam}${dateFromParam}${dateToParam}`;

  const config = signal ? { signal } : {};

  const response = await axios.get(url, config);
  return response;
};

export const getDroneDataFile = async (fileName) => {
  const url = `/drones/file/download/${fileName}`;
  const response = await axios.get(url, {
    method: 'GET',
    responseType: 'blob',
  });
  return response;
};
