import { AccordionBodyItem } from 'component/Accordion';
import { ButtonComponent } from 'component/Button';
import { DatePickerWrapper } from 'component/DataPicker';
import moment from 'moment';
import React from 'react';
import { useUser } from 'routes/UserContext';
import { ReactComponent as SaveSvg } from 'assets/images/icons/save-1.svg';
import { toast } from 'react-toastify';
import { updateEoreReportById } from 'api/EOREApi';
import { useParams } from 'react-router-dom';

export const SessionDate = (props) => {
  const { formData } = props;
  const { user } = useUser();

  const date = formData?.info?.session_information?.eore_date;

  const updateSessionDate = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        session_information: {
          ...prevFormData.info.session_information,
          eore_date: moment(value, 'DD.MM.YYYY').format('L'),
        },
      },
    }));
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>2.6</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Дата проведення"
      titleEN="The date of the event"
      children={(
        user?.permissions?.change_eore_report_date ? (
          <DatePickerWrapper
            value={date ? moment(date, 'DD.MM.YYYY') : null}
            handleDatePicker={updateSessionDate}
          />
        ) : date
      )}
    />
  );
};

export const SessionDateWithSaveButton = (props) => {
  const { formData } = props;
  const { reportId } = useParams();

  const updateSessionDate = (value) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        session_information: {
          ...prevFormData.info.session_information,
          eore_date: moment(value, 'DD.MM.YYYY').format('L'),
        },
      },
    }));
  };

  const date = formData?.info?.session_information?.eore_date;
  const handleSaveButton = async () => {
    try {
      const formattedDate = moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY');
      const body = {
        info: {
          session_information: {
            eore_date: formattedDate,
          },
        },
      };

      await updateEoreReportById(reportId, body);
      toast.success('Report updated');
    } catch (error) {
      toast.error('Error with updating report date');
    }
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>2.6</span>
          <span className="star">*</span>
        </div>
      )}
      titleUA="Дата проведення"
      titleEN="The date of the event"
      children={(
        <div
          className="flex-column-with-save-button"
        >
          <DatePickerWrapper
            value={date ? moment(date, 'DD.MM.YYYY') : null}
            handleDatePicker={updateSessionDate}
          />
          <ButtonComponent
            iconLeft={<SaveSvg />}
            handleButton={handleSaveButton}
            label="Save"
          />
        </div>

      )}
    />
  );
};
