import { CheckboxInput } from 'component/Checkbox';
import { Map } from 'component/Map';
import mapboxgl from 'mapbox-gl';
import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import moment from 'moment';
import { featureCollection } from '@turf/helpers';
import APIcon from 'assets/images/eo-found-icons/ap1.png';
import AVIcon from 'assets/images/eo-found-icons/av.png';
import BloodyTrapIcon from 'assets/images/eo-found-icons/boody-trap.png';
import AntiShipIcon from 'assets/images/eo-found-icons/anti-ship.png';
import RocketIcon from 'assets/images/eo-found-icons/rocket.png';
import MortarIcon from 'assets/images/eo-found-icons/mortar.png';
import GrenadeIcon from 'assets/images/eo-found-icons/grenade.png';
import FuzeIcon from 'assets/images/eo-found-icons/fuze.png';
import ProjectileIcon from 'assets/images/eo-found-icons/projectile.png';
import EngeneeringChargesIcon from 'assets/images/eo-found-icons/engeneering_charges.png';
import AircraftBombIcon from 'assets/images/eo-found-icons/aircraft_bomb.png';
import ExplosiveIcon from 'assets/images/eo-found-icons/explosive.png';
import SaaIcon from 'assets/images/eo-found-icons/saa.png';
import SubmunitionIcon from 'assets/images/eo-found-icons/submunition.png';
import TorpedoIcon from 'assets/images/eo-found-icons/torpedo.png';
import { SmallLoader } from 'component/Loader';
import { convertCoords3857To4326 } from 'utils/Helper';
import { CategoryTranslations } from 'consts/Daily';
import bbox from '@turf/bbox';
import { Legend } from './Legend';
import { ColorItem } from './ColorItem';
import { ReactComponent as LeftIcon } from 'assets/images/icons/Left.svg';
import { ReactComponent as RightIcon } from 'assets/images/icons/Right.svg';

const getEOFoundGeojson = (categories, zone) => {
  const features = [];
  Object.keys(categories)?.forEach((category) => {
    categories[category]?.evidences?.forEach((evidence) => {
      if (!evidence?.lat || !evidence?.long) return;
      features.push({
        type: 'Feature',
        properties: {
          long: evidence.long,
          lat: evidence.lat,
          category: CategoryTranslations[category][evidence.item],
        },
        geometry: {
          type: 'Point',
          coordinates: convertCoords3857To4326([
            Number(evidence?.long),
            Number(evidence?.lat),
          ], zone),
        },
      });
    });
  });
  return featureCollection(features);
};
const initEOFoundHover = (mapInstance) => {
  let hoveredStateId = null;
  const popupOnHover = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
  });
  mapInstance.on('mousemove', 'eo-found', (e) => {
    if (e.features.length > 0) {
      mapInstance.getCanvas().style.cursor = 'pointer';
      if (hoveredStateId !== null) {
        mapInstance.setFeatureState(
          { source: 'eo-found', id: hoveredStateId },
          { hover: false },
        );
      }
      hoveredStateId = e.features[0].id;
      mapInstance.setFeatureState(
        { source: 'eo-found', id: hoveredStateId },
        { hover: true },
      );
    }
    const html = `
      <div class="flex-column-group">
        <span>${e.features[0]?.properties?.category}</span>
        <span>lat: ${e.features[0]?.properties?.lat}</span>
        <span>long: ${e.features[0]?.properties?.long}</span>
      </div>
    `;
    popupOnHover
      .setLngLat(e.lngLat)
      .setHTML(html)
      .addTo(mapInstance);
    popupOnHover.addClassName('hover-progress-map-popup');
  });
  mapInstance.on('mouseleave', 'eo-found', () => {
    mapInstance.getCanvas().style.cursor = '';
    if (hoveredStateId !== null) {
      mapInstance.setFeatureState(
        { source: 'eo-found', id: hoveredStateId },
        { hover: false },
      );
    }
    hoveredStateId = null;
    popupOnHover.remove();
  });
};
const setEOFoundGeojsonToMap = (map, geojson) => {
  if (!map) return;
  if (!map.getSource('eo-found')) {
    [
      { 'ap-icon': APIcon },
      { 'av-icon': AVIcon },
      { 'bloody-trap-icon': BloodyTrapIcon },
      { 'anti-ship-icon': AntiShipIcon },
      { 'rocket-icon': RocketIcon },
      { 'mortar-icon': MortarIcon },
      { 'grenade-icon': GrenadeIcon },
      { 'fuze-icon': FuzeIcon },
      { 'projectile-icon': ProjectileIcon },
      { 'engeneering-charges-icon': EngeneeringChargesIcon },
      { 'aircraft-bomb-icon': AircraftBombIcon },
      { 'explosive-icon': ExplosiveIcon },
      { 'saa-icon': SaaIcon },
      { 'submunition-icon': SubmunitionIcon },
      { 'torpedo-icon': TorpedoIcon },
    ].forEach((icon) => {
      map.loadImage(Object.values(icon)[0], (error, image) => {
        if (error) throw error;
        map.addImage(Object.keys(icon)[0], image);
      });
    });
    map.addSource('eo-found', {
      type: 'geojson',
      data: geojson,
      generateId: true,
    });
    map.addLayer({
      id: 'eo-found',
      type: 'symbol',
      source: 'eo-found',
      layout: {
        'icon-image': [
          'match',
          ['get', 'category'],
          'AP', 'ap-icon',
          'AP (controlled)', 'ap-icon',
          'AV', 'av-icon',
          'Bloody trap', 'bloody-trap-icon',
          'Anti-ship', 'anti-ship-icon',
          'Rocket', 'rocket-icon',
          'Mortar', 'mortar-icon',
          'Grenade', 'grenade-icon',
          'Fuze', 'fuze-icon',
          'Projectile', 'projectile-icon',
          'Engeneering charges', 'engeneering-charges-icon',
          'Aircraft bomb', 'aircraft-bomb-icon',
          'Explosive', 'explosive-icon',
          'SAA', 'saa-icon',
          'Submunition', 'submunition-icon',
          'Torpedo', 'torpedo-icon',
          'ap-icon',
        ],
        'icon-size': 0.1,
        'icon-offset': [0, 0],
        'icon-allow-overlap': true,
      },
    });
    initEOFoundHover(map);
  } else {
    map.getSource('eo-found').setData(geojson);
  }
};
export const DailyMap = ({
  map,
  setMap,
  methodTypes,
  formData,
  gridGeojson,
  setGridGeojson,
  loader,
  zone,
  haReportPolygon,
  mode,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [polygonGeojson, setPolygonGeojson] = useState(haReportPolygon);
  const [isLegendOpen, setIsLegendOpen] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const notUpdatedGrids = new Set(gridGeojson?.features
    ?.filter((f) => f.properties.colors.length > 0).map((f) => f.properties.id));
    const openLegend = () => setIsLegendOpen(true);
    const closeLegend = () => setIsLegendOpen(false);

    const fitMapToBounds = (map, geojson) => {
      if (map && geojson?.features?.length > 0) {
        const bounds = bbox(geojson);
        map.fitBounds(bounds, {
          padding: 50,
        });
      }
    };

  const initGridPolygonHover = (mapInstance) => {
    let hoveredStateId = null;
    const popupOnHover = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });
    mapInstance.on('mousemove', 'grid-polygon', (e) => {
      if (e.features.length > 0) {
        mapInstance.getCanvas().style.cursor = 'pointer';
        if (hoveredStateId !== null) {
          mapInstance.setFeatureState(
            { source: 'grid', id: hoveredStateId },
            { hover: false },
          );
        }
        hoveredStateId = e.features[0].id;
        mapInstance.setFeatureState(
          { source: 'grid', id: hoveredStateId },
          { hover: true },
        );
        if (JSON.parse(e.features[0].properties.colors).length !== 0) {
          let html = JSON.parse(e.features[0].properties.colors).map((color) => {
            const foundMethod = methodTypes.find((method) => method.color === color);
            return foundMethod
              ? `<div class="flex-group">
                    <div class="color-item"
                      style="background-color: ${color}; width: 20px; height: 20px;"></div>
                    <span>${foundMethod.name}</span>
                  </div>`
              : '';
          }).join('');
          const date = e.features[0]?.properties?.updated_date
            ? moment(e.features[0]?.properties?.updated_date).format('DD.MM.YYYY')
            : 'Дата відсутня';
          html += `<div class="date">${date}</div>`;
          popupOnHover.setLngLat(e.lngLat)
            .setHTML(html)
            .addTo(mapInstance);
          popupOnHover.addClassName('hover-progress-map-popup');
        }
      }
    });
    mapInstance.on('mouseleave', 'grid-polygon', () => {
      mapInstance.getCanvas().style.cursor = '';
      if (hoveredStateId !== null) {
        mapInstance.setFeatureState(
          { source: 'grid', id: hoveredStateId },
          { hover: false },
        );
      }
      hoveredStateId = null;
      popupOnHover.remove();
    });
  };
  const changePolygonColor = (colors, feature) => {
    setGridGeojson((prevGridGeojson) => {
      const updatedGridFeatures = prevGridGeojson.features.map((f) => {
        if (f.id === feature.properties.id) {
          return {
            ...f,
            properties: {
              ...f.properties,
              colors: colors || [],
              additional_colors: colors || [],
              updated_date: new Date().toISOString(),
              methods: methodTypes.filter((method) => colors
                ?.includes(method.color)).map((method) => method.id),
            },
          };
        }
        return f;
      });
      return {
        ...prevGridGeojson,
        features: updatedGridFeatures,
      };
    });
  };
  const initGridPolygonClick = (mapInstance) => {
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });
    mapInstance.on('click', () => {
      if (popup) {
        popup.remove();
      }
    });
    mapInstance.on('click', 'grid-polygon', (e) => {
      if (notUpdatedGrids.has(e.features[0].id)) return;
      if (mode === 'view') return;
      const popupNode = document.createElement('div');
      const root = createRoot(popupNode);
      root.render(
        <PopupContent
          changePolygonColor={changePolygonColor}
          feature={e.features[0]}
          methodTypes={methodTypes}
        />,
      );
      popup.setLngLat(e.lngLat)
        .setDOMContent(popupNode)
        .addTo(map);
      popup.on('close', () => {
        root.unmount();
      });
    });
  };
  const setPolygonGeojsonToMap = (geojson) => {
    setPolygonGeojson(geojson);
    if (!map) return;
    if (!map.getSource('polygon')) {
      map.addSource('polygon', {
        type: 'geojson',
        data: polygonGeojson,
      });
      map.addLayer({
        id: 'polygon',
        type: 'fill',
        source: 'polygon',
        layout: {},
        paint: {
          'fill-color': '#FF3F3F',
          'fill-opacity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            0, 1,
            14, 0.6,
            16, 0.4,
            18, 0.3,
            20, 0.2,
            22, 0.1
          ],
        },
      });
      map.addLayer({
        id: 'polygon-outline',
        type: 'line',
        source: 'polygon',
        layout: {},
        paint: {
          'line-color': '#FF0000',
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            0, 0.5,
            14, 0.5,
            18, 0.8,
            22, 1.0
          ],
          'line-opacity': 0.7,
        },
      });
    } else {
      map.getSource('polygon').setData(geojson);
    }
  };
  useEffect(() => {
    if (!map) return;
    if (!polygonGeojson) return;
    setPolygonGeojsonToMap(polygonGeojson);
  }, [map, polygonGeojson]);

  useEffect(() => {
    if (map && gridGeojson?.features?.length > 0 && isInitialLoad) {
      const bounds = bbox(gridGeojson);
      map.fitBounds(bounds, {
        padding: 50,
      });
      setIsInitialLoad(false);
    }
  }, [map, gridGeojson, isInitialLoad]);
  useEffect(() => {
    if (!haReportPolygon) return;
    setPolygonGeojson(haReportPolygon);
  }, [haReportPolygon]);
// useEffect(() => {
//   if (map && gridGeojson?.features?.length > 0) {
//     const bounds = bbox(gridGeojson);
//     map.fitBounds(bounds, {
//       padding: 50,
//     });
//   }
// }, [map, gridGeojson, gridGeojson?.features?.length]);

  useEffect(() => {
    if (!map) return;
    if (!methodTypes?.length) return;
    if (!map.getSource('grid')) {
      gridGeojson?.features?.forEach((gridFeature) => {
        gridFeature.properties.additional_colors = gridFeature.properties.colors;
      });
      map.addSource('grid', {
        type: 'geojson',
        data: gridGeojson,
      });
      map.addLayer({
        id: 'grid-polygon',
        type: 'fill',
        source: 'grid',
        layout: {},
        paint: {
          'fill-color': [
            'case',
            ['==', ['length', ['get', 'colors']], 0],
            'transparent',
            ['to-color', ['at', ['-', ['length', ['get', 'colors']], 1], ['get', 'colors']]],
          ],
          'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            0.9,
            0.7,
          ],
        },
      });
      map.addLayer({
        id: 'grid-line',
        type: 'line',
        source: 'grid',
        layout: {},
        paint: {
          'line-color': '#f00',
          'line-opacity': 0.8,
        },
      });
      initGridPolygonClick(map);
      initGridPolygonHover(map);
      if (gridGeojson && gridGeojson.features && gridGeojson.features.length > 0) {
        const bounds = bbox(gridGeojson);
        map.fitBounds(bounds);
      }
      fitMapToBounds(map, gridGeojson);
    }
  }, [map, methodTypes]);
  useEffect(() => {
    if (!map) return;
    if (!methodTypes?.length) return;
    gridGeojson?.features.forEach((feature) => {
      feature.id = feature.properties.id;
    });
    map.getSource('grid').setData(gridGeojson);
  }, [map, gridGeojson, methodTypes]);
  useEffect(() => {
    const geojson = getEOFoundGeojson(formData?.info?.eo_found_categories || [], zone);
    setEOFoundGeojsonToMap(map, geojson);
  }, [formData?.info?.eo_found_categories, zone]);

  useEffect(() => {
    if (!map) return;
    if (!methodTypes?.length) return;
    gridGeojson?.features.forEach((feature) => {
      feature.id = feature.properties.id;
    });
    map.getSource('grid').setData(gridGeojson);
  }, [map, gridGeojson, methodTypes]);

  return (
<div className="daily-map">
  <Map
    map={map}
    setMap={setMap}
    turnPoints={haReportPolygon}
    googleWms
  />
  {gridGeojson && (
    <>
      <div className={`legend-container ${isLegendOpen ? 'open' : 'closed'}`}>
        <Legend
          map={map}
          colors={methodTypes?.map((method) => method?.color)}
          gridGeojson={gridGeojson}
          setGridGeojson={setGridGeojson}
          methodTypes={methodTypes}
        />
        <RightIcon
        className="close-legend"
          onClick={closeLegend}
          style={{ cursor: 'pointer' }}
          />
      </div>
      {!isLegendOpen && (
      <LeftIcon
      className="open-legend"
      onClick={openLegend}
      style={{ cursor: 'pointer' }}
      />
      )}
    </>
  )}
  <div className="daily-map-loader">
    <SmallLoader visible={loader} />
  </div>
</div>
  );
};
const PopupContent = ({
  changePolygonColor,
  feature,
  methodTypes,
}) => {
  const [colors, setColors] = useState(JSON.parse(feature?.properties?.colors) || []);
  if (!feature) return null;
  const handleCheckboxChange = (color) => {
    const newColors = colors?.includes(color)
      ? colors?.filter((c) => c !== color)
      : [...colors, color];
    setColors(newColors);
    changePolygonColor(newColors, feature);
  };
  return (
    <div className="flex-column-group">
      {methodTypes?.map((method, index) => (
        <CheckboxInput
          key={index}
          label={(
            <div className="flex-group">
              <ColorItem color={method.color} />
              <span>{method.name}</span>
            </div>
          )}
          value={method.color}
          name={method.name}
          id={method.name}
          checked={colors?.includes(method.color)}
          handleInput={(value, checked) => {
            handleCheckboxChange(value, checked);
          }}
        />
      ))}
    </div>
  );
};