import { Map } from 'component/Map';
import React, { useEffect, useRef, useState } from 'react';
import { lineString, point } from '@turf/helpers';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import { bearing } from '@turf/bearing';
import { destination } from '@turf/destination';
import { pointToLineDistance } from '@turf/point-to-line-distance';
import { ButtonComponent } from 'component/Button';

function createPerpendicularLineThroughPoint(lineCoords, targetPoint, length) {
  const lineStart = point(lineCoords[0]);
  const lineEnd = point(lineCoords[1]);
  const lineBearing = bearing(lineStart, lineEnd);

  const perpendicularBearing1 = (lineBearing + 90) % 360;
  const perpendicularBearing2 = (lineBearing - 90) % 360;

  const centerPoint = point(targetPoint);

  const perpendicularPoint1 = destination(centerPoint, length, perpendicularBearing1);
  const perpendicularPoint2 = destination(centerPoint, length, perpendicularBearing2);

  return lineString([
    perpendicularPoint1.geometry.coordinates,
    perpendicularPoint2.geometry.coordinates,
  ]);
}

const createSquarePolygon = (center, xAxis) => {
  if (!center || !xAxis) return;

  const point1 = point(center);
  const myBearing = bearing(point1, point(xAxis[0]));
  const distance = 1;
  const options = { units: 'meters' };
  const destination1 = destination(point1, distance, myBearing - 90, options);
  const destination2 = destination(destination1, distance, myBearing, options);
  const destination3 = destination(destination2, distance, myBearing + 90, options);

  const coords = [
    center,
    destination1.geometry.coordinates,
    destination2.geometry.coordinates,
    destination3.geometry.coordinates,
    center,
  ];

  return lineString(coords);
};

const createCrosshairGeoJSON = (center, edgeDirection) => {
  const dx = 0.001 * Math.cos(edgeDirection);
  const dy = 0.001 * Math.sin(edgeDirection);

  const xAxisCoords = [
    [center[0] - dx, center[1] - dy],
    [center[0] + dx, center[1] + dy],
  ];

  const perpendicularLine = createPerpendicularLineThroughPoint(xAxisCoords, center, 0.1);

  const yAxisCoords = perpendicularLine.geometry.coordinates;

  const squarePolygon = createSquarePolygon(center, xAxisCoords);

  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: xAxisCoords,
        },
        properties: {},
      },
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: yAxisCoords,
        },
        properties: {},
      },
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: center,
        },
        properties: {},
      },
      squarePolygon ? squarePolygon : {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [],
        },
        properties: {},
      },
    ],
  };
};

// eslint-disable-next-line no-shadow
const findNearestEdge = (lineString, point) => {
  let nearestEdge = null;
  let minDistance = Infinity;

  const coords = lineString.geometry.coordinates;
  for (let i = 0; i < coords.length - 1; i++) {
    const edge = [coords[i], coords[i + 1]];
    const distance = pointToLineDistance(point, edge);
    if (distance < minDistance) {
      minDistance = distance;
      nearestEdge = edge;
    }
  }

  return nearestEdge;
};

export const ProgressMapBlock = React.memo(({
  tcpFormData,
  geojson,
  hazardAreaType,
  crosshair,
  setCrosshair,
  errors,
  mode = 'edit',
}) => {
  const [map, setMap] = useState(null);
  const moveCrosshairRef = useRef(true);

  // eslint-disable-next-line no-shadow
  const createSightLayer = (map, formDataGeojson, crosshair, setCrosshair) => {
    const center = [24.026378717755577, 49.84396491870458];

    map.addSource('crosshair', {
      type: 'geojson',
      data: crosshair || createCrosshairGeoJSON(center, 0),
    });

    map.addLayer({
      id: 'crosshair-layer',
      type: 'line',
      source: 'crosshair',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#000000',
        'line-width': 2,
      },
    });

    if (crosshair) {
      moveCrosshairRef.current = false;
    }

    map.on('mousemove', (e) => {
      if (!moveCrosshairRef.current) return;
      if (mode === 'view') return;

      const mousePoint = [e.lngLat.lng, e.lngLat.lat];

      const lineStringTrans = lineString(formDataGeojson?.features[0].geometry.coordinates[0]);

      const nearestPointOnEdge = nearestPointOnLine(lineStringTrans, mousePoint);
      const newCenter = nearestPointOnEdge.geometry.coordinates;

      const nearestEdge = findNearestEdge(lineStringTrans, newCenter);
      const edgeDirection = Math.atan2(
        nearestEdge[1][1] - nearestEdge[0][1],
        nearestEdge[1][0] - nearestEdge[0][0],
      );

      const updatedCrosshairGeoJSON = createCrosshairGeoJSON(newCenter, edgeDirection);

      map.getSource('crosshair').setData(updatedCrosshairGeoJSON);
    });

    map.on('click', () => {
      if (mode === 'view') return;
      moveCrosshairRef.current = false;
      setCrosshair(map.getSource('crosshair')._data);
    });
  };

  useEffect(() => {
    if (!tcpFormData?.crosshair) return;
    setCrosshair(tcpFormData?.crosshair);
  }, []);

  useEffect(() => {
    if (!geojson || !map) return;

    if (!map.getSource('crosshair')) {
      createSightLayer(map, geojson, crosshair, setCrosshair);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      map.off('mousemove');
      map.off('click');
    };
  }, [map, geojson]);

  return (
    <div className="flex-column-group">
      {mode !== 'view' && (
        <>
          <div className="title-with-line">
            <div className={`title ${errors.crosshair
              ? 'error'
              : ''}`}
            >
              <span>Налаштуйте положення сітки</span>
              <span>Adjust the position of the grid</span>
            </div>
            <div className="line" />
          </div>
          <div className="flex-group flex-right">
            <ButtonComponent
              className="primary"
              handleButton={() => {
                moveCrosshairRef.current = true;
                setCrosshair(null);
              }}
              label="Change position"
            />
          </div>
        </>
      )}
      <Map
        map={map}
        setMap={setMap}
        turnPointsPolygon={geojson}
        hazardAreaType={hazardAreaType}
        googleWms
      />
    </div>
  );
});

export const ProgressMapBlockView = ({
  geojson,
  hazardAreaType,
  crosshair,
  setCrosshair,
}) => (
  <ProgressMapBlock
    geojson={geojson}
    hazardAreaType={hazardAreaType}
    crosshair={crosshair}
    setCrosshair={setCrosshair}
    mode="view"
  />
);
