import React, { useState } from 'react';
import 'assets/styles/pages/eoreMaterials.scss';
import { AddNewDetailsDialog } from 'component/Dialog';
import { useUser } from 'routes/UserContext';
import { ReactComponent as ArrowDown } from 'assets/images/icons/Down.svg';
import AccordionSecretSettingsDetails from './AccordionSecretSettingsDetails';

const ClearanceFiles = ({ setLoader }) => {
  const [refreshData, setRefreshData] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useUser();
  const handleRefreshData = () => {
    setRefreshData((prev) => !prev);
  };

  const toggleAccordion = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="eore-materials-container">
      <div className="title" onClick={toggleAccordion}>
        <div className="eore-materials-accordion">
          <span>Clearance Files</span>
          <ArrowDown className={`arrow-icon ${isOpen ? 'rotated' : ''}`} />
        </div>
      </div>
      {isOpen
        && (
          <>
            <AccordionSecretSettingsDetails
              setLoader={setLoader}
              refreshData={refreshData}
              user={user}
              type="clearance_files"
            />
            {user?.permissions?.crud_handouts_superuser
              && (
                <div className="add-validation-user-button">
                  <AddNewDetailsDialog
                    refreshData={handleRefreshData}
                    title="Files"
                    type="clearance_files"
                  />
                </div>
              )}
          </>
        )}
    </div>
  );
};

export default ClearanceFiles;
