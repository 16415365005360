import React, { useEffect, useState } from 'react';
import 'assets/styles/pages/task-clearance-plan.scss';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as ArrowLeftIcon } from 'assets/images/icons/arrow_back_24px.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/images/icons/arrow_forward_24px.svg';
import { toast, ToastContainer } from 'react-toastify';
import { tcpForm } from 'consts/TCPForm';
import { useUser } from 'routes/UserContext';
import axios from 'axios';
import { postProgressMap, postTCPForm, updateTCPForm } from 'api/TCPApi';
import { Loader } from 'component/Loader';
import { feature, featureCollection } from '@turf/helpers';
import { useNavigate } from 'react-router-dom';
import { areGeoJsonEqual } from 'utils/Helper';
import { TCPNavigationStatuses } from 'consts/TCP';
import { TCPFormTabs } from '../Tabs/FormTabs';
import { NavigationTabLinks } from '../Tabs/NavigationLinks';

export const TaskClearancePlanPage = () => {
  const [formData, setFormData] = useState(null);
  const [tcpFormData, setTcpFormData] = useState(tcpForm);
  const [navigationStatuses, setNavigationStatuses] = useState(TCPNavigationStatuses);
  const [loader, setLoader] = useState(false);
  const { user } = useUser();

  useEffect(() => {
    setNavigationStatuses((prev) => ({
      ...prev,
      tcpCreate: 'active',
      daily: 'inactive',
      weekly: 'inactive',
      completed: 'inactive',
      done: 'inactive',
    }));
  }, []);

  useEffect(() => {
    if (!user) return;
    setTcpFormData((prev) => ({
      ...prev,
      user: user.id,
    }));
  }, [user]);

  useEffect(() => {
    if (!formData) return;
    setTcpFormData((prev) => ({
      ...prev,
      ha_report: formData?.id,
    }));
  }, [formData?.id]);

  return (
    <section>
      <div className="flex-column-group">
        <NavigationTabLinks
          mode="create"
          statuses={navigationStatuses}
        />
        <TaskClearancePlanForm
          formData={formData}
          setFormData={setFormData}
          tcpFormData={tcpFormData}
          setTcpFormData={setTcpFormData}
          setLoader={setLoader}
        />
      </div>
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};

export const TaskClearancePlanForm = ({
  formData,
  setFormData,
  tcpFormData,
  setTcpFormData,
  setLoader,
  originalCrosshair,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [turnPointsFiles, setTurnPointsFiles] = useState([]);
  const [crosshair, setCrosshair] = useState(null);
  const [map, setMap] = useState(null);
  const [filesLinks, setFilesLinks] = useState([]);
  const [turnPointsFromShape, setTurnPointsFromShape] = useState(null);
  const [btnState, setBtnState] = useState(null);
  const [errors, setErrors] = useState({});
  const [showFileValidation, setShowFileValidation] = useState(false);
  const [showTCPValidation, setShowTCPValidation] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setTcpFormData((prev) => ({
      ...prev,
      crosshair,
    }));
  }, [crosshair]);
  const getAllClearanceLinks = async () => {
    try {
      const response = await axios.get('/reports/clearance/files/');
      const clearancesFiles = response.data?.map((item) => ({
        id: item.id,
        clearance_file: item.name,
        file_link: tcpFormData?.files
          ?.find((file) => file.clearance_file === item.id)?.file_link || '',
      }));
      setFilesLinks(clearancesFiles);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getAllClearanceLinks();
  }, []);
  useEffect(() => {
    if (tcpFormData.id) {
      getAllClearanceLinks();
      setTurnPointsFromShape(featureCollection([feature(tcpFormData?.deminers_polygon)]));
    }
  }, [tcpFormData.id]);
  const sendProgressMap = async (id) => {
    if (!crosshair || !crosshair.features.length) return;
    if (originalCrosshair
      && areGeoJsonEqual(originalCrosshair, tcpFormData?.crosshair)) return;
    try {
      setLoader(true);
      const pointForMap = featureCollection(crosshair?.features
        ?.filter((item) => item.geometry.type === 'Point'));
      await postProgressMap(id, pointForMap);
      toast.success('Progress map has been successfully saved');
      setLoader(false);
    } catch (error) {
      toast.error('Progress map has not been saved');
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const isCreateMode = !tcpFormData.id;

  const handleFormResponse = (res, successMessage) => {
    toast.success(successMessage);

    if (!tcpFormData.id && res.data.id) {
      setTcpFormData(prev => ({
        ...prev,
        id: res.data.id
      }));
    }

    const clearanceId = res.data.id || tcpFormData.id;

    if(!res.data.draft) {
      sendProgressMap(res.data.id);
      navigate('/task-clearance/list/');
    } else {
      setLoader(false);

      if (isCreateMode) {
        navigate(`/task-clearance/${clearanceId}/edit`);
      }
    }
  };

  const handleFormError = (error, failureMessage) => {
    toast.error(failureMessage);
    setErrors(error.response.data);
    setLoader(false);
  };

  const sendTCPForm = async () => {
    setLoader(true);
    const formAction = tcpFormData?.id
      ? updateTCPForm(tcpFormData.id, tcpFormData)
      : postTCPForm(tcpFormData);
    formAction
      .then((res) => {
        const successMessage = tcpFormData?.id
          ? 'TCP form has been successfully updated'
          : 'TCP form has been successfully saved';
        handleFormResponse(res, successMessage);
      })
      .catch((error) => {
        const failureMessage = tcpFormData?.id
          ? 'TCP form has not been updated'
          : 'TCP form has not been saved';
        handleFormError(error, failureMessage);
      })
      .finally(() => {
        setBtnState(null);
      });
  };

  useEffect(() => {
    if (btnState === 'draft' || btnState === 'send') {
      sendTCPForm();
    }
  }, [btnState]);
  useEffect(() => {
    setTcpFormData((prev) => ({
      ...prev,
      files: filesLinks?.map((item) => ({
        clearance_file: item.id,
        file_link: item.file_link,
      })),
    }));
  }, [filesLinks]);
  const handleSendOrNext = () => {
    if (!formData?.id) {
      toast.error('You cannot change the tab without selecting the HA report');
      return;
    }
    if (activeTabIndex === 5) {
      setShowTCPValidation(false);
      setShowFileValidation(false);
      setErrors({});
      const newErrors = {};
      let hasErrors = false;
      if (!tcpFormData.methods?.length || !tcpFormData.donor) {
        setShowTCPValidation(true);
        if (!tcpFormData.methods?.length) {
          newErrors.methods = 'Method type is required';
        }
        if (!tcpFormData.donor) {
          newErrors.donor = 'Donor is required';
        }
        hasErrors = true;
      }
      const hasEmptyLinks = filesLinks.some(link => link.file_link.length === 0);
      if (hasEmptyLinks) {
        setShowFileValidation(true);
        newErrors.pdf_file_link = 'All file links must be filled';
        hasErrors = true;
      }
      if (hasErrors) {
        setErrors(newErrors);
        toast.error('Please fill in all required fields');
        return;
      }
      setTcpFormData((prev) => ({
        ...prev,
        draft: false,
      }));
      setBtnState('send');
    }
    if (activeTabIndex < 5) {
      setActiveTabIndex(activeTabIndex + 1);
    }
  };
  const handleDraft = () => {
    if (!formData?.id) {
      toast.error('You cannot save the form without selecting the HA report');
      return;
    }
    setShowTCPValidation(false);
    setErrors(prev => {
      const newErrors = { ...prev };
      delete newErrors.methods;
      delete newErrors.donor;
      return newErrors;
    });
    setTcpFormData((prev) => ({
      ...prev,
      draft: true,
    }));
    setBtnState('draft');
  };
  return (
    <div className="tcp-form">
      <TCPFormTabs
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
        formData={formData}
        setFormData={setFormData}
        turnPointsFiles={turnPointsFiles}
        setTurnPointsFiles={setTurnPointsFiles}
        filesLinks={filesLinks}
        setFilesLinks={setFilesLinks}
        map={map}
        setMap={setMap}
        crosshair={crosshair}
        setCrosshair={setCrosshair}
        turnPointsFromShape={turnPointsFromShape}
        setTurnPointsFromShape={setTurnPointsFromShape}
        tcpFormData={tcpFormData}
        setTcpFormData={setTcpFormData}
        errors={errors}
        showFileValidation={showFileValidation}
        showTCPValidation={showTCPValidation}
      />
      <div className="form-footer">
        <ButtonComponent
          label="Previous"
          className="warning"
          disabled={activeTabIndex === 0}
          handleButton={() => setActiveTabIndex(activeTabIndex - 1)}
          iconLeft={<ArrowLeftIcon />}
        />
        <ButtonComponent
          label="Draft"
          className="warning"
          handleButton={handleDraft}
        />
        <ButtonComponent
          label={activeTabIndex === 5 ? 'Send' : 'Next'}
          className="primary"
          disabled={Boolean(!tcpFormData?.ha_report)}
          handleButton={handleSendOrNext}
          iconRight={<ArrowRightIcon />}
        />
      </div>
    </div>
  );
};
