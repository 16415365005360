import { AccordionBodyItem, AccordionBodyItemView } from 'component/Accordion';
import React from 'react';

export const ReporterPosition = ({ formData, hasError }) => (
  <AccordionBodyItem
    accordionSubId={(
      <div>
        <span>1.6</span>
        <span className="star" />
      </div>
      )}
    titleUA="Його посада"
    titleEN="Reporter position"
    className={hasError ? 'error error-text' : ''}
    children={(
        formData?.info?.dms?.reporter_position || '-'
      )}
  />
);

export const ReporterPositionView = ({ reporterPosition }) => (
  <AccordionBodyItemView
    accordionSubId={1.6}
    titleUA="Його посада"
    titleEN="Reporter position"
    children={reporterPosition || '-'}
  />
);
