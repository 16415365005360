import { DatePickerWrapper } from 'component/DataPicker';
import moment from 'moment';
import React from 'react';

export const StartDate = ({ tcpFormData, setTcpFormData }) => {
  const handleStartDate = (value) => {
    const formattedDate = moment(value)?.format('DD.MM.YYYY');
    setTcpFormData((prevFormData) => ({
      ...prevFormData,
      start_date: formattedDate,
    }));
  };

  return (
    <div className="flex-column-group">
      <div className="double-text">
        <span>Дата початку</span>
        <span>Start date</span>
      </div>
        <DatePickerWrapper
          value={tcpFormData?.start_date ? moment(tcpFormData?.start_date, 'DD.MM.YYYY') : null}
          handleDatePicker={handleStartDate}
          />
    </div>
  );
};

export const StartDateView = ({ startDate }) => (
  <div className="flex-column-group">
    <div className="double-text">
      <span>Дата початку</span>
      <span>Start date</span>
    </div>
    <div className="text-grey">
      {startDate}
    </div>
  </div>
);
