import { getDonorById, getDonorsNames } from 'api/DonorsApi';
import { SelectComponent } from 'component/Select';
import React, { useEffect, useState } from 'react';

export const Donor = ({
  tcpFormData,
  setTcpFormData,
  errors,
  showValidation
}) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    getDonorsNames()
      .then((res) => {
        const resOptions = res.data?.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setOptions(resOptions);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleDonor = (value) => {
    setTcpFormData((prevFormData) => ({
      ...prevFormData,
      donor: value,
    }));
  };
  return (
    <div className="flex-column-group">
      <div className={`double-text ${(showValidation && errors) ? 'error' : ''}`}>
        <span>Донор</span>
        <span>Donor</span>
      </div>
      <SelectComponent
        isMulti={false}
        value={options.find((obj) => obj.value === tcpFormData?.donor)}
        handleSelect={handleDonor}
        options={options}
        placeholder="Select"
        isSearchable
      />
    </div>
  );
};

export const DonorView = ({ donorId }) => {
  const [donorName, setDonorName] = useState(null);

  const getDonorName = () => {
    getDonorById(donorId)
      .then((res) => {
        setDonorName(res?.data?.name);
      })
      .catch((err) => {
        console.error(err);
        setDonorName(null);
      });
  };

  useEffect(() => {
    if (!donorId) return;
    getDonorName();
  }, [donorId]);

  return (
    <div className="flex-column-group">
      <div className="double-text">
        <span>Донор</span>
        <span>Donor</span>
      </div>
      <div className="text-grey">
        {donorName || 'Відсутній'}
      </div>
    </div>
  );
};
