import { AccordionBodyItem, AccordionBodyItemView } from 'component/Accordion';
import { InputComponent } from 'component/Input';
import React from 'react';
import { UPDATE_NUMBER_OF_DEMINERS } from 'reducers/dailyFormActions';

export const NumberOfDeminers = ({ formData, dispatch, hasError }) => {
  const handleNumberOfDeminers = (value) => {
    dispatch({
      type: UPDATE_NUMBER_OF_DEMINERS,
      payload: value,
    });
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>2.3</span>
        </div>
      )}
      titleUA="Кількість саперів на полі"
      titleEN="Number of deminers in the field"
      className={hasError('field_work.number_of_deminers') ? 'error error-text' : ''}
      children={(
        <InputComponent
          value={formData?.info?.field_work?.number_of_deminers}
          handleInput={handleNumberOfDeminers}
          placeholder="Type here"
          className="number"
        />
      )}
    />
  );
};

export const NumberOfDeminersView = ({ numberOfDeminers }) => (
  <AccordionBodyItemView
    accordionSubId={2.3}
    titleUA="Кількість саперів на полі"
    titleEN="Number of deminers in the field"
    children={numberOfDeminers || '-'}
  />
);
