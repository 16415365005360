import { getDonorsNames } from 'api/DonorsApi';
import { AccordionBodyItem } from 'component/Accordion';
import { React, useEffect, useState } from 'react';

export const DailyDonor = (props) => {
  const [donorName, setDonorName] = useState([]);

  useEffect(() => {
    if (!props.donorId) return;
    getDonorsNames()
      .then((res) => {
        setDonorName(res.data?.filter((item) => item.id === props.donorId)[0]?.name);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          {props.subId && (
            <span>{props.subId}</span>
          )}
          {!props.subId && (
            <span />
          )}
        </div>
      )}
      titleUA="Донор"
      titleEN="Donor"
      className={props.hasError ? 'error error-text' : ''}
      children={donorName || '-'}
    />
  );
};
