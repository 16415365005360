import React, { useState } from 'react';
import { ReactComponent as ForwardIcon } from 'assets/images/icons/forward.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/delete_24px.svg';
import { ButtonComponent } from './Button';
import 'assets/styles/twoColumns.scss';
import { CheckboxInput } from './Checkbox';

export const TwoMovedColumns = ({
  nameFirstColumn,
  nameSecondColumn,
  contentFirstColumn,
  contentSecondColumn,
  setContentFirstColumn,
  setContentSecondColumn,
}) => {
  const [selectedData, setSelectedData] = useState([]);

  const handleMoveItems = (e) => {
    e.preventDefault();
    setContentFirstColumn(contentFirstColumn?.filter((item) => !selectedData
      .includes(String(item.id))));
    setContentSecondColumn((prev) => [
      ...prev,
      ...selectedData.map((item) => contentFirstColumn
        ?.find((content) => Number(content.id) === Number(item))),
    ]);
    setSelectedData([]);
  };

  const handleDelete = (content) => {
    setContentFirstColumn((prev) => [...prev, content]);
    setContentSecondColumn((prev) => prev.filter((item) => item.id !== content.id));
  };

  return (
    <div className="two-moved-columns">
      <Column
        header={nameFirstColumn}
        content={contentFirstColumn}
        isSelectable
        selectedData={selectedData}
        setSelectedData={setSelectedData}
      />
      <div className="move-button">
        <ButtonComponent
          label="Add"
          className="primary"
          iconRight={<ForwardIcon />}
          handleButton={handleMoveItems}
        />
      </div>
      <Column
        header={nameSecondColumn}
        content={contentSecondColumn}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export const Column = ({
  header,
  content,
  emptyMessage = 'Empty data',
  isSelectable = false,
  setSelectedData,
  handleDelete,
  deleteIcon = true,
}) => (
  <div className="column">
    <div className="header">
      <span>{header}</span>
    </div>
    <div className="content">
      {content?.map((item) => (isSelectable ? (
        <CheckboxInput
          key={item.id}
          id={item.id}
          value={item.id}
          label={item.value}
          name={item.value}
          handleInput={(value, checked) => {
            if (checked) {
              setSelectedData((prev) => [...prev, value]);
            } else {
              setSelectedData((prev) => prev.filter((i) => i !== value));
            }
          }}
        />
      ) : (
        <SelectedItem
          key={item.id}
          content={item}
          handleDelete={handleDelete}
          deleteIcon={deleteIcon}
        />
      )))}
      {content?.length === 0 && <p className="empty">{emptyMessage}</p>}
    </div>
  </div>
);

const SelectedItem = ({ content, handleDelete, deleteIcon }) => (
  <div className="selected-item">
    <span>{content.value}</span>
    {deleteIcon
      && (
        <DeleteIcon
          className="delete-button"
          onClick={() => handleDelete(content)}
        />
      )}
  </div>
);
