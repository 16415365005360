import { React, useState, useEffect } from 'react';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import { axiosWrapper, getPageValue } from 'utils/Helper';
import {
  getEoreTemplatesSchema,
  getHaTemplatesSchema, getNtsTemplatesSchema, getTemplatesFiltered, getTemplatesList,
} from 'api/TemplatesApi';
import { Loader } from 'component/Loader';
import { ToastContainer } from 'react-toastify';
import { Templates } from './Templates';
import { Reports } from './Reports';

export const ExportPage = () => {
  const [loader, setLoader] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [templates, setTemplates] = useState([]);
  const [reports, setReports] = useState([]);
  const [pageTemplates, setPageTemplates] = useState(1);
  const [pageReports, setPageReports] = useState(1);
  const [nextTemplates, setNextTemplates] = useState(false);
  const [nextReports, setNextReports] = useState(false);
  const [NTSAttributes, setNTSAttributes] = useState(null);
  const [HAAttributes, setHAAttributes] = useState(null);
  const [EoreAttributes, setEoreAttributes] = useState(null);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const getTemplates = (templatePage) => {
    axiosWrapper(() => {
      getTemplatesList(templatePage)
        .then((res) => {
          if (templatePage === 1) {
            setTemplates(res.data.results);
          } else {
            setTemplates((prevData) => [...prevData, ...res.data.results]);
          }
          setNextTemplates(res.data.next);
          if (res.data.next) {
            setPageTemplates(Number(getPageValue(res.data.next)));
          }
          setLoader(false);
        })
        .catch((err) => {
          console.error(err);
          setLoader(false);
        });
    });
  };

  const getReports = (reportsPage) => {
    axiosWrapper(() => {
      getTemplatesFiltered(reportsPage)
        .then((res) => {
          if (reportsPage === 1) {
            setReports(res.data.results);
          } else {
            setReports((prevData) => [...prevData, ...res.data.results]);
          }
          setNextReports(res.data.next);
          if (res.data.next) {
            setPageReports(Number(getPageValue(res.data.next)));
          }
          setLoader(false);
        })
        .catch((err) => {
          console.error(err);
          setLoader(false);
        });
    });
  };

  const getAttributes = (type) => {
    setLoader(true);
    axiosWrapper(() => {
      if (type === 'nts') {
        getNtsTemplatesSchema()
          .then((res) => {
            setNTSAttributes(res.data);

            setLoader(false);
          })
          .catch((err) => {
            console.error(err);
            setLoader(false);
          });
      }
      if (type === 'ha') {
        getHaTemplatesSchema()
          .then((res) => {
            setHAAttributes(res.data);

            setLoader(false);
          })
          .catch((err) => {
            console.error(err);
            setLoader(false);
          });
      }
      if (type === 'eore') {
        getEoreTemplatesSchema()
          .then((res) => {
            setEoreAttributes(res.data);

            setLoader(false);
          })
          .catch((err) => {
            console.error(err);
            setLoader(false);
          });
      }
    });
  };

  useEffect(() => {
    getReports(pageReports);
    getTemplates(pageTemplates);
    getAttributes('nts');
    getAttributes('ha');
    getAttributes('eore');
    document.title = 'Export';
  }, []);

  return (
    <section>
      <Tabs selectedIndex={activeTab} onSelect={handleTabChange}>
        <div className="settings-header">
          <div className="title">
            {/* <SettingsIcon /> */}
            Export to excel
          </div>
          <div className="flex-right">
            <TabList>
              <Tab>Reports</Tab>
              <Tab>Template</Tab>
            </TabList>
          </div>
        </div>

        <TabPanel>
          <Reports
            reports={reports}
            setLoader={setLoader}
            getReports={getReports}
            next={nextReports}
            page={pageReports}
          />
        </TabPanel>
        <TabPanel>
          <Templates
            templates={templates}
            setLoader={setLoader}
            getTemplates={getTemplates}
            next={nextTemplates}
            page={pageTemplates}
            NTSAttributes={NTSAttributes}
            HAAttributes={HAAttributes}
            EoreAttributes={EoreAttributes}
          />
        </TabPanel>
      </Tabs>
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};
