import React from 'react';
import { CheckboxInput } from 'component/Checkbox';

export const EoreAttributesBlock = (props) => (
  props.EoreAttributes?.map((attribute, index1) => (
    (
      <div className="flex-column-group" key={index1}>
        <CheckboxInput
          id={`checkbox-${attribute.name}`}
          name={`checkbox-${attribute.name}`}
          label={(
            <div className="double-text">
              <span>{attribute.ua_name || '-'}</span>
              <span>{attribute.eng_name || '-'}</span>
            </div>
            )}
          disabled={props.viewMode}
          value={props.findKey(props.selectedEoreAttr, attribute.name, props.selectedEoreBlocks)}
          checked={(() => {
            if (attribute.name === 'eore_point') {
              return props.selectedEoreAttr[attribute.name];
            }

            if (props.selectedEoreBlocks.hasOwnProperty(attribute.name)) {
              return props.findKey(props.selectedEoreBlocks, attribute.name, '');
            }

            return props.findKey(props.selectedEoreAttr, attribute.name, props.selectedEoreBlocks);
          })()}
          handleInput={(value, checked) => {
            props.handleTemplateBlockCheckbox(attribute.name, value, checked, attribute.name);
            props.handleTemplateCheckbox(
              props.selectedEoreAttr,
              props.selectedEoreBlocks,
              attribute.name,
              checked,
            );
          }}
        />
        {props.selectedEoreBlocks[attribute.name] && (
        <div className="checkbox-children">
          {attribute.children?.map((сhildAttr, index2) => (

            <div className="flex-column-group" key={index2}>
              <CheckboxInput
                id={`checkbox-${attribute.name}-${сhildAttr.name}`}
                name={`checkbox-${attribute.name}-${сhildAttr.name}`}
                label={(
                  <div className="double-text">
                    <span>{сhildAttr.ua_name || '-'}</span>
                    <span>{сhildAttr.eng_name || '-'}</span>
                  </div>
                    )}
                disabled={props.viewMode}
                value={props.findKey(
                  props.selectedEoreAttr[attribute.name],
                  сhildAttr.name,
                  props.selectedEoreBlocks,
                )}
                checked={сhildAttr.name === 'description_of_place_type'
                      || сhildAttr.name === 'number_of_session'
                  ? props.selectedEoreAttr[attribute.name][сhildAttr.name]
                  : props.findKey(
                    props.selectedEoreAttr[attribute.name],
                    сhildAttr.name,
                    props.selectedEoreBlocks,
                  )}
                handleInput={(value, isChecked) => {
                  if (сhildAttr.name !== 'description_of_place_type'
                        || сhildAttr.name !== 'number_of_session') {
                    props.handleTemplateBlockCheckbox(сhildAttr.name, value, isChecked, `${attribute.name}__${сhildAttr.name}`);
                  }
                  props.handleTemplateCheckbox(props.selectedEoreAttr, props.selectedEoreBlocks, `${attribute.name}__${сhildAttr.name}`, isChecked);
                }}
              />
              {props.selectedEoreBlocks[сhildAttr.name] && (
              <div className="checkbox-children">
                {сhildAttr.children?.map((сhildAttr2, index3) => (
                  <div className="flex-column-group" key={index3}>
                    <CheckboxInput
                      id={`checkbox-${сhildAttr.name}-${сhildAttr2.name}`}
                      name={`checkbox-${сhildAttr.name}-${сhildAttr2.name}`}
                      label={(
                        <div className="double-text">
                          <span>{сhildAttr2.ua_name || '-'}</span>
                          <span>{сhildAttr2.eng_name || '-'}</span>
                        </div>
                            )}
                      disabled={props.viewMode}
                      value={props.findKey(
                        props.selectedEoreAttr[attribute.name][сhildAttr.name],
                        сhildAttr2.name,
                        props.selectedEoreBlocks,
                      )}
                      checked={props.findKey(
                        props.selectedEoreAttr[attribute.name][сhildAttr.name],
                        сhildAttr2.name,
                        props.selectedEoreBlocks,
                      )}
                      handleInput={(value, isChecked) => {
                        props.handleTemplateCheckbox(props.selectedEoreAttr, props.selectedEoreBlocks, `${attribute.name}__${сhildAttr.name}__${сhildAttr2.name}`, isChecked);
                      }}
                    />
                  </div>
                ))}
              </div>
              )}
            </div>
          ))}
        </div>
        )}
      </div>
    )
  ))
);
