import { Loader } from 'component/Loader';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { TCPNavigationStatuses } from 'consts/TCP';
import { DatePickerWrapper } from 'component/DataPicker';
import { ReactComponent as AddSvg } from 'assets/images/icons/add_24px.svg';
import { ButtonComponent } from 'component/Button';
import { getProgressMapStatus } from 'api/DailyApi';
import { useUser } from 'routes/UserContext';
import { getTCPFormById } from 'api/TCPApi';
import { NavigationTabLinks } from '../Tabs/NavigationLinks';
import CreatePopup from '../Weekly/CreatePopup';
import ClearanceWeekly from './ClearanceWeeklyList';

const ClearanceWeeklyPage = () => {
  const [loader, setLoader] = useState(false);
  const [navigationStatuses, setNavigationStatuses] = useState(TCPNavigationStatuses);
  const [currentPage, setCurrentPage] = useState(1);
  const [clearanceWeeklyList, setClearanceWeeklyList] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isMapReady, setIsMapReady] = useState(false);
  const [validationStatus, setValidationStatus] = useState(null);
  const { user } = useUser();

  const resetPage = () => setCurrentPage(1);

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const applyFilters = (selectedFilters) => {
    const baseUrl = '/reports/clearance/clearance-tasks/';

    const statusQuery = selectedFilters.selectedStatus?.length
      ? selectedFilters.selectedStatus
          .map((status) => `validation_status=${status.value}`)
          .join('&')
      : '';
    const donorQuery = selectedFilters.selectedDonors?.length
      ? selectedFilters.selectedDonors.map((donor) => `donor=${donor.value}`).join('&')
      : '';
    const methodTypeQuery = selectedFilters.selectedMethodTypes?.length
      ? selectedFilters.selectedMethodTypes.map((method) => `method=${method.value}`).join('&')
      : '';

    const queryParams = [statusQuery, donorQuery, methodTypeQuery].filter(Boolean).join('&');
    const url = `${baseUrl}?${queryParams}`;

    setLoader(true);
    axios
      .get(url)
      .then((response) => {
        setLoader(false);
        const { data } = response;
        setClearanceWeeklyList(data);
      })
      .catch((error) => {
        setLoader(false);
        console.error('Error applying filters:', error);
      });
  };

  const handleFiltersSelection = (selectedFilters) => {
    applyFilters(selectedFilters);
  };

  useEffect(() => {
    setNavigationStatuses((prev) => ({
      ...prev,
      tcpCreate: 'completed',
      daily: 'completed',
      weekly:'active',
      completed: 'inactive',
    }));
  }, []);

  const navigate = useNavigate();
  const { clearanceId } = useParams();

  useEffect(() => {
    const checkMapStatus = async (abortSignal) => {
      try {
        setLoader(true);
        const response = await getProgressMapStatus(clearanceId, {}, abortSignal);
        setIsMapReady(response.data?.status === 'success');
        if (response.data?.status === 'created' || response.data?.status === 'in_progress') {
          toast.warning(
            'Map is not ready yet. You can create weekly report only after progress map is ready'
          );
        }
      } catch (error) {
        console.error('Failed to check map status:', error);
        setIsMapReady(false);
      } finally {
        setLoader(false);
      }
    };
    checkMapStatus();
  }, []);

  useEffect(() => {
    getTCPFormById(clearanceId).then((response) => {
      setValidationStatus(response?.data?.validation_status?.value);
    });
  }, []);

  return (
    <section>
      <div className="flex-column-group">
        <NavigationTabLinks mode="view" id={clearanceId} statuses={navigationStatuses} />
        <div className="lists-header">
          <div className="title">
            <div className="datePicker">
              <div className="flex-group flex-between" style={{ width: '220px' }}>
                <span>From</span>
                <DatePickerWrapper value={startDate} handleDatePicker={handleStartDateChange} />
              </div>
              <div className="flex-group flex-between" style={{ width: '200px' }}>
                <span>To</span>
                <DatePickerWrapper value={endDate} handleDatePicker={handleEndDateChange} />
              </div>
            </div>
            {/* <FilterForDailyAndWeeklyDialog
              onFiltersChange={handleFiltersSelection}
              resetPage={resetPage}
            /> */}
          </div>
          <div className="flex-right">
            <div className="buttons-group py-10">
              <ButtonComponent
                iconLeft={<AddSvg style={{ width: '20px', height: '20px' }} />}
                className="green"
                label="Create daily"
                handleButton={() => {
                  navigate(`/task-clearance/${clearanceId}/daily/create`);
                }}
                disabled={
                  !isMapReady ||
                  !user?.permissions?.create_daily_report ||
                  validationStatus !== 'validated'
                }
              />
              <CreatePopup
                isMapReady={isMapReady}
                validationStatus={validationStatus}
                user={user}
              />
            </div>
          </div>
        </div>
        <ClearanceWeekly
          currentPage={currentPage}
          clearanceWeeklyList={clearanceWeeklyList}
          setClearanceWeeklyList={setClearanceWeeklyList}
          setCurrentPage={setCurrentPage}
          setLoader={setLoader}
          searchTerm={searchTerm}
          statuses={[]}
          startDate={startDate}
          endDate={endDate}
          clearanceId={clearanceId}
        />
      </div>
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};
export default ClearanceWeeklyPage;
