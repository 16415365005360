import React from 'react';
import { TaskId, TaskIdView } from './TaskId';
import { TCPlanId, TCPlanIdView } from './TCPlanId';
import { StartDate, StartDateView } from './StartDate';
import { Duration, DurationView } from './Duration';
import { MethodType, MethodTypeView } from './MethodType';
import { Donor, DonorView } from './Donor';
import { Creator, CreatorView } from './Creator';

export const TCPBlock = ({
  methodOptions,
  tcpFormData,
  setTcpFormData,
  errors,
  showValidation
}) => {
  return (
  <div className="flex-column-group">
    <div className="flex-group gap-40">
      <TaskId
        tcpFormData={tcpFormData}
        setTcpFormData={setTcpFormData}
        errors={errors?.internal_id}
      />
      <TCPlanId
        tcpFormData={tcpFormData}
        setTcpFormData={setTcpFormData}
        errors={errors?.imsma_id}
      />
    </div>
    <div className="flex-group gap-40">
    <MethodType
        tcpFormData={tcpFormData}
        setTcpFormData={setTcpFormData}
        options={methodOptions}
        errors={errors?.methods}
        showValidation={showValidation}
      />
      <Donor
        tcpFormData={tcpFormData}
        setTcpFormData={setTcpFormData}
        errors={errors.donor}
        showValidation={showValidation}
      />
    </div>
    <div className="flex-group gap-40">
      <StartDate
        tcpFormData={tcpFormData}
        setTcpFormData={setTcpFormData}
      />
      <Creator
        tcpFormData={tcpFormData}
      />
    </div>
    <div className="flex-group gap-40">
      <Duration
        tcpFormData={tcpFormData}
        setTcpFormData={setTcpFormData}
        errors={errors.estimate_days}
      />
    </div>
  </div>
);
};

export const TCPBlockView = ({ tcpFormData }) => (
  <div className="flex-column-group">
    <div className="flex-group gap-40 column-350 align-left">
      <TaskIdView
        taskId={tcpFormData?.internal_id}
      />
      <MethodTypeView
        methodTypes={tcpFormData?.methods}
      />
    </div>
    <div className="flex-group gap-40 column-350 align-left">
      <TCPlanIdView
        imsmaId={tcpFormData?.imsma_id}
      />
      <DonorView
        donorId={tcpFormData?.donor}
      />
    </div>
    <div className="flex-group gap-40 column-350 align-left">
      <StartDateView
        startDate={tcpFormData?.start_date}
      />
      <CreatorView
        creatorId={tcpFormData?.user}
      />
    </div>
    <div className="flex-group gap-40 column-350 align-left">
      <DurationView
        duration={tcpFormData?.estimate_days}
      />
    </div>
  </div>
);
