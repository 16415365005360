import { AccordionBodyItem, AccordionBodyItemView } from 'component/Accordion';
import { SelectComponent } from 'component/Select';
import React from 'react';
import { SET_TEAM_ACTIVITY } from 'reducers/dailyFormActions';

const optionsTeamActivity = [{
  value: 'maintance_day',
  label: 'Maintance day/training',
},
{
  value: 'field_work',
  label: 'Field work',
}];

export const TeamActivity = ({ formData, dispatch, hasError }) => {
  const handleTeamActivity = (value) => {
    dispatch({
      type: SET_TEAM_ACTIVITY,
      payload: value
    });
  };

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>1.7</span>
        </div>
      )}
      titleUA="Діяльність команди"
      titleEN="Team activity"
      className={hasError ? 'error error-text' : ''}
      children={(
        <div className="flex-group no-wrap">
          <SelectComponent
            isMulti={false}
            value={optionsTeamActivity.find((option) =>
              option.value === formData?.info?.dms?.team_activity
            )}
            handleSelect={handleTeamActivity}
            options={optionsTeamActivity}
            placeholder="Select"
            isSearchable
          />
        </div>
      )}
    />
  );
};

export const TeamActivityView = ({ teamActivity }) => (
  <AccordionBodyItemView
    accordionSubId={1.7}
    titleUA="Діяльність команди"
    titleEN="Team activity"
    children={
      <div className="activity-container">
        {Array.isArray(teamActivity)
          ? teamActivity?.map((activity, index) => (
              <div key={index}>
                {optionsTeamActivity?.find((option) => option?.value === activity)?.label}
              </div>
            ))
          : <>
          {optionsTeamActivity?.find((option) => option?.value === teamActivity)?.label}
          </>
        }
      </div>
    }
  />
);