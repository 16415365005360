import { useState, useEffect, useReducer, useRef } from 'react';
import { toast } from 'react-toastify';
import { useUser } from 'routes/UserContext';
import { getDailyFormData } from 'api/DailyApi';
import moment from 'moment';
import { getTCPFormById } from 'api/TCPApi';
import { getHAById } from 'api/HaApi';
import useProgressMap from './useProgressMap';
import {
  SET_FORM_DATA, SET_GRID_GEOJSON, SET_HA_REPORT, SET_LOADER,
  SET_TCP_DATA, SET_TEAM_ACTIVITY, SET_USER_DATA, SET_ZONE
} from 'reducers/dailyFormActions';
import dailyFormReducer, { initialState } from 'reducers/dailyFormReducer';
import { EOFoundCategories } from 'consts/Daily';

export const useDailyFormManagement = (clearanceId, dailyId = null) => {
  const [state, dispatch] = useReducer(dailyFormReducer, initialState);
  const { user } = useUser();

  const [readyToLoadMap, setReadyToLoadMap] = useState(false);

  const [gridGeojson, mapLoader, setGridGeojson] = useProgressMap(clearanceId, dailyId, null, readyToLoadMap);

  const dailyDataFetched = useRef(false);
  const tcpDataFetched = useRef(false);
  const haDataFetched = useRef(false);
  const userDataSet = useRef(false);

  const checkAllDataLoaded = () => {
    if (
      userDataSet.current &&
      (dailyId ? dailyDataFetched.current : true) &&
      tcpDataFetched.current
    ) {
      setReadyToLoadMap(true);
    }
  };

  useEffect(() => {
    if (!user || userDataSet.current) return;
    dispatch({
      type: SET_USER_DATA,
      payload: {
        team_id: user.team_id,
        reporter_name: `${user.name} ${user.surname}`,
        reporter_position: user.reporter_position,
        clearance_task_id: clearanceId,
        report_date: moment().format('DD.MM.YYYY')
      }
    });
    userDataSet.current = true;
    dispatch({ type: SET_LOADER, payload: false });
    checkAllDataLoaded();
  }, [user, clearanceId]);

  useEffect(() => {
    if (!dailyId || !user || dailyDataFetched.current) return;
    dispatch({ type: SET_LOADER, payload: true });
    getDailyFormData(dailyId)
      .then((res) => {
        dispatch({
          type: SET_FORM_DATA,
          payload: res.data
        });
        dailyDataFetched.current = true;
      })
      .catch((err) => {
        console.error(err);
        toast.error('Failed to load daily form data');
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
        checkAllDataLoaded();
      });
  }, [dailyId, user]);

  useEffect(() => {
    if (!user || !clearanceId || (dailyId && !dailyDataFetched.current) || tcpDataFetched.current) return;
      getTCPFormById(clearanceId)
      .then((res) => {
        const teamActivity = state.formData?.info?.dms?.team_activity;
        if (teamActivity === 'field_work') {
          dispatch({ type: SET_LOADER, payload: false });
          dispatch({
            type: SET_TCP_DATA,
            payload: {
              donor: res.data.donor,
              clearance_task_imsma_id: res.data.imsma_id || res.data?.internal_id,
              preserveExisting: true
            }
          });
        } else {
          dispatch({
            type: SET_TCP_DATA,
            payload: {
              donor: res.data.donor
            }
          });
        }
        tcpDataFetched.current = true;
        if (res.data.ha_report && !haDataFetched.current) {
          return getHAById(res.data.ha_report);
        }
        return null;
      })
      .then((response) => {
        if (response) {
          dispatch({
            type: SET_HA_REPORT,
            payload: response.data
          });
          dispatch({
            type: SET_ZONE,
            payload: response.data?.file_zone
          });
          haDataFetched.current = true;
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error('Failed to load TCP/HA data');
      })
      .finally(() => {
        dispatch({ type: SET_LOADER, payload: false });
        checkAllDataLoaded();
      });
  }, [user, clearanceId, state.formData]);

  useEffect(() => {
    if (state.originalGridGeojson?.features?.length > 0) return;
    if (gridGeojson && gridGeojson?.features?.length > 0) {
      dispatch({
        type: SET_GRID_GEOJSON,
        payload: gridGeojson
      });
    }
  }, [gridGeojson, state.originalGridGeojson]);

  // useEffect(() => {
  //   if (!dailyId) {
  //     dailyDataFetched.current = true;
  //     checkAllDataLoaded();
  //   }
  // }, [dailyId]);

  const handleTeamActivity = (value) => {
    dispatch({
      type: SET_TEAM_ACTIVITY,
      payload: value,
      preserveFieldWork: true
    });
  };

  return {
    formData: state?.formData,
    coords3857: state.coords3857,
    zone: state.zone,
    loader: state.loader,
    gridGeojson,
    originalGridGeojson: state.originalGridGeojson,
    mapLoader,
    setGridGeojson,
    haReport: state.haReport,
    handleTeamActivity,
    dispatch
  }
}