import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Accordion, AccordionItem } from 'react-accessible-accordion';
// import { TCPNavigationStatuses } from 'consts/TCP';
import { useValidationComments } from 'hooks/useValidationComments';
import { Loader } from 'component/Loader';
import { REPORT_TYPES } from 'consts/ReportValidation';
import axios from 'axios';
// import { NavigationTabLinks } from '../../Tabs/NavigationLinks';
import { DMSBlockView } from 'pages/TaskClearance/Daily/Blocks/DMSBlock/DMSBlock';
import { FieldWorkBlockView } from 'pages/TaskClearance/Daily/Blocks/FieldWorkBlock/FieldWorkBlock';
import useProgressMap from 'hooks/useProgressMap';

export const WeeklyViewPage = () => {
  const { clearanceId, weeklyId } = useParams();
  const [formData, setFormData] = useState(null);
  const [loader, setLoader] = useState(false);
  const validationComments = useValidationComments(REPORT_TYPES.WEEKLY, weeklyId);
  const [gridGeojson, mapLoader, setGridGeojson] = useProgressMap(
    clearanceId,
    null,
    weeklyId,
    !!formData
  );

  useEffect(() => {
    document.title = 'View weekly';
  }, []);

  useEffect(() => {
    const getWeeklyFormData = async () => {
      setLoader(true);
      try {
        const response = await axios.get(`/reports/clearance/weekly/${weeklyId}/`);
        setFormData(response.data);
      } catch (error) {
        console.error('Failed to fetch weekly data:', error);
      } finally {
        setLoader(false);
      }
    };

    if (weeklyId) {
      getWeeklyFormData();
    }
  }, [weeklyId]);

  return (
    <section>
      <div className="flex-column-group">
        {/* <NavigationTabLinks
          statuses={navigationStatuses}
          mode="view"
          id={clearanceId}
        /> */}
        <WeeklyViewForm
          formData={formData}
          commentsData={validationComments}
          mapLoader={mapLoader}
          gridGeojson={gridGeojson}
          setGridGeojson={setGridGeojson}
        />
      </div>
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};

const WeeklyViewForm = ({ formData, commentsData, mapLoader, gridGeojson, setGridGeojson }) => (
  <div className="daily-form">
    <div className="form-container flex-column-group">
      <WeeklyFormHeader />
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        preExpanded={['dms', 'fieldWork', 'marking', 'battle-area-clearance', 'eoFound']}
        className="custom-accordion"
      >
        <AccordionItem uuid="dms">
          <DMSBlockView
            formData={formData}
            // dispatch={dispatch}
            commentsData={commentsData}
            mode="view"
          />
        </AccordionItem>
        {(formData?.info?.dms?.team_activity === 'field_work' ||
          formData?.info?.dms?.team_activity?.includes('field_work')) && (
          <AccordionItem uuid="fieldWork">
            <FieldWorkBlockView
              formData={formData}
              // dispatch={dispatch}
              setGridGeojson={setGridGeojson}
              // coords3857={coords3857}
              // zone={zone}
              gridGeojson={gridGeojson}
              loader={mapLoader}
              mode="view"
              commentsData={commentsData}
            />
          </AccordionItem>
        )}
      </Accordion>
    </div>
  </div>
);

const WeeklyFormHeader = () => (
  <div className="form-header">
    <span>View a weekly</span>
    <div className="line" />
  </div>
);

export default WeeklyViewPage;
