import { Table, TableHeader, TableRow } from 'component/Table';
import React, { useEffect, useRef, useState } from 'react';
import { formatArea, formatEOFound, formatPeriod, ValidationStatusComponent } from 'utils/Helper';
import { ButtonComponent } from 'component/Button';
import { ReactComponent as ArrowBackSvg } from 'assets/images/icons/arrow_back_24px.svg';
import { ReactComponent as ArrowNextSvg } from 'assets/images/icons/Right.svg';
import { ReactComponent as SortByIcon } from 'assets/images/icons/Sort-by2.svg';
import { toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';
import { ReportActions } from '../Lists/ReportActions';
import { useNavigate } from 'react-router-dom';
import { WEEKLY_HEADERS } from 'consts/ReportHeaders';

 export const ClearanceWeeklyTable = ({
  clearanceWeeklyList,
  setLoader,
  setClearanceWeeklyList,
  setCurrentPage,
  fetchClearanceWeeklyList,
  statuses,
  mode,
  clearanceId,
}) => {

  const navigate = useNavigate();


  return (
    <div className="eore-table">
    <Table>
      <TableHeader>
      {WEEKLY_HEADERS?.map((header, index) => (
  <th
    key={index}
    className={`text-with-icon ${header.style ? '' : ''}`}
    style={header.style || {}}
  >
    {header.label}
  </th>
))}
      </TableHeader>
      <tbody>
        {clearanceWeeklyList?.results?.length === 0
          && (
            <TableRow>
              <td>Дані відсутні</td>
            </TableRow>
          )}
        {clearanceWeeklyList?.results?.map((item) => (
          <TableRow key={item?.id}>
                <td>{item?.created_at ? new Date(item?.created_at).toLocaleDateString() : '-'}</td>
                <td>{item?.internal_id || '-'}</td>
                <td>{formatPeriod(item?.period)}</td>
                <td>{item?.type || '-'}</td>
                {/* <td>10.01.2025 <br /> 18.01.2025</td> */}
                <td>
                {formatArea(item?.area, item?.cleared_area)}
                </td>
            <td style={{ textAlign: 'start'}}>{formatEOFound(item?.eo_found)}</td>
            <td>
              {item?.validation_status?.value
                && (<ValidationStatusComponent status={item?.validation_status} />)}
            </td>
            <td aria-label="actions">
              <ReportActions
                reportId={item?.id}
                setLoader={setLoader}
                setReportsPageList={setClearanceWeeklyList}
                validationStatus={item?.validation_status?.value}
                isCurrentValidationRole={item?.is_current_validation_role}
                mode={mode}
                clearanceId={clearanceId}
                />
            </td>
          </TableRow>
        ))}
        {clearanceWeeklyList?.next && (
          <div className="loadMore-div">
            <button
              className="loadMore-button"
              onClick={() => {
                setCurrentPage((prevPage) => {
                  const newPage = prevPage + 1;
                  fetchClearanceWeeklyList(newPage, statuses);
                  return newPage;
                });
              }}
              >
              Load more...
            </button>
          </div>
        )}
        <div className="drone-footer buttons-group py-10">
          <ButtonComponent
            iconLeft={<ArrowBackSvg style={{ width: '20px', height: '20px' }} />}
            className="warning"
            label="Previous"
            handleButton={() => { navigate(-1)}}
            />
          <ButtonComponent
            iconRight={<ArrowNextSvg style={{ width: '14px', height: '14px' }} />}
            label="Complete"
            handleButton={() => {navigate(`/task-clearance/${clearanceId}/completed`)}}
            />
        </div>
      </tbody>
    </Table>
  </div>

);

};

const ClearanceWeekly = ({
  setLoader,
  clearanceWeeklyList,
  setClearanceWeeklyList,
  searchTerm,
  statuses,
  setCurrentPage,
  currentPage,
  startDate,
  endDate,
  clearanceId,
}) => {
  const fetchClearanceWeeklyList = async (page, filters) => {
    setLoader(true);
    try {
      const baseUrl = `/reports/clearance/weekly/${clearanceId}/clearance-task/`;
      const params = new URLSearchParams();
      params.append('page', page);

      if (filters.ordering) {
        params.append('ordering', filters.ordering);
      }

      if (startDate) {
        const dateFrom = moment(startDate).format('DD.MM.YYYY');
        params.append('date_from', dateFrom);
      }

      if (endDate) {
        const dateTo = moment(endDate).format('DD.MM.YYYY');
        params.append('date_to', dateTo);
      }



      if (searchTerm) {
        params.append('imsma_id', searchTerm);
      }

      const url = `${baseUrl}?${params.toString()}`;
      const response = await axios.get(url);

      setClearanceWeeklyList((prevList) => (page === 1
        ? response.data
        : {
          ...response.data,
          results: prevList
            ? [...prevList.results, ...response.data.results]
            : response.data.results,
        }));
    } catch (error) {
      toast.error('Error loading clearance weekly list');
    } finally {
      setLoader(false);
    }
  };

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchClearanceWeeklyList(1, statuses);
      return;
    }

    const timer = setTimeout(() => {
      setCurrentPage(1);
      fetchClearanceWeeklyList(1, statuses);
    }, 300);

    return () => clearTimeout(timer);
  }, [searchTerm, startDate, endDate]);

  return (
    <ClearanceWeeklyTable
    clearanceWeeklyList={clearanceWeeklyList}
    setCurrentPage={setCurrentPage}
    currentPage={currentPage}
    fetchClearanceWeeklyList={fetchClearanceWeeklyList}
    statuses={statuses}
    setLoader={setLoader}
    setClearanceWeeklyList={setClearanceWeeklyList}
    mode={'weekly'}
    clearanceId={clearanceId}
    />
  );
};

export default ClearanceWeekly;
