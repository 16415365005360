import { getHAById } from 'api/HaApi';
import { getTCPFormById } from 'api/TCPApi';
import { TCPNavigationStatuses } from 'consts/TCP';
import { tcpForm } from 'consts/TCPForm';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from 'component/Loader';
import { toast, ToastContainer } from 'react-toastify';
import { ButtonComponent } from 'component/Button';
import { useValidationComments } from 'hooks/useValidationComments';
import { handleReportValidation } from 'component/CommentBlock';
import { REPORT_TYPES } from 'consts/ReportValidation';
import { TaskClearancePlanFormView } from '../View/TaskClearancePlan';
import { NavigationTabLinks } from '../Tabs/NavigationLinks';

export const TaskClearancePlanPageValidation = () => {
  const [formData, setFormData] = useState(null);
  const [tcpFormData, setTcpFormData] = useState(tcpForm);
  const [navigationStatuses, setNavigationStatuses] = useState(TCPNavigationStatuses);
  const [loader, setLoader] = useState(true);
  const { clearanceId } = useParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const commentsData = useValidationComments(REPORT_TYPES.TASK_CLEARANCE, clearanceId);

  const {
    savedComment,
    resetCommentForm,
  } = commentsData;

  useEffect(() => {
    setNavigationStatuses((prev) => ({
      ...prev,
      tcpCreate: 'active',
      daily: 'inactive',
      weekly: 'inactive',
      completed: 'inactive',
      done: 'inactive',
    }));
  }, []);

  useEffect(() => {
    if (!clearanceId) return;
    const fetchData = async () => {
      try {
        const tcpResponse = await getTCPFormById(clearanceId);
        setTcpFormData(tcpResponse.data);

        const haResponse = await getHAById(tcpResponse.data.ha_report);
        setFormData(haResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Error loading form data');
      } finally {
        setLoader(false);
      }
    };

    fetchData();
  }, [clearanceId]);

  const handleValidation = (status, event) => handleReportValidation(
    status,
    event,
    `/validations/clearance-task/${clearanceId}/`,
    '/task-clearance/list',
    setLoader,
    savedComment,
    resetCommentForm,
    navigate,
  );

  return (
    <section>
      <div className="flex-column-group">
        <NavigationTabLinks
          mode="view"
          id={tcpFormData.id}
          statuses={navigationStatuses}
        />
        <TaskClearancePlanFormView
          formData={formData}
          setFormData={setFormData}
          tcpFormData={tcpFormData}
          setTcpFormData={setTcpFormData}
          setLoader={setLoader}
          mode="validation"
          commentsData={commentsData}
        />
      </div>
      <div className="form-buttons-end buttons-group py-10">
        <ButtonComponent
          handleButton={(event) => handleValidation('rejected', event)}
          label="Reject"
          disabled={isSubmitting}
          className="danger"
          type="button"
        />
        <ButtonComponent
          handleButton={(event) => handleValidation('accepted', event)}
          label="Accept"
          disabled={isSubmitting}
          type="button"
        />
      </div>
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};
