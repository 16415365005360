import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as PlusSvg } from 'assets/images/icons/add_24px.svg';
import { AccordionBodyItemView2 } from 'component/Accordion';
import { FileItem, FileItemView } from '../../Marking/Files';
import { ADD_HANDOVER_FILE, REMOVE_HANDOVER_FILE, UPDATE_HANDOVER_FILE } from 'reducers/dailyFormActions';

export const HandOverFiles = ({ formData, dispatch }) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const existingFiles = formData?.info?.hand_over_block?.files || [];
    if (JSON.stringify(files) !== JSON.stringify(existingFiles)) {
      setFiles(existingFiles);
    }
  }, [formData?.info?.hand_over_block?.files]);

  const addFile = () => {
    dispatch({
      type: ADD_HANDOVER_FILE
    });
  };

  const removeFile = (index) => {
    dispatch({
      type: REMOVE_HANDOVER_FILE,
      payload: index
    });
  };

  const handleFileInput = (value, key, index) => {
    dispatch({
      type: UPDATE_HANDOVER_FILE,
      payload: {
        index,
        field: key,
        value
      }
    });
  };
  return (
    <div className="flex-column-group">
      <div className="flex-between">
        <div className="double-text">
          <span>Файли</span>
          <span>Files</span>
        </div>
        <span className="line" />
        <div className="plus-table">
          <PlusSvg onClick={addFile} />
        </div>
      </div>
      {files?.map((file, i) => (
        <FileItem
          key={i}
          index={i}
          removeFile={removeFile}
          file={file}
          handleFileInput={(value, key) => handleFileInput(value, key, i)}
        />
      ))}
    </div>
  );
};

export const HandOverFilesView = ({ handOverBlock }) => (
  <AccordionBodyItemView2
    titleUA="Файли"
    titleEN="Files"
  >
    {handOverBlock?.files && handOverBlock?.files?.length > 0 ? (
      <div className="flex-column-group file-links">
        {handOverBlock?.files?.map((file, i) => (
          <FileItemView
            key={i}
            file={file}
          />
        ))}
      </div>
    ) : (
      <div>-</div>
    )}
  </AccordionBodyItemView2>
);