import React, { useEffect, useState } from 'react';
import { ReactComponent as PlusSvg } from 'assets/images/icons/add_24px.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/icons/delete_24px.svg';
import { InputComponent } from 'component/Input';
import { ADD_MARKING_FILE, DELETE_MARKING_FILE, UPDATE_MARKING_FILES } from 'reducers/dailyFormActions';

export const MarkingFiles = ({ formData, dispatch }) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFiles(formData?.info?.marking?.files || []);
  }, [formData?.info?.marking?.files]);

  const removeFile = (index) => {
    dispatch({
      type: DELETE_MARKING_FILE,
      payload: index
    });
  };

  const addFile = () => {
    dispatch({
      type: ADD_MARKING_FILE,
      payload: {
        name: '',
        link: ''
      }
    });
  };

  const handleFileInput = (value, key, index) => {
    dispatch({
      type: UPDATE_MARKING_FILES,
      payload: {
        index,
        field: key,
        value
      }
    });
  };

  return (
    <div className="flex-column-group">
      <div className="flex-between">
        <div className="double-text">
          <span>Файли</span>
          <span>Files</span>
        </div>
        <span className="line" />
        <div className="plus-table">
          <PlusSvg
            onClick={addFile}
          />
        </div>
      </div>
      {files?.map((file, i) => (
        <FileItem
          key={i}
          index={i}
          removeFile={removeFile}
          file={file}
          handleFileInput={(value, key) => handleFileInput(value, key, i)}
        />
      ))}
    </div>
  );
};

export const FileItem = ({
  index, removeFile, file, handleFileInput,
}) => (
  <div className="flex-group">
    <InputComponent
      label="Name"
      id={`file-name-${index}`}
      placeholder="Write here"
      value={file.name}
      handleInput={(value) => handleFileInput(value, 'name', index)}
    />
    <InputComponent
      label="Link"
      id={`file-${index}`}
      placeholder="Write here"
      value={file.link}
      handleInput={(value) => handleFileInput(value, 'link', index)}
    />
    <DeleteIcon
      style={{
        cursor: 'pointer',
        height:'22px',
        marginTop: '10px'
        }}
      onClick={() => removeFile(index)}
    />
  </div>
);

export const FileItemView = ({ file }) => (
  <div className="flex-group one-link">
    <div className="file-name">
      <span>Name:</span>
      <span>{file.name}</span>
    </div>
    <div className="file-name">
      <span>Link:</span>
      <span>
        <a href={file.link} target="_blank" rel="noreferrer">{file.link}</a>
      </span>
    </div>
  </div>
);

export const MarkingFilesView = ({ files }) => (
  <div className="flex-column-group">
    <div className="flex-between gap-40">
      <div className="double-text">
        <span>Файли</span>
        <span>Files</span>
      </div>
    </div>
    <div className="file-links flex-column-group">
      {files?.map((file, i) => (
        <FileItemView
          key={i}
          file={file}
        />
      ))}
    </div>
  </div>
);
