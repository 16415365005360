import { TCPNavigationStatuses } from 'consts/TCP';
import { NavigationTabLinks } from 'pages/TaskClearance/Tabs/NavigationLinks';
import { Accordion, AccordionItem } from 'react-accessible-accordion';
import { React, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Loader } from 'component/Loader';
import { ButtonComponent } from 'component/Button';
import { CommentBlock, handleReportValidation } from 'component/CommentBlock';
import { useValidationComments } from 'hooks/useValidationComments';
import { REPORT_TYPES } from 'consts/ReportValidation';
import { useDailyFormManagement } from 'hooks/useDailyFormManagement';
import { FieldWorkBlockView } from '../Blocks/FieldWorkBlock/FieldWorkBlock';
import { DMSBlockView } from '../Blocks/DMSBlock/DMSBlock';

export const DailyValidatePage = () => {
  const { clearanceId, dailyId } = useParams();
  const [navigationStatuses, setNavigationStatuses] = useState(TCPNavigationStatuses);
  const [map,setMap] = useState(null);

  const {
    formData,
    setFormData,
    loader,
    setLoader,
    gridGeojson,
    setGridGeojson,
    mapLoader,
    coords3857,
    setCoords3857,
    zone,
    setZone,
    dispatch,
  } = useDailyFormManagement(clearanceId, dailyId);

  useEffect(() => {
    setNavigationStatuses((prev) => ({
      ...prev,
      tcpCreate: 'completed',
      daily: 'active',
      weekly: 'inactive',
      completed: 'inactive',
      done: 'inactive',
    }));
    document.title = `Validation daily ${dailyId}`;
  }, []);

  return (
    <section>
      <div className="flex-column-group">
        <NavigationTabLinks
          statuses={navigationStatuses}
          mode="view"
          id={clearanceId}
        />
        <DailyValidateForm
          formData={formData}
          setFormData={setFormData}
          coords3857={coords3857}
          setCoords3857={setCoords3857}
          zone={zone}
          setZone={setZone}
          gridGeojson={gridGeojson}
          setGridGeojson={setGridGeojson}
          mapLoader={mapLoader}
          setLoader={setLoader}
          dispatch={dispatch}
          map={map}

          // haReportPolygon={haReportPolygon}
        />
      </div>
      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};

export const DailyValidateForm = ({
  formData,
  setFormData,
  coords3857,
  setCoords3857,
  zone,
  setZone,
  gridGeojson,
  setGridGeojson,
  mapLoader,
  setLoader,
  haReportPolygon,
  dispatch,
  map,
}) => {
  const { dailyId } = useParams();
  const commentsData = useValidationComments(REPORT_TYPES.DAILY, dailyId);
  const {
    comments,
    commentForm,
    savedComment,
    commentEntered,
    onSaveComment,
    onDeleteComment,
    handleComments,
    resetCommentForm,
  } = commentsData;

  const navigate = useNavigate();

  const handleValidation = (status, event) => {
    handleReportValidation(
      status,
      event,
      `/validations/daily-reports/${dailyId}/`,
      '/task-clearance/list',
      setLoader,
      savedComment,
      resetCommentForm,
      navigate,
      dispatch,
    );
  };
  return (
    <div className="daily-form">
      <div className="form-container flex-column-group">
        <DailyFormHeader />
        <Accordion
          allowMultipleExpanded
          allowZeroExpanded
          preExpanded={[
            'dms', 'fieldWork', 'marking', 'battle-area-clearance', 'eoFound']}
          className="custom-accordion"
        >
          <AccordionItem uuid="dms">
            <DMSBlockView
              formData={formData}
              setFormData={setFormData}
            />
            <CommentBlock
              comments={comments}
              savedComment={savedComment}
              commentForm={commentForm}
              commentEntered={commentEntered}
              block="dms"
              onDeleteComment={onDeleteComment}
              handleComments={handleComments}
              onSaveComment={onSaveComment}
            />

          </AccordionItem>
          {formData?.info?.dms?.team_activity === 'field_work' && (
            <AccordionItem uuid="fieldWork">
              <FieldWorkBlockView
                formData={formData}
                setFormData={setFormData}
                coords3857={coords3857}
                setCoords3857={setCoords3857}
                zone={zone}
                setZone={setZone}
                gridGeojson={gridGeojson}
                setGridGeojson={setGridGeojson}
                loader={mapLoader}
                mode="validation"
                commentsData={commentsData}
                haReportPolygon={haReportPolygon}
                map={map}
              />
              <CommentBlock
                comments={comments}
                savedComment={savedComment}
                commentForm={commentForm}
                commentEntered={commentEntered}
                block="field_work"
                onDeleteComment={onDeleteComment}
                handleComments={handleComments}
                onSaveComment={onSaveComment}
              />
            </AccordionItem>
          )}
          <div className="form-buttons-end buttons-group py-10">
            <ButtonComponent
              handleButton={(event) => handleValidation('rejected', event)}
              label="Reject"
              disabled={false}
              className="danger"
              type="button"
            />
            <ButtonComponent
              handleButton={(event) => handleValidation('accepted', event)}
              label="Accept"
              disabled={false}
              type="button"
            />
          </div>
        </Accordion>
      </div>
    </div>
  );
};

const DailyFormHeader = () => (
  <div className="form-header">
    <span>Validate a daily</span>
    <div className="line" />
  </div>
);
